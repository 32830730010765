.container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 50%; */
}
.content h3 {
  font-size: 2.25em;
  font-style: normal;
  font-weight: 700;
}
.content p {
  font-size: 1.375rem;
  font-weight: 400;
  margin-top: 1.375rem;
}
.content div {
  margin-top: 5rem;
}
.applyBtn {
  background: var(--main-color);
  border: 3px solid var(--main-color);
  box-sizing: border-box;
  border-radius: 37.44px;
  width: 16.875rem;
  height: 4rem;
  color: var(--default);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: var(--cursor-pointer);
  margin-top: auto;
  margin-bottom: 2.4rem;
  transition: 0.3s ease-in-out;
}
.applyBtn:hover {
  background: var(--default);
  color: var(--main-color);
}
.imgContainer {
  display: flex;
  justify-content: flex-end;
}
.imgContainer img {
  margin-right: 5%;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .lineBreak {
    display: none;
  }
  .applyBtn {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .imgContainer {
    justify-content: center;
  }
  .imgContainer img {
    margin: 0;
  }
  .content div {
    margin-top: 3rem;
  }
  .applyBtn {
    margin: 2rem auto 0;
  }
  .lineBreak {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .content h3 {
    font-size: calc(1rem + 2vmin);
  }
  .content p {
    font-size: 1rem;
  }
  .applyBtn {
    font-size: 1rem;
    width: 12.875rem;
    height: 3.5rem;
  }
}
