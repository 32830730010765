.container {
  width: 100%;
  padding: 2em 1em;
  background: var(--default);
  animation: fade-in 650ms forwards;
}

@keyframes fade-in {
  from {
    transform: scale(0.9) translateY(-50px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background: var(--default);
  padding: 2em 0em;
  border-radius: 0.5em;
  box-shadow: var(--shadow-light);
}
.content {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
}
.heading {
  margin-bottom: 2em;
  padding: 0 4rem;
}

.wallet {
  background: rgb(236, 236, 236);
  height: 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: all 300ms;
}
.option {
  width: 100%;
  margin-bottom: 2em;
}

.option h3 {
  margin-bottom: 0.5em;
}

.option p {
  margin-bottom: 0.25em;
  font-size: 0.85rem;
}

.warn {
  color: rgb(255, 38, 0) !important;
  display: none;
}

.warn.active {
  display: block;
  margin: 0.5em 0;
}

.images {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-bottom: 3rem;
}

.uploadIcon {
  top: 40%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.uploadIcon input {
  display: none;
}

.uploadIcon img {
  width: 68px !important;
  height: 68px !important;
  cursor: var(--cursor-pointer);
}
.uploadIcon img:hover {
  cursor: var(--cursor-hover);
  opacity: 0.5;
  transition: 0.4s ease-in-out;
}

.profile {
  width: 150px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 150px;
}
.profile img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.banner {
  width: 100%;
  height: 18.6em;
  display: flex;
}
.banner img {
  width: 100%;

  height: 18.6em;
}

.option label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 0.5em;
}

.option label div {
  font-weight: bold;
}

.option input {
  width: 100%;
  font-size: 1rem;
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--outline);
  background: rgb(236, 236, 236);
  transition: all 300ms;
  outline: 2px solid transparent;
}

.option input:focus {
  background: var(--default);
  outline: 1px solid var(--main-color);
}

.option.invalid input {
  outline: 1px solid red;
}

.social {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5em;
  margin-top: 2em;
}

.buttons {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1em;
}

.submit,
.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.5em;
  height: 3.2em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
}

.submit {
  background: var(--main-color);
  border: 2px solid transparent;
  color: white;
}

.submit:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
}

.cancel {
  border: 2px solid var(--main-color);
}

.cancel:hover {
  background: var(--main-lightest);
}

.toggleButton {
  background: var(--default);
  display: inline-block;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  padding: 2px;
  border: 1px solid transparent;
  transition: all 300ms;
}

.toggleButton.active {
  background: var(--main-color);
  border: 1px solid transparent;
}

.toggleButton.active .toggle {
  transform: translateX(23px);
  background: var(--default);
}

.toggleButton .toggle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 300ms;
  background: var(--default);
}

@media screen and (max-width: 1000px) {
  .profile {
    top: 55%;
  }
  .profile img {
    width: 130px;
    height: 130px;
  }
  .wrapper {
    padding: 2em 0;
    max-width: 768px;
  }
  .content {
    padding: 0 2rem;
  }
  .heading {
    padding: 0 2rem;
  }
  .banner {
    height: 14em;
  }
  .banner img {
    height: 12em;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 480px;
  }

  .social {
    gap: 1em;
  }

  .images {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    align-items: center;
    text-align: center;
  }

  .profile {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 30px;
  }
  .profile img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .banner {
    height: 7em;
  }
  .banner img {
    height: 7em;
  }
}

@media screen and (max-width: 480px) {
  .submit,
  .cancel {
    border-radius: 0.5em;
    font-size: 0.85rem;
    transition: all 300ms;
  }
  .banner img {
    height: 7em;
  }
  .content {
    padding: 0 1em;
  }
}

.copyIcon {
  width: 0.5em;
}

.copyIcon:hover {
  transform: scale(0.8);
  cursor: var(--cursor-pointer);
}

.copyIcon.active {
  transform: scale(0.8);
  border: 1px solid var(--main-color);
}

.text {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--outline);
  border-radius: 5px;
  background: rgb(236, 236, 236);
}

.text input {
  border: none;
  font-size: 13px;
}

.text img {
  width: 30px;
  height: 30px;
  /* border-radius: 50%; */
  /* padding: 3px; */
  margin-top: 0.3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
