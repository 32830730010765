.wrapper {
  width: 100%;
  padding: 0 1em;
  padding-top: 2em;
}

.container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2em;
  margin: 0 auto;
  margin-bottom: 4em;
  min-height: 100vh;
}

.sidebar {
  min-width: 20em;
  position: sticky;
  top: 9em;
  border: 1px solid #c4c4c4;
  border-radius: 0.5em;
}

.display {
  width: auto;
}

.collectionDetail {
  width: 100%;
  padding: 1em 1.6em;
}

.collectionDetail .wrapper {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.25em;
  margin-bottom: 2em;
  border: 1px solid var(--card-outline);
}

.collectionDetail img {
  filter: invert(1);
}

.collectionDetail textarea {
  resize: none;
  width: 100%;
  border-radius: 0.25em;
  border: 1px solid var(--card-outline);
  padding: 0.6rem 0rem 0.3rem 0.8rem;
}

.collectionDetail textarea:focus {
  outline: 1px solid var(--outline);
}

.collectionDetail .tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.collectionDetail .toggleContainer {
  width: 36px;
  border-radius: 0.75em;
  padding: 0px 0;
  background: var(--default);
  cursor: var(--cursor-pointer);
  padding: 2px 2px;
  transition: all 300ms;
  border: 1px solid var(--card-outline);
}

.collectionDetail .toggleContainer.active {
  background: var(--main-color);
  border: 1px solid var(--outline);
}

.collectionDetail .toggle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 300ms;
  background: gray;
}

.collectionDetail .toggle.active {
  transform: translateX(100%);
  background: var(--default);
}

.actionContainer {
  padding: 1em 0.8em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1em;
}

.ipfs_tip {
  text-align: center;
}

.actionContainer h3 {
  margin-top: -20px;
  background: var(--default);
  width: fit-content;
  padding-right: 6px;
  margin-left: -5px;
  padding-left: 5px;
}

.actionContainer label {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.75em;
  border-radius: 0.25em;
  background: var(--card-layer);
  color: var(--default);
}

.actionContainer .btnWrapper > button {
  border: 1px solid var(--main-color);
  width: 100%;
  border: none;
  height: 3em;
  font-size: 1rem;
  border-radius: 0.25em;
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.actionContainer .btnWrapper > button:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}

.preview {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
}

.card {
  min-width: 12em;
  max-width: 20em;
  padding: 0.5rem;
  position: relative;
}

.cardActive {
  background: rgba(0, 153, 135, 0.3);
}

.iconClose {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background: var(--default);
  border-radius: 0.15em;
  transition: all 300ms;
}

.iconClose img {
  width: 0.7em;
  margin: 0;
}

.asset {
  display: inline-block;
  width: 100%;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  border: 1px solid var(--outline);
}

.cardBody {
  background: var(--card-layer);
  color: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.cardBody .textWrapper {
  width: 100%;
  color: var(--text-dark-900);
}

.cardBody .textWrapper {
  color: var(--default);
}

.cardBody textarea {
  width: 100%;
  background: var(--card-shade);
  color: var(--default);
  padding: 0.25em;
  resize: none;
  border-radius: 0.25em;
  outline: none;
}

.cardBody textarea::placeholder {
  color: var(--text-placeholder);
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.buttonContainer button {
  flex: 1;
  padding: 0.5em 0em;
  white-space: nowrap;
  border-radius: 0.25em;
  border: 1px solid;
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 150ms;
  outline: 1px solid transparent;
  font-weight: 600;
  font-size: 0.6em;
}

.buttonContainer button:last-child {
  border-color: var(--default);
}

.buttonContainer button:first-child,
.nftDonwload {
  background: var(--default);
  border-color: var(--default);
  color: var(--main-color);
}

.buttonContainer button:last-child:hover {
  background: var(--card-layer);
  outline: 2px solid var(--card-layer);
  border-color: transparent;
}

.buttonContainer button:first-child:hover,
.nftDonwload {
  background: var(--main-hovered);
  border-color: var(--main-hovered);
  color: var(--default);
}

.foramtWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  border: 1px solid #c4c4c4;
  padding: 0.6rem 0.8rem;
  padding-bottom: 1.3rem;
}

.foramtWrapper p {
  color: var(--default);
}

.radioBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
}

.radioBtn::before {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--card-layer);
  position: absolute;
  content: "";
  outline: 2px solid var(--default);
  outline-offset: 2px;
}

.radioBtn.clicked::before {
  background: var(--default);
}

.paginate {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 4em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  padding: 0.25em;
}

.paginate input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.25em;
}

.pageControl {
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--card-layer);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.pageControl:hover {
  outline: 2px solid gray;
}

.pageCount {
  font-weight: bold;
  white-space: nowrap;
}

/* -------------------------------- GIF Genrator -------------------------------- */
.btnWrapper {
  padding: 0 0.8rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  gap: 0.5rem;
}

.btnWrapper > button.gifButton {
  margin-top: 2em;
  background: var(--default);
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: 0.3s ease-in;
}

.btnWrapper > button.gifButton:hover {
  background: var(--main-color);
  color: var(--default);
  outline: none;
}

.gifButton span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.75em;
}

.cheked {
  background: var(--main-color);
}

.playTut {
  background: rgba(152, 162, 179, 0.32);
  border-radius: 4px;
  /* margin-left: 1em; */
  padding: 0.7em;
  display: flex;
  cursor: var(--cursor-pointer);
}

.playTut svg {
  margin: auto;
  margin-right: 6px;
}

.playTut svg {
  transition: 0.2s ease-in;
}

.playTut:hover svg {
  transform: translate(4px, 0px);
}

.mintButtonWrapper {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10%;
  gap: 10%;
  flex-wrap: wrap;
  margin: 1.5em 0;
  grid-template-columns: 45% 45%;
}

.mintButtonWrapper button {
  flex: 1 1;
  height: 2.8em;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mintBtn {
  background: var(--main-color);
  border: 2px solid var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
}

.mintBtn svg {
  margin-right: 8px;
}

.mintBtn:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
  cursor: var(--cursor-pointer);
}

.cancelBtn {
  border: 2px solid var(--main-color);
  background: var(--default);
  transition: all 300ms;
  font-size: 1rem;
}

.cancelBtn:hover {
  border: 2px solid var(--main-hovered);
}

.durationWrapper {
  padding: 0 0.5rem;
  width: 100%;
}

.durationField {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.durationLabel {
  display: flex;
  width: 100%;
  margin-bottom: 0.8em;
  font-size: 0.7em;
  justify-content: space-between;
}

.durationLabel p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
}

.durationLabel img {
  width: 30px;
  margin-left: 5px;
}

.durationField div span {
  position: absolute;
  right: 1em;
  margin: auto 0;
  font-size: 1.1em;
  display: block;
  top: 50%;
  transform: translate(-50%, -50%);
}

.durationInput {
  display: flex;
  width: 100%;
  position: relative;
}

.durationInput input {
  height: 3em;
  padding-left: 1em;
  border-radius: 5px;
  border: 1px solid #adb5bd;
  background: var(--default);
  transition: all 300ms;
  font-size: 1rem;
  width: 100%;
}

.durationInput input:focus {
  border: 2px solid var(--main-color);
  outline: none;
}

.durationInput input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-placeholder);
  opacity: 1;
  /* Firefox */
}

/* Chrome, Safari, Edge, Opera */
.durationInput input::-webkit-outer-spin-button,
.durationInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.durationInput input[type="number"] {
  -moz-appearance: textfield;
}

.numberCounter {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  width: 75px;
  justify-content: space-evenly;
}

.numberCounter p {
  color: #adb5bd;
  display: flex;
  align-items: center;
  width: 50%;
}

.verticalLine {
  width: 1px;
  background: #c4c4c4;
  height: 34px;
  margin: auto 0;
}

.inputArrows {
  display: flex;
  flex-direction: column;
  height: 65%;
  margin: auto 0;
  justify-content: space-around;
  width: 50%;
  align-items: center;
}

.inputArrows img {
  cursor: var(--cursor-pointer);
}

/* -------------------------------- GIF Model -------------------------------- */
.modelContainer {
  display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.modelActive {
  display: flex;
}

.guideContainer {
  width: 100%;
  max-width: 48em;
  border-radius: 1em;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.guideContainer > svg {
  margin-left: auto;
  margin-bottom: 0.7em;
  cursor: var(--cursor-pointer);
  width: 25px;
  height: 25px;
}

.guideContainer > svg > path {
  fill: var(--icon-color);
}

.imgContainer {
  border: 1px solid var(--card-outline);
  display: flex;
  padding: 1em 1.5em 1.5em;
  flex-direction: column;
}

.modelPreview {
  max-height: 24em;
  overflow-y: scroll;
  grid-template-columns: repeat(3, 1fr);
}

.closeIcon path {
  fill: #f1423b;
}

.gifAllBtn {
  display: flex;
  margin-top: 1.4em;
  justify-content: flex-end;
}

.gifAllBtn p,
.gifAllBtn div {
  border-radius: 40px;
  width: 13em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 0.8em;
  cursor: var(--cursor-pointer);
}

.gifAllBtn div {
  background: var(--main-color);
  color: var(--default);
  border: 1px solid var(--main-color);
  margin-left: 20px;
}

.gifAllBtn div:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
}

.gifAllBtn p {
  color: #f1423b;
  border: 1px solid #f1423b;
}

.gifAllBtn p:hover {
  background: #f1423b;
  color: #fff;
  border: 2px solid #f1423b;
}

.sidebarActive {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 9em);
}

@media screen and (max-width: 1280px) {
  .preview {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1060px) {
  .durationLabel {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .playTut {
    margin-bottom: 10px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .preview {
    grid-template-columns: repeat(2, 1fr);
  }

  .modelPreview {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sidebar {
    width: 100%;
    position: static;
  }

  .sidebarActive {
    overflow-y: unset;
    height: auto;
  }

  .modelPreview {
    grid-template-columns: repeat(2, 1fr);
    max-height: 30em;
  }
}

@media screen and (max-width: 480px) {
  .preview {
    grid-template-columns: auto;
    justify-items: center;
  }

  .paginate * {
    font-size: 0.75rem;
  }

  .backBtn {
    width: 2em;
    height: 2em;
    position: static;
  }
}

/* Animation Keyframes*/
@keyframes loopplay {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(10px, 0px);
  }
}

@-moz-keyframes loopplay {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(10px, 0px);
  }
}

@-webkit-keyframes loopplay {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(10px, 0px);
  }
}

@-o-keyframes loopplay {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(10px, 0px);
  }
}
