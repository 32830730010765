.container {
  width: 100%;
  overflow: auto;
  border: 1px solid var(--outline);
  background: var(--default);
  border-radius: 0.5em;
  margin-bottom: 2em;
  padding: 1em;
  padding-right: 0;
  box-shadow: 6px 9px 18px rgba(93, 134, 194, 0.1);
}

.layer {
  position: relative;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding-right: 1em;
}

.title {
  color: var(--text-dark-900);
  font-weight: 500;
}

.wrapper {
  width: 100%;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  gap: 1em;
  padding: 0.5em;
  margin-bottom: 1em;
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  border: 2px solid var(--outline);
  border-right-color: transparent;
}

.wrapper * {
  flex: 0 0 auto;
}

.uploadCard {
  width: 100%;
  height: 16em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  cursor: var(--cursor-pointer);
  background: var(--card-upload);
  border: 1px solid #b2c5d0;
}
.uploadCard > svg path {
  fill: #c1ddff;
}
.uploadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.uploadTitle {
  font-weight: 700;
  color: #263238;
}
.uploadText {
  font-size: 0.875rem;
  color: #767676;
}
.addIcon,
.uploadIcon {
  width: 1.5em;
  height: 1.5em;
  fill: var(--main-color);
}

.uploadBtn:hover {
  background: var(--main-color);
  color: var(--default);
}

.uploadBtn:hover .uploadIcon {
  fill: var(--default);
}

.btnContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1em;
}

.btnContainer .upload,
.btnContainer .addBlank {
  width: 8em;
  padding: 0.5em 0;
  border-radius: 0.5em;
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  color: var(--main-color);
  background: var(--card-light);
  box-shadow: 3px 4px 4px rgba(178, 197, 208, 0.44);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: all 300ms;
}

.btnContainer .upload:hover,
.btnContainer .addBlank:hover {
  border: 2px solid var(--main-color);
}

@media screen and (max-width: 768px) {
  .uploadText {
    font-size: 0.8em;
  }
}
