.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 2em;
  border-radius: 1em;
  background: var(--default);
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
}
.keyword {
  font-weight: 600;
  font-size: 1.625rem;
}
.searchCount {
  font-weight: 600;
  font-size: 0.875rem;
  color: #747c90;
}
.header {
  display: flex;
  align-items: center;
  margin-top: 5rem;
  gap: 2rem;
}
.tabs {
  display: flex;
  margin-right: auto;
  gap: 2rem;
}
.tabs > div {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5em;
  color: #8c93a3;
  padding: 0 1rem 0.6rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: 300ms ease;
}
.tabs > div:hover {
  border-color: var(--main-color);
}
.tabs > div.active {
  color: var(--main-color);
  border-color: var(--main-color);
}
.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 1em;
  margin-top: 2.5em;
}
.wrapper a {
  width: 100%;
}
.skeleton {
  margin-top: 2.5em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  z-index: 0;
}

.skeleton * {
  z-index: 0;
}

.noResult {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10em;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  .wrapper,
  .skeleton {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .wrapper,
  .skeleton {
    grid-template-columns: repeat(2, 1fr);
  }
  .tabs {
    gap: 1rem;
  }
  .tabs > div {
    font-size: 1.25rem;
    padding: 0 0.5em 0.1em;
  }
}

@media screen and (max-width: 680px) {
  .wrapper,
  .skeleton {
    grid-template-columns: repeat(1, 1fr);
  }
  .container {
    padding: 2em 1em;
  }
  .header {
    flex-wrap: wrap;
  }
  .tabs {
    gap: 0.5rem;
  }
  .tabs > div {
    font-size: 0.875rem;
  }
}
