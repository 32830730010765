.container {
  width: max-content;
  position: absolute;
  left: 0;
  top: 2em;
  z-index: 10;
  visibility: hidden;
}

.container.active {
  animation: drop 300ms forwards;
  visibility: visible;
}

@keyframes drop {
  from {
    transform: translateY(-1em);
  }
  to {
    transform: translateY(0);
  }
}

.wrapper {
  width: 16em;
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1em;
  background: var(--default);
  border-radius: 0.5em;
}

.name {
  width: 100%;
  border: 1px solid var(--outline);
  padding: 0.75em;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-heading);
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 1em;
  cursor: pointer;
}

.option * {
  color: var(--text-sub-heading);
}

.option:hover {
  background: var(--card-light);
}

.icon {
  width: 1em;
  height: 1em;
}
