.container {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1em;
}

.container button {
  border: 1px solid var(--outline);
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
  background: var(--error-400);
}

._0 {
  background: var(--outline) !important;
  cursor: no-drop;
}

.container input::placeholder {
  color: var(--outline);
}

.closeIcon {
  fill: var(--default);
}
