@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

:root {
  --btn-open: 0px 3.03px 3.03px rgba(0, 0, 0, 0.2);
  --btn-close: 0px 0px 3.03px rgba(0, 0, 0, 0.2);

  --shadow-dark: 3px 3px 6px rgba(0, 0, 0, 0.2);
  --shadow-light: 2px 2px 4px rgba(0, 0, 0, 0.2);

  --default: #ffffff;
  --main-color: #0d99ff;
  --main-hovered: #0082e0;
  --main-light: #62bdff;
  --main-lighter: #dbf0ff;
  --main-lightest: #f3faff;
  --main-disable: #ccecff;

  --text-heading: #0f1d40;
  --text-sub-heading: #525c76;
  --text-light: #6c757d;
  --text-placeholder: #b2b7c2;
  --text-placeholder-light: #d3d6de;

  --outline: #e5e8eb;
  --card-outline: #c4c4c4;
  --outline-dark: #a4a9b6;

  --icon-color: #4b4b5a;

  --card-bg: #626277;
  --card-bg-light: #737387;
  --card-icon-color: #515164;
  --card-shade: #646a89;
  --card-preview: #646a89;
  --card-layer: #585e7d;
  --card-tooltip: #686f93;
  --card-info: #e0f2ff;
  --card-upload: #f6faff;
  --card-trait: #f4f5f6;
  --card-light: #eff3f9;
  --card-lighter: #fcfdff;
  --btn-close: #eef5f9;

  --success-100: #ebfcd5;
  --success-200: #d4f9ac;
  --success-300: #b1ee80;
  --success-400: #8fde5d;
  --success-500: #5fc92e;
  --success-600: #44ac21;
  --success-700: #2d9017;
  --success-800: #1a740e;
  --success-900: #0d6008;

  --warning-100: #fff5ce;
  --warning-200: #ffe89e;
  --warning-300: #ffd76d;
  --warning-400: #ffc749;
  --warning-500: #ffad0d;
  --warning-600: #db8c09;
  --warning-700: #b76f06;
  --warning-800: #935404;
  --warning-900: #7a4102;

  --error-100: #ffe3d6;
  --error-200: #ffc0ad;
  --error-300: #ff9783;
  --error-400: #ff6f65;
  --error-500: #ff3236;
  --error-600: #db2438;
  --error-700: #b71938;
  --error-800: #930f35;
  --error-900: #7a0933;

  --text-dark-100: #747c90;
  --text-dark-200: #656e85;
  --text-dark-300: #5c657d;
  --text-dark-400: #525c76;
  --text-dark-500: #49536e;
  --text-dark-600: #3a4662;
  --text-dark-700: #2c3857;
  --text-dark-800: #192648;
  --text-dark-900: #0f1d40;

  --cursor-default: default;
  --cursor-pointer: pointer;
  /* --cursor-disabled: url("./assets/cursor-disabled.svg"), auto; */
  --cursor-grab: grab;
  --cursor-hover: default;
  /* --cursor-scopeg: url("./assets/cursor-scope.svg"), auto;
  --cursor-camera: url("./assets/cursor-camera.svg"), auto;
  --cursor-default-disabled: url("./assets/cursor-default-disabled.svg"), auto;
  --cursor-default-loading: url("./assets/cursor-default-loading.svg"), auto; */
}

li {
  list-style-type: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "SF Pro Display", sans-serif;
  color: var(--text-heading);
  line-height: 1.5em;
}

a {
  text-decoration: none;
  color: unset;
  cursor: var(--cursor-pointer);
}

.App {
  width: 100%;
  cursor: var(--cursor-default);
  position: relative;
}

.App * {
  transition: width max-width font-size 150ms;
}

.Routes {
  width: 100%;
  min-height: calc(100vh - 21em);
}

.topSectionContainer {
  z-index: 20;
  position: sticky;
  top: 0;
}

/* width */
::-webkit-scrollbar {
  width: inherit;
  height: 6px;
  width: 10px;
  border-radius: 1em;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 0px;
}

::-webkit-scrollbar-corner {
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}

button {
  cursor: var(--cursor-pointer);
  border: none;
  outline: none;
  background: transparent;
}
