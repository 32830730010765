.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: auto;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* user-select: none; */
  /* pointer-events: none; */
}

.cardContainer {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 650ms !important;
  padding: 1em 0;
}

.ctrlBtn_left,
.ctrlBtn_right {
  position: absolute;
  padding: 0.5em;
  z-index: 1;
  background: rgb(255, 255, 255, 0.75);
  cursor: var(--cursor-pointer);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-light);
  transition: all 300ms;
}

.ctrlBtn_left:hover,
.ctrlBtn_right:hover {
  background: rgb(255, 255, 255, 1);
  box-shadow: var(--shadow-dark);
}

.ctrlBtn_left {
  left: 1em;
  display: none;
}

.ctrlBtn_left.active {
  display: flex;
}

.ctrlBtn_right {
  right: 1em;
}

.ctrlBtn_right.active {
  display: flex;
}

@media screen and (max-width: 480px) {
  .cardContainer {
    padding: 1em;
  }
}
