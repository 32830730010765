.card {
  width: 100%;
  border-radius: 0.5em;
  background: var(--default);
  transition: all 250ms;
  border: 1px solid var(--outline);
  overflow: hidden;
  max-width: 32em;
}

.card:hover {
  box-shadow: var(--shadow-light);
  transform: translateY(-5px);
  cursor: var(--cursor-pointer);
}

.imageContainer {
  background: rgba(0, 0, 0, 0.05);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 16em;
}

.body {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5em;
  padding-top: 3em;
  padding-bottom: 1em;
}

.thumbnail {
  width: 5em;
  height: 5em;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  background: var(--default);
  box-shadow: var(--shadow-light);
}

.thumbnail img {
  width: 100%;
  border-radius: 50%;
}

.name {
  margin-bottom: 1em;
}

.description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 1em;
  padding: 0 1em;
  min-height: 1.5em;
}

.usdPrice {
  font-size: 0.7rem;
  font-weight: lighter;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notListedWrap {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}

.notListed {
  margin-left: 10px;
  background-color: var(--outline);
  padding: 5px 15px;
  border-radius: 16px;
}
.notListed span {
  font-size: 14px;
  color: black;
}

.floor {
  font-size: 0.65em;
  font-weight: bold;
  color: rgba(173, 181, 189, 1);
}

.price {
  color: var(--main-color);
  font-weight: bold;
}

.priceImg {
  width: 25px;
}

.price img {
  transform: translateY(20%);
  margin-right: 10px;
  width: 30px;
}

.chain {
  font-weight: 300;
  font-size: 0.85em;
}

.nftCount {
  padding: 0.5em 1.5em;
  background: var(--main-color);
  color: white;
  border-radius: 0.7em;
  font-size: 0.85rem;
}
