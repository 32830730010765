.container {
  width: 100%;
  margin-bottom: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em 2em;
}

.container * {
  transition: max-width 300ms;
}
.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1em;
  white-space: nowrap;
}
.load {
  width: 30%;
  max-width: 25em;
}
.heading {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5em;
  font-size: 3rem;
  font-weight: 600;
}

.heading .accent {
  color: var(--main-color);
}

.description {
  text-align: center;
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--text-sub-heading);
  padding: 0 1em;
  margin-bottom: 1em;
}

.btn {
  display: inline-block;
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 2em;
}

.btn:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.cardGrid {
  display: flex;
  grid-gap: 0.6em;
}

.card {
  height: 20em;
  width: 18em;
  flex: 0 0 auto;
  padding: 0.5em;
  padding-bottom: 2em;
  border-radius: 1em;
  border: 1px solid var(--outline);
}

.imgContainer {
  height: 17em;
}
.imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 300ms;
  margin-bottom: 0.5em;
  border-radius: 1em;
}

.card:hover img {
  transform: scale(1.01);
  cursor: pointer;
}

.card .name {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 0.5em;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 2.5rem;
  }
  .card:nth-child(4) {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .heading {
    font-size: 2rem;
  }
  .card:nth-child(3) {
    display: none;
  }

  .heading .accent {
    white-space: nowrap;
  }

  .description {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 540px) {
  .container {
    padding: 2em 1em;
    margin-bottom: 4em;
  }
  .cardGrid {
    justify-content: center;
  }
  .card:nth-child(2) {
    display: none;
  }
  .heading {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }
}
