.container {
  width: auto;
  position: fixed;
  z-index: 2000;
  right: 10px;
  top: 4em;
  background: var(--default);
  color: var(--text-dark-900);
  visibility: hidden;
  opacity: 0;
  padding: 0.5em 1.5em;
  border-radius: 0.25em;
  transition: all 300ms;
  box-shadow: var(--shadow-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(10px);
}

.icon {
  animation: rotate 1000ms infinite;
  width: 1em;
  margin-right: 0.5em;
}

.message {
  text-transform: lowercase;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
