.container {
  width: 100%;
  padding: 1em 2em 6em;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4em;
}

.content {
  flex: 60%;
  max-width: 36em;
}

.heading {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1em;
}

.heading .accent {
  color: var(--main-color);
}

.description {
  margin-bottom: 2em;
  font-size: 1.5rem;
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.link svg {
  transition: transform 300ms;
  fill: var(--text-sub-heading);
  fill: var(--main-color);
}

.link:hover svg {
  transform: translateX(1em);
}

.orgs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
}

.org {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5em;
  background: #ffffff;
  border: 1px solid transparent;
  border-right: 3px solid #e5e8eb;
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  position: relative;
  transition: all 300ms;
  cursor: pointer;
}

.org:hover {
  border: 3px solid #e5e8eb;
}

.org:hover .icon {
  animation-play-state: paused;
}

.icon {
  position: absolute;
  width: 5em;
  animation: rotate 12s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }

  50% {
    transform: rotate(360deg) translate(1em, 1em);
  }

  100% {
    transform: rotate(360deg) translate(0, 0);
  }
}

.celo .icon {
  top: -2em;
  left: -2em;
  z-index: -1;
  animation-delay: 1s;
}

.mp .icon {
  bottom: -2em;
  left: -2em;
  z-index: -1;
  animation-delay: 2s;
}

.algo .icon {
  bottom: -2em;
  right: -2em;
  z-index: -1;
  animation-delay: 3s;
}

.near .icon {
  top: -2em;
  right: -2em;
  z-index: -1;
  animation-delay: 4s;
}

.mp {
  align-self: flex-start;
  justify-self: flex-end;
}

.near {
  align-self: flex-end;
}

.celo,
.algo {
  width: 13em;
  height: 12.5em;
}

.near,
.mp {
  width: 12em;
  height: 10em;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 2.5rem;
  }

  .celo,
  .algo {
    width: 12em;
    height: 11.5em;
  }

  .near,
  .mp {
    width: 11em;
    height: 9em;
  }

  .mp .logo {
    width: 5em;
  }

  .near .logo {
    width: 6em;
  }

  .celo .logo {
    width: 6em;
  }

  .algo .logo {
    width: 6em;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    flex-direction: column;
    gap: 2em;
  }

  .heading {
    font-size: 2rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .content {
    max-width: 100%;
    text-align: center;
  }

  .orgsIcon {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 1em 1em 4em;
  }

  .heading {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .orgsIcon {
    height: 20em;
  }

  .orgs {
    gap: 1em;
  }

  .org {
    border-radius: 0.7em;
  }

  .icon {
    width: 3.5em;
  }

  .celo,
  .algo {
    width: 8em;
    height: 7.5em;
  }

  .near,
  .mp {
    width: 7em;
    height: 6em;
  }

  .mp .logo {
    width: 3em;
  }

  .algo .logo,
  .celo .logo,
  .near .logo {
    width: 4em;
  }
}
