.container {
  width: 100%;
  padding-bottom: 2em;
  background: var(--default);
  display: flex;
  padding-top: 2rem;
}
.form {
  height: calc(100vh - (120px));
  margin: auto;
}
