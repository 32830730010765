.container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  padding: 0 1em;
}

.card {
  width: 100%;
  max-width: 28em;
  overflow: auto;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2em;
  padding-bottom: 3em;
  gap: 1em;
  border-radius: 1em;
  animation: show 300ms alternate;
  margin: 6em auto 4em auto;
}

@keyframes show {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

.heading {
  text-align: center;
  margin-bottom: 1em;
}

.heading h3 {
  margin-bottom: 0.5em;
}

.description {
  font-size: 0.85rem;
}

.iconContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeIcon {
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
  cursor: var(--cursor-pointer);
  fill: var(--icon-color);
}

.closeIcon:hover {
  background: var(--outline-dark);
  fill: var(--default);
}

.wrapper {
  width: 100%;
  position: relative;
}

.chains {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 0.5em;
}

.chains.active {
  display: none;
}

.chain {
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.5em 0;
  padding-left: 1em;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.chain.comingSoon {
  background: var(--card-trait);
  cursor: var(--cursor-default);
}

.chain.comingSoon h4 {
  color: var(--text-placeholder);
}

.chain.comingSoon p {
  color: var(--text-placeholder);
}

.chain.comingSoon:hover {
  background: var(--outline);
  color: inherit;
}

.chain:hover {
  background: var(--main-hovered);
  border-color: transparent;
}

.chain:hover h4 {
  color: var(--default);
}

.chain:hover p {
  color: var(--default);
}

.chain img {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.chain h4 {
  font-size: 1rem;
  width: 100%;
  text-align: left;
}

.chain span {
  font-size: 0.56rem;
  font-weight: 500;
  color: var(--default);
  background: #ebba4f;
  padding: 0.25em 0.5em;
}

.chain p {
  font-size: 0.85rem;
  width: 100%;
  text-align: left;
}

.viewBtnContainer {
  margin-top: 0.5em;
}

.viewBtn {
  display: inline-block;
  cursor: var(--cursor-pointer);
  border-radius: 1em;
  padding: 0.15em 1em;
  transition: all 300ms;
  color: var(--main-color);
}

.viewBtn:hover {
  text-decoration: underline;
}

.connectionMethods {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  background: var(--default);
}

.connectionMethods.active {
  display: flex;
}

.connectionMethod {
  width: 100%;
  padding: 1em 0;
  border: 1px solid transparent;
  box-shadow: var(--shadow-light);
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  gap: 0.5em;
  border-radius: 1em;
  text-align: center;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.connectionMethod:hover {
  box-shadow: var(--shadow-dark);
  border: 1px solid var(--outline);
}

.connectionMethod.metamask {
  display: none;
}
.connectionMethod.metamask.active {
  display: flex;
}

.connectionMethod img {
  width: 4em;
  height: 4em;
}

.connectionMethod p {
  font-size: 0.85rem;
}
.networkSwitch {
  font-size: 0.75rem;
  background: var(--card-info);
  padding: 0.5em 1rem;
  margin-top: 0.5em;
  text-align: left;
}
.networkSwitch a {
  color: var(--main-color);
}
.networkSwitch a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .heading h4 {
    font-size: 0.85rem;
  }

  .card {
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 1em;
  }

  .chain {
    padding: 1em 0;
    padding-left: 1em;
  }

  .chain img {
    width: 2em;
    height: 2em;
  }

  .connectionMethod img {
    width: 3em;
    height: 3em;
  }
}

@media screen and (max-width: 480px) {
  .heading h4 {
    font-size: 0.75rem;
  }

  .chain {
    padding: 0.5em 0;
    padding-left: 1em;
  }

  .chain h4 {
    font-size: 0.75rem;
  }

  .chain p {
    font-size: 0.75rem;
  }

  .chain img {
    width: 1.5em;
    height: 1.5em;
  }

  .connectionMethod h3 {
    font-size: 1rem;
  }

  .connectionMethod p {
    font-size: 0.85rem;
  }

  .connectionMethod img {
    width: 2em;
    height: 2em;
  }
}
