.container {
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.container img {
  width: 100%;
}

.singleVideo {
  width: 400px;
  height: 300px;
}
