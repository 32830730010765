.container {
  width: 100%;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
}

.heading {
  padding: 0.5em;
  font-weight: 600;
  font-size: 1.5rem;
}

.content {
  padding: 1em;
}

.content > div {
  word-wrap: break-word;
}

.showBtn {
  color: var(--main-color);
  cursor: pointer;
  transition: color 300ms;
}

.showBtn:hover {
  color: var(--main-hoverd);
}
