.container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
.wrapper {
  background: url("../../../assets/wavesArtist.svg");
  height: 40rem;
  display: flex;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}
.container h3 {
  font-size: 2.25em;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
.container p {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 1.375rem auto 0;
  text-align: center;
}
.container a {
  background: transparent;
  border: 3px solid var(--main-color);
  box-sizing: border-box;
  border-radius: 37.44px;
  width: 16.875rem;
  height: 4rem;
  color: var(--main-color);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: var(--cursor-pointer);
  margin: 4.5rem auto 5.5rem;
  transition: 0.3s ease-in-out;
}
.container a:hover {
  background: var(--main-color);
  color: white;
}
@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 768px;
  }
  .lineBreak {
    display: none;
  }
  .container a {
    margin: 2.5rem auto 5.5rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 540px;
    padding: 0 2em;
  }
  .container h3 {
    font-size: calc(1rem + 2vmin);
  }
  .container p {
    font-size: 1rem;
  }
  .container a {
    font-size: 1rem;
    width: 12.875rem;
    height: 3.5rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0 1em;
  }
}
