.container {
  width: 100%;
  max-width: 56em;
  border-radius: 0.5em;
  background: var(--default);
  position: relative;
  border: 1px solid var(--outline);
  padding: 2em;
  box-shadow: 12px 0px 35px rgba(93, 134, 194, 0.1), -2px -10px 35px rgba(93, 134, 194, 0.1);
}

.content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3em;
  border-radius: 0.5em;
  padding: 2em;
  background: var(--card-lighter);
}

.content h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 1em;
}

.description {
  text-align: center;
}

.description div:first-child {
  margin-bottom: 0.5em;
}

.wrapper button {
  font-size: 0.85rem;
  transition: all 300ms;
}

.createBtn {
  color: var(--main-color);
}

.upgradeBtn {
  padding: 0.75em 4em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
}

.upgradeBtn:hover {
  background: var(--main-hovered);
}

.createBtn:hover {
  color: var(--main-hovered);
}

@media screen and (max-width: 540px) {
  .container,
  .content {
    padding: 1em;
  }

  .cartIcon {
    width: 8em;
    height: 8em;
  }

  .content h1 {
    font-size: 1.5rem;
  }
}
