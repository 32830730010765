.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1em;
  border-radius: 1em;
  padding: 1em;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 1em;
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list * {
  color: #525c76;
}

.value {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: color 300ms;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
}

.value svg {
  width: 1em;
}

.total {
  font-weight: 600;
  font-size: 1.2rem;
}
