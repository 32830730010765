.container {
  padding: 4rem 1rem;
  width: 100%;
  height: fit-content;
  gap: 1.5rem;
  background: var(--default);
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.12);
  border-radius: 14px;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  border: 1px solid #e5ecfb;
}
.card {
  display: flex;
  height: fit-content;
  border: 1px solid #e5e8eb;
  box-shadow: 28.8589px 19.9792px 19.9792px rgb(93 134 194 / 5%);
  border-radius: 15.5394px;
  padding: 0.875rem 2rem;
  gap: 1rem;
  text-align: left;
  cursor: pointer;
}
.icon {
  width: 48px;
  height: 48px;
  background: #f3f6fc;
  padding: 10px;
  border-radius: 50%;
}
.icon svg {
  height: 100%;
  width: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 54px);
}
.title {
  font-size: 0.875rem;
  color: var(--main-color);
  font-weight: 500;
}
.desc {
  font-size: 0.75rem;
  color: #263238;
}
.card:hover,
.card.active {
  background: var(--main-color);
}
.card:hover .title,
.card:hover .desc,
.card.active .title,
.card.active .desc {
  color: var(--default);
}
.card:hover .icon,
.card.active .icon {
  background: var(--default);
}
.card:hover .icon svg path,
.card.active .icon svg path {
  stroke: var(--main-color);
}
.card:nth-child(5):hover .icon svg path,
.card:nth-child(5).active .icon svg path {
  fill: var(--main-color);
}
.card:nth-child(2):hover .icon svg path:last-child,
.card:nth-child(2).active .icon svg path:last-child {
  fill: var(--main-color);
}
.card:nth-child(6):hover .icon svg path,
.card:nth-child(6).active .icon svg path {
  fill: var(--main-color);
}
