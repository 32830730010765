.container {
  width: 100%;
  margin-bottom: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 4em;
  overflow: hidden;
}

.container * {
  transition: max-width 300ms;
}

.heading {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5em;
  color: var(--text-heading);
}

.heading .accent {
  color: var(--main-color);
}

.description {
  text-align: center;
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  margin-bottom: 3em;
  font-size: 1.2rem;
  color: var(--text-sub-heading);
  line-height: 1.5em;
}

.wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3em;
}

.wrapper img {
  width: 100%;
  max-width: 60em;
  height: 100%;
}

.btn {
  background: var(--main-color);
  border: 1px solid transparent;
  color: var(--default);
  padding: 0.75em 3em;
  border-radius: 0.7em;
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn:hover {
  background: var(--default);
  color: var(--main-color);
  border-color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: calc(1rem + 2vmin);
  }

  .description {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 0 2em;
    margin-bottom: 4em;
  }
}

@media screen and (max-width: 540px) {
  .wrapper {
    gap: 2em;
  }
}
