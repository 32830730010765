.container {
  width: 100%;
  margin-bottom: 6em;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 3em;
  padding-top: 5em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 16em;
  background: linear-gradient(
    255.93deg,
    #eff3f9 -39.69%,
    #eff3f9 5.2%,
    rgba(205, 237, 255, 0.65) 49.41%,
    #ffffff 91.12%
  );
}

.features {
  font-weight: 700;
  font-size: 8.1875rem;
  line-height: 1em;
  text-align: center;
  color: #2d3748;
  white-space: nowrap;
}

.description {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: #2d3748;
}

.more {
  text-align: center;
  font-size: 1.75rem;
  letter-spacing: 0.02em;
  color: #2d3748;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2em;
  margin-top: 2em;
}

.btn_1,
.btn_2 {
  padding: 0.75em 4em;
  border-radius: 0.7em;
  color: var(--main-color);
  border: 1px solid transparent;
  transition: all 300ms;
  cursor: pointer;
}

.btn_1 {
  background: var(--main-color);
  color: var(--default);
}

.btn_1:hover {
  background: var(--default);
  color: var(--main-color);
}

.btn_2 {
  background: var(--default);
  color: var(--main-color);
}

.btn_2:hover {
  background: var(--main-color);
  color: var(--default);
}

.demo {
  width: 90%;
  max-width: 80%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  border-radius: 4em;
  margin-top: -14em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.demo video {
  width: 100%;
}

.playIcon {
  cursor: pointer;
  transition: transform 300ms;
  width: 12em;
  position: absolute;
  z-index: 10em;
  display: none;
}

.demo.active .playIcon {
  display: block;
}

.playIcon:hover {
  transform: scale(0.95);
}

@media screen and (max-width: 1200px) {
  .features {
    font-size: 6.5rem;
  }

  .description {
    font-size: 1.5rem;
  }

  .playIcon {
    width: 8em;
  }
}

@media screen and (max-width: 900px) {
  .heading {
    padding-top: 4em;
    padding-bottom: 6em;
  }

  .demo {
    margin-top: -6em;
  }

  .playIcon {
    width: 6em;
  }

  .features {
    font-size: 5rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .more {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .features {
    font-size: 4rem;
  }

  .description {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 540px) {
  .container {
    margin-bottom: 4em;
  }

  .features {
    font-size: 2.75rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .more {
    font-size: 0.75rem;
  }

  .demo {
    border-radius: 0.7em;
  }

  .playIcon {
    width: 4em;
  }

  .btn_1,
  .btn_2 {
    padding: 0.5em 2em;
  }
}
@media screen and (max-width: 350px) {
  .features {
    font-size: 2rem;
  }
}
