.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--default);
}

.gena,
.drop {
  font-size: 6rem;
  font-weight: bold;
  visibility: hidden;
  color: var(--main-color);
}

.gena {
  animation: enter-from-right 1s 200ms forwards;
}

.drop {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: enter-from-left 1s 300ms forwards;
}

.imageContainer {
  width: 0;
  animation: open 500ms 1s forwards;
}

.image {
  transform: translateY(-100%);
  width: 0.75em;
  visibility: hidden;
  opacity: 0;
  animation: drop 1650ms 1650ms forwards;
}

@keyframes enter-from-right {
  from {
    transform: translate(0%, 15px);
    visibility: hidden;
  }
  to {
    transform: translate(-30%, 15px);
    visibility: visible;
  }
}

@keyframes enter-from-left {
  from {
    transform: translate(0%, -15px);
    visibility: hidden;
  }
  to {
    transform: translate(30%, -15px);
    visibility: visible;
  }
}

@keyframes open {
  from {
    width: 0;
  }
  to {
    width: 0.75em;
  }
}

@keyframes drop {
  0% {
    transform: translateY(-100%);
    visibility: hidden;
    opacity: 0;
  }
  60% {
    transform: translateY(35px);
    visibility: visible;
    opacity: 1;
  }
  70% {
    transform: translateY(-5px);
    visibility: hidden;
    opacity: 1;
  }
  100% {
    transform: translateY(25px);
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 540px) {
  .gena,
  .drop {
    font-size: 4rem;
  }

  @keyframes enter-from-right {
    from {
      transform: translate(0%, 10px);
      visibility: hidden;
    }
    to {
      transform: translate(-30%, 10px);
      visibility: visible;
    }
  }

  @keyframes enter-from-left {
    from {
      transform: translate(0%, -10px);
      visibility: hidden;
    }
    to {
      transform: translate(30%, -10px);
      visibility: visible;
    }
  }

  @keyframes drop {
    0% {
      transform: translateY(-150%);
      visibility: hidden;
      opacity: 0;
    }
    40%,
    45% {
      transform: translateY(30px);
      visibility: visible;
      opacity: 1;
    }
    70% {
      transform: translateY(-5px);
      visibility: hidden;
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      visibility: visible;
      opacity: 1;
    }
  }
}
