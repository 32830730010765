.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;
  height: 100%;
}

.wrapper {
  width: 15em;
  position: absolute;
  border-radius: 0.5em;
  overflow: hidden;
  top: 3em;
  right: 0.2em;
  background: var(--default);
  border: 1.5px solid var(--outline);
  box-shadow: 0px 1.43503px 2.87007px rgba(28, 27, 28, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  transform: translateY(-0.5em);
}

.wrapper.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0em);
}

.filterBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  cursor: pointer;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.filterBtn:hover {
  background: var(--btn-close);
}

.filterHeading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.5em 1em;
}

.sort,
.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.dropdown {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
  padding: 0.75em;
}

.priceDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em 0.75em;
}

.inputContainer input {
  width: 100%;
  border: 1px solid #cacdd5;
  outline: none;
  border-radius: 0.25em;
  padding: 0.25em;
}

.label {
  font-size: 0.85rem;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  padding: 1em 0.75em;
}

.btn {
  padding: 0.5em 0;
  border-radius: 0.7em;
  flex: 1;
  text-align: center;
  font-size: 0.85rem;
  border: 1px solid transparent;
  transition: all 300ms;
  cursor: pointer;
}

.btn.apply {
  background: var(--main-color);
  color: var(--default);
}

.btn.apply:hover {
  background: var(--default);
  color: var(--main-color);
  border-color: var(--main-color);
}

.btn.cancel {
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.btn.cancel:hover {
  background: var(--main-color);
  color: var(--default);
}

@media screen and (max-width: 540px) {
  .container {
    justify-content: flex-end;
  }

  .filterBtn {
    gap: 0.5em;
    padding: 0.5em 0.25em;
  }

  .filterBtn div {
    font-size: 0.85rem;
  }

  .filterIcon {
    width: 1em;
    height: 1em;
  }
}
