.footer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}
.pagination {
  margin: 0;
  /* width: 80%; */
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination a {
  font-size: 0.875rem;
  margin: 2px;
  height: 21px;
  display: flex;
  width: 21px !important;
  border-radius: 5px;
  border: 1px solid transparent;
  /* color: var(--main-color); */
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.pagination a:hover {
  border-color: var(--main-color);
}
.pagination .activePage a {
  border-color: var(--main-color);
}
.directPagination {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
}
.directPagination > p {
  color: #8c93a3;
  display: flex;
}
.directPagination > input {
  border: 1px solid #cacdd5;
  outline: none;
  border-radius: 2px;
  width: 40px;
  height: 24px;
  padding: 0 10px;
}
.directPagination > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  color: var(--main-color);
}
.directPagination > div svg {
  transition: all 300ms ease;
}
.directPagination > div:hover svg {
  transform: translateX(4px);
}
