.container {
  width: 20em;
  position: sticky;
  top: 5em;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  padding: 1em 0;
  height: calc(100vh - 10em);
  z-index: 15;
  overflow: auto;
  box-shadow: 26px 49px 27px rgba(93, 134, 194, 0.1);
  display: flex;
  flex-direction: column;
}

.container::-webkit-scrollbar {
  display: none;
}

.container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container i {
  font-size: 14px;
  padding: 0 0.5em;
}

.collectionNameContainer {
  padding: 1em;
  border-bottom: 1px solid var(--outline);
  margin-bottom: 1em;
}

.collectionName {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  background: var(--card-light);
  padding: 0.5em 1em;
  border-radius: 0.25em;
  border: 2px solid var(--outline-dark);
}

.collectionName form {
  flex: 1;
  height: 100%;
  display: flex;
}

.collectionName input {
  background: transparent;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  margin-right: 0.5em;
  font-size: 1rem;
}

.collectionName label {
  max-width: 14em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.collectionName input::placeholder {
  color: var(--text-placeholder-light);
}

.editBtn {
  cursor: var(--cursor-pointer);
  padding: 0.1em;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editBtn img {
  width: 100%;
}

.editIcon {
  fill: black;
}

.info {
  padding: 0.5em 0.75em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  background: var(--card-info);
}

.info p {
  font-size: 0.75rem;
}

.info .closeBtn {
  fill: var(--icon-color);
  width: 2em;
  cursor: var(--cursor-pointer);
}

.layerorder {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: var(--default);
}

.layerHeadWrapper {
  width: 100%;
}

.layer_trait {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  padding-right: 2em;
  text-transform: capitalize;
  background: var(--card-trait);
  color: var(--main-color);
  margin-bottom: 1em;
  border-radius: 0.25em;
}

.listWrapper {
  width: 100%;
  padding: 0.25em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.list > div {
  cursor: var(--cursor-hover);
}
.list > div:active:hover {
  cursor: var(--cursor-grab);
}
.promptWrapper {
  width: 100%;
}

.addBtnContainer {
  position: relative;
}

.addBtn {
  width: 100%;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  color: var(--main-color);
  padding: 0.75em;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 300ms;
  border: 1px solid var(--main-color);
}

.addIcon {
  fill: var(--main-color);
  width: 1.2em;
  height: 1.2em;
}

.addBtn:hover {
  background: var(--main-lightest);
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    position: static;
  }
}
