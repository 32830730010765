.container {
  width: 100%;
  max-width: 48em;
  color: var(--text-dark-900);
  border-radius: 1em;
  background: var(--default);
  box-shadow: var(--shadow-light);
  padding: 2em;
  background: var(--default);
  position: relative;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1em;
  height: 16em;
  overflow: auto;
}

.conflictCard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
  padding: 0.25em;
  border: 1px solid var(--outline);
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-radius: 0.5em;
  position: relative;
}

.content {
  width: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  overflow: auto;
}

.conflict {
  font-style: italic;
}

.deleteRule {
  cursor: var(--cursor-pointer);
  border: 1px solid red;
  padding: 0.25em 1em;
  border-radius: 0.7em;
  color: red;
  font-size: 0.75rem;
  position: absolute;
  top: 1em;
  right: 1em;
  background: var(--default);
  transition: all 300ms;
}

.deleteRule:hover {
  border-color: transparent;
}

.innerContent {
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 auto;
}

.image {
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--outline);
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
}

.description {
  width: 100%;
  border: 1px solid var(--outline);
  background: var(--card-bg);
  color: var(--default);
  padding: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.title,
.text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85rem;
  color: var(--default);
}

.btnContainer {
  width: 100%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
}

.deleteBtn,
.closeBtn {
  border-radius: 0.7em;
  padding: 0.65em 2em;
  cursor: var(--cursor-pointer);
  border: 1px solid transparent;
  text-transform: capitalize;
}

.deleteBtn {
  background: red;
  color: white;
  transition: all 300ms;
}

.deleteBtn:hover {
  background: rgb(181, 0, 0);
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.closeBtn img {
  transition: all 300ms;
}

.closeBtn:hover img {
  transform: translateX(-50%);
}

.notification {
  width: 100%;
  text-align: center;
}

.canvas {
  height: 100px;
  width: 100px;
  border-radius: 0.25em;
}

.container > p {
  font-style: italic;
  color: var(--card-bg);
  font-size: 0.85rem;
  margin-left: 0.5em;
  display: inline-block;
  transform: translateY(-5px);
}

@media screen and (max-width: 480px) {
  .btnContainer {
    justify-content: flex-start;
    gap: 0.5em;
    flex-wrap: wrap;
  }

  .closeBtn,
  .deleteBtn {
    flex: 1;
    font-size: 0.75em;
    padding: 0.5em 1em;
  }
}
