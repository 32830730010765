.container {
  width: calc(100% - 20em);
  z-index: 14;
}

.container.active {
  z-index: 20;
}

.rules {
  width: 100%;
  align-items: center;
  background: var(--default);
  padding: 1em 0.5em;
  z-index: 1;
  gap: 1em;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: sticky;
  top: 3.7em;
}

.addRuleBtn,
.showRuleBtn {
  width: 7.5em;
  height: 2.5em;
  border-radius: 0.7em;
  font-size: 0.85rem;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.addRuleBtn {
  background: var(--main-color);
  color: white;
}

.showRuleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  color: var(--main-color);
  background: var(--default);
  border: 2px solid var(--main-color);
}

.addRuleBtn:hover {
  background: var(--main-hovered);
}

.showRuleBtn:hover {
  background: var(--main-color);
  color: white;
}

.ruleCount {
  height: 24px;
  width: 24px;
  padding-top: 1px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.showRuleBtn:hover .ruleCount {
  background: var(--default);
  color: var(--main-color);
}

.ruleCardWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  z-index: 200000 !important;
}

.fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  min-height: calc(100vh - 9em);
}

.fallback h4 {
  margin-bottom: 1em;
  color: var(--text-dark-300);
}

.fallback img {
  width: 36vw;
  margin-bottom: 1em;
}

.fallback p {
  max-width: 16em;
  color: var(--text-dark-300);
}

.conflictInfo {
  font-size: 0.75em;
  color: var(--text-dark-900);
  background: var(--card-info);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em 0.75em;
  max-width: 34em;
  margin-left: auto;
}

.hidden {
  display: none;
}

.closeIcon {
  fill: var(--icon-color);
  width: 2em;
  height: 2em;
  cursor: var(--cursor-pointer);
}

@media screen and (max-width: 1200px) {
  .container {
    min-height: 10em;
  }
}

@media screen and (max-width: 1000px) {
  .fallback img {
    width: 100%;
    max-width: 28em;
  }

  .rules {
    top: 2.9em;
  }
}

@media screen and (max-width: 950px) {
  .rules {
    flex-wrap: wrap-reverse;
  }
  .conflictInfo {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .rules {
    width: 100%;
    margin-left: 0;
  }

  .showRuleBtn,
  .addRuleBtn {
    font-size: 0.85rem;
  }

  .fallback img {
    width: 18em;
  }
}
