.container {
  width: 100%;
  padding: 1em 2em;
  border-radius: 0.5em;
  border: 1px solid var(--outline-dark);
  cursor: var(--cursor-pointer);
}

.container * {
  transition: color 300ms;
}

.answer,
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3em;
}

.question {
  padding: 1em 0;
  font-weight: 600;
  font-size: 1.5rem;
}
.question span {
  display: flex;
}
.question .title {
  color: var(--text-sub-heading);
}

.question.active .title {
  color: var(--main-color) !important;
}

.question img {
  width: 1.2em;
  height: 1.2em;
}

.title {
  text-transform: capitalize;
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: all 1250ms;
  color: var(--text-heading);
  font-size: 1.2rem;
  width: 100%;
}

.answer a {
  color: var(--main-color);
}

.answer.active {
  max-height: 100vh;
}

.container p {
  flex: 1;
}

@media screen and (max-width: 1200px) {
  .answer,
  .question {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 900px) {
  .answer,
  .question {
    gap: 1em;
    font-size: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 1em;
  }
}
