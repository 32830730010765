.container {
  width: 100%;
  width: max-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.container svg {
  width: 1em;
  height: 1em;
  /* border: 1px solid var(--main-color); */
  border-radius: 50%;
  cursor: var(--cursor-pointer);
}

.container:hover::before {
  content: "";
  position: absolute;
  top: -0.25em;
  transform: translateY(-100%);
  border-top: 1em solid #626277;
  border-right: 1em solid transparent;
  border-left: 1em solid transparent;
}

.card {
  width: max-content;
  max-width: 20em;
  border-radius: 0.25em;
  padding: 0.5em;
  padding-bottom: 1em;
  text-align: center;
  position: absolute;
  top: -1.25em;
  font-size: 0.85rem;
  background: #626277;
  color: var(--default);
  transform: translateX(0) translateY(-100%);
  visibility: hidden;
  z-index: 10000;
}

.container:hover .card {
  visibility: visible;
}
