.container {
  width: max-content;
  border-radius: 0.5em;
  overflow: hidden;
  margin: auto;
  position: relative;
}

.container button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.15em 0.75em;
  cursor: var(--cursor-pointer);
  outline: none;
  font-size: 12px;
  border-radius: 0.2em;
  transition: all 300ms;
  border: none;
  background: var(--default);
  box-shadow: var(--shadow-light);
}

.container button * {
  color: var(--text-dark-900);
}

.container button:hover {
  transform: translateY(1px);
}

.placeholder {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
}
