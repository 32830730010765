.container {
  width: 100%;
  max-width: 24em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 0.7em;
  cursor: pointer;
  background: #ffffff;
  border: 1.41429px solid #a9a9a9;
  box-shadow: 5.65714px 5.65714px 11.3143px rgba(28, 27, 28, 0.04);
}

.container:hover .image {
  transform: scale(1.05);
}

.imageContainer {
  width: 100%;
  padding: 0.5em;
  overflow: hidden;
}

.imageWrapper {
  width: 100%;
  border-radius: 0.7em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 16em;
  object-fit: cover;
  border-radius: 0.7em;
  transition: transform 300ms;
}

.details {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 28, 0.1);
  border-bottom: 1px solid rgba(28, 27, 28, 0.1);
  padding: 1em 0.5em;
}

.nameAndChainWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5em;
}

.name {
  max-width: 10em;
  background: #dbf0ff;
  font-weight: 600;
  padding: 0.5em;
  border-radius: 0.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chain {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.description {
  width: 100%;
  max-width: 18em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #6c757d;
}

.listing {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}

.counts {
  border-radius: 0.7em;
  padding: 0.5em 1em;
  background: rgba(170, 219, 255, 0.4);
  color: #4b4b5a;
  font-weight: 600;
}

.priceLabel {
  color: rgba(28, 27, 28, 0.5);
}

.amount span {
  color: var(--main-color);
  font-size: 0.85rem;
}

.amount span.accent {
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .description {
    max-width: 16em;
  }

  .image {
    height: 14em;
  }

  .name,
  .description,
  .priceLabel,
  .counts,
  .amount {
    font-size: 0.85rem;
  }

  .btn {
    padding: 0.25em 2em;
  }
}
