.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}

.popupContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.popupContainer.active {
  display: block;
}

.connect {
  width: 100%;
  max-width: 12em;
  padding: 0.5em 1.5em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  color: var(--default);
  background: var(--main-color);
  text-align: center;
  border: 1px solid var(--main-color);
  transition: background 300ms;
}

.connect:hover {
  background: var(--main-hovered);
}

.connectedContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.connected {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  cursor: var(--cursor-pointer);
  background: var(--default);
  transition: all 150ms;
  padding: 0.25em 0.25em;
  border-radius: 0.7em;
  padding: 0.25em 0.5em;
  background: var(--main-color);
}

.connected:hover {
  color: var(--main-hovered);
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
  font-size: 2rem;
}

.user img {
  width: 1em;
  height: 1em;
}

.chain {
  height: 24px;
  width: 24px;
  background: var(--default);
  border-radius: 50%;
  display: inline-block;
}

.address {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  padding: 0.25em 0;
  position: relative;
}

.address > span {
  color: var(--default) !important;
}

.dropdownIconContainer {
  border-left: 1px solid var(--outline);
  padding: 0 0.25em;
}

.dropdownIcon {
  fill: var(--default);
}

.networkContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.network {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 0.25em;
  cursor: pointer;
}

.activeNetwork {
  font-size: 0.75rem;
}

.chevronIcon {
  width: 0.75em;
  height: 0.75em;
  fill: var(--main-color);
}

.networkDropdownContainer {
  width: max-content;
  position: absolute;
  top: 0.75em;
  right: 0;
  visibility: hidden;
  cursor: pointer;
}

.networkContainer:hover .networkDropdownContainer {
  animation: drop 300ms forwards;
  visibility: visible;
}

.networkDropdown {
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1.75em;
  background: var(--default);
  border-radius: 0.25em;
  padding: 0.5em 1em;
  text-align: center;
}

.networkDropdown:hover {
  background: var(--card-light);
}

.network:hover .networkDropdown {
  visibility: visible;
  transform: translateY(100%);
}

.network .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f1b56e;
}

.network .dot.mainnet {
  background: #6be26b;
}

.dropdownContainer {
  width: max-content;
  top: 2em;
  position: absolute;
  visibility: hidden;
}

.connectedContainer:hover .dropdownContainer {
  animation: drop 300ms forwards;
  visibility: visible;
}

@keyframes drop {
  from {
    transform: translateY(-1em);
  }
  to {
    transform: translateY(0);
  }
}

.dropdown {
  width: 16em;
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1em;
  background: var(--default);
  border-radius: 0.5em;
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 1em;
  cursor: pointer;
}

.option:hover {
  background: var(--card-light);
}

.option img {
  width: 1.2em;
}

@media screen and (max-width: 1000px) {
  .connect {
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .container {
    justify-content: space-between;
  }
}
