.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.topNav {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  position: sticky;
  top: 3.6em;
  background: var(--default);
  padding: 2em 0;
  z-index: 10;
}

.backBtn {
  padding: 0.5em 1em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.backBtn img {
  width: 1.2em;
  transition: all 300ms;
}

.backBtn:hover img {
  transform: translateX(-50%);
}

.paginate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  padding: 0.25em;
}

.paginate input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
}

.pageControl {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  border: 2px solid transparent;
}

.pageControl.next {
  color: var(--default);
  background: var(--main-color);
  box-shadow: var(--btn-open);
}

.pageControl.next:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.pageControl.prev {
  color: var(--text-sub-heading);
  background: var(--default);
  box-shadow: var(--btn-open);
}

.pageControl.prev:hover {
  background: var(--card-light);
  box-shadow: var(--btn-close);
}

.pageControl.goto {
  background: var(--card-preview);
  color: var(--default);
  box-shadow: var(--btn-open);
}

.pageControl.goto:hover {
  background: var(--card-layer);
  box-shadow: var(--btn-close);
}

.pageCount {
  white-space: nowrap;
  color: var(--text-sub-heading);
}

.gotoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.display {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.assetWrapper {
  width: 100%;
  max-width: 20em;
  height: 20em;
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  border: 4px solid transparent;
  transition: all 300ms;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.assetWrapper img {
  width: 100%;
  height: 20em;
  object-fit: cover;
}

.assetOverlay {
  position: absolute;
  width: 100%;
  height: 50%;
  background: var(--main-color);
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: all 300ms;
  padding: 0.5em;
  color: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.assetName {
  color: var(--default);
}

.assetWrapper:hover .assetOverlay {
  transform: translateY(0);
}

.assetWrapper:hover {
  border: 4px solid var(--main-color);
}

.attrBtn {
  width: 100%;
  margin-top: 1em;
  background: var(--default);
  color: var(--main-color);
  padding: 0.75em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
}

.attrBtn:hover {
  background: var(--main-lighter);
  color: var(--main-hovered);
}

/* Collection Profile Image Select */
.assetWrapperSelect {
  width: 100%;
  max-width: 14em;
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  border: 4px solid transparent;
  transition: all 300ms;
  display: flex;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: var(--shadow-light);
  border-radius: 20px;
  cursor: var(--cursor-pointer);
}
.assetWrapperSelect:hover {
  background: var(--main-color);
}
.assetWrapperSelect img {
  border-radius: 20px;
  width: 100%;
}

.assetWrapperActive {
  background: var(--main-color);
}

.buttonWrapper {
  display: flex;
  margin-top: 2.5rem;
  font-weight: 500;
  font-size: 1.25rem;
  justify-content: center;
}
.buttonWrapper p,
.buttonWrapper div {
  border: 3px solid var(--main-color);
  border-radius: 37px;
  height: 2.8em;
  width: 9.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
  transition: 0.3s ease;
}
.buttonWrapper p {
  margin-right: 1em;
  color: var(--main-color);
}
.buttonWrapper p:hover {
  background: var(--main-color);
  color: white;
}
.buttonWrapper div {
  background: var(--main-color);
  color: white;
}
.buttonWrapper div:hover {
  background: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .topNav {
    flex-direction: column;
    top: 2.8em;
    gap: 0.5em;
    padding: 0.5em 0;
  }

  .paginate input,
  .pageCount,
  .backBtn,
  .pageControl {
    font-size: 0.75rem;
  }

  .backBtn {
    align-self: flex-start;
  }

  .paginate {
    width: 100%;
  }
  .buttonWrapper {
    font-size: 0.9rem;
  }
}
