.sidebar {
  border: 1px solid var(--card-outline);
  width: 100%;
  max-width: 20em;
  position: sticky;
  top: 5em;
}

.sideOverflowWrapper {
  width: 100%;
  height: calc(100vh - 5.5em);
  overflow: auto;
}

.label {
  font-size: 12px;
}

.filterHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  border-bottom: 1px solid var(--card-outline);
}

.filterHeading:hover {
  background: var(--main-lightest);
}

.filterHeading > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.filterHeading span {
  color: var(--main-color);
}

.leftArrow {
  width: 1em;
  height: 1em;
}

.sort,
.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.5em 1em;
  text-transform: capitalize;
}

.attributeFilter .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 1em;
  border-bottom: 2px solid var(--outline);
  border-top: 2px solid var(--outline);
}

.priceFilter {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}

.priceFilter .filterInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.priceFilter .filterInput input {
  padding: 0.75em;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
}

.priceFilter > div {
  width: auto;
  flex: 1;
}

.priceFilter input {
  width: 100%;
}

.priceFilter button {
  background: var(--main-color);
  color: white;
  padding: 1em;
  border-radius: 0.25em;
  transition: all 300ms;
}

.priceFilter button:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}

.attributeFilter .title {
  cursor: var(--cursor-pointer);
}

.attribute {
  max-height: 0em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.attribute.active {
  max-height: 1000vh;
  padding: 1em;
  overflow: auto;
}

.layerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: var(--cursor-pointer);
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--outline);
}

.attributeName {
  max-width: 10em;
  overflow: auto;
  white-space: nowrap;
}

.layerIcon {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.layerIcon img {
  transform: rotate(0deg);
}

.layerIcon.active img {
  transform: rotate(180deg);
}

.layer {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  border-radius: 0.25em;
  background: var(--default);
}

.layer.active {
  max-height: 10em;
  overflow: auto;
  visibility: visible;
  transform: translateY(4px);
}

.value {
  cursor: var(--cursor-pointer);
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  padding: 0.25em;
  border-radius: 0.25em;
}

.value:hover {
  background: var(--outline);
  cursor: pointer;
}

.statusIcon {
  width: 1.4em;
  height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2em;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.statusIcon.active {
  background: var(--main-color);
  border: 1px solid var(--main-color);
}

.markIcon {
  fill: var(--default);
}

.sidebar2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 5.5em);
  border-right: 1px solid var(--outline);
  position: sticky;
  top: 5em;
  cursor: var(--cursor-pointer);
  border-top-left-radius: 0.5em;
  overflow: hidden;
}

.sidebar2 img {
  width: 100%;
  padding: 1em;
  transition: all 300ms;
}

.valuesOfAttr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.attributeValue {
  width: 7em;
  overflow: auto;
}

.sidebar2 img:hover {
  background: var(--main-lightest);
}

@media screen and (max-width: 768px) {
  .sidebar,
  .sidebar2 {
    display: none;
  }
}
