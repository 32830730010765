.container {
  width: 100%;
  margin-bottom: 8em;
  /* animation: breath 1650ms forwards; */
}

/* @keyframes breath {
  from {
    transform: scale(0.98) translateY(-2em);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.wrapper {
  width: 100%;
  padding-top: 2em;
  margin: 0 auto;
  margin-bottom: 8em;
}

.withBg {
  max-width: 100%;
  padding: 0;
  background: #f2f3ff;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 540px;
    padding: 0 2em;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 0 1em;
  }
} */
