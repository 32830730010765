.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 3em 1em;
}

.imageContainer {
  width: 12vmax;
}

.imageContainer img {
  width: 100%;
}

.container h1 {
  font-size: 2rem;
  text-align: center;
}

.container p {
  font-size: calc(0.5rem + 1vmax);
  text-align: center;
}
