.container {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.container.active {
  display: flex;
}

.wrapper {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  position: relative;
  animation: show 300ms alternate;
}

@keyframes show {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

.closeBtn {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.closeBtn:hover {
  box-shadow: var(--shadow-dark);
}

.content {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1em;
  padding: 2em 4em;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
}

.content h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.content h6 {
  font-size: 0.85rem;
  font-weight: 600;
}

.content p {
  font-size: 0.85rem;
  color: var(--outline-dark);
  margin-bottom: 1.5em;
}

.inputContainer {
  width: 100%;
  position: relative;
  margin-bottom: 1.5em;
}

.inputContainer label {
  position: absolute;
  font-size: 0.75rem;
  background: var(--default);
  top: 0;
  left: 1em;
  transform: translateY(-50%);
  font-weight: 600;
}

.inputContainer input {
  width: 100%;
  padding: 0.5em;
  padding-top: 1em;
  border: 2px solid var(--outline);
  border-radius: 0.25em;
}

.inputContainer input:focus {
  border: 2px solid var(--main-color);
  outline: none;
}

.inputContainer input::placeholder {
  color: var(--text-placeholder-light);
}

.content button {
  align-self: center;
  width: 100%;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  background: var(--main-disable);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: default;
}

.content button.active {
  background: var(--main-color);
  cursor: pointer;
}

.content button.active:hover {
  background: var(--main-hovered);
}

.errorMessage {
  margin-top: -3em;
  margin-bottom: 1em;
  font-size: 0.75rem;
  display: none;
  transition: all 300;
}

.errorMessage.error {
  display: inline-flex;
  color: var(--error-500);
}

@media screen and (max-width: 768px) {
  .content {
    padding: 2em;
  }
}
