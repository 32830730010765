.container {
  width: 100%;
  min-height: calc(100vh - 4em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.wrapper {
  width: 100%;
  max-width: 64em;
  padding: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  border-radius: 1em;
  border: 1px solid var(--outline);
  box-shadow: -26px 0px 30px -12px rgba(93, 134, 194, 0.03), 26px 18px 18px rgba(93, 134, 194, 0.03);
}

.heading {
  font-weight: 600;
}

.description {
  width: 90%;
  max-width: 32em;
  text-align: center;
}

.wrapper form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  gap: 2em;
}

.wrapper input {
  width: 36em;
  padding: 0.75em;
  border: 1px solid var(--outline-dark);
  border-radius: 0.25em;
  font-size: 1rem;
  outline: none;
}

.wrapper input::placeholder {
  color: var(--text-placeholder-light);
}

.wrapper button {
  width: 10em;
  text-align: center;
  padding: 0.75em;
  font-size: 1rem;
  border-radius: 0.7em;
  background: var(--main-disable);
  color: var(--default);
  cursor: default;
}

.wrapper button.active {
  background: var(--main-color);
  cursor: pointer;
  transition: all 300ms;
}

.wrapper button.active:hover {
  background: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1em;
  }

  .wrapper {
    padding: 4em 2em;
  }

  .successIcon {
    width: 8em;
    height: 8em;
  }

  .wrapper input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 4em 1em;
  }

  .description {
    font-size: 0.85rem;
  }

  .wrapper input {
    padding: 0.5em;
  }

  .wrapper button {
    font-size: 0.85rem;
  }
}
