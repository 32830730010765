.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  cursor: var(--cursor-pointer);
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.content {
  max-height: 0;
  overflow: hidden;
}

.content.active {
  max-height: 1000vh;
}

.dropdownIcon {
  transform: rotate(0);
}

.dropdownIcon.active {
  transform: rotate(180deg);
}
