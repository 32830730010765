.container {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2em 1em;
  padding-bottom: 6em;
  z-index: 10;
}

.headingWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.heading {
  color: var(var(--text-dark-800));
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5em;
}

.description {
  color: var(var(--text-dark-800));
  font-size: 1rem;
  text-align: center;
}

.description > span {
  color: var(--main-color);
  position: relative;
  line-height: 2em;
}

.description > span img {
  position: absolute;
  width: 100%;
  bottom: -0.25em;
  left: 0;
}

.mintSwitch {
  margin-bottom: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7em;
  background: var(--card-light);
  padding: 0.25em;
  gap: 0.5em;
}

.mintSwitch > button {
  width: 10em;
  border-radius: 0.7em;
  padding: 0.5em 0;
  font-size: 1rem;
  background: var(--card-light);
  color: var(--text-heading);
  transition: all 300ms;
}

.mintSwitch > button:hover {
  background: var(--default);
  color: var(--main-color);
  cursor: var(--cursor-hover);
}

.mintSwitch > button.active {
  background: var(--main-color);
  color: var(--default);
}

.mintSwitch > button.active:hover {
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-hover);
  margin: unset;
}

.cardPlaceholder {
  width: 100%;
  height: 21em;
}

.card {
  width: 100%;
  max-width: 36em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  border-radius: 1em;
  border: 2px dashed var(--main-color);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: all 300ms;
}

.card:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  cursor: var(--cursor-hover);
}

.card.collection {
  animation: slide-left 850ms forwards;
  transform: translateX(2%);
}

.card._1of1 {
  animation: slide-right 850ms forwards;
  transform: translateX(-2%);
}

@keyframes slide-right {
  from {
    transform: translateX(2%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-left {
  from {
    transform: translateX(-2%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.card > img {
  width: 6em;
}

.imagePlaceholder {
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: var(--card-light);
}

.title {
  color: var(--main-color);
  font-size: 1.2rem;
}

.action {
  color: var(--text-dark-900);
  text-align: center;
}

.supportedFiles {
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-sub-heading);
}

.btn {
  width: 10em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  color: var(--main-color);
}

.btn:hover {
  color: var(--main-hovered);
}

.goBack {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 2em;
}

.backIcon {
  transition: all 300ms;
  width: 1.2em;
}

.backIcon:hover {
  transform: translateX(-50%);
}

.takePic {
  display: flex;
  align-items: center;
  cursor: var(--cursor-pointer);
  border-radius: 100px;
  background: #0d99ff;
  padding: 0 2rem;
  transition: all 300ms;
}
.takePic:hover {
  background: var(--main-hovered);
}
.takePic p {
  font-size: 1rem;
  transition: color 0.2s ease;
  color: var(--default);
}
.takePic div {
  border-radius: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.4em;
  margin-right: 0.5em;
}
.explanatoryText {
  width: 100%;
  text-align: center;
}
.explanatoryText div {
  font-size: 0.9375rem;
  font-weight: 500;
}
.explanatoryText p {
  font-size: 0.8125rem;
  color: var(--text-sub-heading);
  line-height: 1.5rem;
}

@media screen and (max-width: 480px) {
  .backIcon {
    width: 0.85em;
  }
}
