.container {
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding: 2em;
  overflow: hidden;
}

.title {
  color: #0d99ff;
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 120%;
}
.title span {
  color: var(--text-dark-600);
}
.description {
  font-size: 1.125rem;
  margin-top: 1.7em;
  line-height: 115%;
  max-width: 670px;
  color: var(--text-dark-600);
}
.downloadBtnWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 8.5rem;
}
.downloadBtnWrapper a {
  color: var(--main-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 160%;
  border: 2px solid #0d99ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5em;
  height: 3.625em;
  cursor: pointer;
  transition: 300ms ease;
}
.downloadBtnWrapper a:hover {
  background: var(--main-color);
  color: var(--default);
}
.topBg {
  position: absolute;
  display: flex;
  height: fit-content;
  right: 0;
  width: 50%;
  z-index: -1;
}
.topBg svg {
  width: 100%;
  height: fit-content;
  margin-top: -25%;
}
.subHeader {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 120%;
  color: var(--text-dark-600);
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-right: 1.8rem;
  position: relative;
  padding-bottom: 12px;
}
.subHeader p {
  background: var(--main-color);
  border-radius: 11px;
  font-size: 1rem;
  height: 0.5em;
  width: 66px;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}
.logoWrapper {
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 774px;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 8.5rem;
}
.logoWrapper > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.logoWrapper > div p {
  color: var(--text-dark-600);
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 115%;
}
.logoWrapper > div svg:first-child {
  display: flex;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: 300ms ease-in;
}
.logoWrapper > div svg:last-child {
  position: absolute;
  width: 30%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.logoWrapper > div svg:last-child path {
  fill: inherit;
}
.logoWrapper > div a {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
}
.logoWrapper > div a:hover svg:first-child path {
  opacity: 0;
}
.logoWrapper > div a:hover svg:last-child {
  opacity: 1;
}

.colorSubHeader {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 115%;
  color: var(--text-dark-600);
  margin: 4rem 0 1rem;
}
.colorPalaett {
  display: flex;
  flex-wrap: wrap;
  max-width: 1016px;
}
.colorPalaett div {
  position: relative;
  width: 12.5%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.colorPalaett div p {
  color: inherit;
  transition: 300ms ease;
}
.colorPalaett div svg {
  position: absolute;
  height: 40%;
  width: auto;
  fill: var(--default);
  opacity: 0;
  transition: 300ms ease;
}

.colorPalaett div:hover svg {
  opacity: 1;
}
.colorPalaett div:hover p {
  opacity: 0;
}
.colorPalaett div svg path {
  fill: inherit;
}
.colorPalaett div:first-child {
  width: 100%;
  justify-content: flex-start;
  padding-left: 29px;
}
.whiteColorPalaett div:first-child {
  color: var(--default);
}
.colorPalaett div:last-child {
  color: var(--text-dark-600);
}
.colorPalaett div:last-child svg {
  fill: var(--text-dark-600);
}
.darkColorPalaett div:last-child {
  color: var(--default);
}
.darkColorPalaett div:last-child svg {
  fill: var(--default);
}
.whiteColorPalaett div {
  color: var(--text-dark-600);
}
.whiteColorPalaett {
  margin-bottom: 8.5rem;
}
.whiteColorPalaett div svg {
  fill: var(--text-dark-600);
}
.fontsDownload {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.fonts {
  max-width: 1016px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
.fonts > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.fonts > div p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 118%;
  color: var(--text-dark-600);
}
.fonts > div div {
  font-size: 1.25rem;
  line-height: 118%;
  color: var(--text-dark-600);
}
@media screen and (max-width: 785px) {
  .colorPalaett div {
    width: 20%;
  }
}
@media screen and (max-width: 480px) {
  .logoWrapper {
    justify-content: center;
  }
  .colorPalaett div {
    width: 25%;
  }
}
@media screen and (max-width: 380px) {
  .colorPalaett div {
    width: calc(100% / 3);
  }
}
