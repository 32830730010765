/* -------------------------------- MarketplaceView -------------------------------- */
.marketplace {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
  gap: 1rem;
}

.marketplace .btn {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: all 300ms;
}

.lock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(170, 219, 255, 0.4);
  border-radius: 6.5px;
  padding: 10px;
}
.lock > img {
  margin-right: 6px;
  width: 16px;
}
.lock > span {
  font-size: 14px;
}

.marketplace .btn:hover {
  color: var(--default);
  background: var(--main-color);
}

.marketplace .btn.disable {
  border: 1px solid transparent;
  background: var(--card-outline);
  color: white;
  padding: 0.5em 1em;
}

.marketplace .priceLabel {
  color: #a4a9b6;
}

.marketplace .amount .accent {
  font-weight: 700;
  font-size: 0.85rem;
}
.marketplace .amount span {
  color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .marketplace .owner .priceLabel {
    font-size: 0.85rem;
  }

  .marketplace .btn {
    padding: 0.25em 2em;
  }
}
/* -------------------------------- OnSalveView -------------------------------- */
.onSale {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}

.onSale .btn {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: all 300ms;
}

.onSale .btn:hover {
  color: var(--default);
  background: var(--main-color);
}

.onSale .btn.disable {
  border: 1px solid transparent;
  background: var(--card-outline);
  color: white;
  padding: 0.5em 1em;
}

.onSale .priceLabel {
  color: #a4a9b6;
}
.onSale .amount {
  display: flex;
  align-items: baseline;
}
.onSale .amount .accent {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: baseline;
}

.onSale .amount .accent span {
  font-size: 0.625rem;
  font-weight: inherit;
}
.onSale .amount div {
  font-size: 0.625rem;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .onSale .owner .priceLabel {
    font-size: 0.85rem;
  }

  .onSale .btn {
    padding: 0.25em 2em;
  }
}
/* -------------------------------- CollectedeView -------------------------------- */
.collected {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}
.collected .list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.3em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  filter: drop-shadow(0px 3.18248px 3.18248px rgba(38, 50, 56, 0.13));
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.875rem;
  transition: 300ms ease;
}
.collected .list:hover {
  color: var(--default);
  background: var(--main-color);
}
.collected .listed {
  background: #e5e8eb;
  color: #000;
  border-color: #e5e8eb;
}
.collected .listed:hover {
  color: #000;
  background: #e5e8eb;
}
