.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--default);
  box-shadow: var(--shadow-light);
  z-index: 1;
  padding: 0.5em 2em;
  gap: 8em;
}

.logoContainer {
  position: relative;
  cursor: var(--cursor-pointer);
  overflow: hidden;
}

.drop {
  width: 1em;
  position: absolute;
  bottom: 0.5em;
  right: 0.75em;
  visibility: hidden;
  opacity: 0;
}

.logoContainer:hover .drop {
  transform: translateY(100%);
  animation: drop 650ms;
}

.logoContainer:hover .logoDesktop,
.logoContainer:hover .logoMobile {
  transform: translate(1px, 1px);
}

.logoMobile,
.logoDesktop {
  transition: all 650ms;
}

@keyframes drop {
  from {
    transform: translateY(0%);
    opacity: 0;
    visibility: hidden;
  }

  to {
    transform: translateY(200%);
    opacity: 1;
    visibility: visible;
  }
}

.logoDesktop {
  width: 4em;
}

.logoMobile {
  display: none;
}

.searchAndNavWrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 6em;
}

.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--default);
}

.navList {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 3em;
  font-size: 1rem;
  text-transform: capitalize;
}

.navItem {
  font-weight: 500;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  transition: all 100ms;
}

.navItem li {
  padding: 0.25em 0;
  cursor: var(--cursor-pointer);
}

.line {
  width: 0%;
  background: var(--main-color);
  height: 2.5px;
  transition: all 300ms;
}

.navItem:hover .line {
  width: 60%;
}

.navItem.active .line {
  width: 100%;
}

.closeIcon,
.iconOpen {
  display: none;
  cursor: var(--cursor-pointer);
}

.closeIcon {
  fill: var(--icon-color);
  width: 1.2em;
  height: 1.2em;
}

.walletAuthContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.wallet {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.walletAuthContainer.mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .container {
    gap: 4em;
  }
}

@media screen and (max-width: 1200px) {
  .searchAndNavWrapper {
    gap: 4em;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    background: var(--default);
  }

  .navContainer.active {
    border: 1px solid var(--outline);
    box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  }

  .navList {
    margin-right: 0;
  }

  .navItem {
    padding: 0.5em;
  }

  .logoDesktop {
    display: none;
  }

  .logoMobile {
    display: block;
    width: 3em;
  }

  .closeIcon,
  .iconOpen {
    display: block;
  }

  .searchAndNavWrapper {
    align-items: center;
  }

  .navContainer {
    width: 100%;
    position: absolute;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 3em;
    right: 0;
    top: 3.4em;
    padding: 0 2em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 650ms;
  }

  .navContainer.active {
    max-height: 22em;
    justify-content: center;
    align-items: center;
  }

  .navList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .navList > * {
    width: 100%;
  }

  .navList li {
    font-weight: normal;
  }

  .navItem {
    width: 100%;
    text-align: center;
    font-weight: 700;
    border: 1px solid var(--outline);
    border-radius: 0.7em;
  }

  .navItem:hover {
    cursor: var(--cursor-hover);
    color: var(--default);
    background: var(--main-color);
  }

  .navItem:hover li {
    color: var(--default);
  }

  .line {
    display: none;
  }

  .walletAuthContainer {
    display: none;
    width: 100%;
    margin-top: 3em;
  }

  .walletAuthContainer.mobile {
    display: flex;
  }

  .wallet {
    width: 100%;
  }

  .drop {
    bottom: 0;
    right: 0.5em;
  }
}

@media screen and (max-width: 480px) {
  .navContainer {
    max-width: 100%;
    right: 0;
    top: 2.9em;
  }

  .searchAndNavWrapper {
    gap: 1em;
  }

  .container {
    gap: 1em;
  }

  .logoContainer {
    margin-right: 0;
  }
}
