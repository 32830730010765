.container {
  max-width: 26em;
  border-radius: 0.7em;
  background: var(--default);
  position: fixed;
  right: 1em;
  bottom: 1em;
  transition: all 650ms;
  transform: translateY(calc(100% + 1em));
  z-index: 20;
  padding: 2.5em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1em;
  border: 1px solid var(--outline);
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
}

.container.active {
  transform: translateY(0);
}

.closeIcon {
  fill: var(--icon-color);
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.closeIcon:hover {
  box-shadow: var(--shadow-dark);
}

.title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--main-color);
}

.description {
  text-align: center;
  color: var(--text-sub-heading);
  margin-bottom: 0.5em;
  font-size: 0.95rem;
}

.list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  gap: 1em;
}

.list > div {
  color: var(--text-sub-heading);
}

.listIcon {
  fill: var(--error-300);
}

.upgradeBtn {
  width: auto;
  background: var(--main-color);
  padding: 0.75em 2em;
  border-radius: 0.7em;
  box-shadow: var(--btn-open);
  transition: all 300ms;
  align-self: center;
  color: var(--default);
  cursor: pointer;
  margin-top: 1em;
}

.upgradeBtn:hover {
  box-shadow: var(--btn-close);
}
