@import url(https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400&family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --btn-open: 0px 3.03px 3.03px rgba(0, 0, 0, 0.2);
  --btn-close: 0px 0px 3.03px rgba(0, 0, 0, 0.2);

  --shadow-dark: 3px 3px 6px rgba(0, 0, 0, 0.2);
  --shadow-light: 2px 2px 4px rgba(0, 0, 0, 0.2);

  --default: #ffffff;
  --main-color: #0d99ff;
  --main-hovered: #0082e0;
  --main-light: #62bdff;
  --main-lighter: #dbf0ff;
  --main-lightest: #f3faff;
  --main-disable: #ccecff;

  --text-heading: #0f1d40;
  --text-sub-heading: #525c76;
  --text-light: #6c757d;
  --text-placeholder: #b2b7c2;
  --text-placeholder-light: #d3d6de;

  --outline: #e5e8eb;
  --card-outline: #c4c4c4;
  --outline-dark: #a4a9b6;

  --icon-color: #4b4b5a;

  --card-bg: #626277;
  --card-bg-light: #737387;
  --card-icon-color: #515164;
  --card-shade: #646a89;
  --card-preview: #646a89;
  --card-layer: #585e7d;
  --card-tooltip: #686f93;
  --card-info: #e0f2ff;
  --card-upload: #f6faff;
  --card-trait: #f4f5f6;
  --card-light: #eff3f9;
  --card-lighter: #fcfdff;
  --btn-close: #eef5f9;

  --success-100: #ebfcd5;
  --success-200: #d4f9ac;
  --success-300: #b1ee80;
  --success-400: #8fde5d;
  --success-500: #5fc92e;
  --success-600: #44ac21;
  --success-700: #2d9017;
  --success-800: #1a740e;
  --success-900: #0d6008;

  --warning-100: #fff5ce;
  --warning-200: #ffe89e;
  --warning-300: #ffd76d;
  --warning-400: #ffc749;
  --warning-500: #ffad0d;
  --warning-600: #db8c09;
  --warning-700: #b76f06;
  --warning-800: #935404;
  --warning-900: #7a4102;

  --error-100: #ffe3d6;
  --error-200: #ffc0ad;
  --error-300: #ff9783;
  --error-400: #ff6f65;
  --error-500: #ff3236;
  --error-600: #db2438;
  --error-700: #b71938;
  --error-800: #930f35;
  --error-900: #7a0933;

  --text-dark-100: #747c90;
  --text-dark-200: #656e85;
  --text-dark-300: #5c657d;
  --text-dark-400: #525c76;
  --text-dark-500: #49536e;
  --text-dark-600: #3a4662;
  --text-dark-700: #2c3857;
  --text-dark-800: #192648;
  --text-dark-900: #0f1d40;

  --cursor-default: default;
  --cursor-pointer: pointer;
  /* --cursor-disabled: url("./assets/cursor-disabled.svg"), auto; */
  --cursor-grab: grab;
  --cursor-hover: default;
  /* --cursor-scopeg: url("./assets/cursor-scope.svg"), auto;
  --cursor-camera: url("./assets/cursor-camera.svg"), auto;
  --cursor-default-disabled: url("./assets/cursor-default-disabled.svg"), auto;
  --cursor-default-loading: url("./assets/cursor-default-loading.svg"), auto; */
}

li {
  list-style-type: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "SF Pro Display", sans-serif;
  color: #0f1d40;
  color: var(--text-heading);
  line-height: 1.5em;
}

a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
  cursor: var(--cursor-pointer);
}

.App {
  width: 100%;
  cursor: default;
  cursor: var(--cursor-default);
  position: relative;
}

.App * {
  transition: width max-width font-size 150ms;
}

.Routes {
  width: 100%;
  min-height: calc(100vh - 21em);
}

.topSectionContainer {
  z-index: 20;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* width */
::-webkit-scrollbar {
  width: inherit;
  height: 6px;
  width: 10px;
  border-radius: 1em;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 0px;
}

::-webkit-scrollbar-corner {
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}

button {
  cursor: pointer;
  cursor: var(--cursor-pointer);
  border: none;
  outline: none;
  background: transparent;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Thin.c656fd92.otf) format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Ultralight.8ce49c80.otf) format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Light.916b3ad9.otf) format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Regular.d9591982.otf) format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Medium.e1d844da.otf) format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Semibold.f50e0151.otf) format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Bold.170e68ac.otf) format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SF-Pro-Display-Heavy.a48bcc07.otf) format("opentype");
  font-weight: 900;
}

.preview_wrapper__1UVlO {
  width: 100%;
  padding: 0 1em;
  padding-top: 2em;
}

.preview_container__Zyf50 {
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
  margin: 0 auto;
  margin-bottom: 4em;
  min-height: 100vh;
}

.preview_sidebar__PhBtC {
  min-width: 20em;
  position: -webkit-sticky;
  position: sticky;
  top: 9em;
  border: 1px solid #c4c4c4;
  border-radius: 0.5em;
}

.preview_display__32O6n {
  width: auto;
}

.preview_collectionDetail__1a-0J {
  width: 100%;
  padding: 1em 1.6em;
}

.preview_collectionDetail__1a-0J .preview_wrapper__1UVlO {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.25em;
  margin-bottom: 2em;
  border: 1px solid var(--card-outline);
}

.preview_collectionDetail__1a-0J img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.preview_collectionDetail__1a-0J textarea {
  resize: none;
  width: 100%;
  border-radius: 0.25em;
  border: 1px solid var(--card-outline);
  padding: 0.6rem 0rem 0.3rem 0.8rem;
}

.preview_collectionDetail__1a-0J textarea:focus {
  outline: 1px solid var(--outline);
}

.preview_collectionDetail__1a-0J .preview_tab__2zpvp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.preview_collectionDetail__1a-0J .preview_toggleContainer__1ZDuV {
  width: 36px;
  border-radius: 0.75em;
  padding: 0px 0;
  background: var(--default);
  cursor: var(--cursor-pointer);
  padding: 2px 2px;
  transition: all 300ms;
  border: 1px solid var(--card-outline);
}

.preview_collectionDetail__1a-0J .preview_toggleContainer__1ZDuV.preview_active__1vVOR {
  background: var(--main-color);
  border: 1px solid var(--outline);
}

.preview_collectionDetail__1a-0J .preview_toggle__2PpnR {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 300ms;
  background: gray;
}

.preview_collectionDetail__1a-0J .preview_toggle__2PpnR.preview_active__1vVOR {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background: var(--default);
}

.preview_actionContainer__1-89Z {
  padding: 1em 0.8em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.preview_ipfs_tip__1jr5H {
  text-align: center;
}

.preview_actionContainer__1-89Z h3 {
  margin-top: -20px;
  background: var(--default);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 6px;
  margin-left: -5px;
  padding-left: 5px;
}

.preview_actionContainer__1-89Z label {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.75em;
  border-radius: 0.25em;
  background: var(--card-layer);
  color: var(--default);
}

.preview_actionContainer__1-89Z .preview_btnWrapper__zll7g > button {
  border: 1px solid var(--main-color);
  width: 100%;
  border: none;
  height: 3em;
  font-size: 1rem;
  border-radius: 0.25em;
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.preview_actionContainer__1-89Z .preview_btnWrapper__zll7g > button:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}

.preview_preview__1ifS2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
}

.preview_card__2T5Wo {
  min-width: 12em;
  max-width: 20em;
  padding: 0.5rem;
  position: relative;
}

.preview_cardActive__1nyws {
  background: rgba(0, 153, 135, 0.3);
}

.preview_iconClose__3v7Bv {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background: var(--default);
  border-radius: 0.15em;
  transition: all 300ms;
}

.preview_iconClose__3v7Bv img {
  width: 0.7em;
  margin: 0;
}

.preview_asset__15w6a {
  display: inline-block;
  width: 100%;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  border: 1px solid var(--outline);
}

.preview_cardBody__3V6HX {
  background: var(--card-layer);
  color: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.preview_cardBody__3V6HX .preview_textWrapper__ykV4I {
  width: 100%;
  color: var(--text-dark-900);
}

.preview_cardBody__3V6HX .preview_textWrapper__ykV4I {
  color: var(--default);
}

.preview_cardBody__3V6HX textarea {
  width: 100%;
  background: var(--card-shade);
  color: var(--default);
  padding: 0.25em;
  resize: none;
  border-radius: 0.25em;
  outline: none;
}

.preview_cardBody__3V6HX textarea::-webkit-input-placeholder {
  color: var(--text-placeholder);
}

.preview_cardBody__3V6HX textarea:-ms-input-placeholder {
  color: var(--text-placeholder);
}

.preview_cardBody__3V6HX textarea::placeholder {
  color: var(--text-placeholder);
}

.preview_buttonContainer__3ooa2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.preview_buttonContainer__3ooa2 button {
  flex: 1 1;
  padding: 0.5em 0em;
  white-space: nowrap;
  border-radius: 0.25em;
  border: 1px solid;
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 150ms;
  outline: 1px solid transparent;
  font-weight: 600;
  font-size: 0.6em;
}

.preview_buttonContainer__3ooa2 button:last-child {
  border-color: var(--default);
}

.preview_buttonContainer__3ooa2 button:first-child,
.preview_nftDonwload__3j76Z {
  background: var(--default);
  border-color: var(--default);
  color: var(--main-color);
}

.preview_buttonContainer__3ooa2 button:last-child:hover {
  background: var(--card-layer);
  outline: 2px solid var(--card-layer);
  border-color: transparent;
}

.preview_buttonContainer__3ooa2 button:first-child:hover,
.preview_nftDonwload__3j76Z {
  background: var(--main-hovered);
  border-color: var(--main-hovered);
  color: var(--default);
}

.preview_foramtWrapper__115Kk {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  border: 1px solid #c4c4c4;
  padding: 0.6rem 0.8rem;
  padding-bottom: 1.3rem;
}

.preview_foramtWrapper__115Kk p {
  color: var(--default);
}

.preview_radioBtn__hiBfS {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
}

.preview_radioBtn__hiBfS::before {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--card-layer);
  position: absolute;
  content: "";
  outline: 2px solid var(--default);
  outline-offset: 2px;
}

.preview_radioBtn__hiBfS.preview_clicked__25aih::before {
  background: var(--default);
}

.preview_paginate__3yGb0 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 4em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  padding: 0.25em;
}

.preview_paginate__3yGb0 input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.25em;
}

.preview_pageControl__3RBaJ {
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--card-layer);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.preview_pageControl__3RBaJ:hover {
  outline: 2px solid gray;
}

.preview_pageCount__1AO9x {
  font-weight: bold;
  white-space: nowrap;
}

/* -------------------------------- GIF Genrator -------------------------------- */
.preview_btnWrapper__zll7g {
  padding: 0 0.8rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.preview_btnWrapper__zll7g > button.preview_gifButton__15I2- {
  margin-top: 2em;
  background: var(--default);
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: 0.3s ease-in;
}

.preview_btnWrapper__zll7g > button.preview_gifButton__15I2-:hover {
  background: var(--main-color);
  color: var(--default);
  outline: none;
}

.preview_gifButton__15I2- span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.75em;
}

.preview_cheked__JXLXJ {
  background: var(--main-color);
}

.preview_playTut__3QjFk {
  background: rgba(152, 162, 179, 0.32);
  border-radius: 4px;
  /* margin-left: 1em; */
  padding: 0.7em;
  display: flex;
  cursor: var(--cursor-pointer);
}

.preview_playTut__3QjFk svg {
  margin: auto;
  margin-right: 6px;
}

.preview_playTut__3QjFk svg {
  transition: 0.2s ease-in;
}

.preview_playTut__3QjFk:hover svg {
  -webkit-transform: translate(4px, 0px);
          transform: translate(4px, 0px);
}

.preview_mintButtonWrapper__Et5cv {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10%;
  grid-gap: 10%;
  gap: 10%;
  flex-wrap: wrap;
  margin: 1.5em 0;
  grid-template-columns: 45% 45%;
}

.preview_mintButtonWrapper__Et5cv button {
  flex: 1 1;
  height: 2.8em;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview_mintBtn__18_Z4 {
  background: var(--main-color);
  border: 2px solid var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
}

.preview_mintBtn__18_Z4 svg {
  margin-right: 8px;
}

.preview_mintBtn__18_Z4:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
  cursor: var(--cursor-pointer);
}

.preview_cancelBtn__28Pon {
  border: 2px solid var(--main-color);
  background: var(--default);
  transition: all 300ms;
  font-size: 1rem;
}

.preview_cancelBtn__28Pon:hover {
  border: 2px solid var(--main-hovered);
}

.preview_durationWrapper__2ELIv {
  padding: 0 0.5rem;
  width: 100%;
}

.preview_durationField__2rf0S {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview_durationLabel__251EV {
  display: flex;
  width: 100%;
  margin-bottom: 0.8em;
  font-size: 0.7em;
  justify-content: space-between;
}

.preview_durationLabel__251EV p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
}

.preview_durationLabel__251EV img {
  width: 30px;
  margin-left: 5px;
}

.preview_durationField__2rf0S div span {
  position: absolute;
  right: 1em;
  margin: auto 0;
  font-size: 1.1em;
  display: block;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.preview_durationInput__2ZtwN {
  display: flex;
  width: 100%;
  position: relative;
}

.preview_durationInput__2ZtwN input {
  height: 3em;
  padding-left: 1em;
  border-radius: 5px;
  border: 1px solid #adb5bd;
  background: var(--default);
  transition: all 300ms;
  font-size: 1rem;
  width: 100%;
}

.preview_durationInput__2ZtwN input:focus {
  border: 2px solid var(--main-color);
  outline: none;
}

.preview_durationInput__2ZtwN input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-placeholder);
  opacity: 1;
  /* Firefox */
}

.preview_durationInput__2ZtwN input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-placeholder);
  opacity: 1;
  /* Firefox */
}

.preview_durationInput__2ZtwN input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-placeholder);
  opacity: 1;
  /* Firefox */
}

/* Chrome, Safari, Edge, Opera */
.preview_durationInput__2ZtwN input::-webkit-outer-spin-button,
.preview_durationInput__2ZtwN input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.preview_durationInput__2ZtwN input[type="number"] {
  -moz-appearance: textfield;
}

.preview_numberCounter__H2P1M {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  width: 75px;
  justify-content: space-evenly;
}

.preview_numberCounter__H2P1M p {
  color: #adb5bd;
  display: flex;
  align-items: center;
  width: 50%;
}

.preview_verticalLine__3LDHt {
  width: 1px;
  background: #c4c4c4;
  height: 34px;
  margin: auto 0;
}

.preview_inputArrows__1ShZT {
  display: flex;
  flex-direction: column;
  height: 65%;
  margin: auto 0;
  justify-content: space-around;
  width: 50%;
  align-items: center;
}

.preview_inputArrows__1ShZT img {
  cursor: var(--cursor-pointer);
}

/* -------------------------------- GIF Model -------------------------------- */
.preview_modelContainer__1rXet {
  display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.preview_modelActive__3FnoW {
  display: flex;
}

.preview_guideContainer__wINYz {
  width: 100%;
  max-width: 48em;
  border-radius: 1em;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.preview_guideContainer__wINYz > svg {
  margin-left: auto;
  margin-bottom: 0.7em;
  cursor: var(--cursor-pointer);
  width: 25px;
  height: 25px;
}

.preview_guideContainer__wINYz > svg > path {
  fill: var(--icon-color);
}

.preview_imgContainer__3W9Wr {
  border: 1px solid var(--card-outline);
  display: flex;
  padding: 1em 1.5em 1.5em;
  flex-direction: column;
}

.preview_modelPreview__w9AKL {
  max-height: 24em;
  overflow-y: scroll;
  grid-template-columns: repeat(3, 1fr);
}

.preview_closeIcon__2KVmA path {
  fill: #f1423b;
}

.preview_gifAllBtn__3FtVh {
  display: flex;
  margin-top: 1.4em;
  justify-content: flex-end;
}

.preview_gifAllBtn__3FtVh p,
.preview_gifAllBtn__3FtVh div {
  border-radius: 40px;
  width: 13em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 0.8em;
  cursor: var(--cursor-pointer);
}

.preview_gifAllBtn__3FtVh div {
  background: var(--main-color);
  color: var(--default);
  border: 1px solid var(--main-color);
  margin-left: 20px;
}

.preview_gifAllBtn__3FtVh div:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
}

.preview_gifAllBtn__3FtVh p {
  color: #f1423b;
  border: 1px solid #f1423b;
}

.preview_gifAllBtn__3FtVh p:hover {
  background: #f1423b;
  color: #fff;
  border: 2px solid #f1423b;
}

.preview_sidebarActive__PtVzt {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 9em);
}

@media screen and (max-width: 1280px) {
  .preview_preview__1ifS2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1060px) {
  .preview_durationLabel__251EV {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .preview_playTut__3QjFk {
    margin-bottom: 10px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .preview_preview__1ifS2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .preview_modelPreview__w9AKL {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .preview_container__Zyf50 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .preview_sidebar__PhBtC {
    width: 100%;
    position: static;
  }

  .preview_sidebarActive__PtVzt {
    overflow-y: unset;
    height: auto;
  }

  .preview_modelPreview__w9AKL {
    grid-template-columns: repeat(2, 1fr);
    max-height: 30em;
  }
}

@media screen and (max-width: 480px) {
  .preview_preview__1ifS2 {
    grid-template-columns: auto;
    justify-items: center;
  }

  .preview_paginate__3yGb0 * {
    font-size: 0.75rem;
  }

  .preview_backBtn__2G395 {
    width: 2em;
    height: 2em;
    position: static;
  }
}

/* Animation Keyframes*/
@keyframes preview_loopplay__3w0yO {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }

  100% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
}

@-webkit-keyframes preview_loopplay__3w0yO {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }

  100% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
}

.GenadropTooltip_container__1keR4 {
  width: 100%;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.GenadropTooltip_container__1keR4 svg {
  width: 1em;
  height: 1em;
  /* border: 1px solid var(--main-color); */
  border-radius: 50%;
  cursor: var(--cursor-pointer);
}

.GenadropTooltip_container__1keR4:hover::before {
  content: "";
  position: absolute;
  top: -0.25em;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  border-top: 1em solid #626277;
  border-right: 1em solid transparent;
  border-left: 1em solid transparent;
}

.GenadropTooltip_card__2jfqA {
  width: -webkit-max-content;
  width: max-content;
  max-width: 20em;
  border-radius: 0.25em;
  padding: 0.5em;
  padding-bottom: 1em;
  text-align: center;
  position: absolute;
  top: -1.25em;
  font-size: 0.85rem;
  background: #626277;
  color: var(--default);
  -webkit-transform: translateX(0) translateY(-100%);
          transform: translateX(0) translateY(-100%);
  visibility: hidden;
  z-index: 10000;
}

.GenadropTooltip_container__1keR4:hover .GenadropTooltip_card__2jfqA {
  visibility: visible;
}

.text-editor_container__2BZCW {
  width: 100%;
}

.text-editor_container__2BZCW form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.text-editor_container__2BZCW form textarea,
.text-editor_container__2BZCW form input {
  flex: 1 1;
  padding: 0.25em;
  border: none;
  outline: 1px solid var(--outline);
}

.text-editor_container__2BZCW button {
  border: none;
  outline: none;
  cursor: var(--cursor-pointer);
  background: transparent;
}

.text-editor_container__2BZCW button img {
  color: white;
}

.text-editor_container__2BZCW div {
  width: 100%;
  display: flex;
  align-items: ce;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.text-editor_container__2BZCW div p {
  flex: 1 1;
  padding: 0.15em;
  color: white;
}

.text-editor_container__2BZCW.text-editor_invert__1V2F8 div p {
  color: var(--card-bg);
}

.text-editor_editIcon__1-8Pj {
  fill: var(--card-bg);
}

.navbar_container__25Fam {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 3.7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  background: var(--default);
  z-index: 1;
  margin-bottom: 0.5em;
}

.navbar_detailsWrapper__1Gci6 {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 1em;
  gap: 1em;
  margin-left: 2em;
  flex-wrap: wrap;
}

.navbar_gifDetail__1qFbQ {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  background: var(--default);
  cursor: var(--cursor-pointer);
  border: 1px solid var(--main-color);
  margin-right: 1em;
}

.navbar_gifDetail__1qFbQ:hover {
  cursor: var(--cursor-hover);
  background: var(--main-color);
}

.navbar_gifDetail__1qFbQ:hover p {
  color: var(--default);
}

.navbar_gifDetail__1qFbQ p {
  margin: auto;
  font-weight: 500;
  color: var(--main-color);
  display: flex;
  align-items: center;
}

.navbar_gifDetail__1qFbQ span {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border: 1px solid var(--main-color);
  background: var(--main-color);
  color: var(--default);
}

.navbar_gifDetail__1qFbQ:hover span {
  color: var(--main-color);
  background: var(--default);
}

.navbar_detail__34Imk {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.65em 1em;
  font-size: 0.95rem;
  border-radius: 0.25em;
  border: 1px solid var(--card-layer);
  background: var(--default);
  color: var(--card-layer);
  transition: all 300ms;
}

.navbar_detail__34Imk.navbar_active__3i4pW {
  border: 2px solid var(--error-500);
}

.navbar_detail__34Imk.navbar_active__3i4pW span {
  color: var(--error-500);
}

.navbar_detail__34Imk.navbar_active__3i4pW span:last-child {
  background: var(--error-500);
  color: var(--default);
  border-color: transparent;
}

.navbar_detail__34Imk span:last-child {
  background: var(--card-layer);
  border: 1px solid var(--card-layer);
  color: var(--default);
  padding: 0 1em;
  border-radius: 0.25em;
}

.navbar_btnContainer__1tBRG {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar_btnContainer__1tBRG:hover .navbar_btnIcon__3lyNd {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.navbar_btnIcon__3lyNd {
  width: 1.5em;
  height: 1.5em;
  transition: all 300ms;
}

@media screen and (max-width: 1024px) {
  .navbar_detail__34Imk {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 768px) {
  .navbar_container__25Fam {
    top: 2.9em;
    border-radius: 0.5em;
    align-items: flex-start;
  }

  .navbar_detailsWrapper__1Gci6 {
    grid-gap: 0.5em;
    gap: 0.5em;
  }
}

@media screen and (max-width: 480px) {
  .navbar_btnIcon__3lyNd {
    width: 1em;
    height: 1em;
  }

  .navbar_detailsWrapper__1Gci6 {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.selectedGifArt_galleryGif__3mfx4 {
  background: #f6fdff;
  -webkit-backdrop-filter: blur(23px);
          backdrop-filter: blur(23px);
  border-radius: 7px;
  padding: 1rem;
  margin-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.selectedGifArt_galleryGifLine__1rpFv {
  height: 3px;
  width: 100%;
  box-shadow: var(--shadow-light);
  background: #ced4da;
  margin-bottom: 0.7rem;
}

.selectedGifArt_galleryGifInfo__1mrIA {
  color: var(--card-layer);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 1rem;
}

.selectedGifArt_galleryGifInfo__1mrIA > p {
  display: flex;
  align-items: center;
}

.selectedGifArt_galleryGifslides__1cjKI {
  display: flex;
  overflow-x: scroll;
  padding: 0 1rem;
}

/* width */
.selectedGifArt_galleryGifslides__1cjKI::-webkit-scrollbar {
  height: 20px;
}

/* Track */
.selectedGifArt_galleryGifslides__1cjKI::-webkit-scrollbar-track {
  background: #f3f4f7;
  border-radius: 0px 0px 8px 8px;
}

/* Handle */
.selectedGifArt_galleryGifslides__1cjKI::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 30px;
}

.selectedGifArt_galleryGifslides__1cjKI > div {
  position: relative;
  margin-right: 10px;
}

.selectedGifArt_galleryGifslides__1cjKI > div > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 9px;
  padding: 8px;
  background: #e5e8eb;
}

.selectedGifArt_closeIcon__24ie2 path {
  fill: #f1423b;
}

.selectedGifArt_iconClose__3KqBd {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background: var(--default);
  box-shadow: var(--shadow-light);
  border-radius: 0.15em;
  transition: all 300ms;
}

.selectedGifArt_iconClose__3KqBd img {
  width: 0.7em;
  margin: 0;
}

.selectedGifArt_card__1_UZe > .selectedGifArt_iconClose__3KqBd:hover {
  box-shadow: var(--shadow-light);
}

.SubscriptionNotification_container__111Nl {
  max-width: 26em;
  border-radius: 0.7em;
  background: var(--default);
  position: fixed;
  right: 1em;
  bottom: 1em;
  transition: all 650ms;
  -webkit-transform: translateY(calc(100% + 1em));
          transform: translateY(calc(100% + 1em));
  z-index: 20;
  padding: 2.5em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  border: 1px solid var(--outline);
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
}

.SubscriptionNotification_container__111Nl.SubscriptionNotification_active__Gcav_ {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.SubscriptionNotification_closeIcon__KbueL {
  fill: var(--icon-color);
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.SubscriptionNotification_closeIcon__KbueL:hover {
  box-shadow: var(--shadow-dark);
}

.SubscriptionNotification_title__2eAfH {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--main-color);
}

.SubscriptionNotification_description__Qdbln {
  text-align: center;
  color: var(--text-sub-heading);
  margin-bottom: 0.5em;
  font-size: 0.95rem;
}

.SubscriptionNotification_list__ZVES_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  grid-gap: 1em;
  gap: 1em;
}

.SubscriptionNotification_list__ZVES_ > div {
  color: var(--text-sub-heading);
}

.SubscriptionNotification_listIcon__z5-KQ {
  fill: var(--error-300);
}

.SubscriptionNotification_upgradeBtn__peo9L {
  width: auto;
  background: var(--main-color);
  padding: 0.75em 2em;
  border-radius: 0.7em;
  box-shadow: var(--btn-open);
  transition: all 300ms;
  align-self: center;
  color: var(--default);
  cursor: pointer;
  margin-top: 1em;
}

.SubscriptionNotification_upgradeBtn__peo9L:hover {
  box-shadow: var(--btn-close);
}

.Explore_container__1s4fI {
  width: 100%;
  padding-bottom: 6em;
  position: relative;
  background: var(--default);
}

.Explore_displayContainer__3uWoE {
  width: 100%;
  padding: 0 2em;
}

.Explore_displayWrapper__2BU3K {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
}

.Explore_section__lKZZ4 {
  border-bottom: 1px solid var(--outline);
  margin-bottom: 2em;
}

.Explore_main__FgtwP {
  flex: 1 1;
  padding: 1em;
}

.Explore_types__3L5Z6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 3em;
  gap: 3em;
}

.Explore_type__2M8nq {
  padding: 0.1em 1.5em;
  font-size: 1.8rem;
  font-weight: 600;
  background: #f7f9fd;
  border-bottom: 4px solid transparent;
  border-radius: 0.4em 0.4em 0 0;
  white-space: nowrap;
  cursor: pointer;
  color: var(--main-color);
  display: flex;
  align-items: center;
}
.Explore_type__2M8nq img {
  margin-right: 0.5em;
}
.Explore_type__2M8nq:hover {
  color: var(--text-sub-heading);
}

.Explore_type__2M8nq.Explore_active__8hs5Q {
  border-bottom: 4px solid var(--main-color);
  color: var(--main-color);
}
.Explore_filterDisplay__xH-yk {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 2em;
}

.Explore_filteredItem__k5XN1 {
  border: 1px solid var(--main-color);
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-radius: 0.5em;
}

.Explore_filteredItem__k5XN1 span:first-child {
  font-weight: 600;
}

.Explore_closeIcon__3w9v6 {
  width: 1em;
  height: 1em;
  padding: 0.15em;
  border-radius: 0.15em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  fill: var(--icon-color);
}

.Explore_closeIcon__3w9v6:hover {
  fill: var(--main-color);
}

.Explore_clearFilter__2V3Ta {
  cursor: var(--cursor-pointer);
  margin-left: 1em;
  transition: all 100ms;
}

.Explore_clearFilter__2V3Ta:hover {
  color: var(--main-color);
}

.Explore_searchContainer__309ia {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 1em;
}

@media screen and (max-width: 900px) {
  .Explore_types__3L5Z6 {
    grid-gap: 2em;
    gap: 2em;
  }

  .Explore_type__2M8nq {
    padding: 0.2em 1em;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .Explore_main__FgtwP {
    width: 100%;
  }

  .Explore_displayWrapper__2BU3K {
    flex-direction: column;
  }

  .Explore_type__2M8nq img {
    width: 2em;
  }
}

@media screen and (max-width: 540px) {
  .Explore_displayContainer__3uWoE {
    padding: 0 1em;
  }

  .Explore_searchContainer__309ia {
    flex-direction: column;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .Explore_searchContainer__309ia > div {
    width: 100%;
  }

  .Explore_types__3L5Z6 {
    grid-gap: 0.5em;
    gap: 0.5em;
    /* margin-bottom: 1.6em; */
    justify-content: center;
  }

  .Explore_type__2M8nq {
    padding: 0.2em 1em;
    font-size: 0.8rem;
  }
}

.Filter_sidebar__1dmre {
  border: 1px solid var(--card-outline);
  width: 100%;
  max-width: 20em;
  position: -webkit-sticky;
  position: sticky;
  top: 5em;
}

.Filter_sideOverflowWrapper__1U5rd {
  width: 100%;
  height: calc(100vh - 5.5em);
  overflow: auto;
}

.Filter_label__3edGx {
  font-size: 12px;
}

.Filter_filterHeading__2WcUR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  border-bottom: 1px solid var(--card-outline);
}

.Filter_filterHeading__2WcUR:hover {
  background: var(--main-lightest);
}

.Filter_filterHeading__2WcUR > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Filter_filterHeading__2WcUR span {
  color: var(--main-color);
}

.Filter_leftArrow__3Daeb {
  width: 1em;
  height: 1em;
}

.Filter_sort__1y-UK,
.Filter_status__1iuMf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
  text-transform: capitalize;
}

.Filter_attributeFilter__3wMcY .Filter_title__112rK {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  border-bottom: 2px solid var(--outline);
  border-top: 2px solid var(--outline);
}

.Filter_priceFilter__1QfeM {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
}

.Filter_priceFilter__1QfeM .Filter_filterInput__2bjtj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}

.Filter_priceFilter__1QfeM .Filter_filterInput__2bjtj input {
  padding: 0.75em;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
}

.Filter_priceFilter__1QfeM > div {
  width: auto;
  flex: 1 1;
}

.Filter_priceFilter__1QfeM input {
  width: 100%;
}

.Filter_priceFilter__1QfeM button {
  background: var(--main-color);
  color: white;
  padding: 1em;
  border-radius: 0.25em;
  transition: all 300ms;
}

.Filter_priceFilter__1QfeM button:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}

.Filter_attributeFilter__3wMcY .Filter_title__112rK {
  cursor: var(--cursor-pointer);
}

.Filter_attribute__1XUik {
  max-height: 0em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.Filter_attribute__1XUik.Filter_active__20eYH {
  max-height: 1000vh;
  padding: 1em;
  overflow: auto;
}

.Filter_layerWrapper__1Bnla {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: var(--cursor-pointer);
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--outline);
}

.Filter_attributeName__11smO {
  max-width: 10em;
  overflow: auto;
  white-space: nowrap;
}

.Filter_layerIcon__2OegS {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.Filter_layerIcon__2OegS img {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.Filter_layerIcon__2OegS.Filter_active__20eYH img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Filter_layer__34J_I {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  border-radius: 0.25em;
  background: var(--default);
}

.Filter_layer__34J_I.Filter_active__20eYH {
  max-height: 10em;
  overflow: auto;
  visibility: visible;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.Filter_value__3pdc6 {
  cursor: var(--cursor-pointer);
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 0.25em;
  border-radius: 0.25em;
}

.Filter_value__3pdc6:hover {
  background: var(--outline);
  cursor: pointer;
}

.Filter_statusIcon__1e6CG {
  width: 1.4em;
  height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2em;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.Filter_statusIcon__1e6CG.Filter_active__20eYH {
  background: var(--main-color);
  border: 1px solid var(--main-color);
}

.Filter_markIcon__b_M4d {
  fill: var(--default);
}

.Filter_sidebar2__1K59o {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 5.5em);
  border-right: 1px solid var(--outline);
  position: -webkit-sticky;
  position: sticky;
  top: 5em;
  cursor: var(--cursor-pointer);
  border-top-left-radius: 0.5em;
  overflow: hidden;
}

.Filter_sidebar2__1K59o img {
  width: 100%;
  padding: 1em;
  transition: all 300ms;
}

.Filter_valuesOfAttr__2Qa10 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Filter_attributeValue__3pils {
  width: 7em;
  overflow: auto;
}

.Filter_sidebar2__1K59o img:hover {
  background: var(--main-lightest);
}

@media screen and (max-width: 768px) {
  .Filter_sidebar__1dmre,
  .Filter_sidebar2__1K59o {
    display: none;
  }
}

.Dropdown_heading__26Yy0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  cursor: var(--cursor-pointer);
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
}

.Dropdown_textWrapper__3OWUe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Dropdown_content__1rNDK {
  max-height: 0;
  overflow: hidden;
}

.Dropdown_content__1rNDK.Dropdown_active__1L-aZ {
  max-height: 1000vh;
}

.Dropdown_dropdownIcon__2EoxB {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.Dropdown_dropdownIcon__2EoxB.Dropdown_active__1L-aZ {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.RadioButton_container__12xAe {
  border: 2px solid var(--outline-dark);
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.RadioButton_container__12xAe.RadioButton_active__PhfeN {
  border-color: var(--main-color);
}

.RadioButton_dot__3cV3z {
  display: none;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: var(--main-color);
}

.RadioButton_container__12xAe.RadioButton_active__PhfeN .RadioButton_dot__3cV3z {
  display: block;
}

.Header_container__eBrUO {
  width: 100%;
  margin-bottom: 12em;
}

.Header_innerContainer__1HBuv {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 2em;
}

.Header_wrapper__Z16gc {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
}

.Header_thumbnail__3mPQr {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}

.Header_linksAndCollectionDetailWrapper__N216j {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}

.Header_nameAndChainWrapper__eiDDB {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Header_name__2NPPe {
  font-weight: 600;
  font-size: 1.5rem;
}

.Header_chain__2nquR {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}
.Header_creator__3BdLu {
  display: flex;
  grid-gap: 0.5em;
  gap: 0.5em;
  align-items: baseline;
}
.Header_creator__3BdLu p {
  color: #6c757d;
  transition: color 300ms;
}
.Header_creator__3BdLu .Header_accent__CY4YI {
  display: flex;
  align-items: center;
}
.Header_creator__3BdLu .Header_accent__CY4YI a {
  font-weight: 600;
  color: black;
  cursor: pointer;
}
.Header_creator__3BdLu .Header_accent__CY4YI a:hover {
  color: var(--main-color);
}

.Header_socialLinks__3gDMM {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.Header_link__3dXGf {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.Header_link__3dXGf img {
  width: 100%;
}

.Header_innerContainer_2__DivLB {
  width: 100%;
  padding: 0 2em;
  -webkit-transform: translateY(7em);
          transform: translateY(7em);
}

.Header_wrapper_2__2Hve7 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.Header_description__1g6t2 {
  width: 100%;
  max-width: 48em;
  margin-bottom: 2em;
  color: #525c76;
}

.Header_statsContainer__1bxmI {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
}

.Header_statWrapper__3BB9_ {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  background: #f4fafc;
}

.Header_statWrapper__3BB9_ img {
  width: 2em;
}

.Header_details__2zoiC .Header__1__1RhSX,
.Header_details__2zoiC .Header__2__Uhkmy {
  font-size: 0.8rem;
}

.Header_details__2zoiC .Header__1__1RhSX {
  color: #6c757d;
}

.Header_details__2zoiC .Header__2__Uhkmy span {
  color: var(--main-color);
}

.Header_details__2zoiC .Header__2__Uhkmy .Header_accent__CY4YI {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .Header_container__eBrUO {
    margin-bottom: 6em;
  }

  .Header_innerContainer_2__DivLB {
    -webkit-transform: translateY(4em);
            transform: translateY(4em);
  }

  .Header_thumbnail__3mPQr {
    width: 6em;
    height: 6em;
  }

  .Header_name__2NPPe {
    font-size: 1rem;
  }

  .Header_chain__2nquR {
    width: 1.5em;
    height: 1.5em;
  }

  .Header_link__3dXGf {
    width: 1.2em;
    height: 1.2em;
  }

  .Header_description__1g6t2 {
    font-size: 0.85rem;
  }

  .Header_creator__3BdLu span {
    font-size: 0.85rem;
  }

  .Header_statsContainer__1bxmI {
    grid-gap: 1em;
    gap: 1em;
  }

  .Header_statWrapper__3BB9_ {
    padding: 0.5em;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .Header_statWrapper__3BB9_ img {
    width: 1.2em;
  }

  .Header_details__2zoiC .Header__1__1RhSX,
  .Header_details__2zoiC .Header__2__Uhkmy {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 540px) {
  .Header_container__eBrUO {
    margin-bottom: 4em;
  }

  .Header_wrapper__Z16gc {
    background: var(--default);
    grid-gap: 1em;
    gap: 1em;
    padding: 0.5em;
    border-radius: 0.5em;
  }

  .Header_innerContainer__1HBuv {
    padding: 0 0.5em;
  }

  .Header_innerContainer_2__DivLB {
    padding: 0 1em;
  }

  .Header_innerContainer_2__DivLB {
    -webkit-transform: translateY(3em);
            transform: translateY(3em);
  }

  .Header_thumbnail__3mPQr {
    width: 3em;
    height: 3em;
  }

  .Header_name__2NPPe {
    font-size: 0.85rem;
  }

  .Header_chain__2nquR {
    width: 0.8em;
    height: 0.8em;
  }

  .Header_creator__3BdLu span {
    font-size: 0.65rem;
  }

  .Header_details__2zoiC .Header__1__1RhSX,
  .Header_details__2zoiC .Header__2__Uhkmy {
    font-size: 0.55rem;
  }
}

.copy_container__1CTj2 {
  cursor: var(--cursor-pointer);
  font-weight: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.copy_copyIcon__2vCsX:hover {
  fill: var(--default);
}
.copy_point__3Y_YL {
  font-size: 0.7em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.copy_copyIcon__2vCsX {
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
}
.copy_point__3Y_YL:hover .copy_copyIcon__2vCsX {
  fill: var(--default);
}

.SingleNftCard_container__20PFg {
  width: 100%;
  max-width: 24em;
  min-width: 16.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-radius: 0.7em;
  cursor: pointer;
  background: #ffffff;
  border: 1.41429px solid rgba(28, 27, 28, 0.1);
  box-shadow: 5.65714px 5.65714px 11.3143px rgba(28, 27, 28, 0.04);
}

.SingleNftCard_container__20PFg:hover .SingleNftCard_image__1kwps {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.SingleNftCard_imageContainer__3eW4g {
  width: 100%;
  padding: 0.5em;
}

.SingleNftCard_imageWrapper__3bJFQ {
  width: 100%;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.SingleNftCard_image__1kwps {
  width: 100%;
  height: 16em;
  object-fit: cover;
  border-radius: 0.7em;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  background-repeat: no-repeat;
}

.SingleNftCard_details__1AK0l {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 28, 0.1);
  border-bottom: 1px solid rgba(28, 27, 28, 0.1);
  padding: 0.5em;
}

.SingleNftCard_nameAndChainWrapper__2LyTK {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.SingleNftCard_tag__1z8MT {
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5em;
  border-radius: 0.5em;
  background: rgba(28, 27, 28, 0.06);
}

.SingleNftCard__name__2CaiY {
  max-width: 10em;
  background: #dbf0ff;
  font-weight: 600;
  padding: 0.5em;
  border-radius: 0.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SingleNftCard_chain__3wcLA {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.SingleNftCard_name__11nO5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1em;
}

.SingleNftCard_owner__263aM {
  width: 100%;
  max-width: 18em;
  font-size: 0.85rem;
  color: var(--main-color);
}

.SingleNftCard_audio__1HsfZ {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.SingleNftCard_thumbnail__1fQH2 {
  width: 100%;
  height: 16em;
  object-fit: cover;
  border-radius: 0.7em;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #98d8ff;
}

@media screen and (max-width: 1200px) {
  .SingleNftCard_image__1kwps,
  .SingleNftCard_thumbnail__1fQH2 {
    height: 14em;
  }

  .SingleNftCard_name__11nO5,
  .SingleNftCard__name__2CaiY,
  .SingleNftCard_owner__263aM .SingleNftCard_priceLabel__3okBR,
  .SingleNftCard_counts__3b0Zg {
    font-size: 0.85rem;
  }

  .SingleNftCard_btn__2WacF {
    padding: 0.25em 2em;
  }
}

/* -------------------------------- MarketplaceView -------------------------------- */
.CardFooter_marketplace__2ZPLJ {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
  grid-gap: 1rem;
  gap: 1rem;
}

.CardFooter_marketplace__2ZPLJ .CardFooter_btn__3Bckl {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: all 300ms;
}

.CardFooter_lock__1wTQU {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(170, 219, 255, 0.4);
  border-radius: 6.5px;
  padding: 10px;
}
.CardFooter_lock__1wTQU > img {
  margin-right: 6px;
  width: 16px;
}
.CardFooter_lock__1wTQU > span {
  font-size: 14px;
}

.CardFooter_marketplace__2ZPLJ .CardFooter_btn__3Bckl:hover {
  color: var(--default);
  background: var(--main-color);
}

.CardFooter_marketplace__2ZPLJ .CardFooter_btn__3Bckl.CardFooter_disable__2uFKG {
  border: 1px solid transparent;
  background: var(--card-outline);
  color: white;
  padding: 0.5em 1em;
}

.CardFooter_marketplace__2ZPLJ .CardFooter_priceLabel__2YE-3 {
  color: #a4a9b6;
}

.CardFooter_marketplace__2ZPLJ .CardFooter_amount__EAp1_ .CardFooter_accent__26k1e {
  font-weight: 700;
  font-size: 0.85rem;
}
.CardFooter_marketplace__2ZPLJ .CardFooter_amount__EAp1_ span {
  color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .CardFooter_marketplace__2ZPLJ .CardFooter_owner__WHyJg .CardFooter_priceLabel__2YE-3 {
    font-size: 0.85rem;
  }

  .CardFooter_marketplace__2ZPLJ .CardFooter_btn__3Bckl {
    padding: 0.25em 2em;
  }
}
/* -------------------------------- OnSalveView -------------------------------- */
.CardFooter_onSale__3Nr04 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}

.CardFooter_onSale__3Nr04 .CardFooter_btn__3Bckl {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: all 300ms;
}

.CardFooter_onSale__3Nr04 .CardFooter_btn__3Bckl:hover {
  color: var(--default);
  background: var(--main-color);
}

.CardFooter_onSale__3Nr04 .CardFooter_btn__3Bckl.CardFooter_disable__2uFKG {
  border: 1px solid transparent;
  background: var(--card-outline);
  color: white;
  padding: 0.5em 1em;
}

.CardFooter_onSale__3Nr04 .CardFooter_priceLabel__2YE-3 {
  color: #a4a9b6;
}
.CardFooter_onSale__3Nr04 .CardFooter_amount__EAp1_ {
  display: flex;
  align-items: baseline;
}
.CardFooter_onSale__3Nr04 .CardFooter_amount__EAp1_ .CardFooter_accent__26k1e {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: baseline;
}

.CardFooter_onSale__3Nr04 .CardFooter_amount__EAp1_ .CardFooter_accent__26k1e span {
  font-size: 0.625rem;
  font-weight: inherit;
}
.CardFooter_onSale__3Nr04 .CardFooter_amount__EAp1_ div {
  font-size: 0.625rem;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .CardFooter_onSale__3Nr04 .CardFooter_owner__WHyJg .CardFooter_priceLabel__2YE-3 {
    font-size: 0.85rem;
  }

  .CardFooter_onSale__3Nr04 .CardFooter_btn__3Bckl {
    padding: 0.25em 2em;
  }
}
/* -------------------------------- CollectedeView -------------------------------- */
.CardFooter_collected__3zsZx {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}
.CardFooter_collected__3zsZx .CardFooter_list__15n8J {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.3em;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  -webkit-filter: drop-shadow(0px 3.18248px 3.18248px rgba(38, 50, 56, 0.13));
          filter: drop-shadow(0px 3.18248px 3.18248px rgba(38, 50, 56, 0.13));
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.875rem;
  transition: 300ms ease;
}
.CardFooter_collected__3zsZx .CardFooter_list__15n8J:hover {
  color: var(--default);
  background: var(--main-color);
}
.CardFooter_collected__3zsZx .CardFooter_listed__3vIhR {
  background: #e5e8eb;
  color: #000;
  border-color: #e5e8eb;
}
.CardFooter_collected__3zsZx .CardFooter_listed__3vIhR:hover {
  color: #000;
  background: #e5e8eb;
}

.Menu_container__AW-r4 {
  width: 100%;
}

.Menu_menu__1mw_P {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2em;
}

.Menu_menu__1mw_P.Menu_resize__3AgNz {
  grid-template-columns: repeat(3, 1fr);
}

.Menu_loader__1IZMe {
  z-index: 0;
}

.Menu_control__l-8h9 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  padding: 0.25em;
}

.Menu_pageControl__30SbM {
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.Menu_pageControl__30SbM:hover {
  outline: 2px solid var(--main-hovered);
}

.Menu_pageCount__2kixQ {
  font-weight: bold;
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .Menu_menu__1mw_P {
    grid-template-columns: repeat(3, 1fr);
  }

  .Menu_menu__1mw_P.Menu_resize__3AgNz {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .Menu_menu__1mw_P {
    grid-template-columns: repeat(2, 1fr);
  }

  .Menu_menu__1mw_P.Menu_resize__3AgNz {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .Menu_menu__1mw_P.Menu_resize__3AgNz {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .Menu_menu__1mw_P,
  .Menu_menu__1mw_P.Menu_resize__3AgNz {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

.searchBar_searchInput__1R9AA {
  flex: 1 1;
  padding: 0 25px;
  transition: all 300ms;
  border-radius: 0.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--outline);
  outline: 1px solid transparent;
}

.searchBar_searchInput__1R9AA:focus-within {
  outline: 1px solid var(--main-color);
}
.searchBar_searchInput__1R9AA > input {
  border: none;
  padding: 10px 25px;
  width: 100%;
}
.searchBar_searchInput__1R9AA > input:focus {
  outline: none;
}

.exploreTransactionHistory_container__OOPhv {
  border: 1px solid var(--card-outline);
  border-radius: 10px;
  width: 100%;
  padding-bottom: 3em;
  min-height: 37.5em;
  max-height: 70em;
  overflow-y: scroll;
  max-width: 1440px;
  margin: 0 auto;
}

.exploreTransactionHistory_wrapper__2G1-y {
  margin: 1em;
}

.exploreTransactionHistory_header__2i3qI {
  background-color: var(--header-bg);
  padding: 1em;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 10px 10px 0 0;
  grid-gap: 1em;
  gap: 1em;
  font-weight: 600;
}

.exploreTransactionHistory_header__2i3qI img {
  width: 1.3em;
  margin-right: 0.7em;
}

.exploreTransactionHistory_filters__39IRs {
  display: flex;
  margin-top: 1em;
}

.exploreTransactionHistory_option__2BJBy,
.exploreTransactionHistory_active__2lh-0,
.exploreTransactionHistory_disabled__1wQyc {
  font-size: 16px;
  font-weight: 500;
  background-color: var(--header-bg);
  padding: 0.5em 1em;
  border-radius: 10px;
  margin-right: 1em;
}

.exploreTransactionHistory_option__2BJBy:hover,
.exploreTransactionHistory_active__2lh-0:hover {
  color: var(--main-hover);
  cursor: pointer;
}
.exploreTransactionHistory_option__2BJBy {
  color: var(--main-color);
}

.exploreTransactionHistory_active__2lh-0 {
  background-color: var(--main-color);
  color: var(--default);
}

.exploreTransactionHistory_disabled__1wQyc {
  color: var(--tab-bg-2);
  background-color: var(--default);
  border: 1px solid var(--tab-bg-2);
}

.exploreTransactionHistory_disabled__1wQyc:hover {
  cursor: not-allowed;
}

.exploreTransactionHistory_searchInput__2jK8o {
  width: 100%;
  max-width: 45em;
  flex: 1 1;
  margin: 1em 0;
  padding: 0 25px;
  transition: all 300ms;
  border-radius: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--outline);
  outline: 1px solid transparent;
}

.exploreTransactionHistory_searchInput__2jK8o:focus-within {
  outline: 1px solid gray;
}
.exploreTransactionHistory_searchInput__2jK8o > input {
  border: none;
  padding: 10px 25px;
  width: 100%;
}
.exploreTransactionHistory_searchInput__2jK8o > input:focus {
  outline: none;
}

.exploreTransactionHistory_transaction__1hHJY {
  border-bottom: 1px solid var(--tab-bg-2);
}

.exploreTransactionHistory_status__1GP7z {
  border: 1px solid var(--tab-bg-2);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1em 0.5em;
  border-radius: 30px;
  margin-top: 1em;
  color: var(--text-dark-700);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.exploreTransactionHistory_status__1GP7z img {
  width: 1em;
  margin: 0 0.3em;
}

.exploreTransactionHistory_transactionDetails__3wpYz {
  display: flex;
  justify-content: space-between;
  margin: 0.5em;
}

.exploreTransactionHistory_label__1P2_o {
  color: var(--text-label);
  margin-right: 0.5em;
  font-size: 14px;
}

.exploreTransactionHistory_detail__Ea1kY {
  width: 10em;
}
.exploreTransactionHistory_value__1bwNT {
  font-size: 14px;
  color: var(--text-dark-900);
}

.exploreTransactionHistory_export__3D-_O img {
  border: 1px solid var(--tab-bg-2);
  padding: 0.2em 0.4em;
  border-radius: 5px;
  position: relative;
  top: -1em;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1000px) {
  .exploreTransactionHistory_option__2BJBy,
  .exploreTransactionHistory_disabled__1wQyc {
    font-size: 14px;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
  }

  .exploreTransactionHistory_export__3D-_O img {
    width: 1.2em;
    padding: 0.1em;
  }

  .exploreTransactionHistory_container__OOPhv {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .exploreTransactionHistory_date__3gGSr,
  .exploreTransactionHistory_value__1bwNT {
    font-size: 12px;
  }

  .exploreTransactionHistory_label__1P2_o {
    font-size: 10px;
  }

  .exploreTransactionHistory_export__3D-_O img {
    width: 1em;
    padding: 0.1em;
  }
}

@media screen and (max-width: 480px) {
  .exploreTransactionHistory_option__2BJBy {
    height: 2.5em;
  }
  .exploreTransactionHistory_active__2lh-0 {
    color: var(--default);
  }
}

.notFound_container__2BnVw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 3em 1em;
}

.notFound_imageContainer__2eiJF {
  width: 12vmax;
}

.notFound_imageContainer__2eiJF img {
  width: 100%;
}

.notFound_container__2BnVw h1 {
  font-size: 2rem;
  text-align: center;
}

.notFound_container__2BnVw p {
  font-size: calc(0.5rem + 1vmax);
  text-align: center;
}

.collections_container__2j0nF {
  width: 100%;
  min-height: 100vh;
}

.collections_wrapper__3D6bG {
  width: 100%;
  padding: 4em 2em;
}

.collections_nfts__2_5aE {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 2em;
  gap: 2em;
}

.collections_heading__VcUO7 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 3em;
  gap: 3em;
  padding: 6em 2em;
  background: linear-gradient(
    259.21deg,
    #b0e2fe -27.76%,
    #d8e6fc 1.15%,
    #fdeafa 26.21%,
    #e3f2f9 47.43%,
    #edf5f8 67.57%,
    #fbeffc 87.77%,
    #b0e2fe 107.4%
  );
}

.collections_title__2wQNg {
  text-align: center;
}

.collections_title__2wQNg h1 {
  font-size: 4rem;
  font-weight: 700;
}

.collections_title__2wQNg p {
  font-size: 1.5rem;
}

.collections_searchAndFilter__2_eka {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.collections_filter__r5-ky {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.collections_chainMobile__GGIF5 {
  display: none;
}

.collections_search__1uwFr {
  width: 100%;
  max-width: 36em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.collections_search__1uwFr input {
  width: 100%;
  outline: none;
  border: none;
  color: var(--textinput);
}

.collections_dateFilter__94Q_t {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.2em;
  border-radius: 0.7em;
  border: 1px solid #cacdd5;
}

.collections_date__1U85j {
  width: 8em;
  text-align: center;
  border-radius: 0.7em;
  padding: 0.5em 0;
  font-weight: 600;
  cursor: pointer;
}

.collections_date__1U85j.collections_active__NY8tl {
  background: #727478;
  color: var(--default);
}

.collections_loader__1Qy3M {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .collections_nfts__2_5aE {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .collections_nfts__2_5aE {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }

  .collections_search__1uwFr {
    max-width: 24em;
  }
}

@media screen and (max-width: 900px) {
  .collections_nfts__2_5aE {
    grid-template-columns: repeat(2, 1fr);
  }

  .collections_title__2wQNg h1 {
    font-size: 3rem;
  }

  .collections_title__2wQNg p {
    font-size: 1.2rem;
  }

  .collections_search__1uwFr {
    max-width: 20em;
  }

  .collections_search__1uwFr input {
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .collections_date__1U85j {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 768px) {
  .collections_wrapper__3D6bG {
    padding: 4em 1em;
  }

  .collections_title__2wQNg h1 {
    font-size: 2rem;
  }

  .collections_title__2wQNg p {
    font-size: 1rem;
  }

  .collections_search__1uwFr {
    max-width: 16em;
  }

  .collections_search__1uwFr input {
    max-width: 4.5em;
  }

  .collections_date__1U85j {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 540px) {
  .collections_nfts__2_5aE {
    grid-template-columns: repeat(1, 1fr);
  }

  .collections_searchAndFilter__2_eka {
    grid-gap: 1em;
    gap: 1em;
  }

  .collections_filter__r5-ky {
    grid-gap: 1em;
    gap: 1em;
  }

  .collections_chainMobile__GGIF5 {
    display: block;
    z-index: 0;
    margin-top: -1em;
  }

  .collections_chainDesktop__10AO7 {
    display: none;
  }

  .collections_heading__VcUO7 {
    grid-gap: 2em;
    gap: 2em;
    padding: 3em 1em;
  }

  .collections_searchAndFilter__2_eka {
    grid-gap: 1em;
    gap: 1em;
  }

  .collections_wrapper__3D6bG {
    padding: 2em 1em;
  }
}

.CollectionNftCard_container__1R_2Z {
  width: 100%;
  max-width: 24em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-radius: 0.7em;
  cursor: pointer;
  background: #ffffff;
  border: 1.41429px solid #a9a9a9;
  box-shadow: 5.65714px 5.65714px 11.3143px rgba(28, 27, 28, 0.04);
}

.CollectionNftCard_container__1R_2Z:hover .CollectionNftCard_image__1xW8H {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.CollectionNftCard_imageContainer__2wwc0 {
  width: 100%;
  padding: 0.5em;
  overflow: hidden;
}

.CollectionNftCard_imageWrapper__2qnw7 {
  width: 100%;
  border-radius: 0.7em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CollectionNftCard_image__1xW8H {
  width: 100%;
  height: 16em;
  object-fit: cover;
  border-radius: 0.7em;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.CollectionNftCard_details__2wUG4 {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 28, 0.1);
  border-bottom: 1px solid rgba(28, 27, 28, 0.1);
  padding: 1em 0.5em;
}

.CollectionNftCard_nameAndChainWrapper__2G45n {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5em;
}

.CollectionNftCard_name__1n9e2 {
  max-width: 10em;
  background: #dbf0ff;
  font-weight: 600;
  padding: 0.5em;
  border-radius: 0.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CollectionNftCard_chain__3G2jv {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.CollectionNftCard_description__1WWgH {
  width: 100%;
  max-width: 18em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #6c757d;
}

.CollectionNftCard_listing__2ahwQ {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5em;
  padding-bottom: 1em;
}

.CollectionNftCard_counts__s1Oz8 {
  border-radius: 0.7em;
  padding: 0.5em 1em;
  background: rgba(170, 219, 255, 0.4);
  color: #4b4b5a;
  font-weight: 600;
}

.CollectionNftCard_priceLabel__3bhVv {
  color: rgba(28, 27, 28, 0.5);
}

.CollectionNftCard_amount__1M4C9 span {
  color: var(--main-color);
  font-size: 0.85rem;
}

.CollectionNftCard_amount__1M4C9 span.CollectionNftCard_accent__3QSph {
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .CollectionNftCard_description__1WWgH {
    max-width: 16em;
  }

  .CollectionNftCard_image__1xW8H {
    height: 14em;
  }

  .CollectionNftCard_name__1n9e2,
  .CollectionNftCard_description__1WWgH,
  .CollectionNftCard_priceLabel__3bhVv,
  .CollectionNftCard_counts__s1Oz8,
  .CollectionNftCard_amount__1M4C9 {
    font-size: 0.85rem;
  }

  .CollectionNftCard_btn__2667W {
    padding: 0.25em 2em;
  }
}

.PageControl_paginate__Qaosb {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  padding: 0.25em;
}

.PageControl_paginate__Qaosb input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.25em;
}

.PageControl_pageControl__3eQjJ {
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.PageControl_pageControl__3eQjJ:hover {
  outline: 2px solid var(--main-hovered);
}

.PageControl_pageCount__3Qq4p {
  font-weight: bold;
  white-space: nowrap;
}

.chainDropdown_chainDropdown__2JqVO {
  position: relative;
  background: var(--default);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chainDropdown_selectedChain__P8fi0 {
  display: flex;
  padding: 0.25em 1em;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.25em;
  gap: 0.25em;
  border: 1px solid var(--card-outline);
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  height: 100%;
  color: var(--main-color);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chainDropdown_selectedChain__P8fi0:hover {
  background: var(--btn-close);
}

.chainDropdown_selectedChain__P8fi0 > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.chainDropdown_chainDropdown__2JqVO .chainDropdown_dropdown__3Fa-v {
  width: 16em;
  top: 3em;
  position: absolute;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  display: flex;
  flex-direction: column;
  cursor: var(--cursor-pointer);
  -webkit-transform: translateY(-0.5em);
          transform: translateY(-0.5em);
  text-transform: capitalize;
  border-radius: 0.5em;
  background: var(--default);
  overflow: hidden;
}

.chainDropdown_chainDropdown__2JqVO .chainDropdown_dropdown__3Fa-v.chainDropdown_active__1cp2B {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0em);
          transform: translateY(0em);
}

.chainDropdown_chain__38aQh:first-child {
  margin-top: 0.25em;
}

.chainDropdown_chain__38aQh {
  padding: 0.5em 1em;
  border-bottom: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
  position: relative;
}

.chainDropdown_chain__38aQh.chainDropdown_inActive__1CKS6 {
  cursor: var(--cursor-default);
  color: var(--outline);
}

.chainDropdown_chain__38aQh.chainDropdown_inActive__1CKS6:hover::after {
  content: "coming soon";
  position: absolute;
  left: 5em;
  font-size: 0.56rem;
  font-weight: 500;
  color: white;
  background: #ebba4f;
  padding: 0.25em 1em;
}

.chainDropdown_chain__38aQh:hover {
  background: var(--main-lightest);
}

.chainDropdown_chainImg__1rNVV {
  width: 2em;
  height: 2em;
}

.chainDropdown_chain__38aQh:last-child {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.chainDropdown_chainDropdown__2JqVO .chainDropdown_dropdown__3Fa-v img {
  width: 1.4em;
  height: 1.4em;
}

.chainDropdown_dropdownIcon__3PYZe {
  transition: all 250ms;
  fill: #8c93a3;
}

.chainDropdown_dropdownIcon__3PYZe.chainDropdown_active__1cp2B {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media screen and (max-width: 540px) {
  .chainDropdown_selectedChain__P8fi0 > div {
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .chainDropdown_selectedChain__P8fi0 {
    padding: 0.5em;
  }

  .chainDropdown_chainImg__1rNVV {
    width: 1.2em;
    height: 1.2em;
  }

  .chainDropdown_dropdownIcon__3PYZe {
    width: 1em;
  }
  .chainDropdown_chainDropdown__2JqVO .chainDropdown_dropdown__3Fa-v {
    width: 12em;
    left: 1em;
  }
}

.FilterDropdown_container__2L8Qa {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;
  height: 100%;
}

.FilterDropdown_wrapper__1MAn9 {
  width: 15em;
  position: absolute;
  border-radius: 0.5em;
  overflow: hidden;
  top: 3em;
  right: 0.2em;
  background: var(--default);
  border: 1.5px solid var(--outline);
  box-shadow: 0px 1.43503px 2.87007px rgba(28, 27, 28, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  -webkit-transform: translateY(-0.5em);
          transform: translateY(-0.5em);
}

.FilterDropdown_wrapper__1MAn9.FilterDropdown_active__1RreF {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0em);
          transform: translateY(0em);
}

.FilterDropdown_filterBtn__2GSoy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  cursor: pointer;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.FilterDropdown_filterBtn__2GSoy:hover {
  background: var(--btn-close);
}

.FilterDropdown_filterHeading__24m06 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
}

.FilterDropdown_sort__2MitK,
.FilterDropdown_status__3fesn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.FilterDropdown_dropdown__2Oe2v {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 0.75em;
}

.FilterDropdown_priceDropdown__24k5M {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 0.75em;
}

.FilterDropdown_inputContainer__x6tLV input {
  width: 100%;
  border: 1px solid #cacdd5;
  outline: none;
  border-radius: 0.25em;
  padding: 0.25em;
}

.FilterDropdown_label__1ZLID {
  font-size: 0.85rem;
}

.FilterDropdown_btnContainer__39lZl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 0.75em;
}

.FilterDropdown_btn__38dtZ {
  padding: 0.5em 0;
  border-radius: 0.7em;
  flex: 1 1;
  text-align: center;
  font-size: 0.85rem;
  border: 1px solid transparent;
  transition: all 300ms;
  cursor: pointer;
}

.FilterDropdown_btn__38dtZ.FilterDropdown_apply__oychm {
  background: var(--main-color);
  color: var(--default);
}

.FilterDropdown_btn__38dtZ.FilterDropdown_apply__oychm:hover {
  background: var(--default);
  color: var(--main-color);
  border-color: var(--main-color);
}

.FilterDropdown_btn__38dtZ.FilterDropdown_cancel__pBjPW {
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.FilterDropdown_btn__38dtZ.FilterDropdown_cancel__pBjPW:hover {
  background: var(--main-color);
  color: var(--default);
}

@media screen and (max-width: 540px) {
  .FilterDropdown_container__2L8Qa {
    justify-content: flex-end;
  }

  .FilterDropdown_filterBtn__2GSoy {
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 0.5em 0.25em;
  }

  .FilterDropdown_filterBtn__2GSoy div {
    font-size: 0.85rem;
  }

  .FilterDropdown_filterIcon__1rUl3 {
    width: 1em;
    height: 1em;
  }
}

.Search_container__1ATIJ {
  flex: 1 1;
}

.Search_placeholder__2G1Lk,
.Search_searchContainer__29xXS {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 0.25em;
  gap: 0.25em;
  transition: all 300ms;
  overflow: hidden;
  background: var(--default);
}

.Search_searchContainer__29xXS {
  padding: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--card-outline);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.Search_placeholder__2G1Lk {
  border-radius: 0.7em;
  padding: 0.15em 1em;
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Search_container__1ATIJ.Search_active__8yYO_ .Search_placeholder__2G1Lk {
  display: none;
}

.Search_placeholder__2G1Lk:hover {
  cursor: var(--cursor-hover);
  border: 1px solid var(--main-color);
}

.Search_placeholder__2G1Lk input,
.Search_searchContainer__29xXS input {
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  padding: 0.25em 0em;
  font-size: 1rem;
}

.Search_placeholder__2G1Lk input {
  padding: 0.25em;
}

.Search_placeholder__2G1Lk input.Search_mobile__1gL-z {
  display: none;
}

.Search_dropdownContainer__lDk0D {
  width: 100%;
  height: 100vh;
  display: none;
  align-items: flex-start;
  justify-content: center;
  background: rgba(75, 75, 90, 0.74);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  padding: 0 2em;
}

.Search_container__1ATIJ.Search_active__8yYO_ .Search_dropdownContainer__lDk0D {
  display: flex;
}

.Search_dropdown__1hPjA {
  width: 100%;
  max-width: 42em;
  margin-top: 0.5em;
  background: var(--default);
  border-radius: 0.5em;
  overflow: hidden;
}

.Search_suggestions__1cOvd {
  width: 100%;
  overflow: auto;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  background: var(--default);
}

.Search_suggestion__2SO5- {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  border: 1px solid var(--card-outline);
}

.Search_suggestion__2SO5-:hover {
  background: var(--card-light);
}

.Search_hint__31_Su {
  width: 100%;
  font-size: 0.85rem;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.Search_result__3V_-r {
  color: var(--main-color);
}
.Search_showAll__1aVMn {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  cursor: pointer;
}
.Search_showAll__1aVMn div {
  height: 100%;
  display: block;
  background: #e5e8eb;
  width: 2px;
  transition: color 300ms ease;
}
.Search_showAll__1aVMn:hover {
  color: var(--main-color);
}
.Search_image__2C_kJ {
  width: 3.5em;
  height: 3.5em;
  object-fit: contain;
  border-radius: 0.5em;
}

.Search_content__2uqQ0 {
  flex: 1 1;
}

.Search_description__1Kt6O {
  color: var(--text-sub-heading);
  font-size: 0.85rem;
}

.Search_type_m__1RJns,
.Search_type__240Rg {
  width: -webkit-max-content;
  width: max-content;
  color: var(--text-sub-heading);
  padding: 0 0.5em;
  border-radius: 0.25em;
  font-size: 0.85rem;
  background: var(--card-trait);
}

.Search_type_m__1RJns {
  display: none;
}

.Search_chain__3u4V5 {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

@media screen and (max-width: 1000px) {
  .Search_dropdownWrapper__3aLhb {
    padding-left: 0;
    justify-content: center;
  }
}

@media screen and (max-width: 540px) {
  .Search_dropdownContainer__lDk0D {
    padding: 1em;
  }

  .Search_suggestion__2SO5- {
    align-items: flex-start;
  }

  .Search_image__2C_kJ {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
    border-radius: 0.5em;
  }

  .Search_chain__3u4V5 {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    align-self: center;
  }

  .Search_description__1Kt6O {
    white-space: nowrap;
    max-width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Search_type__240Rg {
    display: none;
  }

  .Search_type_m__1RJns {
    display: inline-flex;
    font-size: 0.75rem;
  }

  .Search_placeholder__2G1Lk input.Search_desktop__C9hcU {
    display: none;
  }

  .Search_placeholder__2G1Lk input.Search_mobile__1gL-z {
    display: block;
  }
}

.dashboard_container__2c-4- {
  width: 100%;
  margin-bottom: 6em;
  background: var(--default);
}

.dashboard_wrapper__pPRBx {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.dashboard_header__19IQ1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  padding-top: 2em;
  background: var(--default);
  box-shadow: var(--shadow-light-5);
  overflow: hidden;
  margin-bottom: 2em;
}

.dashboard_profile__1PRsW {
  color: var(--main-color);
  border: 2px solid var(--main-color);
  border-radius: 0.5em;
  padding: 0.5em 1em;
}

.dashboard_details__32V5b {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border-bottom: 1px solid #b2c5d0;
  flex-wrap: wrap;
  grid-gap: 2em;
  gap: 2em;
}

.dashboard_detail__1qNqA {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.625em;
  gap: 0.625em;
  padding: 0.75em 1em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  border-bottom: 5px solid transparent;
  margin-bottom: -3px;
}

.dashboard_detail__1qNqA:hover {
  /* box-shadow: var(--shadow-light); */
  border-color: var(--main-color);
}

.dashboard_detail__1qNqA p {
  font-size: 1.2rem;
  transition: all 100ms;
  color: #4b4b5a;
  white-space: nowrap;
}

.dashboard_detail__1qNqA span {
  min-width: 2.5em;
  min-height: 2.5em;
  font-size: 0.85rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9f2ff;
  color: #6c757d;
  font-weight: bold;
  transition: all 100ms;
}

.dashboard_detail__1qNqA.dashboard_active__2i44k {
  color: var(--main-color);
  border-color: var(--main-color);
}

.dashboard_detail__1qNqA.dashboard_active__2i44k p {
  transition: all 100ms;
  color: var(--main-color);
}

.dashboard_detail__1qNqA.dashboard_active__2i44k span {
  background: var(--main-color);
  color: var(--default);
}

.dashboard_bannerContainer__3QKSI {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #febfbb;
  /* height: 25vw; */
  min-height: 10rem;
  background-size: cover;
}

.dashboard_banner__9-6Tf {
  width: 100%;
}
.dashboard_bannerWrapper__2oyOR {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding: 0 2rem;
  margin-top: -4rem;
  margin-bottom: 4rem;
  position: relative;
}
.dashboard_bannerWrapper__2oyOR > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard_bannerWrapper__2oyOR > a {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.dashboard_imageContainer__1imX7 {
  border-radius: 50%;
  object-fit: cover;
  max-width: 12.5rem;
  max-height: 12.5rem;
  min-width: 6rem;

  border: 5px solid #eeeff2;
}
.dashboard_profileHeader__3cOnx {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}
.dashboard_profileDetail__1zg6R {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: auto;
}
.dashboard_social__XX7dX {
  display: flex;
}
.dashboard_social__XX7dX a {
  display: flex;
  align-items: center;
}
.dashboard_socialIcon__1Ssse {
  width: 100%;
  height: 70%;
  margin-right: 5px;
}
.dashboard_socialIcon__1Ssse path {
  transition: 300ms ease;
}
.dashboard_socialIcon__1Ssse:hover path {
  fill: var(--main-hovered);
}
.dashboard_address__3TkSS {
  padding: 0.25em 0.5em;
  background: #e9f2ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.dashboard_address__3TkSS svg {
  height: 1rem;
  cursor: pointer;
}
.dashboard_address__3TkSS svg path {
  transition: all 200ms ease;
}
.dashboard_address__3TkSS svg:hover path:first-child {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
.dashboard_address__3TkSS > img {
  max-width: 20px;
}
.dashboard_address__3TkSS > div span {
  color: #4b4b5a;
}
.dashboard_editProfile__yeqUG {
  border: 1px solid var(--main-color);
  background: var(--default);
  color: #000;
  padding: 0.5em 2em;
  border-radius: 0.5em;
  transition: all 300ms;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dashboard_editProfile__yeqUG:hover {
  background: var(--main-color);
  color: var(--default);
}

.dashboard_main__1wlwg {
  padding: 2em;
}

.dashboard_username__4tZ5S {
  font-size: 1.25rem;
  font-weight: 600;
}

.dashboard_searchAndFilter__1e-tA {
  width: unset;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2em;
}

.dashboard_overview__3G59R {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.dashboard_overview__3G59R > a {
  display: flex;
  width: 100%;
}
.dashboard_overview__3G59R > a > div {
  height: 100%;
  width: 100%;
  min-height: 28rem;
}
.dashboard_skeleton__m0VOE {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  gap: 1em;
}

.dashboard_noResult__1VXFo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10em;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 1280px) {
  .dashboard_overview__3G59R,
  .dashboard_skeleton__m0VOE {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .dashboard_overview__3G59R,
  .dashboard_skeleton__m0VOE {
    grid-template-columns: repeat(3, 1fr);
  }

  .dashboard_detail__1qNqA {
    padding: 1em;
  }

  .dashboard_detail__1qNqA p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .dashboard_overview__3G59R,
  .dashboard_skeleton__m0VOE {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard_heading__1fH41 h3 {
    font-size: 1.6rem;
  }

  .dashboard_detail__1qNqA {
    padding: 0.5em;
    grid-gap: 2em;
    gap: 2em;
  }

  .dashboard_detail__1qNqA p {
    font-size: 1rem;
  }

  .dashboard_detail__1qNqA span {
    min-width: 2em;
    min-height: 2em;
  }
}
@media screen and (max-width: 768px) {
  .dashboard_bannerWrapper__2oyOR {
    justify-content: center;
    flex-wrap: wrap;
  }
  .dashboard_bannerWrapper__2oyOR > a {
    margin: unset;
  }
}
@media screen and (max-width: 768px) {
  .dashboard_detail__1qNqA {
    grid-gap: 1em;
    gap: 1em;
  }
}
@media screen and (max-width: 480px) {
  .dashboard_container__2c-4- {
    margin-bottom: 4em;
  }

  .dashboard_searchAndFilter__1e-tA {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .dashboard_searchAndFilter__1e-tA > div {
    width: 100%;
  }

  .dashboard_overview__3G59R,
  .dashboard_skeleton__m0VOE {
    grid-template-columns: repeat(1, 1fr);
  }

  .dashboard_details__32V5b {
    grid-gap: 1em;
    gap: 1em;
  }
  .dashboard_detail__1qNqA p {
    font-size: 0.75rem;
  }
  .dashboard_detail__1qNqA {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .dashboard_details__32V5b {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .dashboard_main__1wlwg {
    padding: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .dashboard_imageContainer__1imX7 {
    max-width: 10rem;
    max-height: 10rem;
    margin-bottom: 1rem;
  }
}

.DashboardFilterDropdown_filterDropdown__1aLFc {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  min-width: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--default);
  z-index: 10;
}

.DashboardFilterDropdown_filterDropdown__1aLFc span {
  font-size: 0.85rem;
}

.DashboardFilterDropdown_filterHeading__2peK_ {
  width: 100%;
  cursor: var(--cursor-pointer);
  background: var(--default);
  border: 1px solid var(--main-color);
  padding: 0.5em 1em;
  z-index: 99;
  border-radius: 0.7em;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.DashboardFilterDropdown_filterHeading__2peK_:hover {
  background: var(--card-light);
}

.DashboardFilterDropdown_filterHeading__2peK_ {
  color: var(--main-color);
}

.DashboardFilterDropdown_filterDetail__K3yA6 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.DashboardFilterDropdown_dropdown__1sI_u {
  width: -webkit-max-content;
  width: max-content;
  min-width: 100%;
  position: absolute;
  top: 2.5em;
  background: var(--default);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  display: flex;
  flex-direction: column;
  cursor: var(--cursor-pointer);
  -webkit-transform: translateY(-0.5em);
          transform: translateY(-0.5em);
  text-transform: capitalize;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: -9px 13px 18px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
}

.DashboardFilterDropdown_dropdown__1sI_u.DashboardFilterDropdown_active__plhWZ {
  visibility: visible;
  opacity: 1;
  margin-top: 0.25em;
  -webkit-transform: translateY(0em);
          transform: translateY(0em);
}

.DashboardFilterDropdown_filter__L-uhx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2em;
  gap: 2em;
  padding: 0.75em 1em;
  border-bottom: 1px solid var(--outline);
}

.DashboardFilterDropdown_filter__L-uhx:hover {
  background: var(--card-light);
}

.DashboardFilterDropdown_dropdownIcon__3LQ5s {
  transition: all 250ms;
}

.DashboardFilterDropdown_dropdownIcon__3LQ5s.DashboardFilterDropdown_active__plhWZ {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Pagination_footer__TVbDf {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.Pagination_pagination__1Z03K {
  margin: 0;
  /* width: 80%; */
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Pagination_pagination__1Z03K a {
  font-size: 0.875rem;
  margin: 2px;
  height: 21px;
  display: flex;
  width: 21px !important;
  border-radius: 5px;
  border: 1px solid transparent;
  /* color: var(--main-color); */
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.Pagination_pagination__1Z03K a:hover {
  border-color: var(--main-color);
}
.Pagination_pagination__1Z03K .Pagination_activePage__3R7k6 a {
  border-color: var(--main-color);
}
.Pagination_directPagination__1_696 {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
}
.Pagination_directPagination__1_696 > p {
  color: #8c93a3;
  display: flex;
}
.Pagination_directPagination__1_696 > input {
  border: 1px solid #cacdd5;
  outline: none;
  border-radius: 2px;
  width: 40px;
  height: 24px;
  padding: 0 10px;
}
.Pagination_directPagination__1_696 > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 6px;
  gap: 6px;
  color: var(--main-color);
}
.Pagination_directPagination__1_696 > div svg {
  transition: all 300ms ease;
}
.Pagination_directPagination__1_696 > div:hover svg {
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}

.docsEmbed_docs__17nsN {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid #ccc;
}

/* Accordion */

.list_container__3wXpe {
  width: 100%;
  max-width: 1440px;
  padding: 0 1em;
  padding-top: 2em;
  margin: 0 auto;
  margin-bottom: 8em;
  /* padding: 3rem; */
  display: flex;
  flex-direction: column;
}

.list_menu__1wo-U {
  margin: 5rem 0;

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  white-space: nowrap;
}

.list_left__3-Imt {
  width: 30%;
  max-width: 25em;
}

.list_right__3a4T8 {
  width: 50%;
  max-width: 50em;
}

.list_fullLegnth__291Y- {
  width: 100%;
  max-width: 75em;
  margin: 5rem;
}
.list_section1__2Frog {
  display: grid;
  grid-template-columns: calc(45% - 2rem) 55%;
  grid-gap: 2rem;
  gap: 2rem;
}

.list_v_subsection1__2CMsy {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #cacdd5;
  box-shadow: 2px 7px 22px rgb(28 27 28 / 10%);
  border-radius: 24px;
  padding: 1.5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 1rem;
  gap: 1rem;
}

.list_nft__2O4pR {
  width: 100%;
  border-radius: 34.13px;
}

.list_v_subsection2__54BMq {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list_header__1IgSA {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_header__1IgSA .list_title__1pS0- {
  font-weight: 600;
  font-size: 1.5rem;
}
.list_footer__1BzZO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  grid-gap: 1rem;
  gap: 1rem;
}
.list_footer__1BzZO .list_account__2EYL9 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.list_footer__1BzZO .list_account__2EYL9 p {
  color: #0d99ff;
  font-size: 0.875rem;
}
.list_footer__1BzZO .list_account__2EYL9 div {
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
}
.list_footer__1BzZO .list_account__2EYL9 div img {
  width: 1.5em;
  border-radius: 50%;
}
.list_feature__12EKR {
  margin-bottom: 1rem;
  border: 3px solid #e5e8eb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 300ms ease;
}

.list_listButtonWrapper__22twi {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
}

.list_listButton__mwpaG {
  background: var(--main-color);
  color: white;
  width: 13rem;
  height: 4.25rem;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  font-size: 20px;
  transition: all 300ms ease;
  font-weight: bold;
}

.list_listButton__mwpaG.list_disable__kz3VE {
  background: rgba(13, 153, 255, 0.39);
  border: 1px solid var(--outline-dark);
}

.list_listButton__mwpaG.list_disable__kz3VE:hover {
  color: var(--outline);
}

.list_mainDetails__3JauJ {
  padding: 1.25rem 1rem;
  border-bottom: 1px solid var(--outline);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--outline);
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
.list_mainDetails__3JauJ svg {
  width: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  fill: #04111d;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all 300ms ease;
}

.list_collectionHeader__1t1wf {
  display: flex;
  flex-direction: column;
}

.list_collectionName__Kiabd {
  color: #009987;
  font-size: 0.5rem;
}

.list_nftId__OJC2P {
  font-size: 1.3rem;
}

.list_dropdownItems__dAzxc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.list_dropdownContent__2FBHK {
  padding: 1rem;
  border-radius: 1rem;
  /* height: 0%; */
  transition: all 300ms ease-in-out;
  display: block;
  overflow: hidden;
  -webkit-animation: list_slidein__3I97V 0.4s;
          animation: list_slidein__3I97V 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.list_buy__12ZWT,
.list_bid__21DY8 {
  width: 15.8rem;
  height: 4.8rem;
  flex-direction: row;
  color: var(--text-heading);
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  font-weight: bold;
}

.list_buy__12ZWT {
  background-color: white;
  border: 1px solid #0d99ff;
  box-shadow: 0px 4px 4px rgba(38, 38, 38, 0.26);
}

.list_btnText__Ko7xM {
  color: var(--text-heading);
  font-size: 1rem;
  margin-bottom: 5px;
}

.list_btnSpan__1z5g5 {
  color: var(--text-heading);
  font-size: 0.65rem;
  font-weight: 400 !important;
}

.list_bid__21DY8 {
  color: white;
  background-color: var(--outline);
  cursor: not-allowed;
}

.list_buy__12ZWT img,
.list_bid__21DY8 img {
  width: 22px;
  height: 18px;
  margin-right: 7px;
}
.list_heading__25BHR {
  padding: 0.5rem;
  border-bottom: 1px solid #e5e8eb;
  background-color: #f3f4f7;
}

@media screen and (max-width: 1440px) {
  .list_container__3wXpe {
    max-width: 1200px;
  }
  .list_buy__12ZWT,
  .list_bid__21DY8 {
    max-width: 48%;
  }
}

@media screen and (max-width: 1200px) {
  .list_container__3wXpe {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .list_container__3wXpe {
    max-width: 768px;
  }
  .list_dropdownItems__dAzxc {
    flex-direction: column;
  }
  .list_buy__12ZWT,
  .list_bid__21DY8 {
    margin-bottom: 15px;
    max-width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .list_container__3wXpe {
    max-width: 540px;
    padding: 1em 2em;
  }

  .list_section1__2Frog {
    grid-template-columns: 100%;
  }

  .list_v_subsection1__2CMsy {
    width: 100%;
    margin-bottom: 1rem;
    max-width: 222rem;
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 480px) {
  .list_container__3wXpe {
    padding: 2rem 0.5rem;
  }
  .list_section1__2Frog {
    flex-direction: column;
  }

  .list_buy__12ZWT,
  .list_bid__21DY8 {
    width: 95%;
  }

  .list_nftName__1-Pa8 {
    flex-direction: column;
  }
  .list_header__1IgSA .list_title__1pS0-,
  .list_footer__1BzZO .list_account__2EYL9 div {
    font-size: 1rem;
  }
}
.list_icon__3X1KT {
  width: 30px;
}

.list_amount__1Hj8k {
  color: var(--main-color);
  font-weight: 550;
}

.list_chain__25Q-7 {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
  align-items: center;
}
.list_row__2f6AH {
  margin-bottom: 1rem;
  color: var(--text-sub-heading);
}

.list_row__2f6AH span {
  color: var(--text-sub-heading);
}

.list_row__2f6AH span {
  float: right;
}

.list_mintOptions__1yQM2 {
  padding: 1rem;
  height: 100%;
  transition: all 600ms ease-in-out;
}

.list_priceDescription__2ASf- {
  margin-bottom: 1.7rem;
  color: var(--text-sub-heading);
}

.list_inputWrapper__oPPsL {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
}

.list_listHeader__2ljUM h1 {
  margin-bottom: 50px;
  font-size: 30px;
}

.list_inputWrapper__oPPsL input,
.list_inputWrapper__oPPsL select {
  display: block;
  padding: 0.45em;
  resize: none;
  margin-right: 10px;
  border: 1px solid var(--outline);
  border-radius: 0.35em;
  transition: all 300ms;
  font-size: 1rem;
}

.list_inputWrapper__oPPsL > input {
  margin-left: 1rem;
  width: 100%;
  outline: 1px solid transparent;
}

.list_inputWrapper__oPPsL > input:focus-within {
  outline: 1px solid gray;
}

.list_inputWrapper__oPPsL > input:focus,
.list_inputWrapper__oPPsL select:focus {
  outline: 1px solid var(--outline-dark);
}

.list_inputWrapper__oPPsL button {
  transition: all 300ms;
  box-shadow: var(--btn-open);
}

.list_inputWrapper__oPPsL button:hover {
  cursor: var(--cursor-hover);
  text-shadow: 2px 2px 2px grey;
}
.list_disabled__sxoik .list_mainDetails__3JauJ svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.list_disabled__sxoik .list_dropdownContent__2FBHK {
  display: none;
}
@-webkit-keyframes list_expand__1CQBV {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
@keyframes list_expand__1CQBV {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
@-webkit-keyframes list_slidein__3I97V {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes list_slidein__3I97V {
  from {
    opacity: 0.3;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.profile_container__2BbXp {
  width: 100%;
  padding: 2em 1em;
  background: var(--default);
  -webkit-animation: profile_fade-in__1K5yl 650ms forwards;
          animation: profile_fade-in__1K5yl 650ms forwards;
}

@-webkit-keyframes profile_fade-in__1K5yl {
  from {
    -webkit-transform: scale(0.9) translateY(-50px);
            transform: scale(0.9) translateY(-50px);
  }
  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

@keyframes profile_fade-in__1K5yl {
  from {
    -webkit-transform: scale(0.9) translateY(-50px);
            transform: scale(0.9) translateY(-50px);
  }
  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

.profile_wrapper__1Oz3z {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background: var(--default);
  padding: 2em 0em;
  border-radius: 0.5em;
  box-shadow: var(--shadow-light);
}
.profile_content__3vLfG {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
}
.profile_heading__8wrsx {
  margin-bottom: 2em;
  padding: 0 4rem;
}

.profile_wallet__3CBNn {
  background: rgb(236, 236, 236);
  height: 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: all 300ms;
}
.profile_option__1s-ey {
  width: 100%;
  margin-bottom: 2em;
}

.profile_option__1s-ey h3 {
  margin-bottom: 0.5em;
}

.profile_option__1s-ey p {
  margin-bottom: 0.25em;
  font-size: 0.85rem;
}

.profile_warn__TYUyJ {
  color: rgb(255, 38, 0) !important;
  display: none;
}

.profile_warn__TYUyJ.profile_active__187xt {
  display: block;
  margin: 0.5em 0;
}

.profile_images__QOiFw {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-bottom: 3rem;
}

.profile_uploadIcon__1trgm {
  top: 40%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.profile_uploadIcon__1trgm input {
  display: none;
}

.profile_uploadIcon__1trgm img {
  width: 68px !important;
  height: 68px !important;
  cursor: var(--cursor-pointer);
}
.profile_uploadIcon__1trgm img:hover {
  cursor: var(--cursor-hover);
  opacity: 0.5;
  transition: 0.4s ease-in-out;
}

.profile_profile__26YER {
  width: 150px;
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  height: 150px;
}
.profile_profile__26YER img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.profile_banner__3-1BR {
  width: 100%;
  height: 18.6em;
  display: flex;
}
.profile_banner__3-1BR img {
  width: 100%;

  height: 18.6em;
}

.profile_option__1s-ey label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 0.5em;
}

.profile_option__1s-ey label div {
  font-weight: bold;
}

.profile_option__1s-ey input {
  width: 100%;
  font-size: 1rem;
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--outline);
  background: rgb(236, 236, 236);
  transition: all 300ms;
  outline: 2px solid transparent;
}

.profile_option__1s-ey input:focus {
  background: var(--default);
  outline: 1px solid var(--main-color);
}

.profile_option__1s-ey.profile_invalid__654EH input {
  outline: 1px solid red;
}

.profile_social__2AcdM {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1.5em;
  gap: 1.5em;
  margin-top: 2em;
}

.profile_buttons__1vzW7 {
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.profile_submit__1Dsh1,
.profile_cancel__1FmVo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.5em;
  height: 3.2em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
}

.profile_submit__1Dsh1 {
  background: var(--main-color);
  border: 2px solid transparent;
  color: white;
}

.profile_submit__1Dsh1:hover {
  background: var(--main-hovered);
  border: 2px solid var(--main-color);
}

.profile_cancel__1FmVo {
  border: 2px solid var(--main-color);
}

.profile_cancel__1FmVo:hover {
  background: var(--main-lightest);
}

.profile_toggleButton__1vebU {
  background: var(--default);
  display: inline-block;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  padding: 2px;
  border: 1px solid transparent;
  transition: all 300ms;
}

.profile_toggleButton__1vebU.profile_active__187xt {
  background: var(--main-color);
  border: 1px solid transparent;
}

.profile_toggleButton__1vebU.profile_active__187xt .profile_toggle__36svF {
  -webkit-transform: translateX(23px);
          transform: translateX(23px);
  background: var(--default);
}

.profile_toggleButton__1vebU .profile_toggle__36svF {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 300ms;
  background: var(--default);
}

@media screen and (max-width: 1000px) {
  .profile_profile__26YER {
    top: 55%;
  }
  .profile_profile__26YER img {
    width: 130px;
    height: 130px;
  }
  .profile_wrapper__1Oz3z {
    padding: 2em 0;
    max-width: 768px;
  }
  .profile_content__3vLfG {
    padding: 0 2rem;
  }
  .profile_heading__8wrsx {
    padding: 0 2rem;
  }
  .profile_banner__3-1BR {
    height: 14em;
  }
  .profile_banner__3-1BR img {
    height: 12em;
  }
}

@media screen and (max-width: 768px) {
  .profile_wrapper__1Oz3z {
    max-width: 480px;
  }

  .profile_social__2AcdM {
    grid-gap: 1em;
    gap: 1em;
  }

  .profile_images__QOiFw {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    align-items: center;
    text-align: center;
  }

  .profile_profile__26YER {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 30px;
  }
  .profile_profile__26YER img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .profile_banner__3-1BR {
    height: 7em;
  }
  .profile_banner__3-1BR img {
    height: 7em;
  }
}

@media screen and (max-width: 480px) {
  .profile_submit__1Dsh1,
  .profile_cancel__1FmVo {
    border-radius: 0.5em;
    font-size: 0.85rem;
    transition: all 300ms;
  }
  .profile_banner__3-1BR img {
    height: 7em;
  }
  .profile_content__3vLfG {
    padding: 0 1em;
  }
}

.profile_copyIcon__25SmF {
  width: 0.5em;
}

.profile_copyIcon__25SmF:hover {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  cursor: var(--cursor-pointer);
}

.profile_copyIcon__25SmF.profile_active__187xt {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  border: 1px solid var(--main-color);
}

.profile_text__14iPx {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--outline);
  border-radius: 5px;
  background: rgb(236, 236, 236);
}

.profile_text__14iPx input {
  border: none;
  font-size: 13px;
}

.profile_text__14iPx img {
  width: 30px;
  height: 30px;
  /* border-radius: 50%; */
  /* padding: 3px; */
  margin-top: 0.3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.singleNftCollection_container__2BH6N {
  width: 100%;
  min-height: 100vh;
}

.singleNftCollection_wrapper__1VCBk {
  width: 100%;
  padding: 4em 2em;
}

.singleNftCollection_nfts__3MmSn {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 2em;
  gap: 2em;
}

.singleNftCollection_heading__2JqDL {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 3em;
  gap: 3em;
  padding: 6em 2em;
  background: linear-gradient(
    259.21deg,
    #b0e2fe -27.76%,
    #d8e6fc 1.15%,
    #fdeafa 26.21%,
    #e3f2f9 47.43%,
    #edf5f8 67.57%,
    #fbeffc 87.77%,
    #b0e2fe 107.4%
  );
}

.singleNftCollection_title__3h23I {
  text-align: center;
}

.singleNftCollection_title__3h23I h1 {
  font-size: 4rem;
  font-weight: 700;
}

.singleNftCollection_title__3h23I p {
  font-size: 1.5rem;
}

.singleNftCollection_searchAndFilter__1zbLn {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.singleNftCollection_filter__3b_fU {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.singleNftCollection_chainMobile__HbKE3 {
  display: none;
}

.singleNftCollection_search__hVcOh {
  width: 100%;
  max-width: 36em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.singleNftCollection_search__hVcOh input {
  width: 100%;
  outline: none;
  border: none;
  color: var(--textinput);
}

.singleNftCollection_loader__f1eSc {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .singleNftCollection_nfts__3MmSn {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .singleNftCollection_nfts__3MmSn {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }

  .singleNftCollection_search__hVcOh {
    max-width: 24em;
  }
}

@media screen and (max-width: 900px) {
  .singleNftCollection_nfts__3MmSn {
    grid-template-columns: repeat(2, 1fr);
  }

  .singleNftCollection_title__3h23I h1 {
    font-size: 3rem;
  }

  .singleNftCollection_title__3h23I p {
    font-size: 1.2rem;
  }

  .singleNftCollection_search__hVcOh {
    max-width: 20em;
  }

  .singleNftCollection_search__hVcOh input {
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .singleNftCollection_wrapper__1VCBk {
    padding: 4em 1em;
  }

  .singleNftCollection_title__3h23I h1 {
    font-size: 2rem;
  }

  .singleNftCollection_title__3h23I p {
    font-size: 1rem;
  }

  .singleNftCollection_search__hVcOh {
    max-width: 16em;
  }

  .singleNftCollection_search__hVcOh input {
    max-width: 4.5em;
  }
}

@media screen and (max-width: 540px) {
  .singleNftCollection_nfts__3MmSn {
    grid-template-columns: repeat(1, 1fr);
  }

  .singleNftCollection_searchAndFilter__1zbLn {
    grid-gap: 1em;
    gap: 1em;
  }

  .singleNftCollection_filter__3b_fU {
    grid-gap: 1em;
    gap: 1em;
  }

  .singleNftCollection_chainMobile__HbKE3 {
    display: block;
    z-index: 0;
    margin-top: -1em;
  }

  .singleNftCollection_chainDesktop__3ZnPC {
    display: none;
  }

  .singleNftCollection_heading__2JqDL {
    grid-gap: 2em;
    gap: 2em;
    padding: 3em 1em;
  }

  .singleNftCollection_wrapper__1VCBk {
    padding: 2em 1em;
  }
}

.HeroSection_container__2x3fq {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
.HeroSection_content__1gVjf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 50%; */
}
.HeroSection_content__1gVjf h3 {
  font-size: 2.25em;
  font-style: normal;
  font-weight: 700;
}
.HeroSection_content__1gVjf p {
  font-size: 1.375rem;
  font-weight: 400;
  margin-top: 1.375rem;
}
.HeroSection_content__1gVjf div {
  margin-top: 5rem;
}
.HeroSection_applyBtn__3od7t {
  background: var(--main-color);
  border: 3px solid var(--main-color);
  box-sizing: border-box;
  border-radius: 37.44px;
  width: 16.875rem;
  height: 4rem;
  color: var(--default);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: var(--cursor-pointer);
  margin-top: auto;
  margin-bottom: 2.4rem;
  transition: 0.3s ease-in-out;
}
.HeroSection_applyBtn__3od7t:hover {
  background: var(--default);
  color: var(--main-color);
}
.HeroSection_imgContainer__2sSDY {
  display: flex;
  justify-content: flex-end;
}
.HeroSection_imgContainer__2sSDY img {
  margin-right: 5%;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .HeroSection_lineBreak__t8h6T {
    display: none;
  }
  .HeroSection_applyBtn__3od7t {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .HeroSection_container__2x3fq {
    flex-direction: column;
  }
  .HeroSection_imgContainer__2sSDY {
    justify-content: center;
  }
  .HeroSection_imgContainer__2sSDY img {
    margin: 0;
  }
  .HeroSection_content__1gVjf div {
    margin-top: 3rem;
  }
  .HeroSection_applyBtn__3od7t {
    margin: 2rem auto 0;
  }
  .HeroSection_lineBreak__t8h6T {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .HeroSection_content__1gVjf h3 {
    font-size: calc(1rem + 2vmin);
  }
  .HeroSection_content__1gVjf p {
    font-size: 1rem;
  }
  .HeroSection_applyBtn__3od7t {
    font-size: 1rem;
    width: 12.875rem;
    height: 3.5rem;
  }
}

.Join_container__1W70d {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
.Join_wrapper__3kLnB {
  background: url(/static/media/wavesArtist.65fc7206.svg);
  height: 40rem;
  display: flex;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}
.Join_container__1W70d h3 {
  font-size: 2.25em;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
.Join_container__1W70d p {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 1.375rem auto 0;
  text-align: center;
}
.Join_container__1W70d a {
  background: transparent;
  border: 3px solid var(--main-color);
  box-sizing: border-box;
  border-radius: 37.44px;
  width: 16.875rem;
  height: 4rem;
  color: var(--main-color);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: var(--cursor-pointer);
  margin: 4.5rem auto 5.5rem;
  transition: 0.3s ease-in-out;
}
.Join_container__1W70d a:hover {
  background: var(--main-color);
  color: white;
}
@media screen and (max-width: 1440px) {
  .Join_container__1W70d {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .Join_container__1W70d {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .Join_container__1W70d {
    max-width: 768px;
  }
  .Join_lineBreak__1NQ69 {
    display: none;
  }
  .Join_container__1W70d a {
    margin: 2.5rem auto 5.5rem;
  }
}

@media screen and (max-width: 768px) {
  .Join_container__1W70d {
    max-width: 540px;
    padding: 0 2em;
  }
  .Join_container__1W70d h3 {
    font-size: calc(1rem + 2vmin);
  }
  .Join_container__1W70d p {
    font-size: 1rem;
  }
  .Join_container__1W70d a {
    font-size: 1rem;
    width: 12.875rem;
    height: 3.5rem;
  }
}

@media screen and (max-width: 480px) {
  .Join_container__1W70d {
    padding: 0 1em;
  }
}

.artist_container__CWxd9 {
  width: 100%;
  padding-bottom: 6em;
  background: var(--default);
}

.artist_wrapper__2C-Rb {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 1em;
}
@media screen and (max-width: 1440px) {
  .artist_wrapper__2C-Rb {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .artist_wrapper__2C-Rb {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .artist_wrapper__2C-Rb {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .artist_wrapper__2C-Rb {
    max-width: 540px;
    padding: 0 2em;
  }
}

@media screen and (max-width: 480px) {
  .artist_wrapper__2C-Rb {
    padding: 0 1em;
  }
}

/* Accordion */

.listed_container__GKm80 {
  width: 100%;
  max-width: 1440px;
  padding: 0 1em;
  padding-top: 2em;
  margin: 0 auto;
  margin-bottom: 8em;
  display: flex;
  flex-direction: column;
}

.listed_nft__1XYc_ {
  width: 15rem;
  align-self: center;
  border: 5px solid #e5e8eb;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.listed_container__GKm80 span {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.listed_section__2IZ3B {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8eb;
  border-radius: 10px;
  margin-top: 1.5rem;
}

.listed_feature__ehsCq {
  margin-bottom: 1rem;
  border: solid 1px #e5e8eb;
  border-radius: 10px;
}

.listed_mainDetails__1-t9o {
  padding: 0.8rem 0.5rem;
  border-bottom: 1px solid #e5e8eb;
  display: flex;
  flex-direction: row;
  background-color: #f3f4f7;
  border-radius: 10px 10px 0 0;
}

.listed_nftId__1mlL8 {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.listed_buy__1KSA6 {
  flex-direction: row;
  margin: 1rem 0.5rem 1rem 0;
  color: white;
  padding: 0 0.5rem;
  height: 3rem;
  width: 11rem;
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--main-color);
}
.listed_view__2Ub1V {
  margin: 1rem auto;
  border-radius: 2.5rem;
}

.listed_viewtext__2m172 {
  color: var(--main-color);
  padding: 0 0.5rem;
  height: 4rem;
  width: 14rem;
  border-radius: 2.5rem;
  font-size: 1.3rem;
  font-weight: bold;
  border: 3px solid var(--main-color);
}
.listed_buy__1KSA6:hover,
.listed_detailContent__2acyr img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.listed_view__2Ub1V:hover {
  background-color: var(--main-color);
  transition: all 0.4s ease-in-out;
  cursor: var(--cursor-pointer);
}

.listed_viewtext__2m172:hover {
  border-radius: 2.5rem;
  color: white;
}

@media screen and (max-width: 1440px) {
  .listed_container__GKm80 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .listed_container__GKm80 {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .listed_container__GKm80 {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .listed_container__GKm80 {
    max-width: 540px;
    padding: 0 2em;
    margin-top: 2em;
  }

  .listed_section1__QGLLS {
    flex-direction: column;
  }

  .listed_v_subsection1__3ZN89 {
    width: 100%;
    margin-bottom: 1rem;
    max-width: 222rem;
  }
}

@media screen and (max-width: 480px) {
  .listed_container__GKm80 {
    padding: 0 0.5em;
  }
  .listed_container__GKm80 span {
    font-size: 1.2rem;
  }
  .listed_section1__QGLLS {
    flex-direction: column;
  }

  .listed_buy__1KSA6 {
    width: 95%;
  }
  .listed_nftId__1mlL8 {
    font-size: 1rem;
  }

  .listed_detailContent__2acyr img {
    width: 35px !important;
    margin-right: 10px !important;
  }
  .listed_viewtext__2m172 {
    font-size: 1rem;
    height: 3rem;
    width: 12rem;
  }

  .listed_nftName__2NoJP {
    flex-direction: column;
  }
}

.listed_detailContent__2acyr {
  padding: 1rem;
}
.listed_detailContent__2acyr img {
  width: 50px;
  margin-right: 24px;
  cursor: var(--cursor-pointer);
}

.Partner_container__2YVYN {
  width: 100%;
  padding-bottom: 2em;
  background: var(--default);
  display: flex;
  padding-top: 2rem;
}
.Partner_form__3XHu6 {
  height: calc(100vh - (120px));
  margin: auto;
}

.prompt_container__2ZCKy {
  position: fixed;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  left: 0;
  z-index: 100000;
  overflow: hidden;
  font-style: italic;
  color: var(--text-dark-900);
}

.prompt_container__2ZCKy.prompt_isActive__11Wl2 {
  background: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
}

.prompt_wrapper__2ReUk {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  padding: 2em;
  background: var(--default);
  border-radius: 0.5em;
  z-index: 100;
}

.prompt_action__zTnqu {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  align-items: center;
}

.prompt_reject__2wzDR,
.prompt_accept__j3BcK {
  width: 6em;
  padding: 0.5em 0;
  border-radius: 0.5em;
  transition: all 300ms;
  cursor: pointer;
}

.prompt_accept__j3BcK {
  background: var(--error-500);
  color: white;
  outline: 2px solid transparent;
}

.prompt_accept__j3BcK:hover {
  /* cursor: var(--cursor-hover); */
  outline: 2px solid var(--error-700);
}

.prompt_reject__2wzDR:hover {
  /* cursor: var(--cursor-hover); */
  background: var(--default);
}

.minter_container__j5gaq {
  width: 100%;
  background: var(--default);
  padding: 2em 1em;
  margin-bottom: 6em;
  -webkit-animation: minter_show__1wnTN 650ms;
          animation: minter_show__1wnTN 650ms;
}

.minter_ipfs__1TQIt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.minter_tick__GVBIl {
  background: red;
  fill: var(--default);
  fill-opacity: 20%;
  border-radius: 50%;
}

.minter_hashtags__ndoIC {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  overflow-x: scroll;
  overflow-y: hidden;
}
.minter_hashtag__254-j {
  border: 1px solid var(--card-outline);
  padding: 0.5em;
  border-radius: 10em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--main-color);
  margin-bottom: 0.3em;
}

.minter_noTag__ZgA95 {
  color: var(--card-outline);
}

@-webkit-keyframes minter_show__1wnTN {
  from {
    -webkit-transform: scale(0.99) translateY(1em);
            transform: scale(0.99) translateY(1em);
  }

  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

@keyframes minter_show__1wnTN {
  from {
    -webkit-transform: scale(0.99) translateY(1em);
            transform: scale(0.99) translateY(1em);
  }

  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

.minter_container__j5gaq textarea:hover,
.minter_container__j5gaq input:hover {
  cursor: var(--cursor-default);
}

.minter_container__j5gaq input,
.minter_container__j5gaq textarea {
  background: var(--card-trait);
  outline: none;
}

.minter_container__j5gaq input:focus,
.minter_container__j5gaq textarea:focus {
  border: none;
  outline: 1px solid var(--main-color) !important;
  cursor: text;
}

.minter_wrapper__1DKjX {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  flex-direction: column;
  overflow: hidden;
}
.minter_headerTitle__3Qtvl {
  font-weight: 700;
  font-size: 3.5rem;
  color: var(--text-heading);
}
.minter_headerDescription__3oh0M {
  color: var(--text-sub-heading);
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 7rem;
}
.minter_headerDescription__3oh0M span {
  display: inline-block;
  color: var(--main-color);
}
.minter_headerDescription__3oh0M span svg {
  display: block;
  width: 6rem;
}
.minter_grid__UlbTV {
  display: grid;
  grid-template-columns: 27% calc(73% - 2rem);
  grid-gap: 2rem;
  gap: 2rem;
}
.minter_mintSection__zZeFX {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.minter_assetContainer__1T4WK {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  border: 1px solid var(--outline);
  padding: 1em;
  border: 1px solid var(--outline);
  border-radius: 1em;
  position: relative;
}

.minter_imageContainers__B2WwH {
  width: 40%;
  position: relative;
  margin-bottom: 3em;
  top: 3em;
}

.minter_imageContainers__B2WwH.minter____1zkkS {
  height: 18em;
}

.minter_imageContainer__1ubM8 {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  position: absolute;
}

.minter_imageContainer__1ubM8:nth-child(2) {
  -webkit-transform: translate(1.5em, -1.5em);
          transform: translate(1.5em, -1.5em);
}

.minter_imageContainer__1ubM8:nth-child(1) {
  -webkit-transform: translate(3em, -3em);
          transform: translate(3em, -3em);
}

.minter_singleImage__2Jtu4 {
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-light);
  border-radius: 0.5em;
  border: 1px solid var(--outline);
}

.minter_assetContainer__1T4WK button {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  color: var(--main-color);
  border: 1px solid var(--main-hovered);
  transition: all 300ms;
}

.minter_assetContainer__1T4WK button:hover {
  background: var(--main-hovered);
  cursor: var(--cursor-pointer);
  color: white;
}

.minter_assetInfo__3ahWe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  border-radius: 0.5em;
  background: var(--card-info);
  flex-wrap: wrap;
}

.minter_innerAssetInfo__rJqxL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.25rem;
}

.minter_innerAssetInfo__rJqxL div {
  display: flex;
  align-items: center;
}

.minter_innerAssetInfo__rJqxL div span {
  color: var(--text-sub-heading);
}

.minter_innerAssetInfo__rJqxL div p {
  font-weight: 600;
  color: var(--text-heading);
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 2px;
}

.minter_innerAssetInfo__rJqxL .minter_assetInfoTitle__2xp3A > span {
  color: var(--text-heading);
  font-weight: 600;
}

.minter_assetInfoMintPrice__1Ltxx {
  background: var(--main-lightest);
  padding: 4px 10px;
}

.minter_required__BTAUb {
  color: var(--error-500);
}

.minter_showPreview__CQsIX {
  font-size: 0.85rem;
  color: var(--main-color);
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

div.minter_showPreview__CQsIX > span {
  color: var(--main-color);
}

.minter_showPreview__CQsIX img {
  width: 1em;
  transition: all 300ms;
}

.minter_showPreview__CQsIX:hover {
  text-decoration: underline;
}

.minter_showPreview__CQsIX:hover img {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.minter_showPreview___JIyhQ {
  position: absolute;
  left: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
}

.minter_showPreview___JIyhQ img {
  width: 1.5em;
}

.minter_heading__Bcpfk {
  display: inline-block;
  margin: auto;
  color: var(--main-color);
  font-size: 1.6rem;
  margin-bottom: 1em;
  font-weight: 600;
}
.minter_headerIcon__1cc-6 {
  background: #0d99ff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 8px;
}
.minter_headerIcon__1cc-6 svg {
  width: 100%;
  height: 100%;
}
.minter_headerIcon__1cc-6 svg path {
  stroke: white;
}
.minter_headerText__1AbKl {
  color: var(--main-color);
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.minter_details__2X5tr {
  width: 100%;
}

.minter_category__E4UwL {
  font-size: 1.2rem;
  margin-bottom: 0.5em;
  font-weight: 600;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.minter_info__3Zwy3 {
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.1em;
  gap: 0.1em;
  border-radius: 0.5em;
  background: var(--card-info);
}

.minter_info__3Zwy3 span {
  font-size: 0.85rem;
  font-weight: 400;
  white-space: break-spaces;
}

.minter_inputWrapper__1IHct {
  width: 100%;
  margin-bottom: 2.5em;
  border: 1px solid var(--card-outline);
  border-radius: 0.5em;
  padding: 1em;
  /* box-shadow: var(--shadow-light); */
}

.minter_dropInputWrapper__3uh8p {
  border: none;
  margin-bottom: 0;
  padding: 0;
  margin-left: 1em;
  box-shadow: none;
}

.minter_inputWrapper__1IHct label {
  display: flex;
  margin-bottom: 0.75em;
  font-weight: 500;
}

.minter_inputWrapper__1IHct label div {
  margin-left: 0.5em;
}

.minter_inputWrapper__1IHct textarea,
.minter_inputWrapper__1IHct input,
.minter_inputWrapper__1IHct select,
.minter_chinLabel__1OmGc {
  display: block;
  width: 100%;
  padding: 0.5em;
  resize: none;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
  color: var(--text-sub-heading);
  font-size: 1rem;
}

.minter_price__27FyQ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.minter_price__27FyQ input {
  width: 80%;
  margin-right: 1rem;
}

.minter_price__27FyQ span {
  color: var(--main-color);
  font-weight: bold;
  min-width: 20%;
  justify-content: center;
  display: flex;
}

.minter_inputWrapper__1IHct textarea:focus,
.minter_inputWrapper__1IHct input:focus,
.minter_inputWrapper__1IHct select:focus {
  outline: 1px solid gray;
}

.minter_inputWrapper__1IHct button {
  transition: all 300ms;
  color: var(--main-color);
  font-size: 1rem;
}

.minter_inputWrapper__1IHct button:hover {
  color: var(--main-hovered);
}

.minter_attributes__3Ga3Y {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 1em;
}

.minter_mintOptions__1cioU {
  width: 100%;
}

.minter_mintButtonWrapper__1oNrm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.minter_mintButtonWrapper__1oNrm button {
  width: 10em;
  padding: 0.75em 0;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
}

.minter_mintBtn__2vbT6 {
  background: var(--main-color);
  border: 1px solid transparent;
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
}

.minter_mintBtn__2vbT6:hover {
  background: var(--main-hovered);
  border: 1px solid var(--main-color);
}

.minter_cancelBtn__2ZJRF {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: all 300ms;
  font-size: 1rem;
}

.minter_cancelBtn__2ZJRF:hover {
  border: 1px solid transparent;
  background: var(--main-color);
  color: var(--default);
}

.minter_metadata__2QO69 {
  border: 1px solid var(--outline-dark);
  box-shadow: var(--shadow-light);
  border-radius: 0.375em;
  padding: 0.5em;
}

.minter_trait_type__26QQD {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.3em 1em;
  gap: 0.3em 1em;
  flex-wrap: wrap;
  margin-top: 0.5em;
}

.minter_trait_type__26QQD span {
  padding: 0.25em 0.5em;
  font-size: 0.85rem;
  background: #e5e8eb;
  border-radius: 3px;
}

.minter_attribute__2xeeI {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 0.5em;
}

.minter_attribute__2xeeI > * {
  flex: 1 1;
  border: 1px solid var(--outline);
  padding: 0.25em;
  border-radius: 0.25em;
}

.minter_priceInput__1b3Ki {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.minter_calcPrice__3MYg2 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  font-size: 0.85rem;
}

.minter_warn__2Nx-_ {
  color: var(--error-500);
}

.minter_dropWrapper__3mWg_ {
  margin-left: 1rem;
}

.minter_dropWrapper__3mWg_ > div {
  width: 60%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E5E8EBFF' stroke-width='6' stroke-dasharray='10%2c 12' stroke-dashoffset='3' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 0.5em;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  margin-bottom: 3rem;
  cursor: var(--cursor-pointer);
}

.minter_dropWrapper__3mWg_ > div:hover .minter_selectImg__2Cqkq p {
  color: var(--main-color);
}

.minter_dropWrapper__3mWg_ div img {
  height: 6rem;
}

.minter_dropWrapperSeleted__19bNM div img {
  height: 90%;
}

.minter_priceTooltip__1NNwa div {
  margin-left: 0.625em;
}

.minter_noClick__1525H {
  pointer-events: none;
  opacity: 0.3;
}

.minter_noClick__1525H:hover {
  cursor: not-allowed;
}

.minter_mintForm__3oUr0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--outline);
  border-radius: 1em;
  margin-top: 2em;
  padding: 2em 1em;
}

.minter_selectImg__2Cqkq {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minter_selectImg__2Cqkq p {
  color: #adb5bd;
  font-size: 1.25rem;
  margin-top: 0.3em;
}

.minter_selectImg__2Cqkq svg {
  height: 65px;
  width: 76px;
  padding: 0.6rem 1.1rem;
  background: #f6f4ff;
  border-radius: 0.1875em;
}

.minter_chainDropdown__bFxuJ {
  width: 100%;
  border: 2px solid transparent;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  background: var(--default);
  transition: all 650ms;
}

.minter_chainDropdown__bFxuJ.minter_active__3iTuf {
  max-height: 12em;
  overflow: auto;
  border-color: var(--outline);
}

.minter_chain__2QHHW {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid var(--card-outline);
}

.minter_chain__2QHHW img {
  width: 2em;
}

.minter_chain__2QHHW.minter_active__3iTuf {
  border-radius: 0.5em;
  border: 2px solid var(--card-outline);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.minter_chainLabel__2Atwb {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.minter_chain__2QHHW.minter_disable__TU47d {
  background: var(--card-trait);
  position: relative;
}

.minter_chain__2QHHW.minter_disable__TU47d:hover::before {
  position: absolute;
  content: "coming soon";
  right: 1em;
  background: var(--warning-300);
  color: var(--default);
  padding: 0 0.15em;
}

.minter_toggleTitle__RXmZF {
  display: flex;
  justify-content: space-between;
}
.minter_inputWrapper__1IHct.minter_hide__36QyP {
  opacity: 0.5;
  filter: alpha(opacity = 60);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
          filter: gray;
  pointer-events: none;
}
.minter_inputContainer__2MiPR {
  display: flex;
  align-items: center;
  background: var(--card-trait);
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  padding-right: 0.5em;
}

.minter_inputContainer__2MiPR input {
  border: none;
}

.minter_inputContainer__2MiPR input:focus {
  outline: none !important;
}

.minter_toggleTitle__RXmZF .minter_category__E4UwL {
  margin-left: 0px;
}

.minter_receiverAddress__cwUiX {
  transition: opacity 1s ease-out;
  width: 100%;
}
.minter_noDisplay___-D_k {
  display: none;
  transition: opacity 1s ease-out;
}

.minter_overlayImage__3IypF {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 20%;
  height: auto;
}
.minter_headerIcon__1cc-6 .minter_vibeIcon__1BqqG path:first-child {
  fill: var(--main-color);
}
.minter_headerIcon__1cc-6 .minter_vibeIcon__1BqqG path {
  stroke: var(--default);
}
.minter_headerIcon__1cc-6 .minter_artICon__3asvu path:last-child {
  fill: var(--default);
}
.minter_headerIcon__1cc-6 .minter_artICon__3asvu path {
  stroke: var(--default);
}
.minter_headerIcon__1cc-6 .minter_seshIcon__BB5d- path {
  fill: var(--default);
}
.minter_headerIcon__1cc-6 .minter_photoIcon__3ezR4 path {
  fill: var(--default);
}

.minter_tweet__389pI {
  width: 28em;
}
@media screen and (max-width: 1038px) {
  .minter_grid__UlbTV {
    display: grid;
    grid-template-columns: 40% calc(60% - 1rem);
    grid-gap: 1rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .minter_container__j5gaq {
    padding: 2em 1rem;
    margin-bottom: 4em;
  }
  .minter_headerDescription__3oh0M {
    margin-bottom: 3rem;
  }
  .minter_headerTitle__3Qtvl {
    font-size: 2rem;
  }
  .minter_headerDescription__3oh0M {
    font-size: 1rem;
  }
  .minter_headerDescription__3oh0M span svg {
    width: 4rem;
  }
  .minter_tweet__389pI {
    width: 20em;
  }
  .minter_imageContainers__B2WwH {
    width: 90%;
  }

  .minter_showPreview___JIyhQ {
    left: 0;
  }

  .minter_mintButtonWrapper__1oNrm button {
    font-size: 0.85rem;
    width: 8em;
  }

  .minter_innerAssetInfo__rJqxL {
    font-size: 0.85rem;
  }

  .minter_assetContainer__1T4WK button {
    padding: 0.5em 0.75em;
    font-size: 0.75rem;
  }

  .minter_imageContainer__1ubM8:nth-child(2) {
    -webkit-transform: translate(0, -1.5em);
            transform: translate(0, -1.5em);
  }

  .minter_imageContainer__1ubM8:nth-child(1) {
    -webkit-transform: translate(0, -3em);
            transform: translate(0, -3em);
  }

  .minter_imageContainers__B2WwH.minter____1zkkS {
    height: 12em;
  }
  .minter_grid__UlbTV {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .minter_price__27FyQ input {
    width: 50%;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .minter_headerDescription__3oh0M {
    font-size: 0.875rem;
  }
  .minter_headerDescription__3oh0M span svg {
    width: 3.5rem;
  }
  .minter_heading__Bcpfk {
    font-size: 1.2rem;
  }
  .minter_headerText__1AbKl {
    font-size: 1.2rem;
  }
  .minter_headerIcon__1cc-6 {
    width: 2.5rem;
    height: 2.5rem;
  }
  .minter_category__E4UwL {
    font-size: 1.1rem;
  }

  .minter_dropWrapper__3mWg_ > div {
    width: 100%;
  }
}

/* Slidder */

.minter_switch__2Ga-n {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.minter_switch__2Ga-n input {
  opacity: 0;
  width: 0;
  height: 0;
}

.minter_slider__1Q22h {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.minter_slider__1Q22h:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .minter_slider__1Q22h {
  background-color: #2196f3;
}

input:focus + .minter_slider__1Q22h {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .minter_slider__1Q22h:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.minter_locationSlider__1PGpf.minter_active__3iTuf + .minter_slider__1Q22h:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
.minter_locationSlider__1PGpf.minter_active__3iTuf + .minter_slider__1Q22h {
  box-shadow: 0 0 1px #2196f3;
  background-color: #2196f3;
}

.collectionPreview_container__2sr-e {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.collectionPreview_topNav__2JLMG {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  position: -webkit-sticky;
  position: sticky;
  top: 3.6em;
  background: var(--default);
  padding: 2em 0;
  z-index: 10;
}

.collectionPreview_backBtn__2ogbX {
  padding: 0.5em 1em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.collectionPreview_backBtn__2ogbX img {
  width: 1.2em;
  transition: all 300ms;
}

.collectionPreview_backBtn__2ogbX:hover img {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.collectionPreview_paginate__1ZcXY {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.25em;
}

.collectionPreview_paginate__1ZcXY input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
}

.collectionPreview_pageControl__1TlPH {
  padding: 0.5em 2em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  border: 2px solid transparent;
}

.collectionPreview_pageControl__1TlPH.collectionPreview_next__1z-MR {
  color: var(--default);
  background: var(--main-color);
  box-shadow: var(--btn-open);
}

.collectionPreview_pageControl__1TlPH.collectionPreview_next__1z-MR:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.collectionPreview_pageControl__1TlPH.collectionPreview_prev__2ql-B {
  color: var(--text-sub-heading);
  background: var(--default);
  box-shadow: var(--btn-open);
}

.collectionPreview_pageControl__1TlPH.collectionPreview_prev__2ql-B:hover {
  background: var(--card-light);
  box-shadow: var(--btn-close);
}

.collectionPreview_pageControl__1TlPH.collectionPreview_goto__3qNNJ {
  background: var(--card-preview);
  color: var(--default);
  box-shadow: var(--btn-open);
}

.collectionPreview_pageControl__1TlPH.collectionPreview_goto__3qNNJ:hover {
  background: var(--card-layer);
  box-shadow: var(--btn-close);
}

.collectionPreview_pageCount__eALRm {
  white-space: nowrap;
  color: var(--text-sub-heading);
}

.collectionPreview_gotoWrapper__nisZY {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.collectionPreview_display__1OypP {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

.collectionPreview_assetWrapper__18B2e {
  width: 100%;
  max-width: 20em;
  height: 20em;
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  border: 4px solid transparent;
  transition: all 300ms;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.collectionPreview_assetWrapper__18B2e img {
  width: 100%;
  height: 20em;
  object-fit: cover;
}

.collectionPreview_assetOverlay__1w2ho {
  position: absolute;
  width: 100%;
  height: 50%;
  background: var(--main-color);
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: all 300ms;
  padding: 0.5em;
  color: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.collectionPreview_assetName__eWM-S {
  color: var(--default);
}

.collectionPreview_assetWrapper__18B2e:hover .collectionPreview_assetOverlay__1w2ho {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.collectionPreview_assetWrapper__18B2e:hover {
  border: 4px solid var(--main-color);
}

.collectionPreview_attrBtn__3VSLW {
  width: 100%;
  margin-top: 1em;
  background: var(--default);
  color: var(--main-color);
  padding: 0.75em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
}

.collectionPreview_attrBtn__3VSLW:hover {
  background: var(--main-lighter);
  color: var(--main-hovered);
}

/* Collection Profile Image Select */
.collectionPreview_assetWrapperSelect__3lYnC {
  width: 100%;
  max-width: 14em;
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  border: 4px solid transparent;
  transition: all 300ms;
  display: flex;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: var(--shadow-light);
  border-radius: 20px;
  cursor: var(--cursor-pointer);
}
.collectionPreview_assetWrapperSelect__3lYnC:hover {
  background: var(--main-color);
}
.collectionPreview_assetWrapperSelect__3lYnC img {
  border-radius: 20px;
  width: 100%;
}

.collectionPreview_assetWrapperActive__3I_ZY {
  background: var(--main-color);
}

.collectionPreview_buttonWrapper__3jc9w {
  display: flex;
  margin-top: 2.5rem;
  font-weight: 500;
  font-size: 1.25rem;
  justify-content: center;
}
.collectionPreview_buttonWrapper__3jc9w p,
.collectionPreview_buttonWrapper__3jc9w div {
  border: 3px solid var(--main-color);
  border-radius: 37px;
  height: 2.8em;
  width: 9.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
  transition: 0.3s ease;
}
.collectionPreview_buttonWrapper__3jc9w p {
  margin-right: 1em;
  color: var(--main-color);
}
.collectionPreview_buttonWrapper__3jc9w p:hover {
  background: var(--main-color);
  color: white;
}
.collectionPreview_buttonWrapper__3jc9w div {
  background: var(--main-color);
  color: white;
}
.collectionPreview_buttonWrapper__3jc9w div:hover {
  background: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .collectionPreview_topNav__2JLMG {
    flex-direction: column;
    top: 2.8em;
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 0.5em 0;
  }

  .collectionPreview_paginate__1ZcXY input,
  .collectionPreview_pageCount__eALRm,
  .collectionPreview_backBtn__2ogbX,
  .collectionPreview_pageControl__1TlPH {
    font-size: 0.75rem;
  }

  .collectionPreview_backBtn__2ogbX {
    align-self: flex-start;
  }

  .collectionPreview_paginate__1ZcXY {
    width: 100%;
  }
  .collectionPreview_buttonWrapper__3jc9w {
    font-size: 0.9rem;
  }
}

.attributeOverlay_container__3mJzd {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.attributeOverlay_innerContainer__3fvY5 {
  width: 100%;
  max-width: 48em;
  background: var(--default);
  padding: 1em;
  border-radius: 1em;
  position: relative;
}

.attributeOverlay_closeBtn__260qV {
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.attributeOverlay_closeIcon__3_T2s {
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  background: var(--default);
  fill: var(--icon-color);
}

.attributeOverlay_closeIcon__3_T2s:hover {
  box-shadow: var(--shadow-dark);
}

.attributeOverlay_image__2o7Kt {
  width: 14em;
  height: 14em;
  border-radius: 1em;
}

.attributeOverlay_imageAndDescription__pKJwW {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
}

.attributeOverlay_nameAndDescription__TNCIj {
  flex: 1 1 auto;
  padding: 0.5em;
}

.attributeOverlay_name__1-w-G {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 1em;
}

.attributeOverlay_description__dskNs {
  height: 10em;
  overflow: auto;
}

.attributeOverlay_attributeSection__1RHgy {
  width: 100%;
  margin-top: 2em;
}

.attributeOverlay_attributeHeading__1gp7A {
  padding-left: 1em;
  font-weight: bold;
}

.attributeOverlay_attributeMenu__3TPoC {
  height: 16em;
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 0.5em;
  gap: 0.5em;
  flex-wrap: wrap;
  margin-top: 1em;
  overflow: auto;
  padding: 1em;
  border-radius: 1em;
}

.attributeOverlay_attribute__x7Mv9 {
  min-width: 14em;
  flex: 1 1 auto;
  text-align: center;
  padding: 0.75em;
  border-radius: 0.5em;
  background: var(--main-lighter);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  flex-direction: column;
  border: 1px solid var(--main-color);
}

.attributeOverlay_traitTitle__3_zpl,
.attributeOverlay_traitValue__1pmdL {
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .attributeOverlay_innerContainer__3fvY5 {
    max-width: 40em;
  }
}

@media screen and (max-width: 680px) {
  .attributeOverlay_innerContainer__3fvY5 {
    max-width: 32em;
  }
}

@media screen and (max-width: 540px) {
  .attributeOverlay_innerContainer__3fvY5 {
    height: 80vh;
    overflow: auto;
  }

  .attributeOverlay_closeBtn__260qV {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .attributeOverlay_imageAndDescription__pKJwW {
    flex-direction: column;
    align-items: center;
  }

  .attributeOverlay_nameAndDescription__TNCIj {
    width: 100%;
  }

  .attributeOverlay_attributeMenu__3TPoC {
    overflow: unset;
    height: auto;
  }

  .attributeOverlay_description__dskNs {
    height: unset;
  }

  .attributeOverlay_closeIcon__3_T2s {
    box-shadow: var(--shadow-light);
  }
}

.ProfileImgOverlay_modelContainer__153LK {
  display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  padding: 1em;
}
.ProfileImgOverlay_modelActive__YL1Ah {
  display: flex;
}
.ProfileImgOverlay_guideContainer__qE-zL {
  width: 100%;
  max-width: 48em;
  border-radius: 1em;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.ProfileImgOverlay_imgContainer__3O28k {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  height: 432px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.ProfileImgOverlay_imgContainer__3O28k > div {
  padding: 6px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: var(--shadow-light);
  border-radius: 20px;
  height: 200px;
  width: 200px;
  margin-bottom: 1rem;
  cursor: var(--cursor-pointer);
}
.ProfileImgOverlay_imgContainer__3O28k > div:hover {
  background: var(--main-color);
}
.ProfileImgOverlay_imgContainer__3O28k div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: var(--default);
  border-radius: 20px;
}

.ProfileImgOverlay_line__3cdJt {
  height: 2px;
  width: 100%;
  background: var(--outline);
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.ProfileImgOverlay_buttonConatiner__3Fwto {
  display: flex;
  justify-content: space-evenly;
}
.ProfileImgOverlay_buttonConatiner__3Fwto div,
.ProfileImgOverlay_buttonConatiner__3Fwto p {
  border-radius: 2.375rem;
  border: 3px solid var(--main-color);
  font-weight: 500;
  font-size: 1.25rem;
  width: 11.8rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
  transition: 0.3s ease;
}
.ProfileImgOverlay_buttonConatiner__3Fwto p {
  color: var(--main-color);
}
.ProfileImgOverlay_buttonConatiner__3Fwto div {
  color: #fff;
  background: var(--main-color);
}
.ProfileImgOverlay_buttonConatiner__3Fwto div:hover {
  background: var(--main-hovered);
}
.ProfileImgOverlay_buttonConatiner__3Fwto p:hover {
  background: var(--main-color);
  color: #fff;
}
div.ProfileImgOverlay_active__2jXZo {
  background: var(--main-color);
}
.ProfileImgOverlay_viewALlBtn__AtdJG {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5rem auto auto;
  color: var(--main-color);
  font-size: 1.25rem;
  font-weight: 500;
  cursor: var(--cursor-pointer);
}
.ProfileImgOverlay_viewALlBtn__AtdJG:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 580px) {
  .ProfileImgOverlay_buttonConatiner__3Fwto p {
    margin-right: 0.5em;
  }
  .ProfileImgOverlay_buttonConatiner__3Fwto p,
  .ProfileImgOverlay_buttonConatiner__3Fwto div {
    font-size: 1em;
  }
}

.popup_container__3FnjW {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.75);
  z-index: 1;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
  z-index: 20;
}

.popup_container__3FnjW.popup_active__nKBUj {
  display: flex;
}

.popup_popupContainer__2I0tu {
  width: 100%;
  max-width: 32em;
  background: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 4em 1em;
  border-radius: 1em;
  position: relative;
  -webkit-animation: popup_show__10-_5 300ms alternate;
          animation: popup_show__10-_5 300ms alternate;
}

@-webkit-keyframes popup_show__10-_5 {
  from {
    -webkit-transform: scale(0.95) translateY(-1em);
            transform: scale(0.95) translateY(-1em);
  }

  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

@keyframes popup_show__10-_5 {
  from {
    -webkit-transform: scale(0.95) translateY(-1em);
            transform: scale(0.95) translateY(-1em);
  }

  to {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
  }
}

.popup_imgContainer__wIebB {
  padding: 2em;
  border-radius: 0.7em;
  box-shadow: -26px 49px 31px -24px rgba(93, 134, 194, 0.12), 26px 49px 31px -28px rgba(93, 134, 194, 0.12);
  margin-bottom: 1em;
}

.popup_container__3FnjW .popup_heading__1U93b {
  color: var(--main-color);
}

.popup_container__3FnjW .popup_heading__1U93b.popup_error__2M0Ll {
  color: var(--error-500);
}

.popup_closeIcon__11zFE {
  position: absolute;
  top: 1em;
  right: 1em;
  transition: all 300ms;
  cursor: pointer;
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.25em;
  fill: var(--icon-color);
  background: var(--btn-close);
}

.popup_closeIcon__11zFE:hover {
  box-shadow: var(--shadow-dark);
}

.popup_actionBtnContainer__GL-R3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

.popup_actionBtn__15ZLV {
  width: 12em;
  border: 1px solid transparent;
  border-radius: 0.7em;
  padding: 0.75em 0;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  cursor: pointer;
}

.popup_errorBtn__3atw7 {
  background: black;
  color: white;
  transition: all 300ms;
}

.popup_errorBtn__3atw7:hover {
  background: var(--card-bg);
}

.popup_actionBtn__15ZLV.popup__1__3MOc2 {
  background: var(--main-color);
  color: white;
  position: relative;
}

.popup_actionBtn__15ZLV.popup__2__1bwCu {
  background: var(--default);
  color: var(--main-color);
  border-color: var(--main-color);
}

.popup_actionBtn__15ZLV.popup__1__3MOc2:hover {
  background: var(--main-hovered);
}

.popup_actionBtn__15ZLV.popup__2__1bwCu:hover {
  background: var(--main-color);
  color: white;
}

.popup_actionBtn__15ZLV.popup__2__1bwCu img:last-child {
  display: none;
}

.popup_actionBtn__15ZLV.popup__2__1bwCu:hover img:last-child {
  display: block;
}

.popup_actionBtn__15ZLV.popup__2__1bwCu:hover img:first-child {
  display: none;
}

.popup_iconContainer__1Qq_1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_iconContainer__1Qq_1 img {
  width: 1.2em;
}

.popup_detailsContainer__3Kx-E {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.popup_url__2YEmH {
  flex: 1 1;
  max-width: 20em;
  border: 1px solid var(--outline);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.25em 1em;
  font-size: 0.85rem;
}

.popup_errorMsg__3DkO3 {
  width: 100%;
  max-width: 32em;
  max-height: 8em;
  overflow: auto;
  text-align: center;
  padding: 0.25em 1em;
  font-size: 0.85rem;
  border: 1px solid transparent;
}

.popup_tag__3ytCo {
  margin-right: 1em;
  color: var(--text-sub-heading);
}

.popup_copyBtn__16Oh1 {
  padding: 0.25em 0.5em;
  background: var(--main-lightest);
  color: var(--text-sub-heading);
  width: 5em;
  text-align: center;
  border: 1px solid var(--outline);
  transition: all 300ms;
}

.popup_copyBtn__16Oh1:hover {
  background: var(--main-lighter);
}

.SliderInput_container__11J4V {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  /* grid-template-columns: calc(100% - 5rem) 4rem; */
  grid-template-columns: 100%;
  align-items: center;
  margin-bottom: 1rem;
}
.SliderInput_container__11J4V div {
  display: flex;
  align-items: center;
  color: var(--text-sub-heading);
  justify-content: center;
  font-weight: 500;
}
.SliderInput_slider__Wkaym[type="range"] {
  -webkit-appearance: none;
  height: 0.7rem;
  background: #e2e4e8;
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  padding: 0 !important;
}
.SliderInput_slider__Wkaym[type="range"]:focus {
  border: none !important;
  outline: none !important;
}
/* Input Thumb */
.SliderInput_slider__Wkaym[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--default);
  border: 1px solid #747c90;
  cursor: ew-resize;
  box-shadow: 0px 5.71429px 7.85714px rgba(0, 0, 0, 0.08);
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.SliderInput_slider__Wkaym[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  background: var(--main-color);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.SliderInput_slider__Wkaym[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  background: var(--main-color);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  -ms-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

/* .slider[type="range"]::-webkit-slider-thumb:hover {
  background: var(--main-color);
} */

/* .slider[type="range"]::-moz-range-thumb:hover {
  background: var(--main-color);
} */

/* .slider[type="range"]::-ms-thumb:hover {
  background: var(--main-color);
} */

/* Input Track */
.SliderInput_slider__Wkaym[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.SliderInput_slider__Wkaym[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.SliderInput_slider__Wkaym[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.Category_container__2-2P8 {
  padding: 4rem 1rem;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  background: var(--default);
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.12);
  border-radius: 14px;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  border: 1px solid #e5ecfb;
}
.Category_card__2dNxx {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #e5e8eb;
  box-shadow: 28.8589px 19.9792px 19.9792px rgb(93 134 194 / 5%);
  border-radius: 15.5394px;
  padding: 0.875rem 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  text-align: left;
  cursor: pointer;
}
.Category_icon__3WGJg {
  width: 48px;
  height: 48px;
  background: #f3f6fc;
  padding: 10px;
  border-radius: 50%;
}
.Category_icon__3WGJg svg {
  height: 100%;
  width: 100%;
}
.Category_text__3F8Mi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 54px);
}
.Category_title__2eofF {
  font-size: 0.875rem;
  color: var(--main-color);
  font-weight: 500;
}
.Category_desc__1aGfE {
  font-size: 0.75rem;
  color: #263238;
}
.Category_card__2dNxx:hover,
.Category_card__2dNxx.Category_active__3XBne {
  background: var(--main-color);
}
.Category_card__2dNxx:hover .Category_title__2eofF,
.Category_card__2dNxx:hover .Category_desc__1aGfE,
.Category_card__2dNxx.Category_active__3XBne .Category_title__2eofF,
.Category_card__2dNxx.Category_active__3XBne .Category_desc__1aGfE {
  color: var(--default);
}
.Category_card__2dNxx:hover .Category_icon__3WGJg,
.Category_card__2dNxx.Category_active__3XBne .Category_icon__3WGJg {
  background: var(--default);
}
.Category_card__2dNxx:hover .Category_icon__3WGJg svg path,
.Category_card__2dNxx.Category_active__3XBne .Category_icon__3WGJg svg path {
  stroke: var(--main-color);
}
.Category_card__2dNxx:nth-child(5):hover .Category_icon__3WGJg svg path,
.Category_card__2dNxx:nth-child(5).Category_active__3XBne .Category_icon__3WGJg svg path {
  fill: var(--main-color);
}
.Category_card__2dNxx:nth-child(2):hover .Category_icon__3WGJg svg path:last-child,
.Category_card__2dNxx:nth-child(2).Category_active__3XBne .Category_icon__3WGJg svg path:last-child {
  fill: var(--main-color);
}
.Category_card__2dNxx:nth-child(6):hover .Category_icon__3WGJg svg path,
.Category_card__2dNxx:nth-child(6).Category_active__3XBne .Category_icon__3WGJg svg path {
  fill: var(--main-color);
}

.Attribute_container__36Fyj {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}

.Attribute_container__36Fyj button {
  border: 1px solid var(--outline);
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
  background: var(--error-400);
}

.Attribute__0__1Q4-1 {
  background: var(--outline) !important;
  cursor: no-drop;
}

.Attribute_container__36Fyj input::-webkit-input-placeholder {
  color: var(--outline);
}

.Attribute_container__36Fyj input:-ms-input-placeholder {
  color: var(--outline);
}

.Attribute_container__36Fyj input::placeholder {
  color: var(--outline);
}

.Attribute_closeIcon__1BeF0 {
  fill: var(--default);
}

.tweeter_tweetContainer__3OdQk {
  border: 1px solid var(--outline);
  border-radius: 10px;
  box-shadow: 5px 10px 18px var(--outline);
  padding: 1em;
  width: 20em;
}

.tweeter_tweetHeader__20CaD {
  display: flex;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.tweeter_tweetHeader__20CaD img {
  border-radius: 50em;
}

.tweeter_headerRight__1gcgY {
  display: flex;
  flex-direction: column;
}

.tweeter_description__MgAOF,
.tweeter_date__3TPX- {
  text-align: left;
  margin-top: 1em;
}

.tweeter_username__6FXTp {
  font-weight: 700;
  width: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.tweeter_contents__1toSZ {
  margin: 0.5em 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.2em;
  gap: 0.2em;
}

.tweeter_content__1_JJA {
  border-radius: 10px;
}

.tweeter_dark__GkZS_ {
  color: #fff;
  background-color: #000;
}

.tweeter_usertag__nQSY6,
.tweeter_date__3TPX- {
  font-size: 0.8em;
  color: #5b7083;
  text-align: left;
}

.ipfsImage_container__3Kiyf {
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.ipfsImage_container__3Kiyf img {
  width: 100%;
}

.ipfsImage_singleVideo__2G_03 {
  width: 400px;
  height: 300px;
}

.Session_container__3_Mi9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  margin-bottom: 6em;
}

.Session_backBtnContainer__3ElDW {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2em;
  margin-top: 2em;
  cursor: pointer;
}

.Session_backIcon__26YT2 {
  width: 1.2em;
  height: 1.2em;
  transition: all 300ms;
}

.Session_backBtnContainer__3ElDW:hover .Session_backIcon__26YT2 {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Session_wrapper__KTFSN {
  width: 100%;
  max-width: 56em;
  border-radius: 0.5em;
  background: var(--default);
  position: relative;
  border: 1px solid var(--outline);
  padding: 2em;
  box-shadow: 12px 0px 35px rgba(93, 134, 194, 0.1), -2px -10px 35px rgba(93, 134, 194, 0.1);
}

.Session_closeBtn__mfvtj {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.Session_closeBtn__mfvtj:hover {
  box-shadow: var(--shadow-dark);
}

.Session_content___NKKj {
  width: 100%;
  max-width: 42em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 3em;
  gap: 3em;
  border-radius: 0.5em;
}

.Session_content___NKKj h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.Session_subHeading__qU4of {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Session_subHeading__qU4of button {
  align-self: center;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: var(--btn-open);
}

.Session_subHeading__qU4of button:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.Session_sessionContainer__3YmrY {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Session_sessionWrapper__2Setq {
  width: 100%;
  background: var(--card-lighter);
  border: 2px solid var(--outline);
  border-radius: 0.5em;
  padding: 2em;
  transition: all 650ms;
}

.Session_sessionWrapper__2Setq.Session_active__2Br6R {
  border: 2px solid var(--main-color);
}

.Session_session__2ejtO {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Session_plan__3e5iA {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.Session_planName__1yj5e {
  font-weight: 600;
  margin-right: 0.5em;
  text-transform: capitalize;
  font-size: 1.2rem;
}

.Session_planFlag__xxOZi {
  padding: 0.25em 1em;
  padding-right: 1.5em;
  font-size: 0.65rem;
  -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0 100%);
          clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0 100%);
  background: #a6c5fa;
}

.Session_collectionName__csem_ {
  margin-bottom: 0.5em;
  width: 8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Session_action__3tWuQ {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.Session_deleteBtn__zmd_2,
.Session_loadBtn__XkPL7 {
  align-self: center;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  transition: all 300ms;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.85rem;
  text-align: center;
}

.Session_loadBtn__XkPL7 *,
.Session_deleteBtn__zmd_2 * {
  transition: all 300ms;
}

.Session_deleteBtn__zmd_2 {
  color: var(--error-500);
  border: 1px solid var(--outline);
}

.Session_deleteBtn__zmd_2 * {
  color: var(--error-500);
}

.Session_deleteBtn__zmd_2:hover {
  background: var(--error-500);
  color: var(--default);
  border: 1px solid transparent;
}

.Session_deleteBtn__zmd_2:hover * {
  color: var(--default);
}

.Session_loadBtn__XkPL7 {
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.Session_loadBtn__XkPL7 * {
  color: var(--main-color);
}

.Session_loadBtn__XkPL7:hover {
  background: var(--main-color);
  color: var(--default);
}

.Session_loadBtn__XkPL7:hover * {
  color: var(--default);
}

.Session_dropdownIconContainer__n6IzW {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  background: var(--card-light);
  cursor: pointer;
}

.Session_dropdownIcon__1c5Fa {
  width: 1em;
  height: 1em;
  fill: var(--main-color);
  transition: all 650ms;
}

.Session_dropdownIcon__1c5Fa.Session_active__2Br6R {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Session_sessionDropdown__302y3 {
  width: 100%;
  border-top: 1px solid var(--outline);
  margin-top: 2em;
  max-height: 0;
  overflow: hidden;
  transition: all 650ms;
}

.Session_sessionDropdown__302y3.Session_active__2Br6R {
  max-height: 28em;
}

.Session_cost__1yImC {
  margin-top: 1em;
}

.Session_amount__1Becu {
  font-weight: 600;
}

.Session_services__Qvsvl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--outline);
  padding: 2em 0;
}

.Session_service__1b39W {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  font-size: 0.85rem;
}

.Session_closeIcon__2Bofy {
  fill: black;
}

.Session_markIcon__iw_Bj {
  fill: var(--main-color);
}

.Session_upgradeBtnContainer__qT2yZ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Session_upgradeBtnContainer__qT2yZ button {
  align-self: center;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
}

.Session_upgradeBtnContainer__qT2yZ button:hover {
  color: var(--main-hovered);
  border-color: var(--main-hovered);
}

.Session_skeleton__3iZZg {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .Session_sessionWrapper__2Setq {
    padding: 1em;
  }

  .Session_deleteBtn__zmd_2,
  .Session_loadBtn__XkPL7 {
    padding: 0.5em 1em;
    border-radius: 0.7em;
  }

  .Session_dropdownIconContainer__n6IzW {
    padding: 0.5em;
  }
}

@media screen and (max-width: 540px) {
  .Session_container__3_Mi9 {
    margin-bottom: 4em;
  }

  .Session_backBtnContainer__3ElDW {
    margin-top: 1em;
  }

  .Session_wrapper__KTFSN {
    padding: 2em 0.5em;
  }

  .Session_subHeading__qU4of {
    padding: 0 1em;
  }

  .Session_content___NKKj {
    grid-gap: 2em;
    gap: 2em;
  }

  .Session_content___NKKj h1 {
    font-size: 1.5rem;
  }

  .Session_subHeading__qU4of h3 {
    font-size: 1rem;
  }

  .Session_upgradeBtnContainer__qT2yZ button,
  .Session_subHeading__qU4of button {
    font-size: 0.85rem;
  }

  .Session_action__3tWuQ {
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .Session_planFlag__xxOZi,
  .Session_loadBtn__XkPL7 span,
  .Session_deleteBtn__zmd_2 span {
    display: none;
  }

  .Session_planName__1yj5e {
    font-size: 1rem;
  }

  .Session_collectionName__csem_ {
    font-size: 0.85rem;
  }

  .Session_sessionDropdown__302y3 {
    margin-top: 0.5em;
  }

  .Session_dropdownIconContainer__n6IzW {
    padding: 0.35em;
    padding: 0.25em;
  }

  .Session_dropdownIcon__1c5Fa {
    width: 0.75em;
    height: 0.75em;
  }

  .Session_services__Qvsvl {
    padding: 1em 0;
  }

  .Session_backIcon__26YT2 {
    width: 1em;
    height: 1em;
  }
}

.NoResult_container__1jbCD {
  width: 100%;
  max-width: 56em;
  border-radius: 0.5em;
  background: var(--default);
  position: relative;
  border: 1px solid var(--outline);
  padding: 2em;
  box-shadow: 12px 0px 35px rgba(93, 134, 194, 0.1), -2px -10px 35px rgba(93, 134, 194, 0.1);
}

.NoResult_content__2zXMj {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 3em;
  gap: 3em;
  border-radius: 0.5em;
  padding: 2em;
  background: var(--card-lighter);
}

.NoResult_content__2zXMj h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.NoResult_wrapper__2p28J {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 1em;
}

.NoResult_description__3luG4 {
  text-align: center;
}

.NoResult_description__3luG4 div:first-child {
  margin-bottom: 0.5em;
}

.NoResult_wrapper__2p28J button {
  font-size: 0.85rem;
  transition: all 300ms;
}

.NoResult_createBtn__2nFSL {
  color: var(--main-color);
}

.NoResult_upgradeBtn__28-nb {
  padding: 0.75em 4em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
}

.NoResult_upgradeBtn__28-nb:hover {
  background: var(--main-hovered);
}

.NoResult_createBtn__2nFSL:hover {
  color: var(--main-hovered);
}

@media screen and (max-width: 540px) {
  .NoResult_container__1jbCD,
  .NoResult_content__2zXMj {
    padding: 1em;
  }

  .NoResult_cartIcon__2NNA_ {
    width: 8em;
    height: 8em;
  }

  .NoResult_content__2zXMj h1 {
    font-size: 1.5rem;
  }
}

.SessionModal_container__nFO7V {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.SessionModal_container__nFO7V.SessionModal_active__2LPGr {
  display: flex;
}

.SessionModal_wrapper__1AJoT {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  overflow: hidden;
  position: relative;
  -webkit-animation: SessionModal_show__ILft_ 300ms alternate;
          animation: SessionModal_show__ILft_ 300ms alternate;
}

@-webkit-keyframes SessionModal_show__ILft_ {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes SessionModal_show__ILft_ {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.SessionModal_closeBtn__1TwVM {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.SessionModal_closeBtn__1TwVM:hover {
  box-shadow: var(--shadow-dark);
}

.SessionModal_content__44RxA {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 3em;
  gap: 3em;
  border-radius: 0.5em;
}

.SessionModal_content__44RxA h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.SessionModal_sessionContainer__kIr-T {
  width: 100%;
  height: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  grid-gap: 1em;
  gap: 1em;
}

.SessionModal_session__1CQhm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--card-outline);
  padding: 1em 1em;
  border-radius: 0.5em;
}

.SessionModal_name__2Ye4B {
  font-weight: 600;
  margin-bottom: 0.5em;
}

.SessionModal_edited__1lhvT {
  font-size: 0.85rem;
}

.SessionModal_action__2tWfr {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.SessionModal_deleteBtn__1k3Ny,
.SessionModal_loadBtn__1jxmY {
  align-self: center;
  width: 7em;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.85rem;
}

.SessionModal_deleteBtn__1k3Ny {
  background: var(--card-light);
  color: var(--error-500);
  border: 1px solid var(--outline);
}

.SessionModal_deleteBtn__1k3Ny:hover {
  background: var(--error-500);
  color: var(--default);
  border: 1px solid transparent;
}

.SessionModal_loadBtn__1jxmY {
  background: var(--card-bg);
  color: var(--default);
}

.SessionModal_loadBtn__1jxmY:hover {
  background: var(--default);
  color: var(--card-bg);
  border: 1px solid var(--card-bg);
}

.SessionModal_content__44RxA button {
  align-self: center;
  width: 12em;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: var(--btn-open);
}

.SessionModal_content__44RxA button:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.SessionModal_loader__PZKkN {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.SessionModal_loader__PZKkN.SessionModal_active__2LPGr {
  display: flex;
}

.SessionModal_dots___HaUg {
  width: -webkit-max-content;
  width: max-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--card-light);
}

.SessionModal_dots___HaUg div {
  color: var(--main-color);
}

.SessionModal_dotOne__Wj_vH,
.SessionModal_dotTwo__2XcTs,
.SessionModal_dotThree__1Nyi8 {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--card-light);
  -webkit-animation: SessionModal_blink__1hsXc 450ms infinite alternate linear;
          animation: SessionModal_blink__1hsXc 450ms infinite alternate linear;
}

.SessionModal_dotOne__Wj_vH {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.SessionModal_dotTwo__2XcTs {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.SessionModal_dotThree__1Nyi8 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}

@-webkit-keyframes SessionModal_bounce__2hnF4 {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes SessionModal_bounce__2hnF4 {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes SessionModal_blink__1hsXc {
  from {
    background: var(--main-lighter);
  }
  to {
    background: var(--main-color);
  }
}

@keyframes SessionModal_blink__1hsXc {
  from {
    background: var(--main-lighter);
  }
  to {
    background: var(--main-color);
  }
}

.UpgradeModal_container__1bPCS {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.UpgradeModal_container__1bPCS.UpgradeModal_active__3rbsT {
  display: flex;
}

.UpgradeModal_wrapper__2xycK {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  position: relative;
  -webkit-animation: UpgradeModal_show__1ksgx 300ms alternate;
          animation: UpgradeModal_show__1ksgx 300ms alternate;
}

@-webkit-keyframes UpgradeModal_show__1ksgx {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes UpgradeModal_show__1ksgx {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.UpgradeModal_closeBtn__2QKH5 {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.UpgradeModal_closeBtn__2QKH5:hover {
  box-shadow: var(--shadow-dark);
}

.UpgradeModal_content__1PIXC {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1.5em;
  gap: 1.5em;
  padding: 0 4em;
  border-radius: 0.5em;
}

.UpgradeModal_heading__3LeL2 {
  font-weight: 600;
  text-align: center;
}

.UpgradeModal_heading__3LeL2 .UpgradeModal_accent__3PHZK {
  text-transform: uppercase;
  color: var(--main-color);
}

.UpgradeModal_features__3NK0O {
  font-size: 0.85rem;
  font-weight: 600;
}

.UpgradeModal_plans__G-2B8 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--card-light);
  padding: 0 1.5em;
  flex-wrap: wrap;
}

.UpgradeModal_plan__3GOWt {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-right: 1px solid var(--outline);
  padding: 0.75em 1em;
}

.UpgradeModal_plan__3GOWt:first-child {
  border-left: none;
}

.UpgradeModal_plan__3GOWt:last-child {
  border-right: none;
}

.UpgradeModal_content__1PIXC button {
  align-self: center;
  width: 80%;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  margin-top: 1em;
}

.UpgradeModal_content__1PIXC button:hover {
  background: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .UpgradeModal_content__1PIXC {
    padding: 0 2em;
  }

  .UpgradeModal_upgradeIcon__2hDju {
    width: 6em;
    height: 6em;
  }

  .UpgradeModal_plans__G-2B8 {
    padding: 0.5em;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .UpgradeModal_plan__3GOWt:first-child {
    border-left: 1px solid var(--outline);
  }

  .UpgradeModal_plan__3GOWt:last-child {
    border-right: 1px solid var(--outline);
  }

  .UpgradeModal_plan__3GOWt {
    border: 1px solid var(--outline);
  }
}

@media screen and (max-width: 480px) {
  .UpgradeModal_upgradeIcon__2hDju {
    width: 4em;
    height: 4em;
  }

  .UpgradeModal_plan__3GOWt {
    font-size: 0.85rem;
    padding: 0.25em 0.5em;
  }
}

.SuccessPlan_container__1j_Tl {
  width: 100%;
  min-height: calc(100vh - 4em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.SuccessPlan_wrapper__3Vtzi {
  width: 100%;
  max-width: 64em;
  padding: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 1em;
  border: 1px solid var(--outline);
  box-shadow: -26px 0px 30px -12px rgba(93, 134, 194, 0.03), 26px 18px 18px rgba(93, 134, 194, 0.03);
}

.SuccessPlan_heading__78M7m {
  font-weight: 600;
}

.SuccessPlan_description__2bESr {
  width: 90%;
  max-width: 32em;
  text-align: center;
}

.SuccessPlan_wrapper__3Vtzi form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  grid-gap: 2em;
  gap: 2em;
}

.SuccessPlan_wrapper__3Vtzi input {
  width: 36em;
  padding: 0.75em;
  border: 1px solid var(--outline-dark);
  border-radius: 0.25em;
  font-size: 1rem;
  outline: none;
}

.SuccessPlan_wrapper__3Vtzi input::-webkit-input-placeholder {
  color: var(--text-placeholder-light);
}

.SuccessPlan_wrapper__3Vtzi input:-ms-input-placeholder {
  color: var(--text-placeholder-light);
}

.SuccessPlan_wrapper__3Vtzi input::placeholder {
  color: var(--text-placeholder-light);
}

.SuccessPlan_wrapper__3Vtzi button {
  width: 10em;
  text-align: center;
  padding: 0.75em;
  font-size: 1rem;
  border-radius: 0.7em;
  background: var(--main-disable);
  color: var(--default);
  cursor: default;
}

.SuccessPlan_wrapper__3Vtzi button.SuccessPlan_active__29J3W {
  background: var(--main-color);
  cursor: pointer;
  transition: all 300ms;
}

.SuccessPlan_wrapper__3Vtzi button.SuccessPlan_active__29J3W:hover {
  background: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .SuccessPlan_container__1j_Tl {
    padding: 1em;
  }

  .SuccessPlan_wrapper__3Vtzi {
    padding: 4em 2em;
  }

  .SuccessPlan_successIcon__2FMyb {
    width: 8em;
    height: 8em;
  }

  .SuccessPlan_wrapper__3Vtzi input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .SuccessPlan_wrapper__3Vtzi {
    padding: 4em 1em;
  }

  .SuccessPlan_description__2bESr {
    font-size: 0.85rem;
  }

  .SuccessPlan_wrapper__3Vtzi input {
    padding: 0.5em;
  }

  .SuccessPlan_wrapper__3Vtzi button {
    font-size: 0.85rem;
  }
}

.fallback_container__23sjf {
  width: 100%;
  font-size: 1rem;
  text-align: center;
  padding-top: 4em;
  max-height: calc(100vh - 65px);
  overflow-y: hidden;
}

.fallback_container__23sjf h1 {
  font-weight: 700;
  font-size: 40px;
  margin: 1.5vw 0;
}

.fallback_text__19VyF {
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 40px;
}

.fallback_button-container__14qge {
  margin-top: 2.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fallback_go-back__374Yt svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: 0.4s;
}
.fallback_go-back__374Yt svg path {
  stroke: var(--main-color);
  fill: var(--main-color);
}

.fallback_go-back__374Yt:hover {
  background-color: var(--main-color);
  color: white;
}
.fallback_go-back__374Yt:hover svg {
  -webkit-transform: translate(-6px, 0px);
          transform: translate(-6px, 0px);
}
.fallback_go-back__374Yt:hover svg path {
  stroke: white;
  fill: white;
}

.fallback_go-back__374Yt {
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  margin-right: 40px;
  color: var(--main-color);
  font-size: 20px;
  border: 1px solid var(--main-color);
  transition: 0.4s;
  font-weight: 600;
  border-radius: 100px;
  cursor: var(--cursor-pointer);
}

.fallback_home__Ve47I:hover {
  background: none;
  color: var(--main-color);
}
.fallback_home__Ve47I:hover svg path {
  fill: var(--main-color);
}

.fallback_home__Ve47I {
  width: 200px;
  cursor: var(--cursor-pointer);
  height: 60px;
  background-color: var(--main-color);
  color: white;
  font-size: 20px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-color);
  font-weight: 600;
  border-radius: 100px;
}
.fallback_go-back__374Yt img {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}
.fallback_not-found__3I4eM img {
  max-width: 100%;
  height: 35vh;
}
.fallback_home__Ve47I svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: 0.4s;
  margin-bottom: 3px;
  background: none;
}

@media screen and (max-width: 768px) {
  .fallback_container__23sjf {
    padding-top: 2em;
    max-height: calc(100vh - 6em);
  }
  .fallback_not-found__3I4eM h1 {
    font-size: 30px;
  }
  .fallback_text__19VyF {
    font-size: 20px;
  }
  .fallback_go-back__374Yt {
    margin-right: 10px;
  }
  .fallback_go-back__374Yt,
  .fallback_home__Ve47I {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }
}

.FailedPlan_container__2rtlT {
  width: 100%;
  min-height: calc(100vh - 4em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.FailedPlan_wrapper__1zrke {
  width: 100%;
  max-width: 58em;
  padding: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  border-radius: 1em;
  border: 1px solid var(--outline);
  box-shadow: -26px 0px 30px -12px rgba(93, 134, 194, 0.03), 26px 18px 18px rgba(93, 134, 194, 0.03);
}

.FailedPlan_heading__QxhQy {
  font-weight: 600;
}

.FailedPlan_description__2dY3g {
  width: 90%;
  max-width: 32em;
  text-align: center;
}

.FailedPlan_btnContainer__34k9l {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  grid-gap: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.FailedPlan_btn_1__1UiYb,
.FailedPlan_btn_2__3rFu_ {
  transition: all 300ms;
  width: 10em;
  text-align: center;
  padding: 0.75em;
  font-size: 1rem;
  border-radius: 0.7em;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 300ms;
}

.FailedPlan_btn_1__1UiYb {
  color: var(--default);
  background: var(--main-color);
}

.FailedPlan_btn_2__3rFu_ {
  color: var(--main-color);
  border-color: var(--main-color);
  background: var(--default);
}

.FailedPlan_btn_1__1UiYb:hover {
  background: var(--main-hovered);
}

.FailedPlan_btn_2__3rFu_:hover {
  color: var(--main-hovered);
  border-color: var(--main-hovered);
}

@media screen and (max-width: 768px) {
  .FailedPlan_container__2rtlT {
    padding: 1em;
  }

  .FailedPlan_wrapper__1zrke {
    padding: 4em 2em;
  }

  .FailedPlan_failedIcon__2319U {
    width: 8em;
    height: 8em;
  }
}

@media screen and (max-width: 480px) {
  .FailedPlan_wrapper__1zrke {
    padding: 4em 1em;
  }

  .FailedPlan_description__2dY3g {
    font-size: 0.85rem;
  }

  .FailedPlan_btnContainer__34k9l {
    grid-gap: 1em;
    gap: 1em;
  }

  .FailedPlan_btn_1__1UiYb .FailedPlan_btn_2__3rFu_ {
    font-size: 0.85rem;
  }
}

.Pitch_container__3YTej {
  width: 100%;
  background: var(--default);
  display: flex;
  /* padding: 2rem 0; */
}
.Pitch_form__3FJm_ {
  height: calc(100vh - 3.7em);
  margin: auto;
}

.pitchLock_selectContainer__3OpIn {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.pitchLock_selectContainer__3OpIn.pitchLock_active__1aTqY {
  display: flex;
  flex-direction: column;
}

.pitchLock_container__903Aq {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 1em 1em;
  flex-direction: column;
  position: relative;
}

.pitchLock_card__1wfwC {
  width: 100%;
  background: #ffffff;
  border-radius: 43px;
  display: flex;
  align-items: center;
  padding: 2em;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  grid-gap: 1em;
  gap: 1em;
  -webkit-animation: pitchLock_show__2pPaP 300ms alternate;
          animation: pitchLock_show__2pPaP 300ms alternate;
}
@-webkit-keyframes pitchLock_show__2pPaP {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pitchLock_show__2pPaP {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.pitchLock_Icon__2Q6V4 {
  background: #dbf0ff;
  height: 6rem;
  width: 6rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.pitchLock_Icon__2Q6V4 svg path {
  fill: var(--main-color);
}
.pitchLock_title__2gP2a {
  font-weight: 700;
  font-size: 2rem;
  margin-top: 0.5em;
  color: var(--text-dark-400);
}

.pitchLock_text__WGvyq {
  font-weight: 400;
  font-size: 1.5rem;
  padding-left: 1rem;
  margin-bottom: 2em;
  color: var(--text-dark-400);
}

.pitchLock_btn__d-yeC {
  background: var(--main-color);
  border: none;
  border-radius: 67px;
  width: 100%;
  height: 2.5em;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 300ms;
  color: var(--default);
}
.pitchLock_btn__d-yeC:hover {
  background: var(--main-hovered);
}
.pitchLock_passInput__Fy04_ {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  border: 2px solid transparent;
  border-bottom: 2px solid #8c93a3;
  padding: 1rem;
  color: #000000;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}
.pitchLock_inputWrapper__2Pz_M,
.pitchLock_card__1wfwC form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pitchLock_card__1wfwC form {
  grid-gap: 1rem;
  gap: 1rem;
}
.pitchLock_inputWrapper__2Pz_M P {
  text-align: left;
  color: var(--main-color);
  margin-bottom: 0.6em;
}
.pitchLock_passInput__Fy04_::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}
.pitchLock_passInput__Fy04_:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}
.pitchLock_passInput__Fy04_::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}

.pitchLock_passInput__Fy04_:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

.pitchLock_passInput__Fy04_::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}
.pitchLock_passInput__Fy04_:focus {
  border: 2px solid #8c93a3;
  border-radius: 39.8818px;
}
@media (max-width: 480px) {
  .pitchLock_title__2gP2a {
    font-size: 1.5rem;
  }
  .pitchLock_text__WGvyq {
    font-size: 1rem;
  }
  .pitchLock_btn__d-yeC {
    font-size: 1rem;
  }
}

.collection-single_container__1HcZn {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2em 1em;
  padding-bottom: 6em;
  z-index: 10;
}

.collection-single_headingWrapper__A4jFU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.collection-single_heading__2IRjA {
  color: var(var(--text-dark-800));
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5em;
}

.collection-single_description__eVZBE {
  color: var(var(--text-dark-800));
  font-size: 1rem;
  text-align: center;
}

.collection-single_description__eVZBE > span {
  color: var(--main-color);
  position: relative;
  line-height: 2em;
}

.collection-single_description__eVZBE > span img {
  position: absolute;
  width: 100%;
  bottom: -0.25em;
  left: 0;
}

.collection-single_mintSwitch__3aEVU {
  margin-bottom: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7em;
  background: var(--card-light);
  padding: 0.25em;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.collection-single_mintSwitch__3aEVU > button {
  width: 10em;
  border-radius: 0.7em;
  padding: 0.5em 0;
  font-size: 1rem;
  background: var(--card-light);
  color: var(--text-heading);
  transition: all 300ms;
}

.collection-single_mintSwitch__3aEVU > button:hover {
  background: var(--default);
  color: var(--main-color);
  cursor: var(--cursor-hover);
}

.collection-single_mintSwitch__3aEVU > button.collection-single_active__2_92C {
  background: var(--main-color);
  color: var(--default);
}

.collection-single_mintSwitch__3aEVU > button.collection-single_active__2_92C:hover {
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-hover);
  margin: unset;
}

.collection-single_cardPlaceholder__rBv8R {
  width: 100%;
  height: 21em;
}

.collection-single_card__1XyWh {
  width: 100%;
  max-width: 36em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em;
  border-radius: 1em;
  border: 2px dashed var(--main-color);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: all 300ms;
}

.collection-single_card__1XyWh:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  cursor: var(--cursor-hover);
}

.collection-single_card__1XyWh.collection-single_collection__1UGYt {
  -webkit-animation: collection-single_slide-left__1TLAo 850ms forwards;
          animation: collection-single_slide-left__1TLAo 850ms forwards;
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}

.collection-single_card__1XyWh.collection-single__1of1__2rHFh {
  -webkit-animation: collection-single_slide-right__38hUh 850ms forwards;
          animation: collection-single_slide-right__38hUh 850ms forwards;
  -webkit-transform: translateX(-2%);
          transform: translateX(-2%);
}

@-webkit-keyframes collection-single_slide-right__38hUh {
  from {
    -webkit-transform: translateX(2%);
            transform: translateX(2%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes collection-single_slide-right__38hUh {
  from {
    -webkit-transform: translateX(2%);
            transform: translateX(2%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes collection-single_slide-left__1TLAo {
  from {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes collection-single_slide-left__1TLAo {
  from {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.collection-single_card__1XyWh > img {
  width: 6em;
}

.collection-single_imagePlaceholder__3tVIF {
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: var(--card-light);
}

.collection-single_title__s-SxH {
  color: var(--main-color);
  font-size: 1.2rem;
}

.collection-single_action__3pSHp {
  color: var(--text-dark-900);
  text-align: center;
}

.collection-single_supportedFiles__3RvnP {
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-sub-heading);
}

.collection-single_btn__Fj5l9 {
  width: 10em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  color: var(--main-color);
}

.collection-single_btn__Fj5l9:hover {
  color: var(--main-hovered);
}

.collection-single_goBack__2TWIR {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2em;
}

.collection-single_backIcon__3Z6iX {
  transition: all 300ms;
  width: 1.2em;
}

.collection-single_backIcon__3Z6iX:hover {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.collection-single_takePic__2xmub {
  display: flex;
  align-items: center;
  cursor: var(--cursor-pointer);
  border-radius: 100px;
  background: #0d99ff;
  padding: 0 2rem;
  transition: all 300ms;
}
.collection-single_takePic__2xmub:hover {
  background: var(--main-hovered);
}
.collection-single_takePic__2xmub p {
  font-size: 1rem;
  transition: color 0.2s ease;
  color: var(--default);
}
.collection-single_takePic__2xmub div {
  border-radius: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.4em;
  margin-right: 0.5em;
}
.collection-single_explanatoryText__25sGb {
  width: 100%;
  text-align: center;
}
.collection-single_explanatoryText__25sGb div {
  font-size: 0.9375rem;
  font-weight: 500;
}
.collection-single_explanatoryText__25sGb p {
  font-size: 0.8125rem;
  color: var(--text-sub-heading);
  line-height: 1.5rem;
}

@media screen and (max-width: 480px) {
  .collection-single_backIcon__3Z6iX {
    width: 0.85em;
  }
}

.mintTweet_container__20h90 {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2em 1em;
  padding-bottom: 6em;
  z-index: 10;
}

.mintTweet_header__1spAy {
  color: var(--text-dark-800);
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5em;
}

.mintTweet_caption__1q48v {
  color: var(--text-dark-800);
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2em;
}

.mintTweet_instruction__3676w {
  color: var(--text-dark-800);
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1em;
}

.mintTweet_input__383ZX {
  border: 1px solid var(--outline);
  padding: 0.5em;
  border-radius: 10px;
  width: 35em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  box-shadow: 5px 10px 18px var(--outline);
  padding-left: 1em;
}

.mintTweet_input__383ZX img:hover {
  cursor: pointer;
}

.mintTweet_input__383ZX input {
  outline: 0;
  border: 0;
  width: 30em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mintTweet_input__383ZX input:focus {
  border: none;
  cursor: text;
}

.mintTweet_tweeter__2lAUT {
  display: flex;
  align-items: center;
  grid-gap: 5em;
  gap: 5em;
}

.mintTweet_theme__1DOhz {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mintTweet_theme__1DOhz img {
  box-shadow: 5px 10px 18px var(--outline);
  margin-bottom: 1em;
  border-radius: 10px;
}

.mintTweet_theme__1DOhz img:hover {
  border: 1px solid var(--main-color);
  cursor: pointer;
}

.mintTweet_selected__2O399 {
  border: 2px solid var(--main-color);
  cursor: var(--cursor-default);
}

@media screen and (max-width: 550px) {
  .mintTweet_input__383ZX {
    width: 15em;
  }

  .mintTweet_tweeter__2lAUT {
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }
}

.mintIpfs_container__3wpit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}
.mintIpfs_header__n4WeM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mintIpfs_title__3p0QY {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 17px;
}
.mintIpfs_subTitle__XLp0L {
  font-size: 20px;
  margin-bottom: 69px;
  font-weight: 400;
}

.mintIpfs_inputWrapper__KaLka {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mintIpfs_inputWrapper__KaLka > h6 {
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: 400;
}
.mintIpfs_mintButton__1-0Aj {
  background-color: var(--main-color);
  color: var(--default);
  width: 125px;
  height: 45px;
  padding: 3px, 24px, 3px, 24px;
  border-radius: 6px;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 500;
}

.mintIpfs_input__3Gk0s {
  border: 1px solid var(--outline);
  padding: 0.5em;
  border-radius: 18px;
  width: 50%;
  height: 4.93em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
  box-shadow: 5px 2px 2px var(--outline);
  padding-left: 1em;
}

.mintIpfs_input__3Gk0s img:hover {
  cursor: pointer;
}

.mintIpfs_input__3Gk0s input {
  outline: 0;
  border: 0;
  width: 70%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mintIpfs_input__3Gk0s input:focus {
  border: none;
  cursor: text;
}

@media screen and (max-width: 1000px) {
  .mintIpfs_input__3Gk0s {
    width: 80%;
  }

  .mintIpfs_input__3Gk0s input {
    width: 75%;
  }
}

@media screen and (max-width: px) {
  .mintIpfs_container__3wpit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 450px) {
  .mintIpfs_input__3Gk0s {
    width: 90%;
  }
  .mintIpfs_title__3p0QY {
    font-size: 45px;
  }
  .mintIpfs_subTitle__XLp0L {
    font-size: 17px;
  }
  .mintIpfs_input__3Gk0s input {
    width: 80%;
  }
  .mintIpfs_mintButton__1-0Aj {
    width: 80px;
    height: 35px;
    font-size: 15px;
  }
}

.Camera_container__2ZwX4 {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2em 1em;
  padding-bottom: 6em;
  z-index: 10;
}

.Capture_container__3wyLs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 1280px;
}

.Capture_inActive__2KQuO {
  height: 0 !important;
  border: none;
}

.Capture_RecordBtnWrapper__2daK3 {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  transition: outline 300ms;
  position: relative;
}

.Capture_RecordBtnWrapper__2daK3:hover {
  outline: 1px solid #ff3236;
}
.Capture_recordBtn__11VV2 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ff3236;
  border-radius: 50%;
}

.Capture_coninue__3KGAO {
  color: var(--default);
}
.Capture_btn__2RMjv {
  padding: 1.2em 1em;
  border-radius: 50px;
  text-align: center;
  width: 10em;
  background-color: var(--main-color);
  font-size: 1rem;
  color: var(--default);
}
.Capture_btn__2RMjv:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Capture_none__NCbjV {
  display: none !important;
}

.Capture_typeSelcet__3adr9 {
  display: grid;
  max-width: 15rem;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}
.Capture_typeSelcet__3adr9 p {
  color: var(--default);
  font-weight: 500;
}
.Capture_typeSelcet__3adr9 > div.Capture_active__10wIY p {
  color: var(--main-color);
}
.Capture_typeSelcet__3adr9 > div {
  display: flex;
  justify-content: space-evenly;
  background: rgb(15 29 64 / 60%);
  padding: 10px 0;
  border-radius: 10px;
  cursor: var(--cursor-pointer);
}
.Capture_typeSelcet__3adr9 > div.Capture_active__10wIY {
  background: var(--default);
}
.Capture_typeSelcet__3adr9 > div:hover .Capture_rdaioBtn__lgULs > div {
  display: block;
}
.Capture_rdaioBtn__lgULs {
  width: 20px;
  height: 20px;
  border: 2px solid var(--default);
  border-radius: 50%;
  padding: 2px;
}
div.Capture_active__10wIY .Capture_rdaioBtn__lgULs {
  border: 2px solid var(--main-color);
}
.Capture_rdaioBtn__lgULs > div {
  display: none;
  background: var(--main-color);
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.Capture_active__10wIY .Capture_rdaioBtn__lgULs > div {
  display: block;
}

.Capture_testrecod__3OovF {
  position: absolute;
  font-size: 2rem;
  top: 10%;
  background: white;
}

@media screen and (max-width: 768px) {
  .Capture_uploadBtn__2NaO9 {
    display: flex;
  }
}

.webcamEnable_container__2TfnT {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
.webcamEnable_popupWrapper__-5T0R {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 1em 1em;
  flex-direction: column;
  position: relative;
}

.webcamEnable_card__GVlLT {
  width: 100%;
  max-width: 28em;
  overflow: auto;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2em;
  padding-bottom: 3em;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 1em;
  box-shadow: 0px 4.6387px 18.5548px rgba(207, 207, 207, 0.25);
  -webkit-animation: webcamEnable_show__be9vH 300ms alternate;
          animation: webcamEnable_show__be9vH 300ms alternate;
}

@-webkit-keyframes webcamEnable_show__be9vH {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes webcamEnable_show__be9vH {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.webcamEnable_heading__2YF6y {
  text-align: center;
  margin-bottom: 1em;
  color: black;
}
.webcamEnable_heading__2YF6y svg {
  margin: 1em 0;
}
.webcamEnable_heading__2YF6y h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.webcamEnable_heading__2YF6y p {
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: -0.339701px;
  margin-top: 1em;
}

.webcamEnable_iconContainer__UK_8G {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.webcamEnable_closeIcon__1zW2f {
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
  fill: var(--icon-color);
}

.webcamEnable_closeIcon__1zW2f:hover {
  background: var(--main-lightest);
}

.webcamEnable_wrapper__213nR {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.625rem;
  gap: 0.625rem;
}

.webcamEnable_wrapper__213nR div,
.webcamEnable_wrapper__213nR a {
  border-radius: 2.625em;
  font-size: 1em;
  width: 100%;
  padding: 0.675rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.799296px;
  cursor: var(--cursor-pointer);
  transition: background 0.2s ease-in-out;
  border: 1px solid;
}
.webcamEnable_wrapper__213nR div {
  background: var(--main-color);
  color: #ffffff;
  border-color: var(--main-color);
}
.webcamEnable_wrapper__213nR a {
  background: var(--default);
  color: #959cab;
  border-color: #cacdd5;
}
.webcamEnable_wrapper__213nR div:hover {
  background: var(--default);
  color: var(--main-color);
}
.webcamEnable_wrapper__213nR a:hover {
  background: #959cab;
  color: var(--default);
}

.webcamEnable_deactive__3WlYN {
  display: none;
}

@media screen and (max-width: 768px) {
  .webcamEnable_heading__2YF6y h4 {
    font-size: 0.85rem;
  }

  .webcamEnable_card__GVlLT {
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 1em;
  }
}

@media screen and (max-width: 480px) {
  .webcamEnable_heading__2YF6y h4 {
    font-size: 0.75rem;
  }
}

.Camera_cameraWrapper__32mfC {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: calc(100vh - 5rem);
  align-items: center;
}
.Camera_retake__1tkDF {
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: var(--cursor-pointer);
  font-weight: 500;
  font-size: 1.25rem;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 1;
  background: rgb(101 110 133 / 75%);
  padding: 8px;
  border-radius: 50%;
}
.Camera_retake__1tkDF svg {
  transition: all 0.2s ease;
}
.Camera_retake__1tkDF svg path {
  fill: white;
}
.Camera_retake__1tkDF:hover svg {
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px);
}

.Camera_cameraShot__3RZcC {
  position: relative;
  display: flex;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: calc(100vh - 11rem);
}
.Camera_cameraShot__3RZcC img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 5px 5px 32px rgb(0 0 0 / 25%);
}
.Camera_cameraShot__3RZcC video {
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 32px rgb(0 0 0 / 25%);
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.Camera_imgBtn__2en-- {
  display: flex;
  bottom: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}
.Camera_imgBtn__2en-- > div,
.Camera_imgBtn__2en-- > p {
  border-radius: 39px;
  font-size: 1em;
  max-width: 15rem;
  width: 100%;
  padding: 0.675rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.799296px;
  cursor: var(--cursor-pointer);
  transition: background 0.2s ease-in-out;
  border: 1px solid var(--main-color);
  box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.Camera_imgBtn__2en-- > div {
  background: var(--main-color);
  color: var(--default);
}
.Camera_imgBtn__2en-- > p {
  color: var(--main-color);
}
.Camera_imgBtn__2en-- div:hover {
  background: var(--default);
  color: var(--main-color);
}
.Camera_imgBtn__2en-- p:hover {
  background: var(--main-color);
  color: var(--default);
}
.Camera_videoContainer__1rW2- {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.Camera_videoWrapper__1sU2E {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
  height: calc(100vh - 5rem);
}
.Camera_videoWrapper__1sU2E div:first-child {
  width: 100%;
  height: 100%;
}

.Camera_videoOFF__1zlV6 {
  width: 100%;
  height: 100%;
  background: rgba(75, 75, 90, 0.74);
  object-fit: cover;
}

.Camera_enableContainer__1Je0p {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.Camera_closeBtn__20Jis,
.Camera_sideSwitch__1KxU5 {
  position: absolute;
  z-index: 2;
  top: 2rem;
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  border-radius: 50%;
  cursor: var(--cursor-pointer);
  padding: 0.6rem;
  background: #656e85;
  transition: box-shadow 200ms ease;
}
.Camera_closeBtn__20Jis {
  left: 2rem;
}
.Camera_sideSwitch__1KxU5 {
  right: 2rem;
}
.Camera_closeBtn__20Jis svg,
.Camera_sideSwitch__1KxU5 svg {
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
}
.Camera_closeBtn__20Jis svg path {
  fill: var(--default);
}
.Camera_closeBtn__20Jis:hover svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Camera_btnWrapper__5tRuj {
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0 0 0 / 60%);
  padding: 0rem 2rem;
  height: 6rem;
  width: 20em;
  border-radius: 16px;
}
.Camera_btnWrapper__5tRuj > div {
  cursor: var(--cursor-pointer);
  transition: background 0.2s ease-in-out;
}

.Camera_mainBtn__2U8qh {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Camera_mainBtn__2U8qh > p {
  color: var(--main-color);
  font-size: 0.75rem;
  padding: 0.1em 1em;
  font-weight: 500;
  border: 1px solid var(--main-color);
  border-radius: 100px;
  margin-top: 0.5rem;
}
.Camera_captureBtn__15S-D {
  width: 50px;
  height: 50px;
}
.Camera_captureBtn__15S-D circle {
  transition: all 0.2s ease-in-out;
}
.Camera_captureBtn__15S-D:hover circle {
  fill: var(--main-color);
}
.Camera_captureBtn__15S-D:hover circle:last-child {
  stroke: var(--default);
}
.Camera_switchBtn__hcNWn {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  margin-top: 1rem;
}
.Camera_switchBtn__hcNWn svg {
  height: 20px;
  width: 20px;
}
.Camera_switchBtn__hcNWn p {
  color: var(--default);
  font-size: 0.75rem;
  font-weight: 500;
}
.Camera_overlay__3zgi0 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(75, 75, 90, 0.79);
  z-index: 20;
  top: 0;
}
@media screen and (max-width: 480px) {
  .Camera_cameraWrapper__32mfC {
    background: white;
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1000;
    min-height: calc(100vh - 5rem);
  }
  .Camera_cameraShot__3RZcC {
    height: 100vh;
    width: 100%;
    position: fixed;
  }
  .Camera_cameraShot__3RZcC img,
  .Camera_cameraShot__3RZcC video {
    position: absolute;
    height: 100%;
    width: auto;
  }

  .Camera_videoContainer__1rW2- {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1000;
    background: white;
  }
  .Camera_videoWrapper__1sU2E {
    height: 100vh;
    background: white;
  }
  .Camera_videoWrapper__1sU2E video {
    height: 100%;
  }
  .Camera_btnWrapper__5tRuj {
    bottom: 0rem;
    width: 100%;
    height: 7rem;
  }
  .Camera_switch2imgs__1l3-s {
    display: block;
  }
  .Camera_imgBtn__2en-- {
    position: absolute;
    flex-direction: column;
  }
  .Camera_imgBtn__2en-- p,
  .Camera_imgBtn__2en-- div {
    max-width: 90%;
    padding: 1em;
  }
  .Camera_imgBtn__2en-- p {
    -webkit-backdrop-filter: blur(23px);
            backdrop-filter: blur(23px);
    background: rgba(255, 255, 255, 0.67);
    border-color: var(--default);
  }
}

/* ------------------------------------- Double Camera ------------------------------------- */
.Camera_frontCamera__1-xQa > div:first-child {
  position: absolute;
  width: 30%;
  height: 25%;
  top: 1rem;
  left: 1rem;
  overflow: hidden;
}
.Camera_cameraShot__3RZcC .Camera_faceImg__2TxQM {
  position: absolute;
  width: 30%;
  height: 25%;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  overflow: hidden;
}
.Camera_loader__2hBle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.Camera_loader__2hBle p {
  color: #3a9dec;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 1em;
}
.Camera_loader__2hBle div {
  margin: auto;
}
.Camera_combineImgs__1Js6o {
  display: flex;
  width: 100%;
  height: 100%;
}
.Camera_rearImg__1erj4 {
  width: 100%;
  height: 100%;
  position: static;
  position: initial;
}

.Hypnosis_container__2Yygd {
  position: relative;
}
.Hypnosis_container__2Yygd div {
  border: 3px solid transparent;
  border-top-color: var(--main-color);
  border-right-color: var(--main-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
          transform: translate(-50%, -50%) rotate(0);
}
.Hypnosis_animate__2oIl1 {
  -webkit-animation: Hypnosis_spin__17uA6 linear infinite;
          animation: Hypnosis_spin__17uA6 linear infinite;
}
@-webkit-keyframes Hypnosis_spin__17uA6 {
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes Hypnosis_spin__17uA6 {
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
.Hypnosis_firstPartAniamte__eoFSs {
  animation: Hypnosis_spin__17uA6 linear reverse infinite;
}
.Hypnosis_secondPartAniamte__31ho4 {
  -webkit-animation: Hypnosis_spin__17uA6 linear infinite;
          animation: Hypnosis_spin__17uA6 linear infinite;
}

.Tooltip_container__2UtrF {
  width: 100%;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Tooltip_container__2UtrF svg {
  width: 1em;
  height: 1em;
  border: 1px solid var(--main-color);
  border-radius: 50%;
  cursor: var(--cursor-pointer);
}

.Tooltip_container__2UtrF::before {
  content: "";
  position: absolute;
  top: -0.5em;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  border-top: 0.6em solid var(--main-color);
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.Tooltip_card__1DdWm {
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5em;
  padding-bottom: 1em;
  text-align: center;
  position: absolute;
  top: -1.25em;
  font-size: 0.75rem;
  background: var(--main-color);
  color: var(--default);
  -webkit-transform: translateX(0) translateY(-100%);
          transform: translateX(0) translateY(-100%);
  z-index: 10000;
  border-radius: 9px;
  max-width: 10em;
}
@media screen and (max-width: 480px) {
  .Tooltip_card__1DdWm {
    top: -2em;
  }
  .Tooltip_container__2UtrF::before {
    top: -1em;
  }
}

.StickType_container__3HpeK {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
.StickType_popupWrapper__2aCsa {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 1em 1em;
  flex-direction: column;
  position: relative;
}

.StickType_card__1J-OZ {
  margin: auto;
  width: 100%;
  max-width: 1088px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 4.875rem;
  padding-bottom: 7rem;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 1em;
  box-shadow: 0px 4.6387px 18.5548px rgba(207, 207, 207, 0.25);
  -webkit-animation: StickType_show__2fWaW 300ms alternate;
          animation: StickType_show__2fWaW 300ms alternate;
  background: var(--card-lighter);
}

@-webkit-keyframes StickType_show__2fWaW {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes StickType_show__2fWaW {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.StickType_heading__1z3if {
  text-align: center;
  margin-bottom: 1em;
  color: black;
}
.StickType_heading__1z3if svg {
  margin: 1em 0;
}
.StickType_heading__1z3if h3 {
  font-weight: 700;
  font-size: 48px;
  color: #0f1d40;
  margin-bottom: 0.5em;
}

.StickType_heading__1z3if p {
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: -0.339701px;
  margin-top: 1em;
}

.StickType_iconContainer__7dnhg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.StickType_closeIcon__vh7Qg {
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
  fill: var(--icon-color);
}

.StickType_closeIcon__vh7Qg:hover {
  background: var(--main-lightest);
}

.StickType_wrapper__3a9VX {
  margin-top: 3rem;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.StickType_deactive__1CUrM {
  display: none;
}
.StickType_typeCard__1TRAz {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  border: 1px solid;
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.05);
  border-radius: 20px;
  background: var(--default);
  border: 1px solid #e5ecfb;
  min-height: 243px;
  cursor: pointer;
}
.StickType_typeCard__1TRAz:hover {
  border-color: var(--main-color);
}
.StickType_typeCard__1TRAz:hover .StickType_content__1jIqk {
  background: var(--main-color);
}
.StickType_typeCard__1TRAz:hover .StickType_title__y18Nh,
.StickType_typeCard__1TRAz:hover .StickType_cardDesc__3D3aH {
  color: var(--default);
}
.StickType_typeCard__1TRAz .StickType_icon__2Gshr svg {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.StickType_typeCard__1TRAz:hover .StickType_icon__2Gshr svg {
  filter: none; /* IE6-9 */
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(0); /* Microsoft Edge and Firefox 35+ */
}
.StickType_typeCard__1TRAz:hover .StickType_typeIcon__3Oi1G svg path {
  fill: var(--main-color);
}
.StickType_icon__2Gshr {
  height: 100%;
  display: flex;
  align-items: center;
}
.StickType_content__1jIqk {
  display: flex;
  align-items: center;
  background: #f3f6fc;
  border-radius: 11px;
  padding: 10px 14px;
  grid-gap: 4px;
  gap: 4px;
}
.StickType_typeIcon__3Oi1G {
  background: var(--default);
  border-radius: 10px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.StickType_typeIcon__3Oi1G svg path {
  fill: var(--text-light);
}
.StickType_text__tRLu4 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 52px);
}
.StickType_title__y18Nh {
  font-size: 14px;
  color: var(--text-heading);
}
.StickType_cardDesc__3D3aH {
  font-size: 10px;
  color: #959ca3;
}
@media screen and (max-width: 768px) {
  .StickType_wrapper__3a9VX {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .StickType_heading__1z3if h3 {
    font-size: 2rem;
  }
  .StickType_heading__1z3if h4 {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 600px) {
  .StickType_card__1J-OZ {
    padding: 4.5rem 1rem 5rem;
  }
  .StickType_wrapper__3a9VX {
    margin-top: 2rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .StickType_heading__1z3if h3 {
    font-size: 1.5rem;
  }
  .StickType_heading__1z3if h4 {
    font-size: 0.75rem;
  }
  .StickType_content__1jIqk {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .StickType_heading__1z3if h4 {
    font-size: 0.75rem;
  }
}

.Marketplace_container__3UqZz {
  width: 100%;
  padding-bottom: 6em;
  background: var(--default);
}

.Marketplace_header__349iX {
  height: 28vw;
  margin-bottom: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Marketplace_header__349iX span {
  white-space: nowrap;
}

.Marketplace_wrapper__3FNB0 {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 1em;
}

@media screen and (max-width: 540px) {
  .Marketplace_header__349iX {
    font-size: 2rem;
  }
}

.Banner_container__zplHP {
  width: 100%;
  padding: 0 2em;
  padding-top: 2em;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
}

.Banner_wrapper__Lh-JH {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.Banner_heading__3Z9D8 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 3em;
}

.Banner_title__C2wDB {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.2em;
}

.Banner_searchContainer__1F-3j {
  width: 100%;
  max-width: 56em;
}

.Banner_category__1m6ag {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  cursor: pointer;
}

.Banner_category__1m6ag span {
  color: #939599;
  transition: color 300ms;
}

.Banner_category__1m6ag span:hover {
  color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .Banner_title__C2wDB {
    font-size: 4rem;
  }

  .Banner_search__9cFoZ {
    max-width: 42em;
  }
}

@media screen and (max-width: 900px) {
  .Banner_title__C2wDB {
    font-size: 3rem;
  }

  .Banner_search__9cFoZ {
    max-width: 36em;
  }
}

@media screen and (max-width: 900px) {
  .Banner_title__C2wDB {
    font-size: 2.5rem;
  }

  .Banner_search__9cFoZ {
    max-width: 24em;
  }
}

@media screen and (max-width: 540px) {
  .Banner_container__zplHP {
    padding: 0 1em;
    padding-top: 2em;
    margin-bottom: 3em;
  }

  .Banner_heading__3Z9D8 {
    margin-bottom: 2em;
  }

  .Banner_title__C2wDB {
    font-size: 1.5rem;
  }

  .Banner_search__9cFoZ {
    max-width: 16em;
  }

  .Banner_search__9cFoZ .Banner_placeholder__epFDK {
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Banner_category__1m6ag span {
    font-size: 0.8rem;
  }
}

.GenadropCarouselCard_container__3olUr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GenadropCarouselCard_wrapper__1rNns {
  width: auto;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* user-select: none; */
  /* pointer-events: none; */
}

.GenadropCarouselCard_cardContainer__2LkKU {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: -webkit-transform 650ms !important;
  transition: transform 650ms !important;
  transition: transform 650ms, -webkit-transform 650ms !important;
  padding: 1em 0;
}

.GenadropCarouselCard_ctrlBtn_left__1sjiP,
.GenadropCarouselCard_ctrlBtn_right___B0zx {
  position: absolute;
  padding: 0.5em;
  z-index: 1;
  background: rgb(255, 255, 255, 0.75);
  cursor: var(--cursor-pointer);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-light);
  transition: all 300ms;
}

.GenadropCarouselCard_ctrlBtn_left__1sjiP:hover,
.GenadropCarouselCard_ctrlBtn_right___B0zx:hover {
  background: rgb(255, 255, 255, 1);
  box-shadow: var(--shadow-dark);
}

.GenadropCarouselCard_ctrlBtn_left__1sjiP {
  left: 1em;
  display: none;
}

.GenadropCarouselCard_ctrlBtn_left__1sjiP.GenadropCarouselCard_active__3Uajp {
  display: flex;
}

.GenadropCarouselCard_ctrlBtn_right___B0zx {
  right: 1em;
}

.GenadropCarouselCard_ctrlBtn_right___B0zx.GenadropCarouselCard_active__3Uajp {
  display: flex;
}

@media screen and (max-width: 480px) {
  .GenadropCarouselCard_cardContainer__2LkKU {
    padding: 1em;
  }
}

.Chains_container__3v-XD {
  width: 100%;
  padding: 0 1em;
}

.Chains_wrapper__3BW4X {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.Chains_card__oymrF {
  width: 10em;
  height: 12em;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  border: 1px solid transparent;
  position: relative;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.Chains_card__oymrF img {
  width: 3em;
}

.Chains_card__oymrF:hover {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
}

.Chains_card__oymrF.Chains_inActive__1VC56:hover .Chains_soon__2DMzR {
  font-size: 1rem;
  -webkit-transform: translateY(-3.25em);
          transform: translateY(-3.25em);
}

.Chains_soon__2DMzR {
  font-size: 0.75rem;
  position: absolute;
  bottom: 1em;
  transition: all 300ms;
}

@media screen and (max-width: 480px) {
  .Chains_container__3v-XD {
    padding: 0;
  }
}

.AllNfts_container__1_GaI {
  width: 100%;
  padding: 0 2em;
  margin-top: 20px;
}

.AllNfts_notFound__1kfqV {
  grid-column: 1 / span 4;
}

.AllNfts_wrapper__2cDuv {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.AllNfts_types__2YsIW {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 3em;
  gap: 3em;
  margin-bottom: 3em;
}

.AllNfts_type__NLCIP {
  padding: 0.1em 1.5em;
  font-size: 1.8rem;
  font-weight: 600;
  background: #f7f9fd;
  border-bottom: 4px solid transparent;
  border-radius: 0.4em 0.4em 0 0;
  white-space: nowrap;
  cursor: pointer;
  color: #0f1d40;
}

.AllNfts_type__NLCIP:hover {
  color: var(--text-sub-heading);
}

.AllNfts_type__NLCIP.AllNfts_active__3ApaZ {
  border-bottom: 4px solid var(--main-color);
  color: var(--main-color);
}

.AllNfts_filter__EMVZf {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3em;
  grid-gap: 1em;
  gap: 1em;
  /* overflow: hidden; */
}

.AllNfts_categories__1bA11 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  overflow: auto;
  max-width: 900px;
}

.AllNfts_category__-i4uW {
  flex: 0 0 auto;
  padding: 0.3em 1em;
  font-size: 1.2rem;
  border-radius: 0.7em;
  font-weight: 400;
  border: 1.43503px solid rgba(28, 27, 28, 0.1);
  box-shadow: 0px 1.43503px 2.87007px rgba(28, 27, 28, 0.05);
  border-radius: 0.7em;
  cursor: pointer;
  color: rgba(28, 27, 28, 0.6);
  background: var(--default);
}

.AllNfts_category__-i4uW.AllNfts_active__3ApaZ {
  color: var(--default);
  background: var(--main-color);
}

.AllNfts_category__-i4uW.AllNfts_disable__222ko {
  background: var(--outline);
}

.AllNfts_nfts__3-LvC {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 3em;
}

.AllNfts_btnContainer__stwe- {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AllNfts_btn__3oZzy {
  display: inline-block;
  margin: 0 auto;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  transition: all 300ms;
}

.AllNfts_skeleton__1jatI {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  gap: 1em;
}

.AllNfts_btn__3oZzy:hover {
  color: var(--default);
  background: var(--main-color);
}

@media screen and (max-width: 1440px) {
  .AllNfts_nfts__3-LvC {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .AllNfts_nfts__3-LvC {
    grid-template-columns: repeat(2, 1fr);
  }
  .AllNfts_skeleton__1jatI {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .AllNfts_types__2YsIW {
    grid-gap: 2em;
    gap: 2em;
  }

  .AllNfts_type__NLCIP {
    padding: 0.2em 1em;
    font-size: 1.2rem;
  }
  .AllNfts_skeleton__1jatI {
    grid-template-columns: repeat(2, 1fr);
  }

  .AllNfts_category__-i4uW {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .AllNfts_nfts__3-LvC {
    grid-template-columns: repeat(1, 1fr);
  }
  .AllNfts_skeleton__1jatI {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .AllNfts_container__1_GaI {
    padding: 0 1em;
  }

  .AllNfts_skeleton__1jatI {
    grid-template-columns: repeat(1, 1fr);
  }

  .AllNfts_filter__EMVZf {
    flex-wrap: wrap;
    align-items: center;
  }

  .AllNfts_categories__1bA11 {
    width: 100%;
  }

  .AllNfts_types__2YsIW {
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-bottom: 1.6em;
    /* justify-content: center; */
  }

  .AllNfts_type__NLCIP {
    padding: 0.2em 1em;
    font-size: 0.8rem;
  }

  .AllNfts_category__-i4uW {
    font-size: 0.8rem;
  }
}

.footer_container__a7Ksf {
  width: 100%;
}

.footer_wrapper__2dxfi {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_top__3eSbq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--main-hovered);
  padding: 2em;
}

.footer_topLeft__2cGAN {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.footer_socialIcons__RQ_En {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.footer_socialIcons__RQ_En img {
  width: 1.6em;
  transition: all 300ms;
}

.footer_socialIcons__RQ_En .footer_icon__1a_o1 {
  padding: 0.5em;
  transition: all 300ms;
  border-radius: 0.25em;
  background: var(--main-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  height: 3rem;
  width: 3rem;
}

.footer_icon__1a_o1 img {
  width: 2rem;
}

.footer_socialIcons__RQ_En .footer_icon__1a_o1:hover {
  cursor: var(--cursor-pointer);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: var(--main-color);
}

.footer_topRight__TzcrX {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 4em;
  gap: 4em;
}

.footer_links__3GJLC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.footer_links__3GJLC .footer_title__pkoUs {
  font-weight: bold;
  color: var(--default);
}

.footer_links__3GJLC a {
  color: var(--default);
}

.footer_links__3GJLC a:hover {
  cursor: var(--cursor-pointer);
  text-decoration: underline;
}

.footer_bottom__1F41g {
  width: 100%;
  background: var(--main-hovered);
  padding: 1em;
  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
}

.footer_bottom__1F41g * {
  color: var(--default) !important;
}

.footer_termsAndPolicy__1d8cz {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.85rem;
  margin-top: 0.5em;
}

.footer_orgs__3OMF8 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.footer_orgs__3OMF8 a img {
  max-height: 22px;
}

.footer_orgs__3OMF8 > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_build__3KiP8 {
  font-size: 0.85rem;
  margin-right: 4em;
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .footer_build__3KiP8 {
    margin-right: 0;
  }

  .footer_topRight__TzcrX {
    grid-gap: 2em;
    gap: 2em;
  }
}

@media screen and (max-width: 900px) {
  .footer_wrapper__2dxfi {
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    align-items: flex-start;
  }

  .footer_bottom__1F41g {
    padding: 2em 1em;
  }

  .footer_topLeft__2cGAN {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .footer_topLeft__2cGAN img {
    width: 4em;
  }

  .footer_socialIcons__RQ_En img {
    width: 1.4em;
  }
}
@media screen and (max-width: 460px) {
  .footer_socialIcons__RQ_En {
    margin: auto;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .footer_socialIcons__RQ_En .footer_icon__1a_o1 {
    height: 2.5rem;
    width: 2.5rem;
  }

  .footer_build__3KiP8 {
    white-space: unset;
  }
}

.wallet_container__2OiQ7 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 1em;
  gap: 1em;
}

.wallet_popupContainer__3ztba {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.wallet_popupContainer__3ztba.wallet_active__15-5I {
  display: block;
}

.wallet_connect__3wNYc {
  width: 100%;
  max-width: 12em;
  padding: 0.5em 1.5em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  color: var(--default);
  background: var(--main-color);
  text-align: center;
  border: 1px solid var(--main-color);
  transition: background 300ms;
}

.wallet_connect__3wNYc:hover {
  background: var(--main-hovered);
}

.wallet_connectedContainer__ZP_kH {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.wallet_connected__1KbQ0 {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  cursor: var(--cursor-pointer);
  background: var(--default);
  transition: all 150ms;
  padding: 0.25em 0.25em;
  border-radius: 0.7em;
  padding: 0.25em 0.5em;
  background: var(--main-color);
}

.wallet_connected__1KbQ0:hover {
  color: var(--main-hovered);
}

.wallet_user__3BX1K {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--cursor-pointer);
  font-size: 2rem;
}

.wallet_user__3BX1K img {
  width: 1em;
  height: 1em;
}

.wallet_chain__1UUnO {
  height: 24px;
  width: 24px;
  background: var(--default);
  border-radius: 50%;
  display: inline-block;
}

.wallet_address__3ZxIc {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.25em;
  gap: 0.25em;
  padding: 0.25em 0;
  position: relative;
}

.wallet_address__3ZxIc > span {
  color: var(--default) !important;
}

.wallet_dropdownIconContainer__2IJK7 {
  border-left: 1px solid var(--outline);
  padding: 0 0.25em;
}

.wallet_dropdownIcon__2oba7 {
  fill: var(--default);
}

.wallet_networkContainer__1E16x {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.wallet_network__2SnSf {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-gap: 0.25em;
  gap: 0.25em;
  cursor: pointer;
}

.wallet_activeNetwork__EW1BB {
  font-size: 0.75rem;
}

.wallet_chevronIcon___HN73 {
  width: 0.75em;
  height: 0.75em;
  fill: var(--main-color);
}

.wallet_networkDropdownContainer__1Y_AZ {
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  top: 0.75em;
  right: 0;
  visibility: hidden;
  cursor: pointer;
}

.wallet_networkContainer__1E16x:hover .wallet_networkDropdownContainer__1Y_AZ {
  -webkit-animation: wallet_drop__GMBec 300ms forwards;
          animation: wallet_drop__GMBec 300ms forwards;
  visibility: visible;
}

.wallet_networkDropdown__2kZcc {
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1.75em;
  background: var(--default);
  border-radius: 0.25em;
  padding: 0.5em 1em;
  text-align: center;
}

.wallet_networkDropdown__2kZcc:hover {
  background: var(--card-light);
}

.wallet_network__2SnSf:hover .wallet_networkDropdown__2kZcc {
  visibility: visible;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wallet_network__2SnSf .wallet_dot__3FL1r {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f1b56e;
}

.wallet_network__2SnSf .wallet_dot__3FL1r.wallet_mainnet__2J4v5 {
  background: #6be26b;
}

.wallet_dropdownContainer__23JKS {
  width: -webkit-max-content;
  width: max-content;
  top: 2em;
  position: absolute;
  visibility: hidden;
}

.wallet_connectedContainer__ZP_kH:hover .wallet_dropdownContainer__23JKS {
  -webkit-animation: wallet_drop__GMBec 300ms forwards;
          animation: wallet_drop__GMBec 300ms forwards;
  visibility: visible;
}

@-webkit-keyframes wallet_drop__GMBec {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes wallet_drop__GMBec {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.wallet_dropdown__1SEnb {
  width: 16em;
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1em;
  background: var(--default);
  border-radius: 0.5em;
}

.wallet_dropdown__1SEnb.wallet_active__15-5I {
  visibility: visible;
  opacity: 1;
}

.wallet_option__4Ef_4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  cursor: pointer;
}

.wallet_option__4Ef_4:hover {
  background: var(--card-light);
}

.wallet_option__4Ef_4 img {
  width: 1.2em;
}

@media screen and (max-width: 1000px) {
  .wallet_connect__3wNYc {
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .wallet_container__2OiQ7 {
    justify-content: space-between;
  }
}

.walletPopup_container__s66X- {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  padding: 0 1em;
}

.walletPopup_card__1a4mG {
  width: 100%;
  max-width: 28em;
  overflow: auto;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2em;
  padding-bottom: 3em;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 1em;
  -webkit-animation: walletPopup_show__3_dYp 300ms alternate;
          animation: walletPopup_show__3_dYp 300ms alternate;
  margin: 6em auto 4em auto;
}

@-webkit-keyframes walletPopup_show__3_dYp {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes walletPopup_show__3_dYp {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.walletPopup_heading__2xsja {
  text-align: center;
  margin-bottom: 1em;
}

.walletPopup_heading__2xsja h3 {
  margin-bottom: 0.5em;
}

.walletPopup_description__2o1Bl {
  font-size: 0.85rem;
}

.walletPopup_iconContainer__368dW {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.walletPopup_closeIcon__5-0pT {
  padding: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
  cursor: var(--cursor-pointer);
  fill: var(--icon-color);
}

.walletPopup_closeIcon__5-0pT:hover {
  background: var(--outline-dark);
  fill: var(--default);
}

.walletPopup_wrapper__36-Jf {
  width: 100%;
  position: relative;
}

.walletPopup_chains__3BDxW {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.walletPopup_chains__3BDxW.walletPopup_active__s5sQG {
  display: none;
}

.walletPopup_chain__2qFRm {
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 0;
  padding-left: 1em;
  border-radius: 1em;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.walletPopup_chain__2qFRm.walletPopup_comingSoon__2xm13 {
  background: var(--card-trait);
  cursor: var(--cursor-default);
}

.walletPopup_chain__2qFRm.walletPopup_comingSoon__2xm13 h4 {
  color: var(--text-placeholder);
}

.walletPopup_chain__2qFRm.walletPopup_comingSoon__2xm13 p {
  color: var(--text-placeholder);
}

.walletPopup_chain__2qFRm.walletPopup_comingSoon__2xm13:hover {
  background: var(--outline);
  color: inherit;
}

.walletPopup_chain__2qFRm:hover {
  background: var(--main-hovered);
  border-color: transparent;
}

.walletPopup_chain__2qFRm:hover h4 {
  color: var(--default);
}

.walletPopup_chain__2qFRm:hover p {
  color: var(--default);
}

.walletPopup_chain__2qFRm img {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.walletPopup_chain__2qFRm h4 {
  font-size: 1rem;
  width: 100%;
  text-align: left;
}

.walletPopup_chain__2qFRm span {
  font-size: 0.56rem;
  font-weight: 500;
  color: var(--default);
  background: #ebba4f;
  padding: 0.25em 0.5em;
}

.walletPopup_chain__2qFRm p {
  font-size: 0.85rem;
  width: 100%;
  text-align: left;
}

.walletPopup_viewBtnContainer__2I2iN {
  margin-top: 0.5em;
}

.walletPopup_viewBtn__yekGW {
  display: inline-block;
  cursor: var(--cursor-pointer);
  border-radius: 1em;
  padding: 0.15em 1em;
  transition: all 300ms;
  color: var(--main-color);
}

.walletPopup_viewBtn__yekGW:hover {
  text-decoration: underline;
}

.walletPopup_connectionMethods__2cx_f {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
}

.walletPopup_connectionMethods__2cx_f.walletPopup_active__s5sQG {
  display: flex;
}

.walletPopup_connectionMethod__2i-dB {
  width: 100%;
  padding: 1em 0;
  border: 1px solid transparent;
  box-shadow: var(--shadow-light);
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-radius: 1em;
  text-align: center;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.walletPopup_connectionMethod__2i-dB:hover {
  box-shadow: var(--shadow-dark);
  border: 1px solid var(--outline);
}

.walletPopup_connectionMethod__2i-dB.walletPopup_metamask__T6TPb {
  display: none;
}
.walletPopup_connectionMethod__2i-dB.walletPopup_metamask__T6TPb.walletPopup_active__s5sQG {
  display: flex;
}

.walletPopup_connectionMethod__2i-dB img {
  width: 4em;
  height: 4em;
}

.walletPopup_connectionMethod__2i-dB p {
  font-size: 0.85rem;
}
.walletPopup_networkSwitch__3LMox {
  font-size: 0.75rem;
  background: var(--card-info);
  padding: 0.5em 1rem;
  margin-top: 0.5em;
  text-align: left;
}
.walletPopup_networkSwitch__3LMox a {
  color: var(--main-color);
}
.walletPopup_networkSwitch__3LMox a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .walletPopup_heading__2xsja h4 {
    font-size: 0.85rem;
  }

  .walletPopup_card__1a4mG {
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 1em;
  }

  .walletPopup_chain__2qFRm {
    padding: 1em 0;
    padding-left: 1em;
  }

  .walletPopup_chain__2qFRm img {
    width: 2em;
    height: 2em;
  }

  .walletPopup_connectionMethod__2i-dB img {
    width: 3em;
    height: 3em;
  }
}

@media screen and (max-width: 480px) {
  .walletPopup_heading__2xsja h4 {
    font-size: 0.75rem;
  }

  .walletPopup_chain__2qFRm {
    padding: 0.5em 0;
    padding-left: 1em;
  }

  .walletPopup_chain__2qFRm h4 {
    font-size: 0.75rem;
  }

  .walletPopup_chain__2qFRm p {
    font-size: 0.75rem;
  }

  .walletPopup_chain__2qFRm img {
    width: 1.5em;
    height: 1.5em;
  }

  .walletPopup_connectionMethod__2i-dB h3 {
    font-size: 1rem;
  }

  .walletPopup_connectionMethod__2i-dB p {
    font-size: 0.85rem;
  }

  .walletPopup_connectionMethod__2i-dB img {
    width: 2em;
    height: 2em;
  }
}

.Navbar_container__1ESpZ {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--default);
  box-shadow: var(--shadow-light);
  z-index: 1;
  padding: 0.5em 2em;
  grid-gap: 8em;
  gap: 8em;
}

.Navbar_logoContainer__1NhKc {
  position: relative;
  cursor: var(--cursor-pointer);
  overflow: hidden;
}

.Navbar_drop__38d-5 {
  width: 1em;
  position: absolute;
  bottom: 0.5em;
  right: 0.75em;
  visibility: hidden;
  opacity: 0;
}

.Navbar_logoContainer__1NhKc:hover .Navbar_drop__38d-5 {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-animation: Navbar_drop__38d-5 650ms;
          animation: Navbar_drop__38d-5 650ms;
}

.Navbar_logoContainer__1NhKc:hover .Navbar_logoDesktop__FTlj7,
.Navbar_logoContainer__1NhKc:hover .Navbar_logoMobile__2im8- {
  -webkit-transform: translate(1px, 1px);
          transform: translate(1px, 1px);
}

.Navbar_logoMobile__2im8-,
.Navbar_logoDesktop__FTlj7 {
  transition: all 650ms;
}

@-webkit-keyframes Navbar_drop__38d-5 {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 0;
    visibility: hidden;
  }

  to {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes Navbar_drop__38d-5 {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 0;
    visibility: hidden;
  }

  to {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    opacity: 1;
    visibility: visible;
  }
}

.Navbar_logoDesktop__FTlj7 {
  width: 4em;
}

.Navbar_logoMobile__2im8- {
  display: none;
}

.Navbar_searchAndNavWrapper__1t5pF {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  grid-gap: 6em;
  gap: 6em;
}

.Navbar_navContainer__Nd_xd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--default);
}

.Navbar_navList__1GFvw {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  grid-gap: 3em;
  gap: 3em;
  font-size: 1rem;
  text-transform: capitalize;
}

.Navbar_navItem__16nSi {
  font-weight: 500;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  transition: all 100ms;
}

.Navbar_navItem__16nSi li {
  padding: 0.25em 0;
  cursor: var(--cursor-pointer);
}

.Navbar_line__15YIg {
  width: 0%;
  background: var(--main-color);
  height: 2.5px;
  transition: all 300ms;
}

.Navbar_navItem__16nSi:hover .Navbar_line__15YIg {
  width: 60%;
}

.Navbar_navItem__16nSi.Navbar_active__3mbhk .Navbar_line__15YIg {
  width: 100%;
}

.Navbar_closeIcon__2vtf5,
.Navbar_iconOpen__1eYUE {
  display: none;
  cursor: var(--cursor-pointer);
}

.Navbar_closeIcon__2vtf5 {
  fill: var(--icon-color);
  width: 1.2em;
  height: 1.2em;
}

.Navbar_walletAuthContainer__1TSSf {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.Navbar_wallet__1RkTc {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Navbar_walletAuthContainer__1TSSf.Navbar_mobile__3kD0U {
  display: none;
}

@media screen and (max-width: 1440px) {
  .Navbar_container__1ESpZ {
    grid-gap: 4em;
    gap: 4em;
  }
}

@media screen and (max-width: 1200px) {
  .Navbar_searchAndNavWrapper__1t5pF {
    grid-gap: 4em;
    gap: 4em;
  }
}

@media screen and (max-width: 1000px) {
  .Navbar_container__1ESpZ {
    background: var(--default);
  }

  .Navbar_navContainer__Nd_xd.Navbar_active__3mbhk {
    border: 1px solid var(--outline);
    box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  }

  .Navbar_navList__1GFvw {
    margin-right: 0;
  }

  .Navbar_navItem__16nSi {
    padding: 0.5em;
  }

  .Navbar_logoDesktop__FTlj7 {
    display: none;
  }

  .Navbar_logoMobile__2im8- {
    display: block;
    width: 3em;
  }

  .Navbar_closeIcon__2vtf5,
  .Navbar_iconOpen__1eYUE {
    display: block;
  }

  .Navbar_searchAndNavWrapper__1t5pF {
    align-items: center;
  }

  .Navbar_navContainer__Nd_xd {
    width: 100%;
    position: absolute;
    justify-content: center;
    flex-direction: column-reverse;
    grid-gap: 3em;
    gap: 3em;
    right: 0;
    top: 3.4em;
    padding: 0 2em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 650ms;
  }

  .Navbar_navContainer__Nd_xd.Navbar_active__3mbhk {
    max-height: 22em;
    justify-content: center;
    align-items: center;
  }

  .Navbar_navList__1GFvw {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
  }

  .Navbar_navList__1GFvw > * {
    width: 100%;
  }

  .Navbar_navList__1GFvw li {
    font-weight: normal;
  }

  .Navbar_navItem__16nSi {
    width: 100%;
    text-align: center;
    font-weight: 700;
    border: 1px solid var(--outline);
    border-radius: 0.7em;
  }

  .Navbar_navItem__16nSi:hover {
    cursor: var(--cursor-hover);
    color: var(--default);
    background: var(--main-color);
  }

  .Navbar_navItem__16nSi:hover li {
    color: var(--default);
  }

  .Navbar_line__15YIg {
    display: none;
  }

  .Navbar_walletAuthContainer__1TSSf {
    display: none;
    width: 100%;
    margin-top: 3em;
  }

  .Navbar_walletAuthContainer__1TSSf.Navbar_mobile__3kD0U {
    display: flex;
  }

  .Navbar_wallet__1RkTc {
    width: 100%;
  }

  .Navbar_drop__38d-5 {
    bottom: 0;
    right: 0.5em;
  }
}

@media screen and (max-width: 480px) {
  .Navbar_navContainer__Nd_xd {
    max-width: 100%;
    right: 0;
    top: 2.9em;
  }

  .Navbar_searchAndNavWrapper__1t5pF {
    grid-gap: 1em;
    gap: 1em;
  }

  .Navbar_container__1ESpZ {
    grid-gap: 1em;
    gap: 1em;
  }

  .Navbar_logoContainer__1NhKc {
    margin-right: 0;
  }
}

.overlay_overlay__3unaZ {
  position: fixed;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  top: 0;
  left: 0;
  z-index: 5000;
  overflow: hidden;
}

.overlay_overlay__3unaZ.overlay_isLoading__1ogRY {
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
}

.overlay_wrapper__1dPmJ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5em 2em;
  border-radius: 1em;
  background: var(--default);
}

.overlay_wrapper__1dPmJ img {
  width: 4em;
  margin-bottom: 1em;
  -webkit-animation: overlay_bounce__VdHnZ 450ms infinite alternate;
          animation: overlay_bounce__VdHnZ 450ms infinite alternate;
}

@-webkit-keyframes overlay_bounce__VdHnZ {
  from {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes overlay_bounce__VdHnZ {
  from {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.overlay_loader__uJZCw {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.overlay_dotOne__20VP5,
.overlay_dotTwo__1fKz6,
.overlay_dotThree__BiYg5 {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--default);
  -webkit-animation: overlay_blink__1rmUa 450ms infinite alternate linear;
          animation: overlay_blink__1rmUa 450ms infinite alternate linear;
}

.overlay_dotOne__20VP5 {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.overlay_dotTwo__1fKz6 {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.overlay_dotThree__BiYg5 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}

@-webkit-keyframes overlay_blink__1rmUa {
  from {
    background: var(--main-light);
  }
  to {
    background: var(--main-color);
  }
}

@keyframes overlay_blink__1rmUa {
  from {
    background: var(--main-light);
  }
  to {
    background: var(--main-color);
  }
}

.Notification_container__2Ycj5 {
  width: 90%;
  max-width: 22em;
  position: fixed;
  right: 10px;
  z-index: 3000;
  background: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 650ms;
}

.Notification_icon__1Ehyz {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  white-space: nowrap;
}

.Notification_message__eciZi {
  flex: 1 1;
}

.Notification_notification__1C7Ma {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  -webkit-animation: Notification_slide-out__wDtxs 650ms forwards;
          animation: Notification_slide-out__wDtxs 650ms forwards;
  color: white;
  padding: 1em 1.5em;
  border-radius: 0.5em;
  position: relative;
}

.Notification_closeIcon__3kWY- {
  fill: white;
  width: 1.3em;
  height: 1.3em;
  padding: 0.25em;
  border-radius: 50%;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  cursor: var(--cursor-pointer);
}

.Notification_closeIcon__3kWY-:hover {
  background: rgba(0, 0, 0, 0.1);
}

.Notification_notification__1C7Ma.Notification_error__1RsiN {
  background: var(--error-100);
  border: 1px solid var(--error-500);
}

.Notification_notification__1C7Ma.Notification_error__1RsiN .Notification_message__eciZi {
  color: var(--error-600);
}

.Notification_notification__1C7Ma.Notification_error__1RsiN .Notification_closeIcon__3kWY- {
  fill: var(--error-600);
}

.Notification_notification__1C7Ma.Notification_warning__1nYLa {
  background: var(--warning-200);
  border: 1px solid var(--warning-500);
}

.Notification_notification__1C7Ma.Notification_warning__1nYLa .Notification_closeIcon__3kWY- {
  fill: var(--warning-700);
}

.Notification_notification__1C7Ma.Notification_warning__1nYLa .Notification_message__eciZi {
  color: var(--warning-700);
}

.Notification_notification__1C7Ma.Notification_success__2WaDl {
  background: var(--success-500);
  border: 1px solid var(--success-700);
}

.Notification_notification__1C7Ma.Notification_success__2WaDl .Notification_message__eciZi {
  color: var(--default);
}

.Notification_notification__1C7Ma.Notification_default__3aDbL {
  background: var(--card-layer);
}

.Notification_notification__1C7Ma.Notification_default__3aDbL .Notification_message__eciZi {
  color: var(--default);
}

.Notification_container__2Ycj5.Notification_active__3j5c4 {
  visibility: visible;
}

.Notification_container__2Ycj5.Notification_active__3j5c4 .Notification_notification__1C7Ma {
  -webkit-animation: Notification_slide-in__x-3sJ 450ms forwards;
          animation: Notification_slide-in__x-3sJ 450ms forwards;
}

@-webkit-keyframes Notification_slide-in__x-3sJ {
  from {
    -webkit-transform: translateX(110%);
            transform: translateX(110%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Notification_slide-in__x-3sJ {
  from {
    -webkit-transform: translateX(110%);
            transform: translateX(110%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes Notification_slide-out__wDtxs {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes Notification_slide-out__wDtxs {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.clipboard_container__1kJ__ {
  width: 20em;
  position: fixed;
  z-index: 2000;
  right: 10px;
  background: var(--card-bg);
  color: white;
  visibility: hidden;
  padding: 1em 1.5em;
  border-radius: 0.25em;
  overflow: hidden;
  transition: all 300ms;
  box-shadow: var(--shadow-light);
}

.clipboard_container__1kJ__.clipboard_active__20Ii3 {
  visibility: visible;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.clipboard_closeIcon__2TIOX {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 10px;
  margin: 0;
  cursor: var(--cursor-pointer);
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms;
  fill: var(--default);
}

.clipboard_closeIcon__2TIOX:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.clipboard_message__G8iX0 {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.25em;
  color: var(--default);
}

.clipboard_action__GNr3T {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clipboard_action__GNr3T img {
  -webkit-filter: invert(1);
          filter: invert(1);
  margin-left: 4px;
  width: 1em;
}

.clipboard_copy__1Q2gR {
  cursor: var(--cursor-pointer);
  font-size: 0.85rem;
  padding: 0.2em 1em;
  border-radius: 0.25em;
  background: var(--card-bg);
  color: white;
  outline: 2px solid transparent;
}

.clipboard_copy__1Q2gR:hover {
  background: var(--card-bg);
  outline: 2px solid var(--card-bg);
}

.Loader_container__3LFDG {
  width: auto;
  position: fixed;
  z-index: 2000;
  right: 10px;
  top: 4em;
  background: var(--default);
  color: var(--text-dark-900);
  visibility: hidden;
  opacity: 0;
  padding: 0.5em 1.5em;
  border-radius: 0.25em;
  transition: all 300ms;
  box-shadow: var(--shadow-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_container__3LFDG.Loader_active__2r-Nz {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.Loader_icon__2caYb {
  -webkit-animation: Loader_rotate__2CUi0 1000ms infinite;
          animation: Loader_rotate__2CUi0 1000ms infinite;
  width: 1em;
  margin-right: 0.5em;
}

.Loader_message__Eh5w8 {
  text-transform: lowercase;
}

@-webkit-keyframes Loader_rotate__2CUi0 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader_rotate__2CUi0 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.error_container__kqWNp {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
}

.something_wrong_container__2pl3t {
  width: 100%;
  font-size: 1rem;
  height: 80vh;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: center;
  padding-top: 4em;
}

.something_wrong_container__2pl3t h1 {
  font-weight: 700;
  font-size: 3rem;
  margin: 30px 0;
}

.something_wrong_text__3X3Cc {
  font-size: 28px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 40px;
}

.something_wrong_button-container__1SA_H {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.something_wrong_home__3SklV:hover {
  background-color: var(--main-color);
  color: white;
}

.something_wrong_home__3SklV {
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: var(--cursor-pointer);
  background: none;
  color: var(--main-color);
  font-size: 20px;
  border: 1px solid var(--main-color);
  transition: 0.4s;
  margin-right: 20px;
  font-weight: 600;
  border-radius: 0.7em;
}

.something_wrong_go-back__2xfes:hover {
  background: none;
  color: var(--main-color);
}

.something_wrong_go-back__2xfes {
  width: 200px;
  cursor: var(--cursor-pointer);
  height: 60px;
  background-color: var(--main-color);
  color: white;
  font-size: 20px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-color);
  font-weight: 600;
  border-radius: 0.7em;
}
.something_wrong_go-back__2xfes img {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}
.something_wrong_home__3SklV img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: 0.4s;
  margin-bottom: 3px;
  background: none;
}

@media screen and (max-width: 768px) {
  .something_wrong_container__2pl3t {
    padding-top: 2em;
    height: 700px;
  }
  .something_wrong_not-found__2VQ3O img {
    width: 90vw;
    height: 300px;
  }
  .something_wrong_not-found__2VQ3O h1 {
    font-size: 27px;
    margin: 10px 0;
  }
  .something_wrong_text__3X3Cc {
    font-size: 20px;
  }
  .something_wrong_go-back__2xfes {
    margin-right: 10px;
  }
  .something_wrong_go-back__2xfes,
  .something_wrong_home__3SklV {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }
}

.welcome_container__rumZ5 {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--default);
}

.welcome_gena__yaeki,
.welcome_drop__14dqR {
  font-size: 6rem;
  font-weight: bold;
  visibility: hidden;
  color: var(--main-color);
}

.welcome_gena__yaeki {
  -webkit-animation: welcome_enter-from-right__1WlbM 1s 200ms forwards;
          animation: welcome_enter-from-right__1WlbM 1s 200ms forwards;
}

.welcome_drop__14dqR {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: welcome_enter-from-left__3yZBe 1s 300ms forwards;
          animation: welcome_enter-from-left__3yZBe 1s 300ms forwards;
}

.welcome_imageContainer__3bMHA {
  width: 0;
  -webkit-animation: welcome_open__3X_tO 500ms 1s forwards;
          animation: welcome_open__3X_tO 500ms 1s forwards;
}

.welcome_image__2-Fof {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  width: 0.75em;
  visibility: hidden;
  opacity: 0;
  -webkit-animation: welcome_drop__14dqR 1650ms 1650ms forwards;
          animation: welcome_drop__14dqR 1650ms 1650ms forwards;
}

@-webkit-keyframes welcome_enter-from-right__1WlbM {
  from {
    -webkit-transform: translate(0%, 15px);
            transform: translate(0%, 15px);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate(-30%, 15px);
            transform: translate(-30%, 15px);
    visibility: visible;
  }
}

@keyframes welcome_enter-from-right__1WlbM {
  from {
    -webkit-transform: translate(0%, 15px);
            transform: translate(0%, 15px);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate(-30%, 15px);
            transform: translate(-30%, 15px);
    visibility: visible;
  }
}

@-webkit-keyframes welcome_enter-from-left__3yZBe {
  from {
    -webkit-transform: translate(0%, -15px);
            transform: translate(0%, -15px);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate(30%, -15px);
            transform: translate(30%, -15px);
    visibility: visible;
  }
}

@keyframes welcome_enter-from-left__3yZBe {
  from {
    -webkit-transform: translate(0%, -15px);
            transform: translate(0%, -15px);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate(30%, -15px);
            transform: translate(30%, -15px);
    visibility: visible;
  }
}

@-webkit-keyframes welcome_open__3X_tO {
  from {
    width: 0;
  }
  to {
    width: 0.75em;
  }
}

@keyframes welcome_open__3X_tO {
  from {
    width: 0;
  }
  to {
    width: 0.75em;
  }
}

@-webkit-keyframes welcome_drop__14dqR {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    visibility: hidden;
    opacity: 0;
  }
  60% {
    -webkit-transform: translateY(35px);
            transform: translateY(35px);
    visibility: visible;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    visibility: hidden;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    visibility: visible;
    opacity: 1;
  }
}

@keyframes welcome_drop__14dqR {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    visibility: hidden;
    opacity: 0;
  }
  60% {
    -webkit-transform: translateY(35px);
            transform: translateY(35px);
    visibility: visible;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    visibility: hidden;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 540px) {
  .welcome_gena__yaeki,
  .welcome_drop__14dqR {
    font-size: 4rem;
  }

  @-webkit-keyframes welcome_enter-from-right__1WlbM {
    from {
      -webkit-transform: translate(0%, 10px);
              transform: translate(0%, 10px);
      visibility: hidden;
    }
    to {
      -webkit-transform: translate(-30%, 10px);
              transform: translate(-30%, 10px);
      visibility: visible;
    }
  }

  @keyframes welcome_enter-from-right__1WlbM {
    from {
      -webkit-transform: translate(0%, 10px);
              transform: translate(0%, 10px);
      visibility: hidden;
    }
    to {
      -webkit-transform: translate(-30%, 10px);
              transform: translate(-30%, 10px);
      visibility: visible;
    }
  }

  @-webkit-keyframes welcome_enter-from-left__3yZBe {
    from {
      -webkit-transform: translate(0%, -10px);
              transform: translate(0%, -10px);
      visibility: hidden;
    }
    to {
      -webkit-transform: translate(30%, -10px);
              transform: translate(30%, -10px);
      visibility: visible;
    }
  }

  @keyframes welcome_enter-from-left__3yZBe {
    from {
      -webkit-transform: translate(0%, -10px);
              transform: translate(0%, -10px);
      visibility: hidden;
    }
    to {
      -webkit-transform: translate(30%, -10px);
              transform: translate(30%, -10px);
      visibility: visible;
    }
  }

  @-webkit-keyframes welcome_drop__14dqR {
    0% {
      -webkit-transform: translateY(-150%);
              transform: translateY(-150%);
      visibility: hidden;
      opacity: 0;
    }
    40%,
    45% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
      visibility: visible;
      opacity: 1;
    }
    70% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      visibility: hidden;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      visibility: visible;
      opacity: 1;
    }
  }

  @keyframes welcome_drop__14dqR {
    0% {
      -webkit-transform: translateY(-150%);
              transform: translateY(-150%);
      visibility: hidden;
      opacity: 0;
    }
    40%,
    45% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
      visibility: visible;
      opacity: 1;
    }
    70% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      visibility: hidden;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      visibility: visible;
      opacity: 1;
    }
  }
}

.Banner_container__eT32k {
  width: 100%;
  margin-bottom: 6em;
}

.Banner_heading__3dJie {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 3em;
  padding-top: 5em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 16em;
  background: linear-gradient(
    255.93deg,
    #eff3f9 -39.69%,
    #eff3f9 5.2%,
    rgba(205, 237, 255, 0.65) 49.41%,
    #ffffff 91.12%
  );
}

.Banner_features__341LJ {
  font-weight: 700;
  font-size: 8.1875rem;
  line-height: 1em;
  text-align: center;
  color: #2d3748;
  white-space: nowrap;
}

.Banner_description__119SP {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: #2d3748;
}

.Banner_more__SWrbl {
  text-align: center;
  font-size: 1.75rem;
  letter-spacing: 0.02em;
  color: #2d3748;
}

.Banner_btnContainer__IkyDY {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 2em;
  gap: 2em;
  margin-top: 2em;
}

.Banner_btn_1__1bM0I,
.Banner_btn_2__1mIfq {
  padding: 0.75em 4em;
  border-radius: 0.7em;
  color: var(--main-color);
  border: 1px solid transparent;
  transition: all 300ms;
  cursor: pointer;
}

.Banner_btn_1__1bM0I {
  background: var(--main-color);
  color: var(--default);
}

.Banner_btn_1__1bM0I:hover {
  background: var(--default);
  color: var(--main-color);
}

.Banner_btn_2__1mIfq {
  background: var(--default);
  color: var(--main-color);
}

.Banner_btn_2__1mIfq:hover {
  background: var(--main-color);
  color: var(--default);
}

.Banner_demo__3kdcO {
  width: 90%;
  max-width: 80%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  border-radius: 4em;
  margin-top: -14em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.Banner_demo__3kdcO video {
  width: 100%;
}

.Banner_playIcon__1yTd7 {
  cursor: pointer;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  width: 12em;
  position: absolute;
  z-index: 10em;
  display: none;
}

.Banner_demo__3kdcO.Banner_active__Kn1z5 .Banner_playIcon__1yTd7 {
  display: block;
}

.Banner_playIcon__1yTd7:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

@media screen and (max-width: 1200px) {
  .Banner_features__341LJ {
    font-size: 6.5rem;
  }

  .Banner_description__119SP {
    font-size: 1.5rem;
  }

  .Banner_playIcon__1yTd7 {
    width: 8em;
  }
}

@media screen and (max-width: 900px) {
  .Banner_heading__3dJie {
    padding-top: 4em;
    padding-bottom: 6em;
  }

  .Banner_demo__3kdcO {
    margin-top: -6em;
  }

  .Banner_playIcon__1yTd7 {
    width: 6em;
  }

  .Banner_features__341LJ {
    font-size: 5rem;
  }

  .Banner_description__119SP {
    font-size: 1.2rem;
  }

  .Banner_more__SWrbl {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .Banner_features__341LJ {
    font-size: 4rem;
  }

  .Banner_description__119SP {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 540px) {
  .Banner_container__eT32k {
    margin-bottom: 4em;
  }

  .Banner_features__341LJ {
    font-size: 2.75rem;
  }

  .Banner_description__119SP {
    font-size: 0.875rem;
  }

  .Banner_more__SWrbl {
    font-size: 0.75rem;
  }

  .Banner_demo__3kdcO {
    border-radius: 0.7em;
  }

  .Banner_playIcon__1yTd7 {
    width: 4em;
  }

  .Banner_btn_1__1bM0I,
  .Banner_btn_2__1mIfq {
    padding: 0.5em 2em;
  }
}
@media screen and (max-width: 350px) {
  .Banner_features__341LJ {
    font-size: 2rem;
  }
}

.Features_container__2bRCE {
  width: 100%;
  margin-bottom: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2em;
  overflow: hidden;
}

.Features_wrapper__299-4 {
  width: 100%;
  max-width: 1440px;
}

.Features_heading__2l8YC {
  width: 100%;
  max-width: 20em;
  text-align: center;
  margin-bottom: 0.5em;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1em;
  color: var(--text-heading);
}

.Features_heading__2l8YC .Features_accent__17Z-P {
  color: var(--main-color);
}

.Features_featureContainer__3hwGH {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 6em;
  gap: 6em;
  margin-bottom: 6em;
}

.Features_featureContainer__3hwGH:last-child {
  margin-bottom: 0;
}

.Features_featureContainer__3hwGH.Features_not__19z0U {
  flex-direction: row-reverse;
}

.Features_content__3fqkC {
  display: flex;
  width: 100%;
  max-width: 42em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  transition: -webkit-transform 1250ms;
  transition: transform 1250ms;
  transition: transform 1250ms, -webkit-transform 1250ms;
}

.Features_content__3fqkC > * {
  width: 100%;
  text-align: left;
}

.Features_fHeading__1r-mP {
  color: var(--text-sub-heading);
  font-size: 1.2rem;
}

.Features_fTitle__Gtyuo {
  font-weight: 600;
  font-size: 2rem;
  color: var(--text-heading);
}

.Features_fDescription__1s2DI {
  color: var(--text-sub-heading);
  font-size: 1.2rem;
}

.Features_fLink__3LA__ {
  width: -webkit-max-content;
  width: max-content;
  color: var(--main-color);
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  cursor: var(--cursor-pointer);
}

.Features_fLink__3LA__ div {
  color: var(--main-color);
}

.Features_fLink__3LA__ svg {
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  fill: var(--text-sub-heading);
  fill: var(--main-color);
}

.Features_fLink__3LA__:hover svg {
  -webkit-transform: translateX(1em);
          transform: translateX(1em);
}

.Features_image__2gMID {
  width: 100%;
  max-width: 36em;
  transition: -webkit-transform 1650ms;
  transition: transform 1650ms;
  transition: transform 1650ms, -webkit-transform 1650ms;
}

@media screen and (max-width: 1200px) {
  .Features_heading__2l8YC {
    font-size: 2.5rem;
  }

  .Features_fLink__3LA__,
  .Features_fDescription__1s2DI,
  .Features_fHeading__1r-mP {
    font-size: 1.2rem;
  }
  .Features_fTitle__Gtyuo {
    font-size: 1.5rem;
  }

  .Features_image__2gMID {
    max-width: 28em;
  }
}

@media screen and (max-width: 900px) {
  .Features_heading__2l8YC {
    font-size: 2rem;
  }

  .Features_image__2gMID {
    max-width: 36em;
  }

  .Features_content__3fqkC {
    max-width: 100%;
  }

  .Features_featureContainer__3hwGH.Features_not__19z0U,
  .Features_featureContainer__3hwGH {
    flex-direction: column-reverse;
    grid-gap: 2em;
    gap: 2em;
  }
}

@media screen and (max-width: 540px) {
  .Features_container__2bRCE {
    padding: 0 1em;
    margin-bottom: 4em;
  }

  .Features_content__3fqkC {
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
  }

  .Features_heading__2l8YC {
    font-size: 1.5rem;
  }

  .Features_fLink__3LA__,
  .Features_fDescription__1s2DI,
  .Features_fHeading__1r-mP {
    font-size: 1rem;
  }
  .Features_fTitle__Gtyuo {
    font-size: 1.2rem;
  }
}

.Review_container__2EpAP {
  width: 100%;
  margin-bottom: 6em;
  padding: 0 2em;
}

.Review_wrapper__3jE55 {
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.Review_heading__3hboo .Review_accent__1pKVS {
  color: var(--main-color);
}

.Review_description__2SBwI {
  margin-bottom: 2em;
  font-size: 1.5rem;
  text-align: center;
  color: var(--text-sub-heading);
}

.Review_display__1ChW9 {
  width: 100%;
  height: 12em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  position: relative;
}

.Review_shadow__2AqWx {
  width: 100%;
  height: 4em;
  bottom: 0;
  position: absolute;
}

.Review_row__2_xOm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  -webkit-animation: Review_scroll__3chKK 15s infinite linear forwards;
          animation: Review_scroll__3chKK 15s infinite linear forwards;
}

.Review_row__2_xOm:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes Review_scroll__3chKK {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-20em);
            transform: translateY(-20em);
  }
}

@keyframes Review_scroll__3chKK {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-20em);
            transform: translateY(-20em);
  }
}

.Review_reviewCard__1jhkK {
  background: #f5f6f8;
  padding: 1em 0.5em;
  border-radius: 1em;
}

.Review_review__3tkjq {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1em;
}

.Review_profile__3T1zq {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Review_thumbnail__1gcXj {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.Review_name__-jq0Q {
  font-size: 1.2rem;
  font-weight: 600;
}

.Review_handle__2jXq7 {
  font-size: 1rem;
  color: var(--text-sub-heading);
}

@media screen and (max-width: 1200px) {
  .Review_heading__3hboo {
    font-size: 2.5rem;
  }

  .Review_row__2_xOm {
    grid-template-columns: repeat(2, 1fr);
    -webkit-animation-duration: 25s;
            animation-duration: 25s;
  }

  @-webkit-keyframes Review_scroll__3chKK {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-28em);
              transform: translateY(-28em);
    }
  }

  @keyframes Review_scroll__3chKK {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-28em);
              transform: translateY(-28em);
    }
  }
}

@media screen and (max-width: 900px) {
  .Review_heading__3hboo {
    font-size: 2rem;
  }

  .Review_description__2SBwI {
    font-size: 1.2rem;
  }

  .Review_name__-jq0Q,
  .Review_handle__2jXq7,
  .Review_review__3tkjq {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .Review_row__2_xOm {
    grid-template-columns: auto;
    -webkit-animation-duration: 25s;
            animation-duration: 25s;
  }

  @-webkit-keyframes Review_scroll__3chKK {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-48em);
              transform: translateY(-48em);
    }
  }

  @keyframes Review_scroll__3chKK {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-48em);
              transform: translateY(-48em);
    }
  }

  .Review_reviewCard__1jhkK {
    padding: 1em;
    border-radius: 0.5em;
  }

  .Review_name__-jq0Q,
  .Review_handle__2jXq7,
  .Review_review__3tkjq {
    font-size: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .Review_row__2_xOm {
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
  }

  .Review_container__2EpAP {
    padding: 0 1em;
    margin-bottom: 4em;
  }

  .Review_heading__3hboo {
    font-size: 1.5rem;
  }

  .Review_description__2SBwI {
    font-size: 1rem;
  }
}

.FAQ_container__sCUGr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
  margin-bottom: 6em;
}

.FAQ_wrapper__TYM8K {
  width: 100%;
  max-width: 1200px;
}

.FAQ_heading__3_-MM {
  width: 100%;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1em;
  color: var(--text-heading);
}

.FAQ_FQAs__10snz {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

@media screen and (max-width: 1200px) {
  .FAQ_heading__3_-MM {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .FAQ_wrapper__TYM8K {
    padding: 0;
  }

  .FAQ_heading__3_-MM {
    font-size: 2rem;
  }
}

@media screen and (max-width: 540px) {
  .FAQ_container__sCUGr {
    padding: 0 1em;
  }

  .FAQ_heading__3_-MM {
    font-size: 1.5rem;
  }
}

.FAQCard_container__1Ei5V {
  width: 100%;
  padding: 1em 2em;
  border-radius: 0.5em;
  border: 1px solid var(--outline-dark);
  cursor: var(--cursor-pointer);
}

.FAQCard_container__1Ei5V * {
  transition: color 300ms;
}

.FAQCard_answer__3-7tF,
.FAQCard_question__3a_rG {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 3em;
  gap: 3em;
}

.FAQCard_question__3a_rG {
  padding: 1em 0;
  font-weight: 600;
  font-size: 1.5rem;
}
.FAQCard_question__3a_rG span {
  display: flex;
}
.FAQCard_question__3a_rG .FAQCard_title__3XC11 {
  color: var(--text-sub-heading);
}

.FAQCard_question__3a_rG.FAQCard_active__2r6bD .FAQCard_title__3XC11 {
  color: var(--main-color) !important;
}

.FAQCard_question__3a_rG img {
  width: 1.2em;
  height: 1.2em;
}

.FAQCard_title__3XC11 {
  text-transform: capitalize;
}

.FAQCard_answer__3-7tF {
  max-height: 0;
  overflow: hidden;
  transition: all 1250ms;
  color: var(--text-heading);
  font-size: 1.2rem;
  width: 100%;
}

.FAQCard_answer__3-7tF a {
  color: var(--main-color);
}

.FAQCard_answer__3-7tF.FAQCard_active__2r6bD {
  max-height: 100vh;
}

.FAQCard_container__1Ei5V p {
  flex: 1 1;
}

@media screen and (max-width: 1200px) {
  .FAQCard_answer__3-7tF,
  .FAQCard_question__3a_rG {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 900px) {
  .FAQCard_answer__3-7tF,
  .FAQCard_question__3a_rG {
    grid-gap: 1em;
    gap: 1em;
    font-size: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .FAQCard_container__1Ei5V {
    padding: 0 1em;
  }
}

.Orgs_container__pGXt1 {
  width: 100%;
  padding: 1em 2em 6em;
  overflow: hidden;
}

.Orgs_wrapper__2jgo7 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 4em;
  gap: 4em;
}

.Orgs_content__2vPYj {
  flex: 60% 1;
  max-width: 36em;
}

.Orgs_heading__30dG4 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1em;
}

.Orgs_heading__30dG4 .Orgs_accent__2inFF {
  color: var(--main-color);
}

.Orgs_description__2v6f4 {
  margin-bottom: 2em;
  font-size: 1.5rem;
}

.Orgs_link__3p_6R {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.Orgs_link__3p_6R svg {
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  fill: var(--text-sub-heading);
  fill: var(--main-color);
}

.Orgs_link__3p_6R:hover svg {
  -webkit-transform: translateX(1em);
          transform: translateX(1em);
}

.Orgs_orgs__3lOgC {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  gap: 2em;
}

.Orgs_org__2zmxJ {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5em;
  background: #ffffff;
  border: 1px solid transparent;
  border-right: 3px solid #e5e8eb;
  box-shadow: -26px -18px 30px -12px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
  position: relative;
  transition: all 300ms;
  cursor: pointer;
}

.Orgs_org__2zmxJ:hover {
  border: 3px solid #e5e8eb;
}

.Orgs_org__2zmxJ:hover .Orgs_icon__OjO0f {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.Orgs_icon__OjO0f {
  position: absolute;
  width: 5em;
  -webkit-animation: Orgs_rotate__2EIfH 12s infinite linear;
          animation: Orgs_rotate__2EIfH 12s infinite linear;
}

@-webkit-keyframes Orgs_rotate__2EIfH {
  0% {
    -webkit-transform: rotate(0deg) translate(0, 0);
            transform: rotate(0deg) translate(0, 0);
  }

  50% {
    -webkit-transform: rotate(360deg) translate(1em, 1em);
            transform: rotate(360deg) translate(1em, 1em);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(0, 0);
            transform: rotate(360deg) translate(0, 0);
  }
}

@keyframes Orgs_rotate__2EIfH {
  0% {
    -webkit-transform: rotate(0deg) translate(0, 0);
            transform: rotate(0deg) translate(0, 0);
  }

  50% {
    -webkit-transform: rotate(360deg) translate(1em, 1em);
            transform: rotate(360deg) translate(1em, 1em);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(0, 0);
            transform: rotate(360deg) translate(0, 0);
  }
}

.Orgs_celo__1nhAF .Orgs_icon__OjO0f {
  top: -2em;
  left: -2em;
  z-index: -1;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.Orgs_mp__iiG4Z .Orgs_icon__OjO0f {
  bottom: -2em;
  left: -2em;
  z-index: -1;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.Orgs_algo__xp_dw .Orgs_icon__OjO0f {
  bottom: -2em;
  right: -2em;
  z-index: -1;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.Orgs_near__1pWPa .Orgs_icon__OjO0f {
  top: -2em;
  right: -2em;
  z-index: -1;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.Orgs_mp__iiG4Z {
  align-self: flex-start;
  justify-self: flex-end;
}

.Orgs_near__1pWPa {
  align-self: flex-end;
}

.Orgs_celo__1nhAF,
.Orgs_algo__xp_dw {
  width: 13em;
  height: 12.5em;
}

.Orgs_near__1pWPa,
.Orgs_mp__iiG4Z {
  width: 12em;
  height: 10em;
}

@media screen and (max-width: 1200px) {
  .Orgs_heading__30dG4 {
    font-size: 2.5rem;
  }

  .Orgs_celo__1nhAF,
  .Orgs_algo__xp_dw {
    width: 12em;
    height: 11.5em;
  }

  .Orgs_near__1pWPa,
  .Orgs_mp__iiG4Z {
    width: 11em;
    height: 9em;
  }

  .Orgs_mp__iiG4Z .Orgs_logo__3yymE {
    width: 5em;
  }

  .Orgs_near__1pWPa .Orgs_logo__3yymE {
    width: 6em;
  }

  .Orgs_celo__1nhAF .Orgs_logo__3yymE {
    width: 6em;
  }

  .Orgs_algo__xp_dw .Orgs_logo__3yymE {
    width: 6em;
  }
}

@media screen and (max-width: 900px) {
  .Orgs_wrapper__2jgo7 {
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
  }

  .Orgs_heading__30dG4 {
    font-size: 2rem;
  }

  .Orgs_description__2v6f4 {
    font-size: 1.2rem;
  }

  .Orgs_content__2vPYj {
    max-width: 100%;
    text-align: center;
  }

  .Orgs_orgsIcon__1ocNL {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .Orgs_container__pGXt1 {
    padding: 1em 1em 4em;
  }

  .Orgs_heading__30dG4 {
    font-size: 1.5rem;
  }

  .Orgs_description__2v6f4 {
    font-size: 1rem;
  }

  .Orgs_orgsIcon__1ocNL {
    height: 20em;
  }

  .Orgs_orgs__3lOgC {
    grid-gap: 1em;
    gap: 1em;
  }

  .Orgs_org__2zmxJ {
    border-radius: 0.7em;
  }

  .Orgs_icon__OjO0f {
    width: 3.5em;
  }

  .Orgs_celo__1nhAF,
  .Orgs_algo__xp_dw {
    width: 8em;
    height: 7.5em;
  }

  .Orgs_near__1pWPa,
  .Orgs_mp__iiG4Z {
    width: 7em;
    height: 6em;
  }

  .Orgs_mp__iiG4Z .Orgs_logo__3yymE {
    width: 3em;
  }

  .Orgs_algo__xp_dw .Orgs_logo__3yymE,
  .Orgs_celo__1nhAF .Orgs_logo__3yymE,
  .Orgs_near__1pWPa .Orgs_logo__3yymE {
    width: 4em;
  }
}

.home_container__1oK8P {
  width: 100%;
  margin-bottom: 8em;
  /* animation: breath 1650ms forwards; */
}

/* @keyframes breath {
  from {
    transform: scale(0.98) translateY(-2em);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.wrapper {
  width: 100%;
  padding-top: 2em;
  margin: 0 auto;
  margin-bottom: 8em;
}

.withBg {
  max-width: 100%;
  padding: 0;
  background: #f2f3ff;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 540px;
    padding: 0 2em;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 0 1em;
  }
} */

.GenadropCreatedNFTs_container__3Rowg {
  width: 100%;
  margin-bottom: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em 2em;
}

.GenadropCreatedNFTs_container__3Rowg * {
  transition: max-width 300ms;
}
.GenadropCreatedNFTs_loader__35ExW {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  white-space: nowrap;
}
.GenadropCreatedNFTs_load__3m6VX {
  width: 30%;
  max-width: 25em;
}
.GenadropCreatedNFTs_heading__Cp8B3 {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5em;
  font-size: 3rem;
  font-weight: 600;
}

.GenadropCreatedNFTs_heading__Cp8B3 .GenadropCreatedNFTs_accent__3tK10 {
  color: var(--main-color);
}

.GenadropCreatedNFTs_description__3p26o {
  text-align: center;
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--text-sub-heading);
  padding: 0 1em;
  margin-bottom: 1em;
}

.GenadropCreatedNFTs_btn__31qiU {
  display: inline-block;
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 2em;
}

.GenadropCreatedNFTs_btn__31qiU:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.GenadropCreatedNFTs_cardGrid__cz0EP {
  display: flex;
  grid-gap: 0.6em;
}

.GenadropCreatedNFTs_card__1_K_2 {
  height: 20em;
  width: 18em;
  flex: 0 0 auto;
  padding: 0.5em;
  padding-bottom: 2em;
  border-radius: 1em;
  border: 1px solid var(--outline);
}

.GenadropCreatedNFTs_imgContainer__rKxOe {
  height: 17em;
}
.GenadropCreatedNFTs_imgContainer__rKxOe img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  margin-bottom: 0.5em;
  border-radius: 1em;
}

.GenadropCreatedNFTs_card__1_K_2:hover img {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  cursor: pointer;
}

.GenadropCreatedNFTs_card__1_K_2 .GenadropCreatedNFTs_name__1f1pN {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 0.5em;
}

@media screen and (max-width: 1200px) {
  .GenadropCreatedNFTs_heading__Cp8B3 {
    font-size: 2.5rem;
  }
  .GenadropCreatedNFTs_card__1_K_2:nth-child(4) {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .GenadropCreatedNFTs_heading__Cp8B3 {
    font-size: 2rem;
  }
  .GenadropCreatedNFTs_card__1_K_2:nth-child(3) {
    display: none;
  }

  .GenadropCreatedNFTs_heading__Cp8B3 .GenadropCreatedNFTs_accent__3tK10 {
    white-space: nowrap;
  }

  .GenadropCreatedNFTs_description__3p26o {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 540px) {
  .GenadropCreatedNFTs_container__3Rowg {
    padding: 2em 1em;
    margin-bottom: 4em;
  }
  .GenadropCreatedNFTs_cardGrid__cz0EP {
    justify-content: center;
  }
  .GenadropCreatedNFTs_card__1_K_2:nth-child(2) {
    display: none;
  }
  .GenadropCreatedNFTs_heading__Cp8B3 {
    font-size: 1.5rem;
  }

  .GenadropCreatedNFTs_description__3p26o {
    font-size: 1rem;
  }
}

.CameraMint_container__1gRsn {
  width: 100%;
  margin-bottom: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 4em;
  overflow: hidden;
}

.CameraMint_container__1gRsn * {
  transition: max-width 300ms;
}

.CameraMint_heading__2Ml4z {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5em;
  color: var(--text-heading);
}

.CameraMint_heading__2Ml4z .CameraMint_accent__1wIR4 {
  color: var(--main-color);
}

.CameraMint_description__34-sz {
  text-align: center;
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  margin-bottom: 3em;
  font-size: 1.2rem;
  color: var(--text-sub-heading);
  line-height: 1.5em;
}

.CameraMint_wrapper__1h0gc {
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 3em;
  gap: 3em;
}

.CameraMint_wrapper__1h0gc img {
  width: 100%;
  max-width: 60em;
  height: 100%;
}

.CameraMint_btn__2MxlE {
  background: var(--main-color);
  border: 1px solid transparent;
  color: var(--default);
  padding: 0.75em 3em;
  border-radius: 0.7em;
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.CameraMint_btn__2MxlE:hover {
  background: var(--default);
  color: var(--main-color);
  border-color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .CameraMint_heading__2Ml4z {
    font-size: calc(1rem + 2vmin);
  }

  .CameraMint_description__34-sz {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .CameraMint_container__1gRsn {
    padding: 0 2em;
    margin-bottom: 4em;
  }
}

@media screen and (max-width: 540px) {
  .CameraMint_wrapper__1h0gc {
    grid-gap: 2em;
    gap: 2em;
  }
}

.early-access_container__Z73oH {
  width: 100%;
  padding: 0em 4em;
  margin-bottom: 6em;
}

.early-access_wrapper__spKvX {
  width: 100%;
  max-width: -webkit-max-content;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  grid-gap: 1em;
  gap: 1em;
  margin: 0 auto;
  color: var(--main-color);
  background: #f2f7ff;
}

.early-access_wrapper__spKvX p {
  font-size: 1.5rem;
  color: var(--text-sub-heading);
}

.early-access_wrapper__spKvX svg {
  width: 1.5em;
  transition: 300ms;
  fill: var(--text-sub-heading);
}

.early-access_wrapper__spKvX:hover svg:last-child {
  -webkit-transform: translateX(25%);
          transform: translateX(25%);
}

@media screen and (max-width: 1200px) {
  .early-access_container__Z73oH {
    padding: 0 2em;
  }

  .early-access_wrapper__spKvX p {
    font-size: 1.2rem;
  }

  .early-access_wrapper__spKvX svg {
    width: 1.2em;
  }
}

@media screen and (max-width: 900px) {
  .early-access_container__Z73oH {
    padding: 0 2em;
  }

  .early-access_wrapper__spKvX p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 540px) {
  .early-access_container__Z73oH {
    padding: 0 1em;
    margin-bottom: 4em;
  }

  .early-access_wrapper__spKvX {
    padding: 0.5em;
  }

  .early-access_wrapper__spKvX svg {
    width: 3em;
    transition: 300ms;
  }

  .early-access_wrapper__spKvX p {
    font-size: 1rem;
  }
}

.Plans_container__3zepV {
  width: 100%;
  padding: 6em 2em;
  background: #f4f6fa;
}

.Plans_wrapper__2Mnp8 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.Plans_heading__ZzbVJ {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
}

.Plans_heading__ZzbVJ .Plans_accent__2A1CJ {
  color: var(--main-color);
}

.Plans_description__1XPZZ {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-sub-heading);
  margin-bottom: 2em;
}

.Plans_plans__-lv9X {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4em;
  gap: 4em;
  margin-bottom: 2em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--outline);
}

.Plans_planName__2zBsd {
  font-size: 1.5rem;
  cursor: pointer;
}

.Plans_line__2coig {
  width: 0%;
  background: var(--main-color);
  height: 4px;
  transition: all 650ms;
}

.Plans_planName__2zBsd:hover .Plans_line__2coig {
  width: 60%;
}

.Plans_planName__2zBsd.Plans_active__3A8hU .Plans_line__2coig {
  width: 100%;
}

.Plans_plan__14_lE {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.Plans_plan__14_lE.Plans_render__1qfAq {
  -webkit-animation: Plans_render__1qfAq 300ms forwards;
          animation: Plans_render__1qfAq 300ms forwards;
}

.Plans_plan__14_lE.Plans_re_render__28mF- {
  -webkit-animation: Plans_re-render__1tL2r 300ms forwards;
          animation: Plans_re-render__1tL2r 300ms forwards;
}

.Plans_plan__14_lE:nth-child(1) {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}

.Plans_plan__14_lE:nth-child(2) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}

.Plans_plan__14_lE:nth-child(3) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.Plans_plan__14_lE:nth-child(4) {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}

.Plans_plan__14_lE:nth-child(5) {
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}

.Plans_plan__14_lE:nth-child(6) {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}

.Plans_plan__14_lE:nth-child(7) {
  -webkit-animation-delay: 700ms;
          animation-delay: 700ms;
}

@-webkit-keyframes Plans_render__1qfAq {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Plans_render__1qfAq {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes Plans_re-render__1tL2r {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Plans_re-render__1tL2r {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1em);
            transform: translateY(1em);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Plans_closeIcon__rnhAA {
  fill: black;
}

.Plans_markIcon__3uy3V {
  fill: var(--main-color);
}

.Plans_preview__1-1wS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 4em;
  gap: 4em;
  background: var(--default);
  padding: 4em;
}

.Plans_planIcon__23OZ3 {
  width: 100%;
  max-width: 46vw;
  height: auto;
}

.Plans_planIcon__23OZ3.Plans_render__1qfAq {
  -webkit-animation: Plans_shake__HadUF 850ms forwards;
          animation: Plans_shake__HadUF 850ms forwards;
}

.Plans_planIcon__23OZ3.Plans_re_render__28mF- {
  -webkit-animation: Plans_re-shake__3AFBL 850ms forwards;
          animation: Plans_re-shake__3AFBL 850ms forwards;
}

@-webkit-keyframes Plans_shake__HadUF {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes Plans_shake__HadUF {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes Plans_re-shake__3AFBL {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes Plans_re-shake__3AFBL {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Plans_planInfo__1YvwP {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.Plans_infoHeading__3Aurz {
  font-size: 1rem;
  text-transform: uppercase;
}

.Plans_infoDescription__24NE4 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Plans_supportedFeatures__2gUCZ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 1em;
}

.Plans_name__13Y1E {
  font-size: 1rem;
}

.Plans_btn__186LA {
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 650ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.Plans_btn__186LA:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

@media screen and (max-width: 1200px) {
  .Plans_heading__ZzbVJ {
    font-size: 2.5rem;
  }

  .Plans_infoDescription__24NE4 {
    font-size: 1.5rem;
  }

  .Plans_preview__1-1wS {
    padding: 4em 2em;
  }
}

@media screen and (max-width: 900px) {
  .Plans_heading__ZzbVJ {
    font-size: 2rem;
  }

  .Plans_plans__-lv9X {
    grid-gap: 2em;
    gap: 2em;
  }

  .Plans_description__1XPZZ,
  .Plans_planName__2zBsd {
    font-size: 1.2rem;
  }

  .Plans_preview__1-1wS {
    grid-gap: 2em;
    gap: 2em;
    flex-direction: column-reverse;
  }

  .Plans_planIcon__23OZ3 {
    max-width: 100vw;
  }

  .Plans_infoDescription__24NE4 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 540px) {
  .Plans_container__3zepV {
    padding: 4em 1em;
  }

  .Plans_heading__ZzbVJ {
    font-size: 1.5rem;
  }

  .Plans_description__1XPZZ {
    font-size: 1rem;
  }

  .Plans_planName__2zBsd {
    font-size: 1.2rem;
  }

  .Plans_preview__1-1wS {
    padding: 2em 1em;
  }

  .Plans_btn__186LA {
    padding: 0.5em 2em;
  }
}

.JoinDiscord_container__1-o_I {
  width: 100%;
  padding: 0 2em;
  margin-bottom: 6em;
  background: linear-gradient(
    255.93deg,
    #eff3f9 -39.69%,
    #eff3f9 5.2%,
    rgba(205, 237, 255, 0.93) 49.41%,
    #ffffff 91.12%
  );
}

.JoinDiscord_wrapper__1rQJj {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 0;
}

.JoinDiscord_content__1gRSK {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.JoinDiscord_image__3GBNj {
  width: 100%;
  max-width: 36em;
}

.JoinDiscord_heading__TADiE {
  font-size: 3rem;
  font-weight: 600;
}

.JoinDiscord_listContainer__gEmMl {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.JoinDiscord_listItem__15wzN {
  font-size: 1.5rem;
  color: var(--text-heading);
}

.JoinDiscord_listStyle__2fsrN {
  display: inline-block;
  background: var(--text-sub-heading);
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
}

.JoinDiscord_btn__3SD-m {
  display: inline-block;
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.JoinDiscord_btn__3SD-m:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

@media screen and (max-width: 1200px) {
  .JoinDiscord_image__3GBNj {
    max-width: 28em;
  }

  .JoinDiscord_heading__TADiE {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .JoinDiscord_image__3GBNj {
    max-width: 24em;
  }

  .JoinDiscord_heading__TADiE {
    font-size: 1.5rem;
  }

  .JoinDiscord_listItem__15wzN {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .JoinDiscord_wrapper__1rQJj {
    flex-direction: column-reverse;
    grid-gap: 2em;
    gap: 2em;
  }

  .JoinDiscord_content__1gRSK {
    width: 100%;
    align-items: center;
  }

  .JoinDiscord_heading__TADiE {
    font-size: 2rem;
    text-align: center;
  }
}

@media screen and (max-width: 540px) {
  .JoinDiscord_container__1-o_I {
    padding: 0 1em;
    margin-bottom: 4em;
  }

  .JoinDiscord_wrapper__1rQJj {
    padding: 1em;
  }

  .JoinDiscord_heading__TADiE {
    font-size: 1.5rem;
  }

  .JoinDiscord_listItem__15wzN {
    font-size: 1rem;
  }

  .JoinDiscord_btn__3SD-m {
    padding: 0.5em 2em;
  }
}

.Media_container__3sW9Z {
  width: 100%;
  margin-bottom: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 4em; */
  overflow: hidden;
}

.Media_container__3sW9Z * {
  transition: max-width 300ms;
}

.Media_heading__3Xc2Q {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.Media_heading__3Xc2Q .Media_accent__2ehVe {
  color: var(--main-color);
}

.Media_description__2y5iH {
  margin-bottom: 2em;
  font-size: 1.5rem;
  text-align: center;
  color: var(--text-sub-heading);
}

.Media_wrapper__1VMu0 {
  width: 100%;
  /* max-width: 1200px; */
}

@media screen and (max-width: 1200px) {
  .Media_heading__3Xc2Q {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .Media_container__3sW9Z {
    margin-bottom: 4em;
  }
  .Media_heading__3Xc2Q {
    font-size: 2rem;
    padding: 0 2em;
  }
  .Media_description__2y5iH {
    font-size: 1.2rem;
    padding: 0 2em;
  }
}

@media screen and (max-width: 540px) {
  .Media_wrapper__1VMu0 {
    grid-gap: 2em;
    gap: 2em;
  }
  .Media_heading__3Xc2Q {
    font-size: 1.5rem;
  }

  .Media_description__2y5iH {
    font-size: 1rem;
  }
}

.MediaBar_slide__1kp_U {
  display: flex;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 2;
}
.MediaBar_slide__1kp_U img {
  height: 4rem;
  width: auto;
  /* width: 100%; */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  transition: 300ms all;
}
.MediaBar_slide__1kp_U img:hover {
  filter: none; /* IE6-9 */
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(0); /* Microsoft Edge and Firefox 35+ */
}

.Partners_container__14oG8 {
  width: 100%;
  margin-bottom: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 4em; */
  overflow: hidden;
}

.Partners_container__14oG8 * {
  transition: max-width 300ms;
}

.Partners_heading__lDfGz {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.Partners_heading__lDfGz .Partners_accent__msnsh {
  color: var(--main-color);
}

.Partners_description__1l2xm {
  margin-bottom: 2em;
  font-size: 1.5rem;
  text-align: center;
  color: var(--text-sub-heading);
}

.Partners_wrapper__AqIo5 {
  width: 100%;
  /* max-width: 1200px; */
}
.Partners_btn__2X8Jj {
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 650ms;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 5.6rem;
}

.Partners_btn__2X8Jj:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
@media screen and (max-width: 1200px) {
  .Partners_heading__lDfGz {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .Partners_container__14oG8 {
    margin-bottom: 4em;
  }
  .Partners_heading__lDfGz {
    font-size: 2rem;
    padding: 0 2em;
  }
  .Partners_description__1l2xm {
    font-size: 1.2rem;
    padding: 0 2em;
  }
}

@media screen and (max-width: 540px) {
  .Partners_wrapper__AqIo5 {
    grid-gap: 2em;
    gap: 2em;
  }
  .Partners_heading__lDfGz {
    font-size: 1.5rem;
  }

  .Partners_description__1l2xm {
    font-size: 1rem;
  }
  .Partners_btn__2X8Jj {
    padding: 0.5em 2em;
    margin-top: 3rem;
  }
}

.layerorders_container__W2MP4 {
  width: 20em;
  position: -webkit-sticky;
  position: sticky;
  top: 5em;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  padding: 1em 0;
  height: calc(100vh - 10em);
  z-index: 15;
  overflow: auto;
  box-shadow: 26px 49px 27px rgba(93, 134, 194, 0.1);
  display: flex;
  flex-direction: column;
}

.layerorders_container__W2MP4::-webkit-scrollbar {
  display: none;
}

.layerorders_container__W2MP4 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.layerorders_container__W2MP4 i {
  font-size: 14px;
  padding: 0 0.5em;
}

.layerorders_collectionNameContainer__27imr {
  padding: 1em;
  border-bottom: 1px solid var(--outline);
  margin-bottom: 1em;
}

.layerorders_collectionName__2NOYl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  background: var(--card-light);
  padding: 0.5em 1em;
  border-radius: 0.25em;
  border: 2px solid var(--outline-dark);
}

.layerorders_collectionName__2NOYl form {
  flex: 1 1;
  height: 100%;
  display: flex;
}

.layerorders_collectionName__2NOYl input {
  background: transparent;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  margin-right: 0.5em;
  font-size: 1rem;
}

.layerorders_collectionName__2NOYl label {
  max-width: 14em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layerorders_collectionName__2NOYl input::-webkit-input-placeholder {
  color: var(--text-placeholder-light);
}

.layerorders_collectionName__2NOYl input:-ms-input-placeholder {
  color: var(--text-placeholder-light);
}

.layerorders_collectionName__2NOYl input::placeholder {
  color: var(--text-placeholder-light);
}

.layerorders_editBtn__15RPE {
  cursor: var(--cursor-pointer);
  padding: 0.1em;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layerorders_editBtn__15RPE img {
  width: 100%;
}

.layerorders_editIcon__3LfEm {
  fill: black;
}

.layerorders_info__t82GW {
  padding: 0.5em 0.75em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  background: var(--card-info);
}

.layerorders_info__t82GW p {
  font-size: 0.75rem;
}

.layerorders_info__t82GW .layerorders_closeBtn__3Nvee {
  fill: var(--icon-color);
  width: 2em;
  cursor: var(--cursor-pointer);
}

.layerorders_layerorder__2UHeJ {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: var(--default);
}

.layerorders_layerHeadWrapper__igZec {
  width: 100%;
}

.layerorders_layer_trait__3JI2I {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  padding-right: 2em;
  text-transform: capitalize;
  background: var(--card-trait);
  color: var(--main-color);
  margin-bottom: 1em;
  border-radius: 0.25em;
}

.layerorders_listWrapper__3pqTV {
  width: 100%;
  padding: 0.25em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layerorders_list__cGajK {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.layerorders_list__cGajK > div {
  cursor: var(--cursor-hover);
}
.layerorders_list__cGajK > div:active:hover {
  cursor: var(--cursor-grab);
}
.layerorders_promptWrapper__1fdrR {
  width: 100%;
}

.layerorders_addBtnContainer__14tJ0 {
  position: relative;
}

.layerorders_addBtn__1bqPl {
  width: 100%;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  color: var(--main-color);
  padding: 0.75em;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 300ms;
  border: 1px solid var(--main-color);
}

.layerorders_addIcon__GOPCI {
  fill: var(--main-color);
  width: 1.2em;
  height: 1.2em;
}

.layerorders_addBtn__1bqPl:hover {
  background: var(--main-lightest);
}

@media screen and (max-width: 768px) {
  .layerorders_container__W2MP4 {
    width: 100%;
    position: static;
  }
}

.layer_item__3deZ3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 0.35em 0.5em;
  background: var(--card-layer);
  border-radius: 0.25em;
  color: var(--default);
  cursor: grab;
}

.layer__name__36xKk {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.layer_line__2zKPU {
  margin-right: 0.5em;
}

.layer_renameBtn__1t54K,
.layer_editBtn__2JuoF,
.layer_deleteBtn__1P6S9 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  background: rgb(0, 0, 0, 0.1);
}

.layer_renameBtn__1t54K img,
.layer_deleteBtn__1P6S9 img,
.layer_editBtn__2JuoF img {
  width: 1em;
}

.layer_renameBtn__1t54K:hover,
.layer_editBtn__2JuoF:hover,
.layer_deleteBtn__1P6S9:hover {
  background: rgb(0, 0, 0, 0.3);
}

.layer_closeIcon__2jsHZ,
.layer_editIcon__2CBKy {
  fill: white;
}

.layer_renameWrapper__3uarm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.25em;
  gap: 0.25em;
  background: transparent;
  color: white;
  font-size: 1rem;
  border: none;
}

.layer_renameWrapper__3uarm form {
  flex: 1 1;
  height: 100%;
  display: flex;
}

.layer_renameWrapper__3uarm input {
  background: transparent;
  width: 100%;
  outline: none;
  border: none;
  padding: 0.25em;
  border: 1px solid var(--outline-dark);
  color: var(--default);
  border-radius: 0.25em;
  font-size: 1rem;
}

.layer_nameHeader__owmNB {
  width: 100%;
  max-width: 8em;
  overflow: auto;
  white-space: nowrap;
  padding: 0.25em;
  color: var(--default);
}

.layer_trait__DSSmS {
  color: var(--default);
}

.LayerInput_form__3qNED {
  width: 100%;
  background: transparent;
  margin-bottom: 0.25em;
}

.LayerInput_form__3qNED input {
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 1rem;
  padding: 0.25em;
  color: var(--text-heading);
  background: var(--default);
  outline: none;
  border-radius: 0.25em;
  border: 1px solid var(--card-layer);
}

.LayerInput_buttons__16iHP {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 1em;
  gap: 1em;
}

.LayerInput_add__1069D,
.LayerInput_cancel__Er-wo {
  width: 5em;
  outline: none;
  padding: 0.25em 1.5em;
  border-radius: 0.25em;
  cursor: var(--cursor-pointer);
  border: 1px solid transparent;
}

.LayerInput_add__1069D {
  background: var(--card-layer);
  color: var(--default);
  transition: all 300ms;
}

.LayerInput_add__1069D:hover {
  background: var(--card-preview);
}

.collection-details_container__36_E4 {
  width: 100%;
}

.collection-details_content__2suA2 {
  width: 100%;
  min-height: 5em;
  height: calc(100vh - 42em);
  overflow: auto;
  border: 3px solid var(--outline);
  padding: 0.5em;
  border-radius: 0.25em;
}

.collection-details_container__36_E4 h4 {
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--text-dark-900);
}

.collection-details_content__2suA2 p {
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  flex-wrap: wrap;
}

.collection-details_content__2suA2 p > span {
  font-size: 0.85rem;
  color: var(--text-dark-900);
}

@media screen and (max-width: 1200px) {
  .collection-details_content__2suA2 {
    height: auto;
    max-height: 16em;
  }

  .collection-details_content__2suA2 h4 {
    margin-bottom: 1em;
  }
}

.collection-description_container__1kJoi {
  width: 100%;
  height: calc(100vh - 10em);
  max-width: 20em;
  padding: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 5em;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  z-index: 10;
  overflow: auto;
  box-shadow: -9px 13px 18px rgba(93, 134, 194, 0.1), 26px 18px 18px rgba(93, 134, 194, 0.1);
}

/* .container.active {
  z-index: 18;
} */

.collection-description_container__1kJoi::-webkit-scrollbar {
  display: none;
}

.collection-description_container__1kJoi {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.collection-description_preview_details__3eaLd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  margin-bottom: 1em;
}

.collection-description_previewWrapper__3vW72 {
  width: auto;
}

.collection-description_detailsWrapper__3W2A9 {
  width: 100%;
  flex: 1 1;
}

.collection-description_sectionHeading__WPuSf {
  margin-bottom: 0.5em;
}

.collection-description_combinations_amount__2ew_x {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.collection-description_combinations__uS6lx,
.collection-description_amount__3pMa8 {
  flex: 50% 1;
  margin-bottom: 1em;
  background: var(--card-light);
  padding: 0.75em;
  border-radius: 0.25em;
}

.collection-description_combinations__uS6lx .collection-description_title__4VBEx,
.collection-description_amount__3pMa8 .collection-description_title__4VBEx {
  font-size: 0.75rem;
}

.collection-description_combinations__uS6lx .collection-description_count__ycY7Y {
  width: 100%;
  background: var(--default);
  text-align: center;
  margin-top: 0.5em;
  padding: 0.25em;
  border-radius: 0.25em;
  font-size: 0.85rem;
  border: 2px solid transparent;
}

.collection-description_amount__3pMa8 input {
  width: 100%;
  background: var(--default);
  text-align: center;
  margin-top: 0.5em;
  padding: 0.25em;
  border-radius: 0.25em;
  border: 2px solid var(--warning-500);
  outline: none;
  font-size: 0.85rem;
}

.collection-description_amount__3pMa8 input::-webkit-input-placeholder {
  font-size: 0.65rem;
  color: var(--text-placeholder-light);
}

.collection-description_amount__3pMa8 input:-ms-input-placeholder {
  font-size: 0.65rem;
  color: var(--text-placeholder-light);
}

.collection-description_amount__3pMa8 input::placeholder {
  font-size: 0.65rem;
  color: var(--text-placeholder-light);
}

.collection-description_generateContainer__3N0UT {
  width: 100%;
}

.collection-description_generateSettings__3xg39 {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  color: var(--text-dark-500);
  margin-bottom: 2em;
}

.collection-description_generateSettings__3xg39 .collection-description_wrapper__1Bu2a {
  flex: 1 1;
  background: var(--card-light);
  padding: 0.5em;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-description_generateSettings__3xg39 select {
  cursor: var(--cursor-pointer);
  margin-right: 0.5em;
  border: none;
  outline: none;
  font-size: 0.85rem;
}

.collection-description_btnContainer__63t1a {
  display: flex;
  justify-content: center;
  align-items: stretch;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.collection-description_generateBtn__1OhRy {
  flex: 1 1;
  border-radius: 12px;
  padding: 0.75em;
  text-align: center;
  background: var(--main-disable);
  color: var(--default);
  font-size: 1rem;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-description_generateBtn__1OhRy.collection-description_active__2cQPN {
  background: var(--main-color);
}

.collection-description_generateBtn__1OhRy.collection-description_active__2cQPN:hover {
  background: var(--main-hovered);
}

.collection-description_previewBtn__3Q2oX {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--warning-500);
  border-radius: 50%;
}

.collection-description_previewBtn__3Q2oX.collection-description_active__2cQPN {
  -webkit-animation: collection-description_ripple__29Hr0 850ms infinite;
          animation: collection-description_ripple__29Hr0 850ms infinite;
}

@-webkit-keyframes collection-description_ripple__29Hr0 {
  0% {
    box-shadow: 0 0 10px 0 var(--warning-500);
  }
  50% {
    box-shadow: 0 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  100% {
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
  }
}

@keyframes collection-description_ripple__29Hr0 {
  0% {
    box-shadow: 0 0 10px 0 var(--warning-500);
  }
  50% {
    box-shadow: 0 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  100% {
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
  }
}

.collection-description_previewBtn__3Q2oX:hover:before {
  content: "Preview your collection";
  position: absolute;
  top: -1em;
  left: 0;
  white-space: nowrap;
  font-size: 0.75rem;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  padding: 0.5em;
  border-radius: 0.25em;
  background: #626277;
  color: var(--default);
  opacity: 1;
}

.collection-description_previewBtn__3Q2oX:hover:after {
  content: "";
  position: absolute;
  top: -0.35em;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  border-top: 0.5em solid #626277;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  opacity: 1;
}

.collection-description_previewIcon__10nE9 {
  width: 2.6em;
  height: 2.6em;
  border-radius: 50%;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--main-color);
  cursor: var(--cursor-pointer);
  border: 1px solid var(--outline);
}

.collection-description_mintInfo__bBHEb {
  padding: 0.25em;
  border-radius: 0.25em;
  margin-bottom: 0.5em;
  font-size: 0.85em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  color: var(--text-dark-900);
}

.collection-description_mintInfo__bBHEb.collection-description_isLoading__3iHth {
  border: 1px solid var(--main-color);
}

@media screen and (max-width: 1000px) {
  .collection-description_container__1kJoi {
    height: auto;
    max-width: 36em;
    align-self: flex-end;
    margin-top: 2em;
  }

  .collection-description_preview_details__3eaLd {
    flex-direction: row;
    align-items: flex-start;
  }

  .collection-description_generateSettings__3xg39 .collection-description_wrapper__1Bu2a {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .collection-description_preview_details__3eaLd {
    flex-direction: column;
    align-items: center;
  }
}

.collection-preview_container__3Kuj9 {
  width: -webkit-max-content;
  width: max-content;
  border-radius: 0.5em;
  overflow: hidden;
  margin: auto;
  position: relative;
}

.collection-preview_container__3Kuj9 button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.15em 0.75em;
  cursor: var(--cursor-pointer);
  outline: none;
  font-size: 12px;
  border-radius: 0.2em;
  transition: all 300ms;
  border: none;
  background: var(--default);
  box-shadow: var(--shadow-light);
}

.collection-preview_container__3Kuj9 button * {
  color: var(--text-dark-900);
}

.collection-preview_container__3Kuj9 button:hover {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.collection-preview_placeholder__Ln0sn {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
}

.collection-menu_container__2h76O {
  width: 100%;
  overflow: auto;
  border: 1px solid var(--outline);
  background: var(--default);
  border-radius: 0.5em;
  margin-bottom: 2em;
  padding: 1em;
  padding-right: 0;
  box-shadow: 6px 9px 18px rgba(93, 134, 194, 0.1);
}

.collection-menu_layer__3j1bN {
  position: relative;
}

.collection-menu_heading__Cv9WX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding-right: 1em;
}

.collection-menu_title__1kix9 {
  color: var(--text-dark-900);
  font-weight: 500;
}

.collection-menu_wrapper__3btCW {
  width: 100%;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em;
  margin-bottom: 1em;
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  border: 2px solid var(--outline);
  border-right-color: transparent;
}

.collection-menu_wrapper__3btCW * {
  flex: 0 0 auto;
}

.collection-menu_uploadCard__1kMB1 {
  width: 100%;
  height: 16em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  cursor: var(--cursor-pointer);
  background: var(--card-upload);
  border: 1px solid #b2c5d0;
}
.collection-menu_uploadCard__1kMB1 > svg path {
  fill: #c1ddff;
}
.collection-menu_uploadBtn__1ORAt {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.collection-menu_uploadTitle__dvLGJ {
  font-weight: 700;
  color: #263238;
}
.collection-menu_uploadText__17P83 {
  font-size: 0.875rem;
  color: #767676;
}
.collection-menu_addIcon__17N28,
.collection-menu_uploadIcon__2ID8h {
  width: 1.5em;
  height: 1.5em;
  fill: var(--main-color);
}

.collection-menu_uploadBtn__1ORAt:hover {
  background: var(--main-color);
  color: var(--default);
}

.collection-menu_uploadBtn__1ORAt:hover .collection-menu_uploadIcon__2ID8h {
  fill: var(--default);
}

.collection-menu_btnContainer__j-Tnz {
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.collection-menu_btnContainer__j-Tnz .collection-menu_upload__3RC2h,
.collection-menu_btnContainer__j-Tnz .collection-menu_addBlank__1Ab2G {
  width: 8em;
  padding: 0.5em 0;
  border-radius: 0.5em;
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  color: var(--main-color);
  background: var(--card-light);
  box-shadow: 3px 4px 4px rgba(178, 197, 208, 0.44);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: all 300ms;
}

.collection-menu_btnContainer__j-Tnz .collection-menu_upload__3RC2h:hover,
.collection-menu_btnContainer__j-Tnz .collection-menu_addBlank__1Ab2G:hover {
  border: 2px solid var(--main-color);
}

@media screen and (max-width: 768px) {
  .collection-menu_uploadText__17P83 {
    font-size: 0.8em;
  }
}

.art-card_container__2OHCM {
  width: 14em;
  cursor: var(--cursor-pointer);
  position: relative;
  border-radius: 0.5em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-light);
  border: 3px solid transparent;
}

.art-card_container__2OHCM.art-card_active__3wZZS {
  border: 3px solid var(--warning-500);
  box-shadow: var(--shadow-dark-2);
}

.art-card_container__2OHCM.art-card_inActive__2rqoK {
  border-color: transparent;
}

.art-card_container__2OHCM.art-card_active__3wZZS .art-card_addRuleBtn__2YTgB {
  color: var(--main-color);
}

.art-card_imageContainer__2eC1a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--card-trait);
}

.art-card_imageContainer__2eC1a:hover .art-card_image__3JEuV {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.art-card_image__3JEuV {
  object-fit: contain;
  width: 100%;
  height: 12em;
}

.art-card_action__3T9qE {
  width: 100%;
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em;
}

.art-card_details__2yxHH {
  width: 100%;
  bottom: 0;
  left: 0;
  color: var(--text-heading);
  background: var(--default);
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 0.5em;
}

.art-card_editInput__klb_P,
.art-card_inputText__VKMrf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.art-card_editInput__klb_P form {
  flex: 1 1;
}

.art-card_editInput__klb_P input,
.art-card_inputText__VKMrf div {
  flex: 1 1;
  text-align: center;
}

.art-card_editIcon__1sLn1 {
  fill: black;
}

.art-card_container__2OHCM i {
  cursor: var(--cursor-pointer);
  font-size: 0.7rem;
}

.art-card_reset__2XnRA {
  background: var(--main-color);
}

.art-card_reset__2XnRA * {
  -webkit-animation: art-card_bounce__1NW02 650ms infinite alternate linear;
          animation: art-card_bounce__1NW02 650ms infinite alternate linear;
}

@-webkit-keyframes art-card_bounce__1NW02 {
  from {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes art-card_bounce__1NW02 {
  from {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.art-card_details__2yxHH input {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0.25em;
  color: var(--text-heading);
  border: 1px solid var(--outline);
}

.art-card_details__2yxHH input:focus {
  outline: none;
}

.art-card_promptWrapper__2FMbX {
  display: absolute;
  top: 0;
  left: 0;
  display: none;
}

.art-card_promptWrapper__2FMbX.art-card_show__2UKZE {
  display: block;
}

.art-card_closeIcon__35pdh {
  transition: all 300ms;
  padding: 0.25em;
  border-radius: 0.25em;
  fill: var(--icon-color);
  width: 1.4em;
  height: 1.4em;
  cursor: pointer;
}

.art-card_closeIcon__35pdh:hover {
  box-shadow: var(--shadow-dark);
}

.radioButton_container__1OtmZ {
  border: 2px solid var(--outline-dark);
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioButton_container__1OtmZ.radioButton_active__1OdpY {
  border-color: var(--main-color);
}

.radioButton_dot__fRIKb {
  display: none;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: var(--main-color);
}

.radioButton_container__1OtmZ.radioButton_active__1OdpY .radioButton_dot__fRIKb {
  display: block;
}

.collection-overview_container__QTitI {
  width: calc(100% - 20em);
  z-index: 14;
}

.collection-overview_container__QTitI.collection-overview_active__1U0jn {
  z-index: 20;
}

.collection-overview_rules__3MjcY {
  width: 100%;
  align-items: center;
  background: var(--default);
  padding: 1em 0.5em;
  z-index: 1;
  grid-gap: 1em;
  gap: 1em;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: -webkit-sticky;
  position: sticky;
  top: 3.7em;
}

.collection-overview_addRuleBtn__1EdzG,
.collection-overview_showRuleBtn__2AOwl {
  width: 7.5em;
  height: 2.5em;
  border-radius: 0.7em;
  font-size: 0.85rem;
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.collection-overview_addRuleBtn__1EdzG {
  background: var(--main-color);
  color: white;
}

.collection-overview_showRuleBtn__2AOwl {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  color: var(--main-color);
  background: var(--default);
  border: 2px solid var(--main-color);
}

.collection-overview_addRuleBtn__1EdzG:hover {
  background: var(--main-hovered);
}

.collection-overview_showRuleBtn__2AOwl:hover {
  background: var(--main-color);
  color: white;
}

.collection-overview_ruleCount__32-sM {
  height: 24px;
  width: 24px;
  padding-top: 1px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.collection-overview_showRuleBtn__2AOwl:hover .collection-overview_ruleCount__32-sM {
  background: var(--default);
  color: var(--main-color);
}

.collection-overview_ruleCardWrapper__3zBdY {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  z-index: 200000 !important;
}

.collection-overview_fallback__GVa5U {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid var(--outline);
  border-radius: 0.5em;
  min-height: calc(100vh - 9em);
}

.collection-overview_fallback__GVa5U h4 {
  margin-bottom: 1em;
  color: var(--text-dark-300);
}

.collection-overview_fallback__GVa5U img {
  width: 36vw;
  margin-bottom: 1em;
}

.collection-overview_fallback__GVa5U p {
  max-width: 16em;
  color: var(--text-dark-300);
}

.collection-overview_conflictInfo__20swZ {
  font-size: 0.75em;
  color: var(--text-dark-900);
  background: var(--card-info);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 0.75em;
  max-width: 34em;
  margin-left: auto;
}

.collection-overview_hidden__av5Ik {
  display: none;
}

.collection-overview_closeIcon__30KoF {
  fill: var(--icon-color);
  width: 2em;
  height: 2em;
  cursor: var(--cursor-pointer);
}

@media screen and (max-width: 1200px) {
  .collection-overview_container__QTitI {
    min-height: 10em;
  }
}

@media screen and (max-width: 1000px) {
  .collection-overview_fallback__GVa5U img {
    width: 100%;
    max-width: 28em;
  }

  .collection-overview_rules__3MjcY {
    top: 2.9em;
  }
}

@media screen and (max-width: 950px) {
  .collection-overview_rules__3MjcY {
    flex-wrap: wrap-reverse;
  }
  .collection-overview_conflictInfo__20swZ {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .collection-overview_container__QTitI {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .collection-overview_rules__3MjcY {
    width: 100%;
    margin-left: 0;
  }

  .collection-overview_showRuleBtn__2AOwl,
  .collection-overview_addRuleBtn__1EdzG {
    font-size: 0.85rem;
  }

  .collection-overview_fallback__GVa5U img {
    width: 18em;
  }
}

.rulesCard_container__22vR7 {
  width: 100%;
  max-width: 48em;
  color: var(--text-dark-900);
  border-radius: 1em;
  background: var(--default);
  box-shadow: var(--shadow-light);
  padding: 2em;
  background: var(--default);
  position: relative;
  overflow: hidden;
}

.rulesCard_wrapper__2ZN89 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 1em;
  gap: 1em;
  height: 16em;
  overflow: auto;
}

.rulesCard_conflictCard__7w2bn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.25em;
  border: 1px solid var(--outline);
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-radius: 0.5em;
  position: relative;
}

.rulesCard_content__2HSPh {
  width: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  overflow: auto;
}

.rulesCard_conflict__3EjBo {
  font-style: italic;
}

.rulesCard_deleteRule__2dUEf {
  cursor: var(--cursor-pointer);
  border: 1px solid red;
  padding: 0.25em 1em;
  border-radius: 0.7em;
  color: red;
  font-size: 0.75rem;
  position: absolute;
  top: 1em;
  right: 1em;
  background: var(--default);
  transition: all 300ms;
}

.rulesCard_deleteRule__2dUEf:hover {
  border-color: transparent;
}

.rulesCard_innerContent__1If0V {
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 auto;
}

.rulesCard_image__1RSd5 {
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--outline);
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
}

.rulesCard_description__3qrKV {
  width: 100%;
  border: 1px solid var(--outline);
  background: var(--card-bg);
  color: var(--default);
  padding: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.rulesCard_title__wicQH,
.rulesCard_text__1XsF5 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85rem;
  color: var(--default);
}

.rulesCard_btnContainer__2DLO4 {
  width: 100%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 2em;
  gap: 2em;
}

.rulesCard_deleteBtn__3AcDh,
.rulesCard_closeBtn__1JBf8 {
  border-radius: 0.7em;
  padding: 0.65em 2em;
  cursor: var(--cursor-pointer);
  border: 1px solid transparent;
  text-transform: capitalize;
}

.rulesCard_deleteBtn__3AcDh {
  background: red;
  color: white;
  transition: all 300ms;
}

.rulesCard_deleteBtn__3AcDh:hover {
  background: rgb(181, 0, 0);
}

.rulesCard_closeBtn__1JBf8 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.rulesCard_closeBtn__1JBf8 img {
  transition: all 300ms;
}

.rulesCard_closeBtn__1JBf8:hover img {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rulesCard_notification__3MPxy {
  width: 100%;
  text-align: center;
}

.rulesCard_canvas__2PAdO {
  height: 100px;
  width: 100px;
  border-radius: 0.25em;
}

.rulesCard_container__22vR7 > p {
  font-style: italic;
  color: var(--card-bg);
  font-size: 0.85rem;
  margin-left: 0.5em;
  display: inline-block;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

@media screen and (max-width: 480px) {
  .rulesCard_btnContainer__2DLO4 {
    justify-content: flex-start;
    grid-gap: 0.5em;
    gap: 0.5em;
    flex-wrap: wrap;
  }

  .rulesCard_closeBtn__1JBf8,
  .rulesCard_deleteBtn__3AcDh {
    flex: 1 1;
    font-size: 0.75em;
    padding: 0.5em 1em;
  }
}

.create_container__3vOZw {
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  padding-bottom: 0;
  position: relative;
  margin: 0 auto;
  margin-bottom: 8em;
}

.create_wrapper__yF3Lt {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  position: relative;
}

.create_details__22qTu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}

.create_profileContainer__24rZQ {
  z-index: 18;
  display: none;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.create_profileContainer__24rZQ.create_active__1hvwc {
  display: flex;
}

.create_signIn__PTBl9 {
  display: none;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  padding: 0.25em 1em;
  border-radius: 0.7em;
  transition: all 300ms;
  cursor: pointer;
  position: relative;
}

.create_signIn__PTBl9.create_active__1hvwc {
  display: block;
}

.create_signIn__PTBl9:hover {
  background: var(--main-color);
  color: var(--default);
}

.create_overlayer__17eTN {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 2.5em;
}

.create_overlayer__17eTN.create_active__1hvwc {
  display: flex;
}

.create_loadingIcon__1u7_l {
  width: 1em;
  height: 1em;
  -webkit-animation: create_rotate__v8x-H 650ms infinite linear;
          animation: create_rotate__v8x-H 650ms infinite linear;
  fill: var(--main-color);
}

@-webkit-keyframes create_rotate__v8x-H {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes create_rotate__v8x-H {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.create_autoSave__1s1WS,
.create_profile__1Okhp {
  background: var(--default);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  border-radius: 0.7em;
  padding: 0.25em 0.25em;
  padding-right: 0.75em;
  transition: all 300ms;
  cursor: pointer;
  position: relative;
}

.create_diskIcon__L0ZGM {
  width: 1.2em;
  height: 1.2em;
}

.create_profile__1Okhp.create_active__1hvwc {
  border: 1px solid var(--main-color);
}

.create_dropdownIconContainer__300Jx {
  border-left: 1px solid var(--outline);
  padding: 0 0.25em;
}

.create_dropdownIcon__2ZjMc {
  fill: var(--main-color);
}

.create_collectionNameContainer__Jwan3 {
  max-width: 6em;
  overflow: hidden;
}

.create_collectionName__102O- {
  display: none;
}

.create_collectionName__102O-.create_active__1hvwc {
  width: -webkit-max-content;
  width: max-content;
  display: inline-flex;
  color: var(--main-color);
  white-space: nowrap;
  overflow: auto;
  font-weight: 600;
}

.create_collectionName__102O-.create_move__g6r1c {
  -webkit-animation: create_move__g6r1c 5s infinite alternate linear;
          animation: create_move__g6r1c 5s infinite alternate linear;
}

@-webkit-keyframes create_move__g6r1c {
  0%,
  20% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  80%,
  100% {
    -webkit-transform: translateX(calc(-100% + 6em));
            transform: translateX(calc(-100% + 6em));
  }
}

@keyframes create_move__g6r1c {
  0%,
  20% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  80%,
  100% {
    -webkit-transform: translateX(calc(-100% + 6em));
            transform: translateX(calc(-100% + 6em));
  }
}

.create_autoSave__1s1WS {
  background: var(--main-color);
  box-shadow: var(--btn-open);
  font-size: 0.85rem;
  border: 1px solid transparent;
  padding: 0.5em;
}

.create_autoSave__1s1WS:hover {
  box-shadow: var(--btn-close);
}

.create_autoSave__1s1WS * {
  color: var(--default);
}

.create_profile__1Okhp * {
  color: var(--text-heading);
}

.create_layer_overview__3zpq8 {
  width: calc(100% - 22em);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 2em;
  gap: 2em;
}

.create_icon__3Rd57 {
  width: 40%;
}

.create_guide__wAypv {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.75rem;
}

.create_guide__wAypv > *:nth-child(2) {
  color: var(--main-color);
  border: 1px solid var(--outline);
  border-radius: 1em;
  box-shadow: var(--shadow-light-3);
  padding: 0.5em;
  cursor: var(--cursor-pointer);
}

.create_guide__wAypv > *:nth-child(3) {
  color: var(--main-color);
  cursor: var(--cursor-pointer);
}

.create_guide__wAypv > *:nth-child(2):hover,
.create_guide__wAypv > *:nth-child(3):hover {
  color: var(--main-hovered);
}

@media screen and (max-width: 1000px) {
  .create_wrapper__yF3Lt {
    flex-direction: column;
    margin-bottom: 6em;
  }

  .create_layer_overview__3zpq8 {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .create_layer_overview__3zpq8 {
    flex-direction: column;
  }

  .create_guide__wAypv {
    display: none;
  }
}

.CollectionNameModal_container__UR2ez {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.CollectionNameModal_container__UR2ez.CollectionNameModal_active__2QD3E {
  display: flex;
}

.CollectionNameModal_wrapper__13Vzp {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  position: relative;
  -webkit-animation: CollectionNameModal_show__1Ni0_ 300ms alternate;
          animation: CollectionNameModal_show__1Ni0_ 300ms alternate;
}

@-webkit-keyframes CollectionNameModal_show__1Ni0_ {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes CollectionNameModal_show__1Ni0_ {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.CollectionNameModal_closeBtn__1CyIF {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.CollectionNameModal_closeBtn__1CyIF:hover {
  box-shadow: var(--shadow-dark);
}

.CollectionNameModal_content__1Ak_j {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em 4em;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
}

.CollectionNameModal_content__1Ak_j h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.CollectionNameModal_content__1Ak_j h6 {
  font-size: 0.85rem;
  font-weight: 600;
}

.CollectionNameModal_content__1Ak_j p {
  font-size: 0.85rem;
  color: var(--outline-dark);
  margin-bottom: 1.5em;
}

.CollectionNameModal_inputContainer__3XC-t {
  width: 100%;
  position: relative;
  margin-bottom: 1.5em;
}

.CollectionNameModal_inputContainer__3XC-t label {
  position: absolute;
  font-size: 0.75rem;
  background: var(--default);
  top: 0;
  left: 1em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: 600;
}

.CollectionNameModal_inputContainer__3XC-t input {
  width: 100%;
  padding: 0.5em;
  padding-top: 1em;
  border: 2px solid var(--outline);
  border-radius: 0.25em;
}

.CollectionNameModal_inputContainer__3XC-t input:focus {
  border: 2px solid var(--main-color);
  outline: none;
}

.CollectionNameModal_inputContainer__3XC-t input::-webkit-input-placeholder {
  color: var(--text-placeholder-light);
}

.CollectionNameModal_inputContainer__3XC-t input:-ms-input-placeholder {
  color: var(--text-placeholder-light);
}

.CollectionNameModal_inputContainer__3XC-t input::placeholder {
  color: var(--text-placeholder-light);
}

.CollectionNameModal_content__1Ak_j button {
  align-self: center;
  width: 100%;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  background: var(--main-disable);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: default;
}

.CollectionNameModal_content__1Ak_j button.CollectionNameModal_active__2QD3E {
  background: var(--main-color);
  cursor: pointer;
}

.CollectionNameModal_content__1Ak_j button.CollectionNameModal_active__2QD3E:hover {
  background: var(--main-hovered);
}

.CollectionNameModal_errorMessage__3GmWe {
  margin-top: -3em;
  margin-bottom: 1em;
  font-size: 0.75rem;
  display: none;
  transition: all 300;
}

.CollectionNameModal_errorMessage__3GmWe.CollectionNameModal_error__3lQ7k {
  display: inline-flex;
  color: var(--error-500);
}

@media screen and (max-width: 768px) {
  .CollectionNameModal_content__1Ak_j {
    padding: 2em;
  }
}

.create-guide_wrapper__1hjaR {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background: rgb(0, 0, 0, 0.7);
}

.create-guide_wrapper__1hjaR.create-guide_active__3QgFO {
  display: flex;
}

.create-guide_mainContainer__3qrsI {
  width: 100%;
  max-width: -webkit-max-content;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 2em 3em;
  border-radius: 1em;
  position: relative;
  background: var(--main-color);
}

.create-guide_container__EO9jB {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.create-guide_closeBtn__17DYs {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: var(--cursor-pointer);
  fill: var(--default);
  padding: 0.35em;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.25em;
}

.create-guide_closeBtn__17DYs:hover {
  box-shadow: var(--shadow-light);
}

.create-guide_heading__32XK3 {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  color: var(--default);
}

.create-guide_swipeContainer__2NlZ_ {
  width: 36em;
  overflow: auto;
  z-index: 1;
}

.create-guide_swipeContainer__2NlZ_.create-guide_active__3QgFO {
  -webkit-animation: create-guide_zoom__s5IF0 650ms forwards;
          animation: create-guide_zoom__s5IF0 650ms forwards;
}

@-webkit-keyframes create-guide_zoom__s5IF0 {
  from {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  to {
    transition: scale(1);
  }
}

@keyframes create-guide_zoom__s5IF0 {
  from {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  to {
    transition: scale(1);
  }
}

.create-guide_cardContainer__jnisC {
  width: -webkit-max-content;
  width: max-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 650ms;
}

.create-guide_card__8WirF {
  width: 32em;
  flex: 0 0 auto;
  height: 100%;
  background: var(--default);
}

.create-guide_control__KXiyy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
}

.create-guide_control__KXiyy .create-guide_right__1LH7D,
.create-guide_control__KXiyy .create-guide_left__2byPy {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: var(--cursor-pointer);
  visibility: hidden;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.5em;
  transition: all 300ms;
}

.create-guide_control__KXiyy .create-guide_right__1LH7D.create-guide_active__3QgFO,
.create-guide_control__KXiyy .create-guide_left__2byPy.create-guide_active__3QgFO {
  visibility: visible;
}

.create-guide_control__KXiyy .create-guide_right__1LH7D:hover,
.create-guide_control__KXiyy .create-guide_left__2byPy:hover {
  background: rgba(255, 255, 255, 1);
}

/* hide scrollbar */
.create-guide_swipeContainer__2NlZ_::-webkit-scrollbar {
  display: none;
}

.create-guide_swipeContainer__2NlZ_ {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.create-guide_rightSideCard__rEoL5,
.create-guide_leftSideCard__3Q0QB {
  height: auto;
  width: 4em;
  background: transparent;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  overflow: hidden;
  display: none;
}

.create-guide_rightSideCard__rEoL5.create-guide_hide__35m48,
.create-guide_leftSideCard__3Q0QB.create-guide_hide__35m48 {
  display: none;
}

.create-guide_rightSideCard__rEoL5.create-guide__2__396KP,
.create-guide_leftSideCard__3Q0QB.create-guide__2__396KP {
  width: 2em;
}

.create-guide_leftSideCard__3Q0QB.create-guide_active__3QgFO {
  -webkit-animation: create-guide_slideLeft__1JYEV 650ms forwards;
          animation: create-guide_slideLeft__1JYEV 650ms forwards;
}

@-webkit-keyframes create-guide_slideLeft__1JYEV {
  from {
    -webkit-transform: translateX(2em) scale(1.05);
            transform: translateX(2em) scale(1.05);
  }
  to {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

@keyframes create-guide_slideLeft__1JYEV {
  from {
    -webkit-transform: translateX(2em) scale(1.05);
            transform: translateX(2em) scale(1.05);
  }
  to {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

.create-guide_rightSideCard__rEoL5.create-guide_active__3QgFO {
  -webkit-animation: create-guide_slideRight__2N_WE 650ms forwards;
          animation: create-guide_slideRight__2N_WE 650ms forwards;
}

@-webkit-keyframes create-guide_slideRight__2N_WE {
  from {
    -webkit-transform: translateX(-2em) scale(1.05);
            transform: translateX(-2em) scale(1.05);
  }
  to {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

@keyframes create-guide_slideRight__2N_WE {
  from {
    -webkit-transform: translateX(-2em) scale(1.05);
            transform: translateX(-2em) scale(1.05);
  }
  to {
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

.create-guide_indicatorContainer__JK3_q {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.create-guide_indicator__2RubT {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 2px;
  transition: all 1s;
}

.create-guide_indicator__2RubT.create-guide_active__3QgFO {
  border-color: white;
}

.create-guide_indicator__2RubT > div {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.create-guide_indicator__2RubT.create-guide_active__3QgFO > div {
  background: var(--default);
}

@media screen and (max-width: 1000px) {
  .create-guide_mainContainer__3qrsI {
    grid-gap: 1em;
    gap: 1em;
  }

  .create-guide_heading__32XK3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .create-guide_leftSideCard__3Q0QB,
  .create-guide_rightSideCard__rEoL5 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .create-guide_wrapper__1hjaR {
    padding: 0.5em;
  }

  .create-guide_mainContainer__3qrsI {
    border-radius: 0.5em;
    padding: 1em;
  }

  .create-guide_heading__32XK3 {
    font-size: 0.85rem;
  }

  .create-guide_heading__32XK3 span {
    display: none;
  }

  .create-guide_indicatorContainer__JK3_q {
    grid-gap: 0;
    gap: 0;
  }

  .create-guide_indicator__2RubT {
    padding: 1px;
  }

  .create-guide_control__KXiyy .create-guide_right__1LH7D,
  .create-guide_control__KXiyy .create-guide_left__2byPy {
    width: 2em;
  }
}

.googleAuth_signIn__3_48t {
  cursor: pointer;
  color: var(--main-color) !important;
}

.googleAuth_profileContainer__1zJU5 {
  position: relative;
}

.googleAuth_profileIcon__3woct {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--default) !important;
  background: var(--main-light);
  cursor: pointer;
}

.profileDropdown_container__KSGqD {
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  left: 0;
  top: 2em;
  z-index: 10;
  visibility: hidden;
}

.profileDropdown_container__KSGqD.profileDropdown_active__1dgG- {
  -webkit-animation: profileDropdown_drop__2Qkj1 300ms forwards;
          animation: profileDropdown_drop__2Qkj1 300ms forwards;
  visibility: visible;
}

@-webkit-keyframes profileDropdown_drop__2Qkj1 {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes profileDropdown_drop__2Qkj1 {
  from {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.profileDropdown_wrapper__3xMjU {
  width: 16em;
  border: 1px solid var(--card-outline);
  overflow: hidden;
  margin-top: 1em;
  background: var(--default);
  border-radius: 0.5em;
}

.profileDropdown_name__3NCrU {
  width: 100%;
  border: 1px solid var(--outline);
  padding: 0.75em;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-heading);
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profileDropdown_option__2CICH {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  cursor: pointer;
}

.profileDropdown_option__2CICH * {
  color: var(--text-sub-heading);
}

.profileDropdown_option__2CICH:hover {
  background: var(--card-light);
}

.profileDropdown_icon__BuX7y {
  width: 1em;
  height: 1em;
}

.ProgressBar_container__3Cu6Y {
  color: var(--main-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.85rem;
}

.Pricing_container__18suV {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 6em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Pricing_heading__342VM {
  width: 100%;
  text-align: center;
  margin-bottom: 3em;
}

.Pricing_heading__342VM h1 {
  margin-bottom: 0.5em;
}

.Pricing_cardMenu__qCQND {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 2em;
  gap: 2em;
  justify-items: center;
}

.Pricing_wrapper__ahcHu {
  border-radius: 1rem;
}
.Pricing_wrapper__ahcHu.Pricing_active__17sCY {
  background: var(--main-color);
}

.Pricing_card__1WjQo {
  width: auto;
  max-width: 19em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
  padding: 2em;
  border-radius: 1em;
  border: 1px solid #e5e8eb;
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.1);
}

.Pricing_type__2gnY2 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
}

.Pricing_description__2h8wo {
  font-weight: 600;
  text-align: center;
}

.Pricing_price__37YOU {
  font-size: 3rem;
  font-weight: 700;
}

.Pricing_price__37YOU span {
  display: inline-block;
  font-size: 1rem;
  margin-top: 1em;
}

.Pricing_services__2P-ZK {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 1em;
}

.Pricing_service__fP0v_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  font-size: 0.85rem;
}

.Pricing_closeIcon__2EQG3 {
  fill: black;
}

.Pricing_markIcon__3yhRV {
  fill: var(--main-color);
}

.Pricing_subscribeBtn__1IeQE {
  width: 10em;
  text-align: center;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  color: var(--main-color);
  background: var(--default);
  border: 1px solid var(--main-color);
  cursor: pointer;
  transition: all 300ms;
}

.Pricing_subscribeBtn__1IeQE:hover {
  background: var(--main-color);
  color: var(--default);
}

.Pricing_subscribeBtn__1IeQE.Pricing_active__17sCY {
  background: var(--main-color);
  color: var(--default);
}

.Pricing_subscribeBtn__1IeQE.Pricing_active__17sCY:hover {
  background: var(--main-hovered);
  color: var(--default);
}

.Pricing_subscribeBtn__1IeQE.Pricing_disabled__VGyrw {
  color: var(--text-placeholder);
  border: 1px solid var(--card-outline);
}

.Pricing_subscribeBtn__1IeQE.Pricing_disabled__VGyrw:hover {
  color: var(--default);
  background: var(--card-bg);
  border-color: transparent;
}

.Pricing_mark__2KtpT {
  text-align: center;
  color: var(--default);
  padding: 0.5em;
}

.PricingModal_container__p7LS0 {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.PricingModal_container__p7LS0.PricingModal_active__2vILg {
  display: flex;
}

.PricingModal_wrapper__XWm_a {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  position: relative;
  -webkit-animation: PricingModal_show__1VBEX 300ms alternate;
          animation: PricingModal_show__1VBEX 300ms alternate;
}

@-webkit-keyframes PricingModal_show__1VBEX {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes PricingModal_show__1VBEX {
  from {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.PricingModal_closeBtn__3kWdY {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.PricingModal_closeBtn__3kWdY:hover {
  box-shadow: var(--shadow-dark);
}

.PricingModal_content__3C8B9 {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 4em;
  gap: 4em;
  padding: 2em 4em;
  border-radius: 0.5em;
}

.PricingModal_content__3C8B9 h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.PricingModal_methodContainer__2VOK6 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.PricingModal_stripeBtn__FTBfW {
  border: 1px solid var(--main-color);
  width: 10em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.PricingModal_stripeIcon__3z8OE {
  width: auto;
  height: 1.5em;
}

.PricingModal_content__3C8B9 button {
  align-self: center;
  width: 80%;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: var(--btn-open);
}

.PricingModal_content__3C8B9 button:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

@media screen and (max-width: 1024px) {
  .PricingModal_wrapper__XWm_a {
    max-width: 36em;
  }

  .PricingModal_content__3C8B9 {
    padding: 2em;
    grid-gap: 3em;
    gap: 3em;
  }

  .PricingModal_content__3C8B9 h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .PricingModal_wrapper__XWm_a {
    padding: 2em;
  }

  .PricingModal_content__3C8B9 {
    grid-gap: 2em;
    gap: 2em;
    padding: 1em 2em;
  }

  .PricingModal_content__3C8B9 h1 {
    font-size: 1.2rem;
  }
}

.links_pageOverlay__1gI7H {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000;
  inset: 0px;
  top: 0;
  display: flex;
}
.links_container__191Zu {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 12px;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.links_wrapper__PiqR0 {
  margin: 0px auto;
  height: 100%;
  width: 100%;
  max-width: 680px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.links_header__2K3qN {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 32px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.links_logo__AH9ox {
  border-radius: 50%;
  width: 96px;
  height: 96px;
  margin-bottom: 16px;
  overflow: hidden;
}

.links_logo__AH9ox img {
  width: 100%;
  height: 100%;
}
.links_title__1U1pt {
  white-space: nowrap;
  font-weight: 600;
  line-height: 1.5;
}
.links_description__2lfyL {
  line-height: 1.5;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
.links_links__TPbXZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding-bottom: 80px;
}
.links_link__2qQ_M {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow-wrap: break-word;
  word-break: break-word;
  padding-left: 66px;
  padding-right: 66px;
  min-height: 56px;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgb(10 11 13 / 8%) 0px 2px 4px 0px;
  transition: -webkit-transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
  transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
  transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s, -webkit-transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
}
.links_link__2qQ_M:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.links_link__2qQ_M img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 4px;
  width: 48px;
  height: 48px;
}
.links_link__2qQ_M div {
  line-height: 1.5;
  font-weight: 500;
  font-size: 14px;
  color: rgb(0, 0, 0);
}
.links_topBar__3CE-t {
  display: flex;
  width: calc(100% - 12px);
  left: 0px;
  right: 0px;
  grid-template-columns: 1fr auto 1fr;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  position: fixed;
  margin: 0px auto;
  height: 64px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 10;
  top: 0px;
  justify-content: flex-end;
}
.links_topBar__3CE-t.links_active__3CUXI {
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
  border: 1px solid rgb(235, 238, 241);
  border-radius: 72px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background 100ms ease 0s, -webkit-transform 100ms ease 0s, -webkit-backdrop-filter 1ms ease 0s;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  justify-content: space-between;
}
.links_shareBtn__qCehA {
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(240, 240, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 100ms ease;
}
.links_shareBtn__qCehA svg {
  width: 1rem;
  width: 1rem;
  fill: rgb(0, 0, 0);
}
.links_shareBtn__qCehA svg path {
  fill: rgb(0, 0, 0);
}
.links_shareBtn__qCehA:hover {
  background-color: rgb(231, 231, 231);
  /* background-color: rgb(39, 39, 39); */
}
.links_topBar__3CE-t.links_active__3CUXI .links_shareBtn__qCehA {
  border: 1px solid black;
  background-color: black;
}
.links_topBar__3CE-t.links_active__3CUXI .links_shareBtn__qCehA svg path {
  fill: rgb(251, 251, 251);
}
.links_topBar__3CE-t.links_active__3CUXI .links_shareBtn__qCehA:hover {
  background-color: rgb(39, 39, 39);
}
.links_topLogo__23Gqs {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: none;
}
.links_topLogo__23Gqs img {
  width: 100%;
}

.links_headerTop__FC-tq {
  font-weight: 600;
  font-size: 16px;
  color: black;
  display: none;
}
.links_topBar__3CE-t.links_active__3CUXI .links_topLogo__23Gqs,
.links_topBar__3CE-t.links_active__3CUXI .links_headerTop__FC-tq {
  display: block;
}
.links_copyright__Sr22f {
  font-weight: 600;
  text-align: center;
}
.links_bg__2sEnm {
  position: fixed;
  inset: 0px;
  z-index: 0;
}
.links_bg__2sEnm div {
  width: 100%;
  height: 100%;
  position: relative;
}
.links_bg__2sEnm div::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background-image: url(https://d1fdloi71mui9q.cloudfront.net/6ERzInNWR4CsQAywumpx_O5pXdojFkm3aBjxf);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.25;
  -webkit-filter: blur(50px);
          filter: blur(50px);
}
.links_bg__2sEnm div::after {
  content: "";
  position: fixed;
  width: 100%;
  top: 0px;
  height: 100%;
  background-image: url(https://assets.production.linktr.ee/profiles/_next/static/images/img-noise-256x256-fb8404a….png);
  background-repeat: repeat;
  opacity: 0.05;
  mix-blend-mode: overlay;
}
@media (min-width: 768px) {
  .links_topBar__3CE-t {
    max-width: calc(764px);
  }
}

.searchResult_container__2w0mO {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 2em;
  border-radius: 1em;
  background: var(--default);
  display: flex;
  flex-direction: column;
}
.searchResult_title__3Z-cC {
  display: flex;
  align-items: baseline;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.searchResult_keyword__1144L {
  font-weight: 600;
  font-size: 1.625rem;
}
.searchResult_searchCount__Zw1g8 {
  font-weight: 600;
  font-size: 0.875rem;
  color: #747c90;
}
.searchResult_header__jof-u {
  display: flex;
  align-items: center;
  margin-top: 5rem;
  grid-gap: 2rem;
  gap: 2rem;
}
.searchResult_tabs__8aUFY {
  display: flex;
  margin-right: auto;
  grid-gap: 2rem;
  gap: 2rem;
}
.searchResult_tabs__8aUFY > div {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5em;
  color: #8c93a3;
  padding: 0 1rem 0.6rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: 300ms ease;
}
.searchResult_tabs__8aUFY > div:hover {
  border-color: var(--main-color);
}
.searchResult_tabs__8aUFY > div.searchResult_active__2A-Vz {
  color: var(--main-color);
  border-color: var(--main-color);
}
.searchResult_wrapper__1I4vb {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 1em;
  gap: 1em;
  margin-top: 2.5em;
}
.searchResult_wrapper__1I4vb a {
  width: 100%;
}
.searchResult_skeleton__1Jy9n {
  margin-top: 2.5em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  gap: 1em;
  z-index: 0;
}

.searchResult_skeleton__1Jy9n * {
  z-index: 0;
}

.searchResult_noResult__1PvVq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10em;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  .searchResult_wrapper__1I4vb,
  .searchResult_skeleton__1Jy9n {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .searchResult_wrapper__1I4vb,
  .searchResult_skeleton__1Jy9n {
    grid-template-columns: repeat(2, 1fr);
  }
  .searchResult_tabs__8aUFY {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .searchResult_tabs__8aUFY > div {
    font-size: 1.25rem;
    padding: 0 0.5em 0.1em;
  }
}

@media screen and (max-width: 680px) {
  .searchResult_wrapper__1I4vb,
  .searchResult_skeleton__1Jy9n {
    grid-template-columns: repeat(1, 1fr);
  }
  .searchResult_container__2w0mO {
    padding: 2em 1em;
  }
  .searchResult_header__jof-u {
    flex-wrap: wrap;
  }
  .searchResult_tabs__8aUFY {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .searchResult_tabs__8aUFY > div {
    font-size: 0.875rem;
  }
}

.collectionsCard_card__2f4Kf {
  width: 100%;
  border-radius: 0.5em;
  background: var(--default);
  transition: all 250ms;
  border: 1px solid var(--outline);
  overflow: hidden;
  max-width: 32em;
}

.collectionsCard_card__2f4Kf:hover {
  box-shadow: var(--shadow-light);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  cursor: var(--cursor-pointer);
}

.collectionsCard_imageContainer__5pbEJ {
  background: rgba(0, 0, 0, 0.05);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 16em;
}

.collectionsCard_body__2Zgbr {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5em;
  padding-top: 3em;
  padding-bottom: 1em;
}

.collectionsCard_thumbnail__IxMgn {
  width: 5em;
  height: 5em;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--default);
  box-shadow: var(--shadow-light);
}

.collectionsCard_thumbnail__IxMgn img {
  width: 100%;
  border-radius: 50%;
}

.collectionsCard_name__3BaEe {
  margin-bottom: 1em;
}

.collectionsCard_description__12WxB {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 1em;
  padding: 0 1em;
  min-height: 1.5em;
}

.collectionsCard_usdPrice__bbKIx {
  font-size: 0.7rem;
  font-weight: lighter;
}

.collectionsCard_wrapper__1CZ9b {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collectionsCard_notListedWrap__1TJi4 {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}

.collectionsCard_notListed__3BXcs {
  margin-left: 10px;
  background-color: var(--outline);
  padding: 5px 15px;
  border-radius: 16px;
}
.collectionsCard_notListed__3BXcs span {
  font-size: 14px;
  color: black;
}

.collectionsCard_floor__15l35 {
  font-size: 0.65em;
  font-weight: bold;
  color: rgba(173, 181, 189, 1);
}

.collectionsCard_price__1RdRM {
  color: var(--main-color);
  font-weight: bold;
}

.collectionsCard_priceImg__2fo3a {
  width: 25px;
}

.collectionsCard_price__1RdRM img {
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  margin-right: 10px;
  width: 30px;
}

.collectionsCard_chain__1aX1U {
  font-weight: 300;
  font-size: 0.85em;
}

.collectionsCard_nftCount__3r0Pj {
  padding: 0.5em 1.5em;
  background: var(--main-color);
  color: white;
  border-radius: 0.7em;
  font-size: 0.85rem;
}

.NftCard_card__2Bqz5 {
  width: 100%;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
  transition: all 300ms;
  max-width: 32em;
  height: 28rem;
}

.NftCard_card__2Bqz5:hover {
  cursor: var(--cursor-pointer);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: var(--shadow-dark);
}

.NftCard_imageContainer__3S1kS {
  width: 100%;
  padding: 0.2em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16em;
}

.NftCard_creator__33B4b {
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
}
.NftCard_creator__33B4b img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
}
.NftCard_creatoAddress__3bCdd {
  flex-direction: column;
}

.NftCard_address__1SbsY {
  color: var(--main-color);
}

.NftCard_imageContainer__3S1kS img {
  object-fit: cover;
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
}

.NftCard_cardBody__1CN7Y {
  width: 100%;
  padding: 0.5em;
  margin-top: 2px;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.NftCard_collectionName__38DnE {
  margin-bottom: 0.5em;
  overflow: auto;
}
.NftCard_listPrice__1kjLO {
  margin-top: 15px;
}
.NftCard_name__HyolQ {
  margin-bottom: 1em;
  overflow: auto;
}

.NftCard_usdPrice__1EXAj {
  font-size: 0.7rem;
  font-weight: lighter;
}

.NftCard_wrapper__2cA3U {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.NftCard_list__31eJ8 {
  font-weight: 500;
  color: gray;
  font-size: 0.75rem;
}

.NftCard_price__39DAu {
  color: var(--main-color);
  font-weight: bold;
}
.NftCard_price__39DAu img {
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  margin-right: 10px;
  width: 25px;
}
.NftCard_chain__2IV3I {
  font-size: 0.75rem;
}

.NftCard_notListed__2neFQ {
  font-size: 1rem;
  padding: 0.65em 1em;
  margin-top: 1.5em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.NftCard_button__35loH {
  font-size: 1rem;
  padding: 0.65em 1.5em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.NftCard_button__35loH:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}
.NftCard_buttonSold__2feIZ {
  background-color: #707a83;
  cursor: var(--cursor-default);
}
.NftCard_buttonSold__2feIZ:hover {
  cursor: var(--cursor-hover);
  background: #707a83;
  outline: none;
}

.Brand_container__n0cJD {
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding: 2em;
  overflow: hidden;
}

.Brand_title__aWZpX {
  color: #0d99ff;
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 120%;
}
.Brand_title__aWZpX span {
  color: var(--text-dark-600);
}
.Brand_description__YQfdJ {
  font-size: 1.125rem;
  margin-top: 1.7em;
  line-height: 115%;
  max-width: 670px;
  color: var(--text-dark-600);
}
.Brand_downloadBtnWrapper__17tUE {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 8.5rem;
}
.Brand_downloadBtnWrapper__17tUE a {
  color: var(--main-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 160%;
  border: 2px solid #0d99ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5em;
  height: 3.625em;
  cursor: pointer;
  transition: 300ms ease;
}
.Brand_downloadBtnWrapper__17tUE a:hover {
  background: var(--main-color);
  color: var(--default);
}
.Brand_topBg__g1zdd {
  position: absolute;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  right: 0;
  width: 50%;
  z-index: -1;
}
.Brand_topBg__g1zdd svg {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -25%;
}
.Brand_subHeader__1Y7D5 {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 120%;
  color: var(--text-dark-600);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-right: 1.8rem;
  position: relative;
  padding-bottom: 12px;
}
.Brand_subHeader__1Y7D5 p {
  background: var(--main-color);
  border-radius: 11px;
  font-size: 1rem;
  height: 0.5em;
  width: 66px;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}
.Brand_logoWrapper__3UVAR {
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 774px;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 8.5rem;
}
.Brand_logoWrapper__3UVAR > div {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.Brand_logoWrapper__3UVAR > div p {
  color: var(--text-dark-600);
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 115%;
}
.Brand_logoWrapper__3UVAR > div svg:first-child {
  display: flex;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: 300ms ease-in;
}
.Brand_logoWrapper__3UVAR > div svg:last-child {
  position: absolute;
  width: 30%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
}
.Brand_logoWrapper__3UVAR > div svg:last-child path {
  fill: inherit;
}
.Brand_logoWrapper__3UVAR > div a {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
}
.Brand_logoWrapper__3UVAR > div a:hover svg:first-child path {
  opacity: 0;
}
.Brand_logoWrapper__3UVAR > div a:hover svg:last-child {
  opacity: 1;
}

.Brand_colorSubHeader__21i1g {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 115%;
  color: var(--text-dark-600);
  margin: 4rem 0 1rem;
}
.Brand_colorPalaett__1P4HE {
  display: flex;
  flex-wrap: wrap;
  max-width: 1016px;
}
.Brand_colorPalaett__1P4HE div {
  position: relative;
  width: 12.5%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.Brand_colorPalaett__1P4HE div p {
  color: inherit;
  transition: 300ms ease;
}
.Brand_colorPalaett__1P4HE div svg {
  position: absolute;
  height: 40%;
  width: auto;
  fill: var(--default);
  opacity: 0;
  transition: 300ms ease;
}

.Brand_colorPalaett__1P4HE div:hover svg {
  opacity: 1;
}
.Brand_colorPalaett__1P4HE div:hover p {
  opacity: 0;
}
.Brand_colorPalaett__1P4HE div svg path {
  fill: inherit;
}
.Brand_colorPalaett__1P4HE div:first-child {
  width: 100%;
  justify-content: flex-start;
  padding-left: 29px;
}
.Brand_whiteColorPalaett__3EpTb div:first-child {
  color: var(--default);
}
.Brand_colorPalaett__1P4HE div:last-child {
  color: var(--text-dark-600);
}
.Brand_colorPalaett__1P4HE div:last-child svg {
  fill: var(--text-dark-600);
}
.Brand_darkColorPalaett__1022d div:last-child {
  color: var(--default);
}
.Brand_darkColorPalaett__1022d div:last-child svg {
  fill: var(--default);
}
.Brand_whiteColorPalaett__3EpTb div {
  color: var(--text-dark-600);
}
.Brand_whiteColorPalaett__3EpTb {
  margin-bottom: 8.5rem;
}
.Brand_whiteColorPalaett__3EpTb div svg {
  fill: var(--text-dark-600);
}
.Brand_fontsDownload__30c2R {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.Brand_fonts__3ML7y {
  max-width: 1016px;
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.Brand_fonts__3ML7y > div {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.Brand_fonts__3ML7y > div p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 118%;
  color: var(--text-dark-600);
}
.Brand_fonts__3ML7y > div div {
  font-size: 1.25rem;
  line-height: 118%;
  color: var(--text-dark-600);
}
@media screen and (max-width: 785px) {
  .Brand_colorPalaett__1P4HE div {
    width: 20%;
  }
}
@media screen and (max-width: 480px) {
  .Brand_logoWrapper__3UVAR {
    justify-content: center;
  }
  .Brand_colorPalaett__1P4HE div {
    width: 25%;
  }
}
@media screen and (max-width: 380px) {
  .Brand_colorPalaett__1P4HE div {
    width: calc(100% / 3);
  }
}

.Attributes_container__5j7Co {
  width: 100%;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 1em;
}

.Attributes_heading__3Yk_O {
  font-weight: 600;
  margin-bottom: 0.5em;
  padding: 0.5em;
  font-weight: 600;
  font-size: 1.5rem;
}

.Attributes_attributes__3CYv8 {
  padding: 1em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  gap: 1em;
}

.Attributes_attribute__1GV3F {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  border-radius: 0.5em;
  background: #fafafb;
  border: 1px solid #86ccff;
  border-radius: 10.6849px;
}

.Attributes_title__19onQ {
  color: var(--text-placeholder);
}

.Attributes_value__MuWJ9 {
  font-weight: 600;
  max-width: 10em;
  overflow: auto;
}

@media screen and (max-width: 1200px) {
  .Attributes_rarity__EUybX,
  .Attributes_type__3POks {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 540px) {
  .Attributes_rarity__EUybX,
  .Attributes_type__3POks {
    font-size: 0.65rem;
  }

  .Attributes_attributes__3CYv8 {
    padding: 0.5em;
    /* grid-template-columns: auto; */
  }

  .Attributes_attribute__1GV3F {
    padding: 0.5em;
    border-radius: 0.5em;
  }
}

.Deals_container__3SrTW {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em 1em;
  border-radius: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 3.5em;
  background: var(--default);
}

.Deals_chainIcon__2CWox {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.Deals_lock__1pCTe {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: not-allowed;
  justify-content: space-around;
  background-color: #B2B7C2;
  border-radius: 6.5px;
  padding: 15px;
}
.Deals_lock__1pCTe > img {
  margin-right: 6px;
  width: 31px;
}
.Deals_lock__1pCTe > span {
  font-size: 22px;
  color: white;
}

.Deals_title__1Arau {
  margin-bottom: 0.5em;
  color: var(--text-placeholder);
}

.Deals_priceSection__3Srto {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.Deals_price__EP7JH {
  font-weight: 600;
  font-size: 1.2rem;
}

.Deals_appx__3uJRh {
  color: var(--text-placeholder);
}

.Deals_btn__2HDt4 {
  padding: 0.5em 1.5em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  border: 1px solid var(--main-color);
  cursor: pointer;
  transition: all 300ms;
  text-align: center;
  width: 8em;
  font-size: 1.2em;
}

.Deals_btn__2HDt4:hover {
  background: var(--default);
  color: var(--main-color);
}

.Deals_btn__2HDt4.Deals_disable__17zax {
  background: var(--outline-dark);
  cursor: not-allowed;
  border: 1px solid var(--outline-dark);
}

.Deals_btn__2HDt4.Deals_disable__17zax:hover {
  color: var(--outline);
}

.Deals_sea__1z1tT {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Deals_opensea__3e8LM {
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.Deals_opensea__3e8LM:hover {
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .Deals_btn__2HDt4 {
    padding: 0.5em 1.5em;
  }
}

@media screen and (max-width: 540px) {
  .Deals_container__3SrTW {
    top: 3em;
  }
  .Deals_btn__2HDt4 {
    padding: 0.5em !important;
    width: 6em;
  }
  .Deals_lock__1pCTe {
    padding: 10px;
  }

  .Deals_lock__1pCTe span {
    font-size: 16px;
  }
  .Deals_lock__1pCTe img {
    width: 25px;
  }
}

.Description_container__1Qryo {
  width: 100%;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
}

.Description_heading__2g6qZ {
  padding: 0.5em;
  font-weight: 600;
  font-size: 1.5rem;
}

.Description_content__2LQcq {
  padding: 1em;
}

.Description_content__2LQcq > div {
  word-wrap: break-word;
}

.Description_showBtn__B7rpi {
  color: var(--main-color);
  cursor: pointer;
  transition: color 300ms;
}

.Description_showBtn__B7rpi:hover {
  color: var(--main-hoverd);
}

.Details_container__rb5MA {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 1em;
  padding: 1em;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 1em;
}

.Details_heading__13A_8 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Details_list__71Cm1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Details_list__71Cm1 * {
  color: #525c76;
}

.Details_value__1AWd7 {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: color 300ms;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.Details_value__1AWd7 svg {
  width: 1em;
}

.Details_total__1HFTp {
  font-weight: 600;
  font-size: 1.2rem;
}

.Metadata_container__3htKB {
  width: 50%;
}

.Metadata_heading__DkYAq {
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: #f5f6f7;
  border-radius: 10px 10px 0px 0px;
}

.Metadata_content__5-TYX {
  height: 32em;
  overflow: auto;
  background-color: #282a36;
}

.Metadata_content__5-TYX * {
  color: var(--text-placehoder);
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .Metadata_container__3htKB {
    display: none;
  }
}

.More_container__1-K50 {
  margin-bottom: 1em;
}

.More_heading__3bU8p {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2em;
}

.More_display__3qJkG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  grid-gap: 2em;
  gap: 2em;
}

.More_display__3qJkG > * {
  flex: 0 0 auto;
}

.More_btnContainer__1KfIu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5em;
}

.More_btn__3FNNa {
  width: 100%;
  max-width: 12em;
  padding: 0.5em 1.5em;
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  color: var(--default);
  background: var(--main-color);
  text-align: center;
  border: 1px solid var(--main-color);
  transition: background 300ms;
}

.More_btn__3FNNa:hover {
  background: var(--main-hovered);
}

.NFT_container__2wS6s {
  width: 100%;
  padding: 1em;
  background: #ffffff;
  border: 2px solid #cacdd5;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 0.7em;
}

.NFT_container__2wS6s > img,
.NFT_container__2wS6s > video,
.NFT_container__2wS6s > audio {
  width: 100%;
  max-width: 36em;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0.7em;
}

.NFT_dropdown__2HWT8 {
  position: relative;
  display: inline-block;
}

.NFT_heading__2qN1- {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  font-weight: 600;
  font-size: 1.5rem;
}

.NFT_shareSection__3YIDR {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.NFT_moreIconContainer__2cctF {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NFT_shareIconContainer__Qx3WX {
  position: relative;
  display: inline-block;
}

.NFT_shareIcon__ygU2e,
.NFT_moreIcon__3Pkmp {
  width: 1em;
  cursor: pointer;
}

.NFT_details__1-Qru {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em 0;
  grid-gap: 4em;
  gap: 4em;
}

.NFT_title__2r9sg {
  margin-bottom: 0.5em;
  font-size: 0.85rem;
  color: var(--main-color);
}

.NFT_subSection__1yh4V {
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.NFT_placeholder__XHTMZ {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.NFT_thumbnail__1eQNu {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.NFT_name__1imYB {
  font-weight: 600;
  transition: all 300ms;
}

.NFT_name__1imYB.NFT_active__3bUAw:hover {
  color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .NFT_details__1-Qru {
    grid-gap: 2em;
    gap: 2em;
  }
}

@media screen and (max-width: 900px) {
  .NFT_details__1-Qru {
    grid-gap: 1em;
    gap: 1em;
  }
}

@media screen and (max-width: 768px) {
  .NFT_container__2wS6s > img {
    max-width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .NFT_name__1imYB {
    font-weight: 500;
    font-size: 15px;
  }
}


.Share_container__orWCC {
  background-color: var(--background-3);
  border-radius: 15px;
  position: absolute;

  /* margin-top: -2em; */
  margin-left: -8em;
  z-index: 1;
  display: none;
}

.Share_container__orWCC.Share_active__3Oagb {
  display: flex;
  width: 25em;
}

.Share_wrapper__2OLhZ {
  background: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 1em;
  border-radius: 1em;
  border: 2px solid var(--outline);
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
}

.Share_content__1r6C6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Share_content__1r6C6 input {
  width: 100%;
}

.Share_shareContent__2G4TJ {
  display: flex;

  /* gap: 1em; */
  flex-direction: column;
}

.Share_shareIcon__KMQiO {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

.Share_point__2FwhB {
  padding: 0.4em;
}

.Share_shareLink__2ymlq {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Share_point__2FwhB span {
  font-weight: 400;
  font-size: 15px;
}

.Share_point__2FwhB:hover {
  background-color: var(--main-color);
  fill: var(--default);
  cursor: pointer;
  border-radius: 5px;
}

.Share_point__2FwhB:hover .Share_shareIcon__KMQiO {
  fill: var(--default);
}

@media screen and (max-width: 768px) {
  .Share_container__orWCC {
    margin-left: -2.2em;
  }
  .Share_point__2FwhB span {
    display: none;
  }

  .Share_point__2FwhB {
    min-width: 0;
  }

  .Share_wrapper__2OLhZ {
    padding: 0.5em;
  }
  .Share_shareIcon__KMQiO {
    margin-right: 0;
  }
}

.imageModal_modalShadow__3mMpJ {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background: rgba(38, 50, 56, 0.63);
  z-index: 98;
}

.imageModal_Modal__2hfmm {
  width: 790px;
  position: fixed;
  top: 110px;
  z-index: 999;
  max-height: 846px;
  left: calc(50% - 350px);
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    left: 0px;
    margin: 0px 10px;
  }
}
.imageModal_ModalImage__1UtjC {
  max-width: 704px;
  max-height: 720px;
  object-fit: cover;
  border-radius: 12px;
}

@media screen and (max-width: 900px) {
  .imageModal_Modal__2hfmm {
    max-width: 710px;
  }
  .imageModal_ModalImage__1UtjC {
    margin: 0px auto;
    width: 654px;
    height: 700px;
  }
}

@media screen and (max-width: 740px) {
  .imageModal_Modal__2hfmm {
    width: 91%;
    left: 10px;
  }
  .imageModal_ModalImage__1UtjC {
    margin: 0px 10px;
    max-width: 100%;
    height: auto;
  }
  .imageModal_CloseIcon__3WwyW {
    width: 35px;
    margin-left: 40px !important;
  }
}

.imageModal_CloseIcon__3WwyW {
  margin-bottom: 22px;
  cursor: pointer;
  float: right;
}

.imageModal_CloseIcon__3WwyW:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.NFTDetail_container__D11Kl {
  width: 100%;
  margin-bottom: 4em;
  padding: 4em 2em;
  position: relative;
}

.NFTDetail_desktop__HEjI- {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.NFTDetail_mobile__1ODjV {
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.NFTDetail_backBtnContainer__2PmDI {
  padding: 0;
  margin-bottom: 2em;
  margin-top: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  cursor: pointer;
  display: none;
}

.NFTDetail_backIcon__3-c0c {
  width: 1em;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.NFTDetail_backBtnContainer__2PmDI:hover .NFTDetail_backIcon__3-c0c {
  -webkit-transform: translateX(-30%);
          transform: translateX(-30%);
}

.NFTDetail_wrapper__2Kpom {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.NFTDetail_sectionWrapper__5zf-5 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 3em;
}

.NFTDetail_nftSection__1GrAi {
  width: 50%;
}

.NFTDetail_detailSection__JQELy {
  width: 50%;
}

.NFTDetail_priceHistory__cJBrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
}

.NFTDetail_historySection__pFkRe {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 4em;
}

@media screen and (max-width: 1200px) {
  .NFTDetail_wrapper__2Kpom {
    max-width: 56em;
  }
}

@media screen and (max-width: 900px) {
  .NFTDetail_wrapper__2Kpom {
    max-width: 64em;
  }
}

@media screen and (max-width: 768px) {
  .NFTDetail_wrapper__2Kpom {
    max-width: 64em;
  }

  .NFTDetail_desktop__HEjI- {
    display: none;
  }

  .NFTDetail_mobile__1ODjV {
    display: flex;
  }

  .NFTDetail_sectionWrapper__5zf-5 {
    flex-direction: column;
    align-items: center;
  }

  .NFTDetail_detailSection__JQELy,
  .NFTDetail_nftSection__1GrAi {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .NFTDetail_container__D11Kl {
    padding: 2em 1em;
  }
}

.graph_chart__1BGsp {
  height: auto;
  max-height: 20em;
  padding: 1em;
}

.graph_nodata__1pZOm {
  /* height: 3rem; */
  text-align: center;
  color: gray;
  font-weight: 700;
  font-size: 1rem;
  /* padding: 10px; */
}

.PriceHistory_container__3G-4p {
  margin-bottom: 3em;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 1em;
}

.PriceHistory_heading__2Z_ks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.75em;
  font-weight: 600;
  font-size: 1.5rem;
  background: #f5f6f7;
  border-radius: 14px 14px 0px 0px;
}

.PriceHistory_history__3g8AF {
  overflow: auto;
}

.TransactionHistory_container__174lf {
  width: 100%;
  max-width: 50%;
  background: #ffffff;
  border: 2px solid #eeeff2;
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
  border-radius: 16px;
}

.TransactionHistory_wrapper__3l5DL {
  width: 100%;
  padding: 0.5em;
}

.TransactionHistory_heading__Mmd7Q {
  width: 100%;
  padding: 0.5em;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: #f5f6f7;
  border-radius: 14px 14px 0px 0px;
}

.TransactionHistory_tabs__2Ubkf {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  overflow: auto;
  margin-bottom: 1em;
}

.TransactionHistory_tab__3jbYN {
  padding: 0.1em 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  color: var(--main-color);
  background: #f5f6f7;
}

.TransactionHistory_tab__3jbYN.TransactionHistory_active__txsw3 {
  background: var(--main-color);
  color: var(--default);
}

.TransactionHistory_search__3FJ1E {
  border: 1px solid black;
  width: 100%;
  max-width: 24em;
  border-radius: 0.7em;
  padding: 0.25em 0.5em;
  margin-bottom: 1em;
  background: #ffffff;
  border: 1px solid #eeeff2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.TransactionHistory_search__3FJ1E input {
  font-size: 0.65rem;
  outline: none;
  border: none;
  flex: 1 1;
}

.TransactionHistory_searchBtn__2HmA0 {
  padding: 0.15em 0.75em;
  font-size: 0.85rem;
  font-weight: 600;
  background: var(--outline);
  color: gray;
  border-radius: 0.7em;
  cursor: pointer;
  border: 1px solid var(--card-outline);
  transition: all 300ms;
}

.TransactionHistory_searchBtn__2HmA0:hover {
  background: var(--outline-dark);
  color: var(--default);
}

.TransactionHistory_listContainer__2-s_A {
  width: 100%;
  height: 24em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
}

.TransactionHistory_list__8cS_O:first-child {
  border-top: 1px solid #dde1e6;
}

.TransactionHistory_list__8cS_O {
  border-bottom: 1px solid #dde1e6;
  width: -webkit-max-content;
  width: max-content;
  min-width: 100%;
  padding: 1em 0;
}

.TransactionHistory_tag__3WnAn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.75rem;
  padding: 0.25em 1em;
  border-radius: 0.7em;
  border: 1px solid #a4a9b6;
}

.TransactionHistory_item__22wQc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  font-size: 0.75rem;
  cursor: pointer;
}

.TransactionHistory_item__22wQc * {
  white-space: nowrap;
  text-align: center;
}

.TransactionHistory_accent__3lfKx {
  font-weight: 600;
  width: 9em;
  text-align: center;
  border-bottom: 1px solid var(--outline);
}

.TransactionHistory_date__2C7O_ {
  width: 9em;
}

.TransactionHistory_txIconContainer__2sIjF {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  border: 0.775px solid #c7ccd1;
  border-radius: 0.25em;
}

@media screen and (max-width: 768px) {
  .TransactionHistory_container__174lf {
    max-width: 100%;
  }

  .TransactionHistory_listContainer__2-s_A {
    height: auto;
  }
}

.TransactionCard_container__-6_Yw {
  width: 100%;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: none;
}

.TransactionCard_container__-6_Yw.TransactionCard_active__1M-pf {
  display: flex;
}

.TransactionCard_wrapper__sdrK7 {
  width: 26em;
  background: var(--default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1.5em 2em;
  border-radius: 1em;
  border: 2px solid var(--outline);
  box-shadow: 2px 7px 22px rgba(28, 27, 28, 0.1);
}

.TransactionCard_wrapper__sdrK7 * {
  font-family: sans-serif;
}

.TransactionCard_type__2hUOJ {
  font-size: 1.4rem;
  font-weight: 700;
}

.TransactionCard_title__3zEyb {
  font-size: 1.2rem;
  color: var(--main-color);
}

.TransactionCard_details__2h0kp {
  width: 100%;
  border: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--outline);
  border-radius: 1em;
  overflow: hidden;
}

.TransactionCard_list__AtlJ6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  border-bottom: 1px solid var(--outline);
}

.TransactionCard_key__1o9qX {
  font-size: 0.7rem;
}

.TransactionCard_list__AtlJ6:first-child {
  background: #f5f6f7;
}

.TransactionCard_list__AtlJ6.TransactionCard_amount__2nWk9 .TransactionCard_value__2K0u- {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.TransactionCard_list__AtlJ6.TransactionCard_amount__2nWk9 img {
  width: 1.2em;
}

.TransactionCard_value__2K0u- {
  font-weight: 600;
  text-align: center;
  width: 8em;
  font-size: 0.9rem;
  transition: all 300ms;
}

.TransactionCard_value__2K0u-.TransactionCard_link__2PBVb {
  cursor: pointer;
}

.TransactionCard_value__2K0u-.TransactionCard_link__2PBVb:hover {
  color: var(--main-color);
}

@media screen and (max-width: 540px) {
  .TransactionCard_wrapper__sdrK7 {
    width: 20em;
    padding: 1em;
  }
}

.LoadingScreen_container__3rfiB {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.LoadingScreen_top__1C1XQ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 6em;
}

.LoadingScreen_left__3x6z9 {
  width: 100%;
}

.LoadingScreen_right__2qvlI {
  width: 100%;
}

.LoadingScreen_bottom__3dFP5 {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .LoadingScreen_top__1C1XQ {
    flex-direction: column;
  }
}

.CollectionOptions_container__r7fP7 {
  padding: 2em 2em 6em;
  max-width: 908px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.CollectionOptions_title__2EKDj {
  font-weight: 700;
  font-size: 4rem;
  color: var(--text-heading);
  margin-bottom: 0.25em;
}
.CollectionOptions_description__o34bb {
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 3.2em;
  text-align: center;
}
.CollectionOptions_cardDeck__3Tjmw {
  display: grid;
  grid-gap: 27px 29px;
  gap: 27px 29px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  margin: auto;
  width: 100%;
}
.CollectionOptions_typeCard__lDas- {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  border: 1px solid;
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.05);
  border-radius: 20px;
  background: var(--default);
  border: 1px solid #e5ecfb;
  min-height: 243px;
  cursor: pointer;
}
.CollectionOptions_typeCard__lDas-:hover {
  border-color: var(--main-color);
}
.CollectionOptions_typeCard__lDas-:hover .CollectionOptions_content__2heut {
  background: var(--main-color);
}
.CollectionOptions_typeCard__lDas-:hover .CollectionOptions_cardTitle__8dL2s,
.CollectionOptions_typeCard__lDas-:hover .CollectionOptions_cardDesc__rvbyV {
  color: var(--default);
}

.CollectionOptions_typeCard__lDas-:hover .CollectionOptions_icon___M5W0 svg path {
  stroke: var(--main-color);
}
.CollectionOptions_typeCard__lDas-:nth-child(3):hover .CollectionOptions_icon___M5W0 svg path {
  fill: var(--main-color);
}
.CollectionOptions_typeCard__lDas-:nth-child(2):hover .CollectionOptions_icon___M5W0 svg path:last-child {
  fill: var(--main-color);
}
.CollectionOptions_icon___M5W0 {
  height: 100%;
  display: flex;
  align-items: center;
}
.CollectionOptions_content__2heut {
  display: flex;
  align-items: center;
  background: #f3f6fc;
  border-radius: 11px;
  padding: 9px 7px 9px 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}
.CollectionOptions_typeIcon__3fB7M {
  background: var(--default);
  border-radius: 10px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CollectionOptions_typeIcon__3fB7M svg path {
  fill: var(--text-light);
}
.CollectionOptions_typeCard__lDas-:last-child .CollectionOptions_typeIcon__3fB7M svg path {
  fill: var(--default);
  stroke: var(--text-light);
}
.CollectionOptions_typeCard__lDas-:hover .CollectionOptions_typeIcon__3fB7M svg path {
  fill: var(--main-color);
}
.CollectionOptions_typeCard__lDas-:last-child:hover .CollectionOptions_typeIcon__3fB7M svg path {
  stroke: var(--main-color);
  fill: var(--default);
}
.CollectionOptions_text__rhOLL {
  display: flex;
  flex-direction: column;
  width: calc(100% - 52px);
}
.CollectionOptions_cardTitle__8dL2s {
  font-size: 14px;
  color: var(--text-heading);
}
.CollectionOptions_cardDesc__rvbyV {
  font-size: 10px;
  color: #959ca3;
}
.CollectionOptions_moreBTN__1BYaa {
  color: var(--main-color);
  font-size: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.CollectionOptions_moreBTN__1BYaa.CollectionOptions_active__MBRSY svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.CollectionOptions_moreBTN__1BYaa svg {
  width: 2.3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}
.CollectionOptions_moreBTN__1BYaa svg path {
  fill: var(--main-color);
}
@media screen and (max-width: 650px) {
  .CollectionOptions_cardDeck__3Tjmw {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .CollectionOptions_cardDeck__3Tjmw {
    grid-template-columns: repeat(1, 1fr);
  }
  .CollectionOptions_typeCard__lDas- {
    min-height: auto;
  }
  .CollectionOptions_title__2EKDj {
    font-size: 2rem;
  }
  .CollectionOptions_description__o34bb {
    font-size: 15px;
  }
  .CollectionOptions_card__1ZzWA {
    padding: 1.25rem 1rem;
  }
  .CollectionOptions_cardTitle__8dL2s {
    font-size: 0.75rem;
  }
  .CollectionOptions_cardDescription__1sbRs {
    font-size: 0.5rem;
  }
  .CollectionOptions_icon___M5W0 {
    width: 80px;
    height: 80px;
    padding: 1rem;
  }
}

.MarketplaceAll_container__3Jl34 {
  width: 100%;
  min-height: 100vh;
}

.MarketplaceAll_wrapper__1AMJ0 {
  width: 100%;
  padding: 4em 2em;
}

.MarketplaceAll_nfts__28srg {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 2em;
  gap: 2em;
}

.MarketplaceAll_heading__1eOwz {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 3em;
  gap: 3em;
  padding: 6em 2em;
  background: linear-gradient(
    259.21deg,
    #b0e2fe -27.76%,
    #d8e6fc 1.15%,
    #fdeafa 26.21%,
    #e3f2f9 47.43%,
    #edf5f8 67.57%,
    #fbeffc 87.77%,
    #b0e2fe 107.4%
  );
}

.MarketplaceAll_title__3F_hH {
  text-align: center;
}

.MarketplaceAll_title__3F_hH h1 {
  font-size: 4rem;
  font-weight: 700;
}

.MarketplaceAll_title__3F_hH p {
  font-size: 1.5rem;
}

.MarketplaceAll_searchAndFilter__1Izoz {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.MarketplaceAll_filter__1aT4V {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.MarketplaceAll_chainMobile__3oA8t {
  display: none;
}

.MarketplaceAll_search__2BSKL {
  width: 100%;
  max-width: 36em;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  background: var(--default);
  border: 1px solid var(--card-outline);
}

.MarketplaceAll_search__2BSKL input {
  width: 100%;
  outline: none;
  border: none;
  color: var(--textinput);
}

.MarketplaceAll_dateFilter__3SbOZ {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.2em;
  border-radius: 0.7em;
  border: 1px solid #cacdd5;
}

.MarketplaceAll_date__hLvyG {
  width: 8em;
  text-align: center;
  border-radius: 0.7em;
  padding: 0.5em 0;
  font-weight: 600;
  cursor: pointer;
}

.MarketplaceAll_date__hLvyG.MarketplaceAll_active__wc-Sq {
  background: #727478;
  color: var(--default);
}

.MarketplaceAll_loader__32LCT {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .MarketplaceAll_nfts__28srg {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .MarketplaceAll_nfts__28srg {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }

  .MarketplaceAll_search__2BSKL {
    max-width: 24em;
  }
}

@media screen and (max-width: 900px) {
  .MarketplaceAll_nfts__28srg {
    grid-template-columns: repeat(2, 1fr);
  }

  .MarketplaceAll_title__3F_hH h1 {
    font-size: 3rem;
  }

  .MarketplaceAll_title__3F_hH p {
    font-size: 1.2rem;
  }

  .MarketplaceAll_search__2BSKL {
    max-width: 20em;
  }

  .MarketplaceAll_search__2BSKL input {
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MarketplaceAll_date__hLvyG {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 768px) {
  .MarketplaceAll_wrapper__1AMJ0 {
    padding: 4em 1em;
  }

  .MarketplaceAll_title__3F_hH h1 {
    font-size: 2rem;
  }

  .MarketplaceAll_title__3F_hH p {
    font-size: 1rem;
  }

  .MarketplaceAll_search__2BSKL {
    max-width: 16em;
  }

  .MarketplaceAll_search__2BSKL input {
    max-width: 4.5em;
  }

  .MarketplaceAll_date__hLvyG {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 540px) {
  .MarketplaceAll_nfts__28srg {
    grid-template-columns: repeat(1, 1fr);
  }

  .MarketplaceAll_searchAndFilter__1Izoz {
    grid-gap: 1em;
    gap: 1em;
  }

  .MarketplaceAll_filter__1aT4V {
    grid-gap: 1em;
    gap: 1em;
  }

  .MarketplaceAll_chainMobile__3oA8t {
    display: block;
    z-index: 0;
    margin-top: -1em;
  }

  .MarketplaceAll_chainDesktop__2LDeB {
    display: none;
  }

  .MarketplaceAll_heading__1eOwz {
    grid-gap: 2em;
    gap: 2em;
    padding: 3em 1em;
  }

  .MarketplaceAll_searchAndFilter__1Izoz {
    grid-gap: 1em;
    gap: 1em;
  }

  .MarketplaceAll_wrapper__1AMJ0 {
    padding: 2em 1em;
  }
}

.Creating_container__3ptkT {
  padding: 2em 2em 6em;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.Creating_title__1nTrS {
  font-weight: 700;
  font-size: 4rem;
  color: var(--text-heading);
  margin-bottom: 0.25em;
}
.Creating_description__2X5FT {
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 3.2em;
  text-align: center;
}
.Creating_cardDeck__18SBH {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 40%;
}
.Creating_card__3EuzH {
  background: var(--default);
  border: 1px solid #e5e8eb;
  box-shadow: 26px 18px 18px rgba(93, 134, 194, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5rem 1rem;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.Creating_comingSoon__2MVS7 {
  background-color: var(--card-light);
  color: var(--card-shade);
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 3px;
}

.Creating_noDrop__1fsQI:hover {
  cursor: no-drop;
}

.Creating_extra__332Ne {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-height: 0;
  overflow: hidden;
  margin-top: 29px;
  width: 100%;
  transition: all 200ms ease;
}
.Creating_extra__332Ne.Creating_active__Dsb9D {
  max-height: 50rem;
  overflow: hidden;
  padding-bottom: 1rem;
}

.Creating_extra__332Ne .Creating_card__3EuzH:hover .Creating_icon__QQXiz svg path {
  /* fill: var(--main-color); */
}
.Creating_card__3EuzH:hover {
  border-color: var(--main-color);
  box-shadow: 26px 18px 18px rgb(93 134 194 / 16%);
}
.Creating_icon__QQXiz {
  background: #f3f6fc;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  width: 6.5rem;
  height: 6.5rem;
}
.Creating_card__3EuzH:hover .Creating_icon__QQXiz {
  background: var(--main-color);
}
.Creating_card__3EuzH:hover .Creating_icon__QQXiz svg path {
  stroke: var(--default);
}
.Creating_card__3EuzH:nth-child(3):hover .Creating_icon__QQXiz svg path {
  fill: var(--default);
}

.Creating_noDrop__1fsQI:nth-child(3):hover .Creating_icon__QQXiz svg path {
  fill: var(--main-color);
}
.Creating_card__3EuzH:nth-child(2):hover .Creating_icon__QQXiz svg path:last-child {
  fill: var(--default);
}
.Creating_icon__QQXiz svg {
  height: 100%;
  width: 100%;
}
.Creating_cardTitle__2ftfJ {
  font-weight: 500;
  color: var(--main-color);
}
.Creating_cardDescription__2P3vK {
  font-size: 0.875rem;
  color: #263238;
}
.Creating_moreBTN__2DRQf {
  color: var(--main-color);
  font-size: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.Creating_moreBTN__2DRQf.Creating_active__Dsb9D svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Creating_moreBTN__2DRQf svg {
  width: 2.3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}
.Creating_moreBTN__2DRQf svg path {
  fill: var(--main-color);
}
@media screen and (max-width: 650px) {
  .Creating_cardDeck__18SBH,
  .Creating_extra__332Ne {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .Creating_title__1nTrS {
    font-size: 2rem;
  }
  .Creating_description__2X5FT {
    font-size: 15px;
  }
  .Creating_card__3EuzH {
    padding: 1.25rem 1rem;
    max-height: 180px;
  }
  .Creating_cardTitle__2ftfJ {
    font-size: 0.75rem;
  }
  .Creating_cardDescription__2P3vK {
    font-size: 0.5rem;
  }
  .Creating_icon__QQXiz {
    width: 57px;
    height: 57px;
    padding: 8px;
  }
  .Creating_moreBTN__2DRQf {
    margin-top: 1rem;
  }
  .Creating_comingSoon__2MVS7 {
    padding: 0.25rem;
    font-size: 13px;
  }
}

.loading_wrapper___dAbH {
  width: 100%;
  margin-top: 46vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.loading_loader__1ZmbU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.loading_dotOne__3_skc,
.loading_dotTwo__3xwKj,
.loading_dotThree__TK8FH {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: var(--main-hovered);
  -webkit-animation: loading_blink__1Ur_W 300ms infinite alternate linear;
          animation: loading_blink__1Ur_W 300ms infinite alternate linear;
}

.loading_dotOne__3_skc {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.loading_dotTwo__3xwKj {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}

.loading_dotThree__TK8FH {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

@-webkit-keyframes loading_blink__1Ur_W {
  from {
    background: var(--default);
  }
  to {
    background: var(--main-color);
  }
}

@keyframes loading_blink__1Ur_W {
  from {
    background: var(--default);
  }
  to {
    background: var(--main-color);
  }
}

