.container {
  width: 100%;
  padding: 0 2em;
  margin-bottom: 6em;
  background: linear-gradient(
    255.93deg,
    #eff3f9 -39.69%,
    #eff3f9 5.2%,
    rgba(205, 237, 255, 0.93) 49.41%,
    #ffffff 91.12%
  );
}

.wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 0;
}

.content {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
}

.image {
  width: 100%;
  max-width: 36em;
}

.heading {
  font-size: 3rem;
  font-weight: 600;
}

.listContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.listItem {
  font-size: 1.5rem;
  color: var(--text-heading);
}

.listStyle {
  display: inline-block;
  background: var(--text-sub-heading);
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
}

.btn {
  display: inline-block;
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 300ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

@media screen and (max-width: 1200px) {
  .image {
    max-width: 28em;
  }

  .heading {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .image {
    max-width: 24em;
  }

  .heading {
    font-size: 1.5rem;
  }

  .listItem {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column-reverse;
    gap: 2em;
  }

  .content {
    width: 100%;
    align-items: center;
  }

  .heading {
    font-size: 2rem;
    text-align: center;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 1em;
    margin-bottom: 4em;
  }

  .wrapper {
    padding: 1em;
  }

  .heading {
    font-size: 1.5rem;
  }

  .listItem {
    font-size: 1rem;
  }

  .btn {
    padding: 0.5em 2em;
  }
}
