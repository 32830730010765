.wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background: rgb(0, 0, 0, 0.7);
}

.wrapper.active {
  display: flex;
}

.mainContainer {
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em 3em;
  border-radius: 1em;
  position: relative;
  background: var(--main-color);
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.closeBtn {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: var(--cursor-pointer);
  fill: var(--default);
  padding: 0.35em;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.25em;
}

.closeBtn:hover {
  box-shadow: var(--shadow-light);
}

.heading {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  color: var(--default);
}

.swipeContainer {
  width: 36em;
  overflow: auto;
  z-index: 1;
}

.swipeContainer.active {
  animation: zoom 650ms forwards;
}

@keyframes zoom {
  from {
    transform: scale(1.05);
  }
  to {
    transition: scale(1);
  }
}

.cardContainer {
  width: max-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 650ms;
}

.card {
  width: 32em;
  flex: 0 0 auto;
  height: 100%;
  background: var(--default);
}

.control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
}

.control .right,
.control .left {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: var(--cursor-pointer);
  visibility: hidden;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.5em;
  transition: all 300ms;
}

.control .right.active,
.control .left.active {
  visibility: visible;
}

.control .right:hover,
.control .left:hover {
  background: rgba(255, 255, 255, 1);
}

/* hide scrollbar */
.swipeContainer::-webkit-scrollbar {
  display: none;
}

.swipeContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rightSideCard,
.leftSideCard {
  height: auto;
  width: 4em;
  background: transparent;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  overflow: hidden;
  display: none;
}

.rightSideCard.hide,
.leftSideCard.hide {
  display: none;
}

.rightSideCard._2,
.leftSideCard._2 {
  width: 2em;
}

.leftSideCard.active {
  animation: slideLeft 650ms forwards;
}

@keyframes slideLeft {
  from {
    transform: translateX(2em) scale(1.05);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

.rightSideCard.active {
  animation: slideRight 650ms forwards;
}

@keyframes slideRight {
  from {
    transform: translateX(-2em) scale(1.05);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

.indicatorContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 2px;
  transition: all 1s;
}

.indicator.active {
  border-color: white;
}

.indicator > div {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.indicator.active > div {
  background: var(--default);
}

@media screen and (max-width: 1000px) {
  .mainContainer {
    gap: 1em;
  }

  .heading {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .leftSideCard,
  .rightSideCard {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 0.5em;
  }

  .mainContainer {
    border-radius: 0.5em;
    padding: 1em;
  }

  .heading {
    font-size: 0.85rem;
  }

  .heading span {
    display: none;
  }

  .indicatorContainer {
    gap: 0;
  }

  .indicator {
    padding: 1px;
  }

  .control .right,
  .control .left {
    width: 2em;
  }
}
