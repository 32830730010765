.container {
  width: 100%;
  padding: 6em 2em;
  background: #f4f6fa;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.heading {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
}

.heading .accent {
  color: var(--main-color);
}

.description {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-sub-heading);
  margin-bottom: 2em;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
  margin-bottom: 2em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--outline);
}

.planName {
  font-size: 1.5rem;
  cursor: pointer;
}

.line {
  width: 0%;
  background: var(--main-color);
  height: 4px;
  transition: all 650ms;
}

.planName:hover .line {
  width: 60%;
}

.planName.active .line {
  width: 100%;
}

.plan {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.plan.render {
  animation: render 300ms forwards;
}

.plan.re_render {
  animation: re-render 300ms forwards;
}

.plan:nth-child(1) {
  animation-delay: 100ms;
}

.plan:nth-child(2) {
  animation-delay: 200ms;
}

.plan:nth-child(3) {
  animation-delay: 300ms;
}

.plan:nth-child(4) {
  animation-delay: 400ms;
}

.plan:nth-child(5) {
  animation-delay: 500ms;
}

.plan:nth-child(6) {
  animation-delay: 600ms;
}

.plan:nth-child(7) {
  animation-delay: 700ms;
}

@keyframes render {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1em);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes re-render {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1em);
  }
  100% {
    transform: translateY(0);
  }
}

.closeIcon {
  fill: black;
}

.markIcon {
  fill: var(--main-color);
}

.preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4em;
  background: var(--default);
  padding: 4em;
}

.planIcon {
  width: 100%;
  max-width: 46vw;
  height: auto;
}

.planIcon.render {
  animation: shake 850ms forwards;
}

.planIcon.re_render {
  animation: re-shake 850ms forwards;
}

@keyframes shake {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes re-shake {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.planInfo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.infoHeading {
  font-size: 1rem;
  text-transform: uppercase;
}

.infoDescription {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.supportedFeatures {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
}

.name {
  font-size: 1rem;
}

.btn {
  padding: 0.75em 3em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  transition: all 650ms;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn:hover {
  background: var(--default);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 2.5rem;
  }

  .infoDescription {
    font-size: 1.5rem;
  }

  .preview {
    padding: 4em 2em;
  }
}

@media screen and (max-width: 900px) {
  .heading {
    font-size: 2rem;
  }

  .plans {
    gap: 2em;
  }

  .description,
  .planName {
    font-size: 1.2rem;
  }

  .preview {
    gap: 2em;
    flex-direction: column-reverse;
  }

  .planIcon {
    max-width: 100vw;
  }

  .infoDescription {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 4em 1em;
  }

  .heading {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .planName {
    font-size: 1.2rem;
  }

  .preview {
    padding: 2em 1em;
  }

  .btn {
    padding: 0.5em 2em;
  }
}
