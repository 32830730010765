.container {
  width: 100%;
}

.content {
  width: 100%;
  min-height: 5em;
  height: calc(100vh - 42em);
  overflow: auto;
  border: 3px solid var(--outline);
  padding: 0.5em;
  border-radius: 0.25em;
}

.container h4 {
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--text-dark-900);
}

.content p {
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 0.5em;
  flex-wrap: wrap;
}

.content p > span {
  font-size: 0.85rem;
  color: var(--text-dark-900);
}

@media screen and (max-width: 1200px) {
  .content {
    height: auto;
    max-height: 16em;
  }

  .content h4 {
    margin-bottom: 1em;
  }
}
