.container {
  width: 100%;
  padding: 0 2em;
  padding-top: 2em;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2em;
  margin-bottom: 3em;
}

.title {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.2em;
}

.searchContainer {
  width: 100%;
  max-width: 56em;
}

.category {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  cursor: pointer;
}

.category span {
  color: #939599;
  transition: color 300ms;
}

.category span:hover {
  color: var(--main-color);
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 4rem;
  }

  .search {
    max-width: 42em;
  }
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 3rem;
  }

  .search {
    max-width: 36em;
  }
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 2.5rem;
  }

  .search {
    max-width: 24em;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 1em;
    padding-top: 2em;
    margin-bottom: 3em;
  }

  .heading {
    margin-bottom: 2em;
  }

  .title {
    font-size: 1.5rem;
  }

  .search {
    max-width: 16em;
  }

  .search .placeholder {
    font-size: 0.85rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .category span {
    font-size: 0.8rem;
  }
}
