.searchInput {
  flex: 1;
  padding: 0 25px;
  transition: all 300ms;
  border-radius: 0.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--outline);
  outline: 1px solid transparent;
}

.searchInput:focus-within {
  outline: 1px solid var(--main-color);
}
.searchInput > input {
  border: none;
  padding: 10px 25px;
  width: 100%;
}
.searchInput > input:focus {
  outline: none;
}
