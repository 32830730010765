.container {
  width: 100%;
  padding: 0 2em;
  margin-top: 20px;
}

.notFound {
  grid-column: 1 / span 4;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.types {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3em;
  margin-bottom: 3em;
}

.type {
  padding: 0.1em 1.5em;
  font-size: 1.8rem;
  font-weight: 600;
  background: #f7f9fd;
  border-bottom: 4px solid transparent;
  border-radius: 0.4em 0.4em 0 0;
  white-space: nowrap;
  cursor: pointer;
  color: #0f1d40;
}

.type:hover {
  color: var(--text-sub-heading);
}

.type.active {
  border-bottom: 4px solid var(--main-color);
  color: var(--main-color);
}

.filter {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3em;
  gap: 1em;
  /* overflow: hidden; */
}

.categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1em;
  overflow: auto;
  max-width: 900px;
}

.category {
  flex: 0 0 auto;
  padding: 0.3em 1em;
  font-size: 1.2rem;
  border-radius: 0.7em;
  font-weight: 400;
  border: 1.43503px solid rgba(28, 27, 28, 0.1);
  box-shadow: 0px 1.43503px 2.87007px rgba(28, 27, 28, 0.05);
  border-radius: 0.7em;
  cursor: pointer;
  color: rgba(28, 27, 28, 0.6);
  background: var(--default);
}

.category.active {
  color: var(--default);
  background: var(--main-color);
}

.category.disable {
  background: var(--outline);
}

.nfts {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2em;
  margin-bottom: 3em;
}

.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  display: inline-block;
  margin: 0 auto;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  transition: all 300ms;
}

.skeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.btn:hover {
  color: var(--default);
  background: var(--main-color);
}

@media screen and (max-width: 1440px) {
  .nfts {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .nfts {
    grid-template-columns: repeat(2, 1fr);
  }
  .skeleton {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .types {
    gap: 2em;
  }

  .type {
    padding: 0.2em 1em;
    font-size: 1.2rem;
  }
  .skeleton {
    grid-template-columns: repeat(2, 1fr);
  }

  .category {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .nfts {
    grid-template-columns: repeat(1, 1fr);
  }
  .skeleton {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 1em;
  }

  .skeleton {
    grid-template-columns: repeat(1, 1fr);
  }

  .filter {
    flex-wrap: wrap;
    align-items: center;
  }

  .categories {
    width: 100%;
  }

  .types {
    gap: 0.5em;
    margin-bottom: 1.6em;
    /* justify-content: center; */
  }

  .type {
    padding: 0.2em 1em;
    font-size: 0.8rem;
  }

  .category {
    font-size: 0.8rem;
  }
}
