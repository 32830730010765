.paginate {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  border-top: 1px solid var(--outline);
  border-bottom: 1px solid var(--outline);
  padding: 0.25em;
}

.paginate input {
  padding: 0.65em;
  max-width: 6em;
  border: 1px solid var(--outline);
  border-radius: 0.25em;
}

.pageControl {
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--main-color);
  color: var(--default);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.pageControl:hover {
  outline: 2px solid var(--main-hovered);
}

.pageCount {
  font-weight: bold;
  white-space: nowrap;
}
