.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
  margin-bottom: 6em;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
}

.heading {
  width: 100%;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1em;
  color: var(--text-heading);
}

.FQAs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    padding: 0;
  }

  .heading {
    font-size: 2rem;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 1em;
  }

  .heading {
    font-size: 1.5rem;
  }
}
