.signIn {
  cursor: pointer;
  color: var(--main-color) !important;
}

.profileContainer {
  position: relative;
}

.profileIcon {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--default) !important;
  background: var(--main-light);
  cursor: pointer;
}
