.container {
  width: 20em;
  position: fixed;
  z-index: 2000;
  right: 10px;
  background: var(--card-bg);
  color: white;
  visibility: hidden;
  padding: 1em 1.5em;
  border-radius: 0.25em;
  overflow: hidden;
  transition: all 300ms;
  box-shadow: var(--shadow-light);
}

.container.active {
  visibility: visible;
  transform: translateY(10px);
}

.closeIcon {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 10px;
  margin: 0;
  cursor: var(--cursor-pointer);
  transition: transform 100ms;
  fill: var(--default);
}

.closeIcon:hover {
  transform: scale(1.1);
}

.message {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.25em;
  color: var(--default);
}

.action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action img {
  filter: invert(1);
  margin-left: 4px;
  width: 1em;
}

.copy {
  cursor: var(--cursor-pointer);
  font-size: 0.85rem;
  padding: 0.2em 1em;
  border-radius: 0.25em;
  background: var(--card-bg);
  color: white;
  outline: 2px solid transparent;
}

.copy:hover {
  background: var(--card-bg);
  outline: 2px solid var(--card-bg);
}
