.container {
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.container.active {
  display: flex;
}

.wrapper {
  width: 100%;
  max-width: 48em;
  border-radius: 0.5em;
  background: var(--default);
  padding: 4em 1em;
  overflow: hidden;
  position: relative;
  animation: show 300ms alternate;
}

@keyframes show {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

.closeBtn {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.closeBtn:hover {
  box-shadow: var(--shadow-dark);
}

.content {
  width: 100%;
  max-width: 36em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3em;
  border-radius: 0.5em;
}

.content h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.sessionContainer {
  width: 100%;
  height: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  gap: 1em;
}

.session {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--card-outline);
  padding: 1em 1em;
  border-radius: 0.5em;
}

.name {
  font-weight: 600;
  margin-bottom: 0.5em;
}

.edited {
  font-size: 0.85rem;
}

.action {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.deleteBtn,
.loadBtn {
  align-self: center;
  width: 7em;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.85rem;
}

.deleteBtn {
  background: var(--card-light);
  color: var(--error-500);
  border: 1px solid var(--outline);
}

.deleteBtn:hover {
  background: var(--error-500);
  color: var(--default);
  border: 1px solid transparent;
}

.loadBtn {
  background: var(--card-bg);
  color: var(--default);
}

.loadBtn:hover {
  background: var(--default);
  color: var(--card-bg);
  border: 1px solid var(--card-bg);
}

.content button {
  align-self: center;
  width: 12em;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: var(--btn-open);
}

.content button:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.loader.active {
  display: flex;
}

.dots {
  width: max-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  background: var(--card-light);
}

.dots div {
  color: var(--main-color);
}

.dotOne,
.dotTwo,
.dotThree {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--card-light);
  animation: blink 450ms infinite alternate linear;
}

.dotOne {
  animation-delay: 0ms;
}

.dotTwo {
  animation-delay: 300ms;
}

.dotThree {
  animation-delay: 600ms;
}

@keyframes bounce {
  from {
    transform: translateY(-1em);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes blink {
  from {
    background: var(--main-lighter);
  }
  to {
    background: var(--main-color);
  }
}
