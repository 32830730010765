.overlay {
  position: fixed;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  top: 0;
  left: 0;
  z-index: 5000;
  overflow: hidden;
}

.overlay.isLoading {
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5em 2em;
  border-radius: 1em;
  background: var(--default);
}

.wrapper img {
  width: 4em;
  margin-bottom: 1em;
  animation: bounce 450ms infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(1em);
  }
  to {
    transform: translateY(0);
  }
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.dotOne,
.dotTwo,
.dotThree {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--default);
  animation: blink 450ms infinite alternate linear;
}

.dotOne {
  animation-delay: 0ms;
}

.dotTwo {
  animation-delay: 300ms;
}

.dotThree {
  animation-delay: 600ms;
}

@keyframes blink {
  from {
    background: var(--main-light);
  }
  to {
    background: var(--main-color);
  }
}
