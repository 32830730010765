.container {
  width: 100%;
  padding-bottom: 6em;
  background: var(--default);
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 1em;
}
@media screen and (max-width: 1440px) {
  .wrapper {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 540px;
    padding: 0 2em;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 0 1em;
  }
}
