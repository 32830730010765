.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  margin-bottom: 6em;
}

.backBtnContainer {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 1em;
  margin-bottom: 2em;
  margin-top: 2em;
  cursor: pointer;
}

.backIcon {
  width: 1.2em;
  height: 1.2em;
  transition: all 300ms;
}

.backBtnContainer:hover .backIcon {
  transform: translateX(-50%);
}

.wrapper {
  width: 100%;
  max-width: 56em;
  border-radius: 0.5em;
  background: var(--default);
  position: relative;
  border: 1px solid var(--outline);
  padding: 2em;
  box-shadow: 12px 0px 35px rgba(93, 134, 194, 0.1), -2px -10px 35px rgba(93, 134, 194, 0.1);
}

.closeBtn {
  fill: var(--icon-color);
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: var(--btn-close);
  cursor: var(--cursor-pointer);
  transition: all 300ms;
}

.closeBtn:hover {
  box-shadow: var(--shadow-dark);
}

.content {
  width: 100%;
  max-width: 42em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3em;
  border-radius: 0.5em;
}

.content h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-heading);
}

.subHeading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subHeading button {
  align-self: center;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: var(--default);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: var(--btn-open);
}

.subHeading button:hover {
  background: var(--main-hovered);
  box-shadow: var(--btn-close);
}

.sessionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.sessionWrapper {
  width: 100%;
  background: var(--card-lighter);
  border: 2px solid var(--outline);
  border-radius: 0.5em;
  padding: 2em;
  transition: all 650ms;
}

.sessionWrapper.active {
  border: 2px solid var(--main-color);
}

.session {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.planName {
  font-weight: 600;
  margin-right: 0.5em;
  text-transform: capitalize;
  font-size: 1.2rem;
}

.planFlag {
  padding: 0.25em 1em;
  padding-right: 1.5em;
  font-size: 0.65rem;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0 100%);
  background: #a6c5fa;
}

.collectionName {
  margin-bottom: 0.5em;
  width: 8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.action {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.deleteBtn,
.loadBtn {
  align-self: center;
  padding: 0.75em 2em;
  border-radius: 0.7em;
  transition: all 300ms;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.85rem;
  text-align: center;
}

.loadBtn *,
.deleteBtn * {
  transition: all 300ms;
}

.deleteBtn {
  color: var(--error-500);
  border: 1px solid var(--outline);
}

.deleteBtn * {
  color: var(--error-500);
}

.deleteBtn:hover {
  background: var(--error-500);
  color: var(--default);
  border: 1px solid transparent;
}

.deleteBtn:hover * {
  color: var(--default);
}

.loadBtn {
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.loadBtn * {
  color: var(--main-color);
}

.loadBtn:hover {
  background: var(--main-color);
  color: var(--default);
}

.loadBtn:hover * {
  color: var(--default);
}

.dropdownIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  background: var(--card-light);
  cursor: pointer;
}

.dropdownIcon {
  width: 1em;
  height: 1em;
  fill: var(--main-color);
  transition: all 650ms;
}

.dropdownIcon.active {
  transform: rotate(180deg);
}

.sessionDropdown {
  width: 100%;
  border-top: 1px solid var(--outline);
  margin-top: 2em;
  max-height: 0;
  overflow: hidden;
  transition: all 650ms;
}

.sessionDropdown.active {
  max-height: 28em;
}

.cost {
  margin-top: 1em;
}

.amount {
  font-weight: 600;
}

.services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--outline);
  padding: 2em 0;
}

.service {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  font-size: 0.85rem;
}

.closeIcon {
  fill: black;
}

.markIcon {
  fill: var(--main-color);
}

.upgradeBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgradeBtnContainer button {
  align-self: center;
  padding: 0.5em 2em;
  border-radius: 0.7em;
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  font-size: 1rem;
  transition: all 300ms;
  cursor: pointer;
}

.upgradeBtnContainer button:hover {
  color: var(--main-hovered);
  border-color: var(--main-hovered);
}

.skeleton {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .sessionWrapper {
    padding: 1em;
  }

  .deleteBtn,
  .loadBtn {
    padding: 0.5em 1em;
    border-radius: 0.7em;
  }

  .dropdownIconContainer {
    padding: 0.5em;
  }
}

@media screen and (max-width: 540px) {
  .container {
    margin-bottom: 4em;
  }

  .backBtnContainer {
    margin-top: 1em;
  }

  .wrapper {
    padding: 2em 0.5em;
  }

  .subHeading {
    padding: 0 1em;
  }

  .content {
    gap: 2em;
  }

  .content h1 {
    font-size: 1.5rem;
  }

  .subHeading h3 {
    font-size: 1rem;
  }

  .upgradeBtnContainer button,
  .subHeading button {
    font-size: 0.85rem;
  }

  .action {
    gap: 0.5em;
  }

  .planFlag,
  .loadBtn span,
  .deleteBtn span {
    display: none;
  }

  .planName {
    font-size: 1rem;
  }

  .collectionName {
    font-size: 0.85rem;
  }

  .sessionDropdown {
    margin-top: 0.5em;
  }

  .dropdownIconContainer {
    padding: 0.35em;
    padding: 0.25em;
  }

  .dropdownIcon {
    width: 0.75em;
    height: 0.75em;
  }

  .services {
    padding: 1em 0;
  }

  .backIcon {
    width: 1em;
    height: 1em;
  }
}
