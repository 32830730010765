.container {
  width: 100%;
  display: grid;
  gap: 1rem;
  /* grid-template-columns: calc(100% - 5rem) 4rem; */
  grid-template-columns: 100%;
  align-items: center;
  margin-bottom: 1rem;
}
.container div {
  display: flex;
  align-items: center;
  color: var(--text-sub-heading);
  justify-content: center;
  font-weight: 500;
}
.slider[type="range"] {
  -webkit-appearance: none;
  height: 0.7rem;
  background: #e2e4e8;
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  padding: 0 !important;
}
.slider[type="range"]:focus {
  border: none !important;
  outline: none !important;
}
/* Input Thumb */
.slider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--default);
  border: 1px solid #747c90;
  cursor: ew-resize;
  box-shadow: 0px 5.71429px 7.85714px rgba(0, 0, 0, 0.08);
  transition: background 0.3s ease-in-out;
}

.slider[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  background: var(--main-color);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.slider[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  background: var(--main-color);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

/* .slider[type="range"]::-webkit-slider-thumb:hover {
  background: var(--main-color);
} */

/* .slider[type="range"]::-moz-range-thumb:hover {
  background: var(--main-color);
} */

/* .slider[type="range"]::-ms-thumb:hover {
  background: var(--main-color);
} */

/* Input Track */
.slider[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.slider[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.slider[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
