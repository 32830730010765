.chainDropdown {
  position: relative;
  background: var(--default);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedChain {
  display: flex;
  padding: 0.25em 1em;
  align-items: center;
  justify-content: space-between;
  gap: 0.25em;
  border: 1px solid var(--card-outline);
  border-radius: 0.7em;
  cursor: var(--cursor-pointer);
  height: 100%;
  color: var(--main-color);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectedChain:hover {
  background: var(--btn-close);
}

.selectedChain > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.chainDropdown .dropdown {
  width: 16em;
  top: 3em;
  position: absolute;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  display: flex;
  flex-direction: column;
  cursor: var(--cursor-pointer);
  transform: translateY(-0.5em);
  text-transform: capitalize;
  border-radius: 0.5em;
  background: var(--default);
  overflow: hidden;
}

.chainDropdown .dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0em);
}

.chain:first-child {
  margin-top: 0.25em;
}

.chain {
  padding: 0.5em 1em;
  border-bottom: 1px solid var(--outline);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  background: var(--default);
  position: relative;
}

.chain.inActive {
  cursor: var(--cursor-default);
  color: var(--outline);
}

.chain.inActive:hover::after {
  content: "coming soon";
  position: absolute;
  left: 5em;
  font-size: 0.56rem;
  font-weight: 500;
  color: white;
  background: #ebba4f;
  padding: 0.25em 1em;
}

.chain:hover {
  background: var(--main-lightest);
}

.chainImg {
  width: 2em;
  height: 2em;
}

.chain:last-child {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.chainDropdown .dropdown img {
  width: 1.4em;
  height: 1.4em;
}

.dropdownIcon {
  transition: all 250ms;
  fill: #8c93a3;
}

.dropdownIcon.active {
  transform: rotate(180deg);
}

@media screen and (max-width: 540px) {
  .selectedChain > div {
    gap: 0.5em;
  }

  .selectedChain {
    padding: 0.5em;
  }

  .chainImg {
    width: 1.2em;
    height: 1.2em;
  }

  .dropdownIcon {
    width: 1em;
  }
  .chainDropdown .dropdown {
    width: 12em;
    left: 1em;
  }
}
