.card {
  width: 100%;
  border-radius: 0.5em;
  border: 1px solid var(--outline);
  transition: all 300ms;
  max-width: 32em;
  height: 28rem;
}

.card:hover {
  cursor: var(--cursor-pointer);
  transform: translateY(-5px);
  box-shadow: var(--shadow-dark);
}

.imageContainer {
  width: 100%;
  padding: 0.2em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16em;
}

.creator {
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
}
.creator img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
}
.creatoAddress {
  flex-direction: column;
}

.address {
  color: var(--main-color);
}

.imageContainer img {
  object-fit: cover;
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
}

.cardBody {
  width: 100%;
  padding: 0.5em;
  margin-top: 2px;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.collectionName {
  margin-bottom: 0.5em;
  overflow: auto;
}
.listPrice {
  margin-top: 15px;
}
.name {
  margin-bottom: 1em;
  overflow: auto;
}

.usdPrice {
  font-size: 0.7rem;
  font-weight: lighter;
}

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.list {
  font-weight: 500;
  color: gray;
  font-size: 0.75rem;
}

.price {
  color: var(--main-color);
  font-weight: bold;
}
.price img {
  transform: translateY(20%);
  margin-right: 10px;
  width: 25px;
}
.chain {
  font-size: 0.75rem;
}

.notListed {
  font-size: 1rem;
  padding: 0.65em 1em;
  margin-top: 1.5em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.button {
  font-size: 1rem;
  padding: 0.65em 1.5em;
  border-radius: 0.7em;
  background: var(--main-color);
  color: white;
  transition: all 300ms;
}

.button:hover {
  background: var(--main-hovered);
  outline: 2px solid var(--main-color);
}
.buttonSold {
  background-color: #707a83;
  cursor: var(--cursor-default);
}
.buttonSold:hover {
  cursor: var(--cursor-hover);
  background: #707a83;
  outline: none;
}
